<template>
<div id="confirmation-modal">
<modal :name="modalName">
  <div class="modal-header">{{title}}</div>
  <div class="modal-body">
  {{bodyText}}
  </div>
  <div class="modal-footer">
    <button v-on:click="ok()" class="btn btn-primary">{{okText}}</button>
    <button v-on:click="$modal.hide(modalName)"  class="btn btn-secondary">{{cancelText}}</button>
  </div>
  
</modal>
</div>
</template>

<script>
//import axios from "axios";
//import { GlobalStore } from '../globalStore'


export default {
  name: 'confirmation-modal',
  data() {
    return {
      //imagePath: '',
      //custGuid: GlobalStore.custGuid,
      //imageObj: { url: '', description: '', alt_text: ''}
    }
  },
  props: {
    title: String,
    okText: String,
    cancelText: String,
    bodyText: String,
    itemId: null,
    modalName: String
  },
  methods:  {
    ok: function() {
      this.$emit("onConfirm", this.itemId);
    }
  },
  components: {

  },
  async mounted () {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/* Modal */

.v--modal-overlay {
    position: fixed;
    box-sizing: border-box;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 2000;
    opacity: 1;
}

.modal-header { 
  font-size: 16pt;
  color: var(--primary);
}

.modal-body {
  margin-bottom: 70px;
}

.modal-footer {
    position: absolute;
    bottom: 0px !important;
    width: 100%;
    display: inline-block;
    background-color: white;
}




</style>
