<template>
  <div>
    <div class="row" style="margin-top: 5px;margin-bottom:10px">
      <div class="col-6">
        <h3>Users</h3>
      </div>
      <div class="col-6 text-right">
        <ul class="navbar-nav align-items-center d-none d-lg-block">
          <li class="nav-item">
            <form
              class="form-inline search-box"
              onkeydown="return event.key != 'Enter';"
            >
              <input
                @keyup="doSearch(1)"
                @search="doSearch(1)"
                v-model="searchString"
                class="form-control rounded-pill search-input search-user"
                type="search"
                placeholder="Search..."
                aria-label="Search"
              /><span
                class="position-absolute fas fa-search text-400 search-box-icon"
              ></span>
            </form>
          </li>
        </ul>
      </div>
    </div>
    <div class="card mb-3">
      <div class="row" style="margin: 20px 10px 20px 10px;">
        <div class="col-6">
          <div class="btn-group">
            <button
              class="btn dropdown-toggle mb-2 btn-light status-filter"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Status - {{ statusFilter }}
            </button>
            <div class="dropdown-menu">
              <a
                class="dropdown-item"
                href="#"
                @click.prevent="setStatusFilter('All')"
                >All</a
              >
              <div class="dropdown-divider"></div>
              <a
                class="dropdown-item"
                href="#"
                @click.prevent="setStatusFilter('Active')"
                >Active</a
              >
              <a
                class="dropdown-item"
                href="#"
                @click.prevent="setStatusFilter('Inactive')"
                >Inactive</a
              >
            </div>
          </div>
        </div>
        <div class="col-6">
          <a
            style="width: 200px; color: white; margin: 10px;"
            class="btn btn-primary btn-sm float-right"
            @click="addUser()"
            >Add User</a
          >
        </div>
      </div>

      <div class="row">
        <div class="col-12" style="padding-right: 30px;">
          <paginator :pagingInfo="pagingInfo" @selectPage="selectPage" />
        </div>
      </div>
      <table class="table user-table">
        <thead class="thead-dark">
          <tr>
            <th scope="col">Status</th>
            <th scope="col">First</th>
            <th scope="col">Last</th>
            <th class="col" scope="col">Login</th>
            <th class="col" scope="col">Last Active</th>
            <th class="col" scope="col">Stores</th>
            <th class="white-space-nowrap" scope="col">
              &nbsp;
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in filteredUserList" :key="index">
            <td>
              <div v-if="item.active == true" class="green-circle"></div>
              <div v-if="item.active == false" class="red-circle"></div>
            </td>
            <td>{{ item.given_name }}</td>
            <td>{{ item.last_name }}</td>
            <td class="white-space-nowrap">{{ item.login }}</td>
            <td>
              {{
                item.date_last_login.match("19..-")
                  ? "Never"
                  : getFuzzyTime(item.date_last_login)
              }}
            </td>
            <td>{{ getStores(item) }}</td>
            <td>
              <div
                @click="editUser(item)"
                style="width: 20px;cursor: pointer;margin-right: 10px;"
              >
                <i class="fas fa-edit edit-icon"></i>
              </div>
              <!-- <button @click="editUser(item)" class="btn btn-primary">Edit</button> -->
            </td>
          </tr>
        </tbody>
        <div v-if="this.contentLoading">
          <page-loader></page-loader>
        </div>
      </table>
    </div>
    <user-detail-modal
      :mode="mode"
      :item="selectedUser"
      @saveDetails="saveUser"
      ref="userDetailModal"
    />
  </div>
</template>

<script>
// import DataTable from '../components/DataTable'
// import ContentItem from '../components/ContentItem'
// import ConfirmationModal from '../components/modals/ConfirmationModal'
import axios from "axios";
import { GlobalStore } from "../globalStore";
import { GlobalMethods } from "./../globalMethods";
// import { imageMethods } from '../helpers/images'
import pageLoader from "../components/pageLoader";
import { userMethods } from "../helpers/user";
import UserDetailModal from "../components/modals/UserDetailModal";
import Paginator from "../components/Paginator";
// import { GlobalStore } from '../globalStore'

export default {
  name: "users",
  data() {
    return {
      contentLoading: true,
      userList: [],
      filteredUserList: [],
      view: "List",
      selectedUser: null,
      mode: "edit",
      searchString: "",
      statusFilter: "Active",
      isAdminGlobal: false,
      pagingInfo: { current_page: 1 },
      current_page: 1,
      //showPaginator: true,
    };
  },
  props: {
    msg: String,
  },
  methods: {
    setStatusFilter(status) {
      this.statusFilter = status;
      this.doSearch(1);
    },
    selectPage(pageNo) {
      console.log("selectPage: pageNo=", pageNo);
      this.pagingInfo.current_page = pageNo;
      this.current_page = pageNo;
      if (this.searchString == "") {
        this.getItems(this.pagingInfo.current_page);
      } else {
        console.log("DoSearch with currentpage=", this.pagingInfo.current_page);
        this.doSearch(this.pagingInfo.current_page);
      }
    },
    doSearch(pageNo = 1) {
      var tempFilter = []; // this.userList;
      var sString = "*";
      console.log("Searching with pageNo=", pageNo);
      if (this.searchString != "") {
        sString = this.searchString + "*";

        userMethods
          .doUserSearch(25, pageNo, this.statusFilter, sString)
          .then((resp) => {
            console.log("search resp", resp.data);
            //this.userList = resp.data.data;
            //this.showPaginator = false;
            //this.filteredUserList = resp.data;

            this.pagingInfo = resp.data;
            this.pagingInfo["current_page"] = pageNo;
            this.current_page = pageNo;
            console.log(
              "post search pageinfo",
              this.pagingInfo,
              "previous_page",
              this.pagingInfo.previous_page,
              "next_page",
              this.pagingInfo.next_page
            );

            if (this.isAdminGlobal == true) {
              // console.log("Current user is a global admin");
              this.filteredUserList = resp.data.data;
            } else {
              // console.log("Current user is NOT a global admin");
              this.filteredUserList = resp.data.data.filter(
                this.UserIsNotGlobalAdmin
              );
            }
          });
      } else {
        console.log("Empty searchbox");
        this.current_page = 1;
        this.pagingInfo = { current_page: 1 };
        // this.getItems(this.pagingInfo.current_page);
        /// *** Somewhere here need to force the Paginator back to page 1 ***
        this.selectPage(1);
      }

      // if (this.statusFilter == "Active" || this.statusFilter == "Inactive") {
      //   var status = true;
      //   if (this.statusFilter == "Inactive") {
      //     status = false;
      //   } else {
      //     status = true;
      //   }

      //   tempFilter = this.userList.filter((u) => u.active == status);
      // }

      // if (this.searchString != "") {
      //   tempFilter = tempFilter.filter((f) => {
      //     var storeMatch = false;
      //     //var retResult = true;
      //     console.log(f);
      //     //return true;

      //     f.stores.forEach((s) => {
      //       if (s.toLowerCase().startsWith(this.searchString.toLowerCase())) {
      //         storeMatch = true;
      //       }
      //     });

      //     if (
      //       f.given_name
      //         .toLowerCase()
      //         .startsWith(this.searchString.toLowerCase()) ||
      //       f.last_name
      //         .toLowerCase()
      //         .startsWith(this.searchString.toLowerCase()) ||
      //       f.login.toLowerCase().startsWith(this.searchString.toLowerCase()) ||
      //       storeMatch == true
      //     )
      //       return true;
      //   });

      //   // tempFilter = tempFilter.filter(
      //   //   f => {
      //   //     var retResult = true;
      //   //     console.log(f);
      //   //     return true;
      //   //   }
      //   // );
      // }

      //

      this.filteredUserList = tempFilter;
    },
    UserIsNotGlobalAdmin(user) {
      return !user.roles.includes("admin_global");
    },

    getItems(pageNo) {
      this.contentLoading = true;

      // function
      userMethods.getUserList(25, pageNo, this.statusFilter).then((resp) => {
        // console.log(resp);
        this.pagingInfo = resp.data;
        this.showPaginator = true;

        this.contentLoading = false;
        // If the current user is not a tcx user, filter out all the tcx users
        if (this.isAdminGlobal == true) {
          // console.log("Current user is a global admin");
          this.userList = resp.data.data;
        } else {
          // console.log("Current user is NOT a global admin");
          this.userList = resp.data.data.filter(this.UserIsNotGlobalAdmin);
        }

        this.filteredUserList = this.userList;

        // console.log(this.userList);
        // this.userList = this.userList.sort(function(a, b) {
        //   var nameA = a.login.toLowerCase(); // ignore upper and lowercase
        //   var nameB = b.login.toLowerCase(); // ignore upper and lowercase
        //   if (nameA < nameB) {
        //     return -1;
        //   }
        //   if (nameA > nameB) {
        //     return 1;
        //   }
        //   // names must be equal
        //   return 0;
        // });

        //this.userList;
      });
    },
    addUser() {
      this.mode = "add";
      this.$refs["userDetailModal"].isSaving = false;
      GlobalMethods.getCollectionSchema("user", () => {
        this.selectedUser = GlobalStore.apiSchemas["user"].emptyObject;
        this.selectedUser.preferred_contact_method = "email";
        this.selectedUser.active = true;
        window.setTimeout(() => {
          this.$modal.show("user-detail-modal");
          this.$refs["userDetailModal"].initModal();
        }, 250);
      });
    },
    getFuzzyTime(timestamp) {
      return GlobalMethods.fuzzyTime(timestamp);
    },
    getStores(user) {
      var returnString = "";
      var commaStr = "";
      user.stores.forEach((store) => {
        returnString += commaStr + store;
        commaStr = ", ";
      });
      return returnString;
    },
    editUser(item) {
      this.mode = "edit";
      this.$refs["userDetailModal"].isSaving = false;
      // console.log(item);
      this.selectedUser = item;
      if (item.preferred_contact_method == null) {
        item.preferred_contact_method = "email";
      }
      window.setTimeout(() => {
        this.$modal.show("user-detail-modal");
        this.$refs["userDetailModal"].initModal();
      }, 500);
      // console.log("editUser Call");
    },
    saveUser(item, sendNotification = false, closeAction) {
      this.$refs["userDetailModal"].isSaving = true;
      // console.log("closeAction:", closeAction);
      // console.log("item", item);
      // Remove spaces from the mobile number
      item.mobile = item.mobile.replace(" ", "");
      // If alt_email length == 0, null the field
      if (item.alt_email) {
        // console.log('alt_email is something:', item.alt_email);
        if (item.alt_email.length == 0) {
          item.email_verified = false;
          // console.log("nulling alt_email");
          item.alt_email = null;
        }
      } else {
        // console.log('alt_email is GONE:', item.alt_email);
        item.alt_email = null;
        item.email_verified = false;
      }
      // if(item.alt_email === null){
      //   console.log("alt_email is null");
      // }
      if (item.mobile.length == 0) {
        item.mobile_verified = false;
      }
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };
      // console.log('this.mode', this.mode);
      if (this.mode == "edit") {
        axios
          .put(
            GlobalStore.APIEndpoint + "/todaycx-admin/user/" + item.user_id,
            item,
            {
              headers: headers,
            }
          )
          .then((resp) => {
            console.log("PUT completed", resp);
            GlobalStore.toastObj = this.$toasted.show(
              item.login + "'s information has been saved.",
              {
                theme: "outline",
                position: "bottom-center",
                duration: 5000,
                type: "information",
                fitToScreen: true,
                fullWidth: false,
              }
            );
            //this.$router.push(this.return);

            if (closeAction == "close") {
              this.$modal.hide("user-detail-modal");
            } else {
              this.$refs["userDetailModal"].isSaving = false;
            }
            console.log("this.pagingInfo", this.pagingInfo);
            console.log("Trying for currentpage method...");
            console.log("this.current_page", this.current_page);
            if (this.searchString != "") {
              this.doSearch(this.current_page);
            } else {
              this.getItems(this.current_page);
            }
            // this.doSearch();
          })
          .catch((err) => {
            this.loggingIn = false;
            this.$refs["userDetailModal"].isSaving = false;
            GlobalStore.toastObj = this.$toasted.show(
              JSON.stringify(err.response.data.message),
              {
                theme: "outline",
                position: "bottom-center",
                duration: 5000,
                type: "error",
                fitToScreen: true,
                fullWidth: false,
              }
            );
          });
      } else {
        delete item.user_id;
        delete item.password_hashed;

        item.date_last_login = "1970-01-01 12:00:00";
        axios
          .post(
            GlobalStore.APIEndpoint +
              "/todaycx-admin/user?send_notification=" +
              (sendNotification == true ? "true" : "false"),
            item,
            {
              headers: headers,
            }
          )
          .then((resp) => {
            console.log(resp);
            GlobalStore.toastObj = this.$toasted.show(
              item.login + "'s account has been created.",
              {
                theme: "outline",
                position: "bottom-center",
                duration: 5000,
                type: "information",
                fitToScreen: true,
                fullWidth: false,
              }
            );

            this.mode = "edit";
            // console.log(item);
            this.selectedUser = resp.data;

            if (sendNotification == true) {
              axios
                .get(
                  GlobalStore.APIEndpoint +
                    "/todaycx-admin/password_reset_user/?login=" +
                    item.login,
                  item,
                  {
                    headers: headers,
                  }
                )
                .then((resp) => {
                  console.log(resp);
                  GlobalStore.toastObj = this.$toasted.show(
                    "This person has been sent information about setting their password",
                    {
                      theme: "outline",
                      position: "bottom-center",
                      duration: 5000,
                      type: "information",
                      fitToScreen: true,
                      fullWidth: false,
                    }
                  );
                })
                .catch((err) => {
                  GlobalStore.toastObj = this.$toasted.show(
                    JSON.stringify(err.response.data.message),
                    {
                      theme: "outline",
                      position: "bottom-center",
                      duration: 5000,
                      type: "error",
                      fitToScreen: true,
                      fullWidth: false,
                    }
                  );
                });
            }

            if (closeAction == "close") {
              this.$modal.hide("user-detail-modal");
            } else {
              this.$refs["userDetailModal"].isSaving = false;
            }

            this.getItems(this.current_page);
            // this.doSearch();
            // this.$router.push(this.return);
          })
          .catch((err) => {
            this.loggingIn = false;
            var errmsg = "";
            if (err.response.data.message.includes("duplicate key error")) {
              errmsg =
                "This user login/email address already exists. Please try another email address.";
            } else {
              errmsg = JSON.stringify(err.response.data.message);
            }

            this.$refs["userDetailModal"].isSaving = false;

            GlobalStore.toastObj = this.$toasted.show(errmsg, {
              theme: "outline",
              position: "bottom-center",
              duration: 5000,
              type: "error",
              fitToScreen: true,
              fullWidth: false,
            });
          });
      }
    },
  },
  async mounted() {
    //this.checkView(this.$route.params.view);
    this.isAdminGlobal = GlobalStore.userMeObject.roles.includes(
      "admin_global"
    );
    console.log("this.pagingInfo.pagingInfo", this.pagingInfo);
    this.getItems(this.pagingInfo.pagingInfo);
  },
  components: {
    UserDetailModal,
    pageLoader,
    Paginator,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.green-circle {
  background-color: green;
  font-size: 18px;
  width: 15px;
  height: 15px;
  border-radius: 15px;
}

.red-circle {
  background-color: red;
  font-size: 18px;
  width: 15px;
  height: 15px;
  border-radius: 15px;
}

.edit-icon {
  font-size: 20px;
}

.search-user {
  width: 100% !important;
}

.user-table th {
  font-size: 14px;
}

.user-table td {
  font-size: 14px;
}

.user-table td:nth-child(1) {
  min-width: 30px;
}

.user-table td:nth-child(2),
.user-table td:nth-child(3) {
  width: 18%;
}

.user-table td:nth-child(4),
.user-table th:nth-child(4) {
  width: 30%;
}
</style>
