import Vue from "vue";
import axios from "axios";
import { GlobalStore } from "../globalStore";

export const actionMethods = new Vue({
  methods: {
    getActionRequestList: async function(
      pageNo,
      archived,
      query,
      item_type,
      status
    ) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };
      // var status_filter = "";

      // if (status == "Published") {
      //   status_filter = "&publish_status=published";
      // }

      // if (status == "Draft") {
      //   status_filter = "&publish_status=draft";
      // }

      // const headers = {
      //   "Content-Type": "application/x-www-form-urlencoded",
      //   Authorization: "Bearer " + GlobalStore.access_token,
      // };

      // this.loading = true;

      // return axios.get(
      //   GlobalStore.APIEndpoint +
      //     "/todaycx-admin/action_request?per_page=" +
      //     GlobalStore.defaultPageSize +
      //     "&page=" +
      //     pageNo +
      //     status_filter,
      //   {
      //     headers: headers,
      //   }
      // );

      if (query != "") {
        query = (query ? query : "") + "*";

        let optional_filter = "";

        if (item_type != "All") {
          optional_filter = "&item_type=" + item_type;
        }

        return axios.get(
          GlobalStore.APIEndpoint +
            `/todaycx-admin/search/action_request?per_page=${
              GlobalStore.defaultPageSize
            }&page=${pageNo}&is_archived=${
              archived == true ? "true" : "false"
            }&query=${query}&publish_status=${status.toLowerCase()}${optional_filter}`,
          {
            headers: headers,
          }
        );
      } else {
        var status_filter = "";
        var archived_filter = "";
        var query_filter = "";

        if (status == "Published") {
          status_filter = "&publish_status=published";
        }

        if (status == "Draft") {
          status_filter = "&publish_status=draft";
        }

        archived_filter = "&is_archived=" + (archived ? "true" : "false");

        if (query != "") {
          query_filter = "&query=" + query;
        }

        if (item_type != "All") {
          query_filter = "&item_type=" + item_type;
        }

        // return axios.get(
        //   GlobalStore.APIEndpoint +
        //     "/todaycx-admin/top_story?per_page=" +
        //     GlobalStore.defaultPageSize +
        //     "&page=" +
        //     pageNo +
        //     status_filter +
        //     archived_filter +
        //     query_filter,
        //   {
        //     headers: headers,
        //   }
        // );

        return axios.get(
          GlobalStore.APIEndpoint +
            "/todaycx-admin/action_request?per_page=" +
            GlobalStore.defaultPageSize +
            "&page=" +
            pageNo +
            status_filter +
            archived_filter +
            query_filter,
          {
            headers: headers,
          }
        );
      }
    },
    getActionRequestItem: async function(action_request_guid) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      this.loading = true;

      return axios.get(
        GlobalStore.APIEndpoint +
          "/todaycx-admin/action_request/" +
          action_request_guid,
        {
          headers: headers,
        }
      );
    },
    copyActionRequestItem: async function(
      action_request_guid,
      new_item_type = "content"
    ) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      this.loading = true;
      // console.log(action_request_guid)
      return axios.post(
        GlobalStore.APIEndpoint +
          "/todaycx-admin/action_request/" +
          action_request_guid +
          "/?new_item_type=" +
          new_item_type,
        "{}",
        {
          headers: headers,
        }
      );
    },
    getActionRequestResponseDetail: async function(action_request_guid) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      this.loading = true;

      return axios.get(
        GlobalStore.APIEndpoint +
          "/todaycx-admin/action_request/" +
          action_request_guid +
          "/response_detail",
        {
          headers: headers,
        }
      );
    },
    deleteItem: async function(action_request_guid) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      this.loading = true;

      return axios.delete(
        GlobalStore.APIEndpoint +
          "/todaycx-admin/action_request/" +
          action_request_guid,
        {
          headers: headers,
        }
      );
    },
  },
});
