import Vue from "vue";
import axios from "axios";
import { imageMethods } from "./helpers/images";
import { GlobalStore } from "./globalStore";
import { userMethods } from "./helpers/user";

export const GlobalMethods = new Vue({
  methods: {
    /****************************************/
    /*      JWT Session Token Methods       */
    /****************************************/
    checkIfAuthenticated: async function() {
      let isAuth = await this.checkSessionTokens();

      // if (isAuth == true) {

      //     let timeUntilExpiry = GlobalStore.expires_at - this.getEpochSeconds();
      //     //console.log(timeUntilExpiry);
      //     setTimeout(() => {
      //         console.log("Time to refresh!");
      //         this.getRefreshToken();
      //     }, (timeUntilExpiry - 100) * 1000);

      //     //await GlobalMethods.getReferenceData();
      // }

      console.log("checking tokens:" + isAuth);

      //GlobalMethods.expireTimer = setTimeout()
      return isAuth;
    },
    updateSessionTokens: function() {
      this.$session.set("r", GlobalStore.refresh_token);
      this.$session.set("a", GlobalStore.access_token);
      this.$session.set("e", GlobalStore.expires_in);
      this.$session.set("e_a", GlobalStore.expires_at);
    },
    checkSessionTokens: async function() {
      if (
        this.$session.get("r") &&
        this.$session.get("a") &&
        this.$session.get("e")
      ) {
        GlobalStore.refresh_token = this.$session.get("r");
        GlobalStore.access_token = this.$session.get("a");
        GlobalStore.userObject = GlobalMethods.parseJwt(
          GlobalStore.access_token
        );
        GlobalStore.custGuid = GlobalStore.userObject.customer_guid;
        GlobalStore.expires_in = this.$session.get("e");
        GlobalStore.expires_at = this.$session.get("e_a");
        GlobalStore.isAuthenticated = true;
        GlobalStore.view = "portal";

        await this.checkRefreshToken();

        const authheaders = {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + GlobalStore.access_token,
        };

        axios
          .get(
            GlobalStore.APIEndpoint +
              "/todaycx-admin/customer/" +
              GlobalStore.userObject.customer_guid,
            {
              headers: authheaders,
            }
          )
          .then((custResp) => {
            GlobalStore.custObj = custResp.data;

            //console.log(GlobalStore.custObj);

            userMethods.getUserMe().then((userResp) => {
              GlobalStore.userMeObject = userResp.data;
            });
          })
          .catch(() => {
            // GlobalStore.custObj = [];
            // console.log(GlobalStore.custObj);
            // this.$emit("changeView", "portal");
            // this.$router.push("dashboard");
          });

        return true;
      }

      return false;
    },
    clearSession: function() {
      GlobalStore.isAuthenticated == false;
      this.$session.destroy();
    },
    getReferenceData: async function() {
      // const headers = {
      //     "Content-Type": "application/x-www-form-urlencoded",
      //     Authorization:
      //       "Bearer " + this.$session.get("r")
      //       //new Buffer(this.username + ":" + this.password).toString("base64")
      //   };

      //const customerImages = ImageMethods.getImages();

      let customerImages = [];

      imageMethods.getImages(1).then((resp) => {
        customerImages = resp.data.data;

        console.log("*************");
        console.log(customerImages);
        GlobalStore.customerImages = customerImages;
        const total_pages = resp.data.total_pages;

        const promises = [];

        if (total_pages > 1) {
          for (var i = 2; i <= total_pages; i++) {
            promises.push(imageMethods.getImages(i));
          }

          Promise.all(promises).then((resp2) => {
            //console.log(resp2);
            resp2.forEach((pg) => {
              customerImages = customerImages.concat(pg.data.data);
            });
            GlobalStore.customerImages = customerImages;
          });
        }

        //console.log(customerImages);

        //console.log('*************' + total_pages);
      });
    },
    getCollectionSchema(schema, callback) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      //this.loading = true;

      //const availableSchemas = ["top_story","action_response","content","customer","image","product","top_story","user"]

      //const promises = [];

      axios
        .get(GlobalStore.APIEndpoint + "/schema/" + schema, {
          headers: headers,
        })
        .then((r) => {
          GlobalStore.apiSchemas[schema] = r.data;
          GlobalStore.apiSchemas[schema][
            "emptyObject"
          ] = this.generateEmptyCollectionObject(schema);
          callback();
          //console.log(GlobalStore.apiSchemas);
        });

      //   Promise.all(promises).then(resp => {
      //     //console.log(resp);
      //     var i = 0;
      //     resp.forEach(r => {
      //         GlobalStore.apiSchemas[availableSchemas[i]] = r.data;
      //         GlobalStore.apiSchemas[availableSchemas[i]]["emptyObject"] = this.generateEmptyCollectionObject(availableSchemas[i]);
      //         i++;
      //     });

      //     console.log(GlobalStore.apiSchemas);
      //});
    },
    generateEmptyCollectionObject: function(objectType) {
      const objectSchema = GlobalStore.apiSchemas[objectType];
      //console.log(objectSchema);
      const retObj = this.getObjectProperties(objectSchema);

      // for (var property in objectSchema.properties) {

      //     const prop = objectSchema.properties[property];
      //     if (prop.type == 'string') {
      //         retObj[prop.title] = '';
      //     }

      //     if (prop.type == 'array') {
      //         retObj[prop.title] = [];
      //     }

      //     // if (prop.type == 'object') {

      //     //     retObj[prop.title] = {};

      //     //     for (var childproperty in prop.properties) {
      //     //         const childProp = prop.properties[childproperty];

      //     //         console.log(childProp);
      //     //     }

      //     //     //retObj[prop.title]["title"]

      //     //     //retObj[prop.title] = [];

      //     //     //for (var childproperty in property.properties) {

      //     //     //}

      //     //     //retObj[prop.title] = [];
      //     // }

      //     //console.log(objectSchema.properties[property]);
      // }

      return retObj;
    },
    getObjectProperties(objectSchema) {
      //console.log(objectSchema);
      const retObj = {};

      for (var property in objectSchema.properties) {
        const prop = objectSchema.properties[property];
        if (prop.type == "string") {
          retObj[prop.title] = "";
        }

        if (prop.type == "array") {
          retObj[prop.title] = [];
        }

        if (prop.type == "integer") {
          retObj[prop.title] = 0;
        }

        if (prop.type == "number") {
          retObj[prop.title] = 0;
        }

        if (prop.type == "object") {
          retObj[prop.title] = this.getObjectProperties(prop);
        }

        //     for (var childproperty in prop.properties) {
        //         const childProp = prop.properties[childproperty];

        //         console.log(childProp);
        //     }

        //     //retObj[prop.title]["title"]

        //     //retObj[prop.title] = [];

        //     //for (var childproperty in property.properties) {

        //     //}

        //     //retObj[prop.title] = [];
        // }

        //console.log(objectSchema.properties[property]);
      }

      return retObj;
    },
    checkRefreshToken: async function() {
      var secondsBeforeExpiry = GlobalStore.expires_at - this.getEpochSeconds();
      console.log(GlobalStore.expires_at);
      //secondsBeforeExpiry = -10;
      if (secondsBeforeExpiry <= 0) {
        await this.getRefreshToken();
      }
      //console.log('token updated');
    },
    getRefreshToken: async function() {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + this.$session.get("r"),
        //new Buffer(this.username + ":" + this.password).toString("base64")
      };

      var urlencoded = new URLSearchParams();
      urlencoded.append("grant_type", "refresh_token");
      urlencoded.append("expires_in", "7200");

      var tokenResp = await axios.post(
        GlobalStore.APIEndpoint + "/authenticate",
        urlencoded,
        {
          headers: headers,
        }
      );
      //.then(resp => {
      console.log("Refreshed!");
      //console.log(resp);
      if (tokenResp.status === 200) {
        GlobalStore.isAuthenticated = true;
        GlobalStore.refresh_token = tokenResp.data.refresh_token;
        GlobalStore.access_token = tokenResp.data.access_token;
        //GlobalStore.userObject = GlobalMethods.parseJwt(tokenResp.data.access_token);
        //GlobalStore.custGuid = GlobalStore.userObject.customer_guid;
        GlobalStore.expires_in = tokenResp.data.expires_in;
        GlobalStore.expires_at =
          GlobalMethods.getEpochSeconds() + parseInt(tokenResp.data.expires_in);
      }

      GlobalMethods.updateSessionTokens();

      console.log("update tokens");

      //});
    },
    // getCustomerImages: function() {
    //     console.log('getting images')
    // }
    /****************************************/
    /*         Helpers and Utilites         */
    /****************************************/
    getEpochSeconds: function() {
      const now = new Date();
      const secondsSinceEpoch = Math.round(now.getTime() / 1000);
      return secondsSinceEpoch;
    },
    handleAPIerror: function(err) {
      if (err.response.status == 401) {
        GlobalMethods.clearSession();
        this.GlobalStore.isAuthenticated = false;
        this.$router.push("login");
      } else {
        console.log(err);
      }
    },
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function(c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
    getUserStores: function(configStoreList) {
      var retList = [];

      if (GlobalStore.userMeObject.stores.length == 0) {
        retList = configStoreList;
      } else {
        GlobalStore.userMeObject.stores.forEach((f) => {
          retList.push({
            code: f,
            name: f,
            selected: false,
          });
        });
      }

      return retList;
    },
    getDisplayableItemTypes(collection, content_item_type=null) {
      // returns the non system item types for the collection provided
      // if(collection == collection){
      //     console.log(GlobalStore.custObj.config.item_types);
      // }
      var item_types, item_types_licensed = [];
      console.log('collection:', collection);
      console.log('content_item_type:', content_item_type);
      
      if (content_item_type == null){
        item_types = GlobalStore.custObj.config.item_types[collection];
      }else{
        item_types = GlobalStore.custObj.config.item_types[content_item_type];
      }
      //console.log(GlobalStore.userMeObject.roles);
      console.log('item_types:', item_types);
      console.log('licence node:', GlobalStore.custObj.licence);
      if(GlobalStore.custObj.licence.hasOwnProperty('item_types')){
        if(GlobalStore.custObj.licence.item_types.hasOwnProperty(collection)){
          item_types_licensed = GlobalStore.custObj.licence.item_types[collection];
        }else{
          console.log(content_item_type + ' not in licenced item_types')
        }
      }else{
        console.log('item_types not in licence node')
      }
      // If there are any that begin with '@' - check they are licensed
      if(item_types_licensed.length > 0){
        console.log('licensed item_types', item_types_licensed)
      }
      var isAdminGlobal = GlobalStore.userMeObject.roles.includes("admin_global");
      var outList = [];
      item_types.forEach(function(it){
        console.log(it + ' loop.')
        if (it.startsWith('@')) { // licensable
          console.log('type(item_types_licensed): ', item_types_licensed)
          if (item_types_licensed.includes(it)){
            console.log(it + ' is licenced.')
            outList.push(it)
          }
        }else{
          if (!it.startsWith("_") || isAdminGlobal){
            outList.push(it)
          }
        }
      })
      console.log('outList: ', outList);
      return outList
        .sort();
    },
    getAudienceListData(destinationList) {
      var _tempconfigStoreList = [];
      var _tempconfigRoleList = [];

      GlobalStore.custObj.config.stores.sort().forEach((c) => {
        _tempconfigStoreList.push({ code: c, name: c, selected: false });
      });

      GlobalStore.custObj.config.roles.sort().forEach((c) => {
        if (!c.startsWith("admin_")) {
          _tempconfigRoleList.push({ code: c, name: c, selected: false });
        }
      });

      var storeList = this.getUserStores(_tempconfigStoreList);

      var _storeList = [];
      var _roleList = [];

      if (destinationList != null) {
        storeList.forEach((s) => {
          if (!destinationList[4].find((f) => f.name == s.name)) {
            _storeList.push(s);
          }
        });

        _tempconfigRoleList.forEach((r) => {
          if (!destinationList[2].find((f) => f.name == r.name)) {
            _roleList.push(r);
          }
        });
      } else {
        _storeList = storeList;
        _roleList = _tempconfigRoleList;
      }

      return [[], [], _roleList, [], _storeList];
    },
    assertRootURL(url) {
      // asserts a leading slash on the url
      if (url.charAt(0) == "/") return url;
      else return "/" + url;
    },
    formatToLocalTime: function(ts) {
      if (ts) {
        var itemDate = new Date(ts);
        var offset = 0;
        //   console.log(ts.indexOf('+'));
        if (ts.indexOf("+") == -1) {
          // not an ISO string - assume a UTC time
          offset = new Date().getTimezoneOffset();
        }
        var shiftedDate = new Date(itemDate.getTime() - offset * 60000);
        //   console.log(ts + ': ' + itemDate + '; offset=' + offset + '; shifted=' + shiftedDate);
        return shiftedDate.toLocaleString("en-AU");
      }
      return "Unknown";
    },
    fuzzyTime(datestamp) {
      // Make a fuzzy time
      var datestamp_ = Date.parse(datestamp);
      var current_time = new Date();
      // var tzOffset = current_time.getTimezoneOffset()
      // var adjusted_time = current_time.getTime() + (tzOffset * 60 * 1000)
      // console.log(datestamp_ + ": " + adjusted_time + ": " + current_time.getTime())
      var delta = Math.round((current_time - datestamp_) / 1000);

      var minute = 60,
        hour = minute * 60,
        day = hour * 24,
        week = day * 7;

      var fuzzy;
      if (delta < 0) {
        delta = -1 * delta;
        if (delta < 30) {
          fuzzy = "In a few seconds";
        } else if (delta < minute) {
          fuzzy = "In " + delta + " seconds.";
        } else if (delta < 2 * minute) {
          fuzzy = "In a minute";
        } else if (delta < hour) {
          fuzzy = "In " + Math.floor(delta / minute) + " minutes";
        } else if (Math.floor(delta / hour) == 1) {
          fuzzy = "In an hour";
        } else if (delta < day) {
          fuzzy = "In " + Math.floor(delta / hour) + " hours.";
        } else if (delta < day * 2) {
          fuzzy = "Tomorrow";
        } else if (delta < 7 * day) {
          fuzzy = "In " + Math.floor(delta / day) + " days";
        } else if (delta < week * 2) {
          fuzzy = "Next week";
        } else {
          fuzzy = "In " + Math.floor(delta / week) + " weeks";
        }
      } else {
        if (delta < 30) {
          fuzzy = "Just then";
        } else if (delta < minute) {
          fuzzy = delta + " seconds ago.";
        } else if (delta < 2 * minute) {
          fuzzy = "A minute ago";
        } else if (delta < hour) {
          fuzzy = Math.floor(delta / minute) + " minutes ago";
        } else if (Math.floor(delta / hour) == 1) {
          fuzzy = "1 hour ago";
        } else if (delta < day) {
          fuzzy = Math.floor(delta / hour) + " hours ago";
        } else if (delta < day * 2) {
          fuzzy = "Yesterday";
        } else if (delta < 7 * day) {
          fuzzy = Math.floor(delta / day) + " days ago";
        } else if (delta < week * 2) {
          fuzzy = "Last week";
        } else {
          fuzzy = Math.floor(delta / week) + " weeks ago";
        }
      }
      return fuzzy;
    },
  },
});
