import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import { GlobalMethods } from "./globalMethods";
import { GlobalStore } from "./globalStore";

import FYI from "./views/FYI.vue";
import Product from "./views/Product.vue";
import BestSellers from "./views/BestSellers.vue";
import BestStores from "./views/BestStores.vue";
import Memos from "./views/Memos.vue";
import ActionRequests from "./views/ActionRequests.vue";
import ActionRequestDashboard from "./views/ActionRequestDashboard.vue";
import ContentDashboard from "./views/ContentDashboard.vue";
import TopStoryDashboard from "./views/TopStoryDashboard.vue";
import Settings from "./views/Settings.vue";
import UserProfile from "./views/UserProfile.vue";
import Users from "./views/Users.vue";
import MediaLibrary from "./views/MediaLibrary.vue";

import ForgotPassword from "./views/password/forgot_password.vue";
import ForgotPasswordConfirmation from "./views/password/forgot_password_confirmation.vue";
import ForgotPasswordNoSend from "./views/password/forgot_password_no_send.vue";
import ResetPassword from "./views/password/reset_password.vue";

import { VueEditor } from "vue2-editor";

import Dashboard from "./views/dashboard.vue";

import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";
import { faCog, faUser } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import VueAxios from "vue-axios";

import { library } from "@fortawesome/fontawesome-svg-core";
import VueSession from "vue-session";
import Toasted from "vue-toasted";
import VModal from "vue-js-modal";

import ImageUploader from "vue-image-upload-resize";
import UUID from "vue-uuid";

import VueTabs from "vue-nav-tabs";
import "vue-nav-tabs/themes/vue-tabs.css";
Vue.use(VueTabs);

Vue.use(UUID);

Vue.use(ImageUploader);

library.add(faCog, faUser);

//import './custom.scss'
Vue.use(VueAxios, axios);
//Vue.use(BootstrapVue)
Vue.use(VueEditor);
Vue.use(VueRouter);
Vue.use(VueSession);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);
Vue.component("font-awesome-layers-text", FontAwesomeLayersText);
Vue.use(VModal);
Vue.use(Toasted);

require("@/assets/lib/perfect-scrollbar/perfect-scrollbar.css");
require("@/assets/lib/owl.carousel/owl.carousel.css");
require("@/assets/css/theme.css");

Vue.config.productionTip = false;

const router = new VueRouter({
  mode: "history",
  routes: [
    { path: "/login", component: Dashboard },
    { path: "/login/", component: Dashboard },
    { path: "/dashboard", component: Dashboard },
    { path: "/fyi", component: FYI },
    { path: "/fyi/:view", component: FYI },
    { path: "/product", component: Product },
    { path: "/product/:view", component: Product },
    { path: "/bestSellers", component: BestSellers },
    { path: "/bestSellers/:view", component: BestSellers },
    { path: "/bestStores", component: BestStores },
    { path: "/bestStores/:view", component: BestStores },
    { path: "/memos", component: Memos },
    { path: "/memos/:view", component: Memos },
    { path: "/toDo", component: ActionRequests },
    { path: "/toDo/:view", component: ActionRequests },
    { path: "/actionRequestDashboard/:guid", component: ActionRequestDashboard,},
    { path: "/contentDashboard/:guid", component: ContentDashboard,},
    { path: "/topstoryDashboard/:guid", component: TopStoryDashboard,},
    { path: "/mediaLibrary", component: MediaLibrary },
    { path: "/settings", component: Settings },
    { path: "/userProfile", component: UserProfile },
    { path: "/users", component: Users },

    { path: "/forgot_password", component: ForgotPassword },
    {
      path: "/forgot_password_confirmation",
      component: ForgotPasswordConfirmation,
    },
    { path: "/forgot_password_no_send", component: ForgotPasswordNoSend },
    { path: "/reset_password", component: ResetPassword },
  ],
});

router.beforeEach(async (to, from, next) => {
  //await GlobalMethods.checkRefreshToken();

  if (
    !(await GlobalMethods.checkIfAuthenticated()) &&
    to.path.toLowerCase() != "/login" &&
    to.path.toLowerCase() != "/login/" &&
    to.path.toLowerCase() != "/forgot_password" &&
    to.path.toLowerCase() != "/forgot_password/" &&
    to.path.toLowerCase() != "/forgot_password_confirmation" &&
    to.path.toLowerCase() != "/forgot_password_confirmation/" &&
    to.path.toLowerCase() != "/forgot_password_no_send" &&
    to.path.toLowerCase() != "/forgot_password_no_send/" &&
    to.path.toLowerCase() != "/reset_password" &&
    to.path.toLowerCase() != "/reset_password/"
  ) {
    next("/login");
  } else {
    if (
      to.path.toLowerCase() == "/forgot_password" ||
      to.path.toLowerCase() == "/forgot_password/" ||
      to.path.toLowerCase() == "/forgot_password_confirmation" ||
      to.path.toLowerCase() == "/forgot_password_confirmation/" ||
      to.path.toLowerCase() == "/forgot_password_no_send" ||
      to.path.toLowerCase() == "/forgot_password_no_send/" ||
      to.path.toLowerCase() == "/reset_password" ||
      to.path.toLowerCase() == "/reset_password/"
    ) {
      GlobalStore.view = "password";
    }
    console.log(GlobalStore.view);
    next();
  }
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
