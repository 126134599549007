<template>
  <div class="search-filter-box" v-if="custObj != ''">
    <div class="row">
      <div class="col-12">
        <h5 class="mb-0 ml-2 mb-3 d-flex align-items-center">
          Filter & Search
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col-4" v-if="!hideStatus">
        <div class="btn-group">
          <button
            class="btn dropdown-toggle mb-2 btn-light status-filter"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Status - {{ statusFilter }}
          </button>
          <div class="dropdown-menu">
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="setStatusFilter('All')"
              >All</a
            >
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="setStatusFilter('Draft')"
              >Draft</a
            >
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="setStatusFilter('Published')"
              >Published</a
            >
          </div>
        </div>
      </div>
      <div class="search-div" :class="!hideStatus ? 'col-4' : 'col-8'">
        <div class="form-inline search-box">
          <input
            @keyup="doSearch"
            @change="doSearch"
            @search="doSearch"
            v-model="searchString"
            class="form-control search-input"
            type="search"
            placeholder="Search..."
            aria-label="Search"
          /><span
            class="position-absolute fas fa-search text-400 search-box-icon"
          ></span>
        </div>
      </div>
      <div class="paginator-div col-4">
        <paginator :pagingInfo="pagingInfo" @selectPage="selectPage" />
      </div>
    </div>

    <div class="row">
      <div class="col-5">
        <div class="btn-group">
          <button
            class="btn dropdown-toggle mb-2 btn-light status-filter"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Item Type - {{ itemType }}
          </button>
          <div class="dropdown-menu">
            <a
              class="dropdown-item"
              href="#"
              key="All"
              @click.prevent="setItemType('All')"
              >All</a
            >
            <div class="dropdown-divider"></div>
            <a
              v-for="iType in getItemTypes"
              :key="iType"
              class="dropdown-item"
              href="#"
              @click.prevent="setItemType(iType)"
              >{{ capitalizeFirstLetter(iType) }}</a
            >
          </div>
        </div>
      </div>
      <div class="col-4 archived-div">
        <input
          name="showArchived"
          type="checkbox"
          v-model="showArchived"
          @change="showArchivedChanged"
        />
        <label style="margin-left: 20px;" for="showArchived"
          ><span>Archived Items</span></label
        >
      </div>
      <div class="col-3" v-if="!hideExpired">
        <input
          name="showExpired"
          type="checkbox"
          v-model="showExpired"
          @change="showExpiredChanged"
        />
        <label style="margin-left: 20px;" for="showExpired"
          ><span>Show Expired</span></label
        >
      </div>
    </div>
  </div>
</template>
<script>
import Paginator from "./Paginator.vue";
//import axios from "axios";
import { GlobalMethods } from "../globalMethods";

export default {
  name: "searchFilter",
  data() {
    return {
      //imagePath: '',
      //custGuid: GlobalStore.custGuid,
      //imageObj: { url: '', description: '', alt_text: ''}
      searchString: "",
      current_page: null,
      statusFilter: "Published",
      showArchived: false,
      showExpired: false,
      itemType: "All",
      //custObj: GlobalStore.custObj,
    };
  },
  props: {
    pagingInfo: Object,
    custObj: Object,
    listItemType: String,
    willShowExpired: Boolean,
    willShowArchived: Boolean,
    hideStatus: Boolean,
    hideExpired: Boolean,
    //imageSrc: String,
    //className: String
  },
  methods: {
    setItemType: function(type) {
      this.itemType = type;
      this.$emit("setItemType", type);
    },
    showExpiredChanged: function(e) {
      //console.log(e.target.checked);
      this.$emit("showExpired", e.target.checked);
    },
    showArchivedChanged: function(e) {
      //console.log(e.target.checked);
      this.$emit("showArchived", e.target.checked);
    },
    doSearch: function() {
      console.log(this.searchString);
      this.$emit("setSearchString", this.searchString);
    },
    setStatusFilter: function(status) {
      this.statusFilter = status;
      this.$emit("setStatusFilter", status);
    },
    selectPage: function(pageNo) {
      this.$emit("selectPage", pageNo);
      this.current_page = pageNo;
    },
    getCurrentPage() {
      if (this.current_page == null) {
        this.current_page = this.pagingInfo.current_page;
      }
      return this.current_page;
    },
    prevPage() {
      if (this.current_page > 1) {
        this.selectPage(this.current_page - 1);
      }
    },
    nextPage() {
      if (this.current_page < this.pagingInfo.total_pages) {
        this.selectPage(this.current_page + 1);
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  computed: {
    getItemTypes() {
      const itemTypes = GlobalMethods.getDisplayableItemTypes(
        this.listItemType
      );
      // console.log("SearchFilter getItemTypes: (" + this.listItemType + ")", itemTypes)
      return itemTypes;
    },
  },
  components: { Paginator },
  async mounted() {
    //console.log(GlobalStore.custObj);
    if (this.willShowArchived) {
      this.showArchived = this.willShowArchived;
    }
    if (this.willShowExpired) {
      this.showExpired = this.willShowExpired;
    }
    this.setItemType('content'); // set the default to content
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.search-filter-box {
  border-style: solid;
  border-color: lightgrey;
  border-width: 1px;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 0px;
  background-color: #f7f7f7;
}

@media (max-width: 1000px) {
  .search-box .search-input {
    width: 75%;
    padding-left: 1.95rem;
  }
}

@media (max-width: 767px) {
  .search-div {
    max-width: 100%;
    flex: 0 0 100%;
  }

  .search-box .search-input {
    width: 100%;
    padding-left: 1.95rem;
  }

  .paginator-div {
    width: 100%;
    margin-top: 20px;
    margin-left: 0px;
  }

  .paginator-div nav {
    float: left !important;
  }

  .archived-div {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

@media (max-width: 320px) {
  .search-filter-box {
    padding: 20px 0px 0px 0px;
  }
  .search-box .search-input {
    width: 90%;
    padding-left: 1.95rem;
  }
}
</style>
