<template>
  <div id="media-library-modal" >
    <modal name="media-library-modal">
      <button @click="$modal.hide('media-library-modal')" class="modal-area">x</button>
      <div class="modal-body"  >
        <media-library-component :useAsImageChooser="true" @useImage="selectedFromLibrary" @addImage="addImage" />

      </div>
      <!-- <div class="modal-footer">
        <button v-on:click="ok()" class="btn btn-primary">Save</button>
        <button v-on:click="$modal.hide('media-library-modal')" class="btn btn-secondary">Cancel</button>
      </div> -->
    </modal>
  </div>
</template>

<script>
//import axios from "axios";
//import { GlobalStore } from "../../globalStore";
//import { imageMethods } from '../../helpers/images'
import MediaLibraryComponent from '../MediaLibraryComponent';

export default {
  name: "media-library-modal",
  data() {
    return {
      //imageBlobEndpoint: GlobalStore.imageBlobEndpoint
      //imagePath: '',
      //custGuid: GlobalStore.custGuid,
      //imageObj: { url: '', description: '', alt_text: ''}
    };
  },
  props: {
    //title: String,
    //item: null,
    //modalName: String
  },
  methods: {
    ok: function() {
      this.$emit("saveDetails", this.item);      
    },
    selectedFromLibrary(imageObj) {
      //console.log(imageObj);
      this.$modal.hide('media-library-modal');
      this.$emit("useImage", imageObj);
    },
    addImage(imageObj) {
      //console.log(imageObj);
      this.$emit("addImage", imageObj);  
    }

  },
  components: {
    MediaLibraryComponent
  },
  async mounted() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Modal */

.modal-area {
  right: 0px;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  position: absolute;
  height: 30px;
  width: 30px;
  background-color: lightgray;
  border: none;
  border-bottom-left-radius: 50%;
}

.v--modal-overlay {
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2000;
  opacity: 1;
}

.modal-header {
  font-size: 16pt;
  color: var(--primary);
}

.modal-body {
  margin-bottom: 70px;
}

.modal-footer {
  position: absolute;
  bottom: 0px !important;
  width: 100%;
  display: inline-block;
  background-color: white;
}

.medialibrary-image-details {
  width: 100%;
  height: 240px;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center !important;
}
</style>
