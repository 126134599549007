
import Vue from 'vue'
//import axios from "axios";
//import { GlobalStore } from '../global'

  export const graphMethods = new Vue({
    methods: {

    renderColumnGraph: function(ref ,data) {
       
      setTimeout(() => {

        //var data = [60, 200, 150, 80, 70, 110, 120]; // Max value of data
    
        var yMax = Math.max.apply(Math, data);
        var dataBackground = data.map(function () {
          return yMax;
        });
    
        var _chart = window.echarts.init(ref); // Default options
    
    
        var _defaultOptions = {
          tooltip: {
            trigger: 'axis',
            padding: [7, 10],
            formatter: '{b1}: {c1}',
            backgroundColor: 'white',
            borderColor: 'lightGray',
            borderWidth: 1,
            textStyle: {
              color: 'darkGray'
            },
            transitionDuration: 0,
            // position: function position(pos, params, dom, rect, size) {
            //   return getPosition(pos, params, dom, rect, size);
            // }
          },
          xAxis: {
            type: 'category',
            data: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            boundaryGap: false,
            axisLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisPointer: {
              type: 'none'
            }
          },
          yAxis: {
            type: 'value',
            splitLine: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisPointer: {
              type: 'none'
            }
          },
          series: [{
            type: 'bar',
            barWidth: '5px',
            barGap: '-100%',
            itemStyle: {
              color: 'lightGray',
              barBorderRadius: 10
            },
            data: dataBackground,
            animation: false,
            emphasis: {
              itemStyle: {
                color: 'lightGray'
              }
            }
          }, {
            type: 'bar',
            barWidth: '5px',
            itemStyle: {
              color: '#2c7be5',
              barBorderRadius: 10
            },
            data: data,
            emphasis: {
              itemStyle: {
                color: 'lightGray'
              }
            },
            z: 10
          }],
          grid: {
            right: 5,
            left: 10,
            top: 0,
            bottom: 0
          }
        }; // Merge user options with lodash
    
           _chart.setOption(_defaultOptions);
    
    }, 2000);

    },
    renderBarGraph: function(ref, data) {

      //var $echartBarTopProducts = document.querySelector(Selector.ECHART_BAR_TOP_PRODUCTS);
      console.log(data);

      //if ($echartBarTopProducts) {
        

        var _data = data.chartData;
    
        //var _$this3 = $($echartBarTopProducts);
    
        //var _userOptions3 = _$this3.data('options');
    
        var _chart3 = window.echarts.init(ref);
    
        var _defaultOptions3 = {
          color: ['#3fa535', '#f8d440', '#f8d440'],
          dataset: {
            source: _data
          },
          tooltip: {
            trigger: 'item',
            padding: [7, 10],
            backgroundColor: 'white',
            borderColor: 'gray',
            borderWidth: 1,
            textStyle: {
              color: 'darkGray'
            },
            transitionDuration: 0,
            // position: function position(pos, params, dom, rect, size) {
            //   return getPosition(pos, params, dom, rect, size);
            // },
            formatter: function formatter(params) {
              return "<div class=\"font-weight-semi-bold\">" + params.seriesName + "</div><div class=\"fs--1 text-600\"><strong>" + params.name + ":</strong> " + params.value[params.componentIndex + 1] + "</div>";
            }
          },
          legend: {
            data: data.legend,
            left: 'left',
            itemWidth: 10,
            itemHeight: 10,
            borderRadius: 0,
            icon: 'circle',
            inactiveColor: 'gray',
            textStyle: {
              color: 'gray'
            }
          },
          xAxis: {
            type: 'category',
            axisLabel: {
              color: '#404040',
              fontSize: 8,
            },
            axisLine: {
              lineStyle: {
                color: 'gray',
                type: 'dashed'
              }
            },
            axisTick: false,
            boundaryGap: true
          },
          yAxis: {
            axisPointer: {
              type: 'none'
            },
            axisTick: 'none',
            splitLine: {
              lineStyle: {
                color: 'gray',
                type: 'dashed'
              }
            },
            axisLine: {
              show: false
            },
            axisLabel: {
              color: 'darkGray',
              fontSize: 8
            }
          },
          series: [{
            type: 'bar',
            barWidth: '22%',
            barGap: '20%',
            label: {
              normal: {
                show: false
              }
            },
            z: 50,
            itemStyle: {
              normal: {
                barBorderRadius: [10, 10, 0, 0],
                color: '#3fa535'
              }
            }
          }, {
            type: 'bar',
            barWidth: '22%',
            barGap: '20%',
            label: {
              normal: {
                show: false
              }
            },
            itemStyle: {
              normal: {
                barBorderRadius: [4, 4, 0, 0],
                color: '#f8d440'
              }
            }
          }, {
          type: 'bar',
          barWidth: '22%',
          barGap: '20%',
          label: {
            normal: {
              show: false
            }
          },
          itemStyle: {
            normal: {
              barBorderRadius: [10, 10, 0, 0],
              color: '#ff7f27'
            }
          }
        }],
          grid: {
            right: '0',
            left: '30px',
            bottom: '10%',
            top: '20%'
          }
        };
    
        //var _options3 = window._.merge(_defaultOptions3, _userOptions3);
    
        //_chart3.setOption(_options3);
        _chart3.setOption(_defaultOptions3);
      //} //



    },
    renderPieGraph: function(ref , data) {

        setTimeout(() => {

        var _chart4 = window.echarts.init(ref);

        var _defaultOptions4 = {
          color: ['#3fa535', 'lightGray'],
          tooltip: {
            trigger: 'item',
            padding: [7, 10],
            backgroundColor: 'white',
            textStyle: {
              color: 'lightGray'
            },
            transitionDuration: 0,
            borderColor: 'lightGray',
            borderWidth: 1,
            formatter: function formatter(params) {
              return "<strong>" + params.data.name + ":</strong> " + params.percent + "%";
            }
          },
          //position: function position(pos, params, dom, rect, size) {
            //return getPosition(pos, params, dom, rect, size);
          //},
          legend: {
            show: false
          },
          series: [{
            type: 'pie',
            radius: ['100%', '70%'],
            avoidLabelOverlap: false,
            hoverAnimation: false,
            itemStyle: {
              borderWidth: 0,
              borderColor: 'White'
            },
            label: {
              normal: {
                show: false,
                position: 'center',
                textStyle: {
                  fontSize: '20',
                  fontWeight: '500',
                  color: 'lightGray'
                }
              },
              emphasis: {
                show: false
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: data
          }]
        };

        //var _options4 = window._.merge(_defaultOptions4, _userOptions4);

        _chart4.setOption(_defaultOptions4);
      } //
      , 1000);
  
    }

  }
  
})