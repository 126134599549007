var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card mb-3"},[_vm._m(0),(_vm.custObj)?_c('ImportImage',{attrs:{"pagingInfo":_vm.pagingInfo,"useAsImageChooser":_vm.useAsImageChooser},on:{"selectPage":_vm.selectPage,"useImage":_vm.useImage}}):_vm._e(),(_vm.custObj && _vm.itemList)?_c('searchFilter',{attrs:{"pagingInfo":_vm.pagingInfo,"listItemType":"image","hideStatus":"","hideExpired":"","custObj":_vm.custObj},on:{"selectPage":_vm.selectPage,"setItemType":_vm.setItemType,"showArchived":_vm.showArchived,"setSearchString":_vm.setSearchString}}):_vm._e(),(this.contentLoading)?_c('div',[_c('page-loader')],1):_vm._e(),_c('div',{staticClass:"row no-gutters"},_vm._l((_vm.itemList),function(item,index){return _c('div',{key:index,class:{
          'col-md-2 col-xxl-2 mb-2 pr-md-2': _vm.useAsImageChooser,
          'col-md-3 col-xxl-3 mb-3 pr-md-3': !_vm.useAsImageChooser,
        }},[_c('div',{staticClass:"card medialibrary-image",attrs:{"alt_text":item.alt_text,"title":item.description +
              '\nWidth: ' +
              (item.width ? item.width : '0') +
              'px\nHeight: ' +
              (item.height ? item.height : '0') +
              'px'},on:{"click":function($event){_vm.useAsImageChooser ? _vm.useImage(item) : null}}},[_c('tcx-image',{attrs:{"className":"img-fluid","imageSrc":item.thumbnail_url ? item.thumbnail_url : item.url,"aspectBlur":true}}),_c('div',[_c('div',{staticClass:"card-body",attrs:{"alt":item.thumbnail_alt_text,"title":item.thumbnail_alt_text}},[(item.sku.code.trim().length > 0 || item.sku.variation.trim().length > 0)?_c('span',{staticClass:"badge sku pull-right hidden-xs"},[_vm._v(_vm._s(_vm.abbrSkuString(item.sku.code))+"/"+_vm._s(_vm.abbrSkuString(item.sku.variation)))]):_vm._e(),(item.is_archived)?_c('span',{staticClass:"badge archived pull-right hidden-xs"},[_vm._v("archived")]):_vm._e(),(item.item_type)?_c('span',{staticClass:"badge item_type pull-right hidden-xs"},[_vm._v(_vm._s(item.item_type))]):_vm._e(),_c('div',{staticClass:"info-button",on:{"click":function($event){return _vm.openDetails(item)}}},[_c('i',{staticClass:"fas fa-info-circle"})]),_c('div',{staticClass:"delete-button",on:{"click":function($event){return _vm.confirmDelete(item)}}},[_c('i',{staticClass:"fa fa-trash"})])])])],1)])}),0)],1),_c('confirmation-modal',{attrs:{"modalName":"image-delete-modal","itemId":_vm.selectedItem && _vm.selectedItem.image_guid,"title":"Delete Image","bodyText":"Are you sure you want to delete this image?","okText":"Delete","cancelText":"Cancel"},on:{"onConfirm":_vm.deleteItem}}),_c('image-detail-modal',{attrs:{"item":_vm.selectedItem},on:{"saveDetails":_vm.saveItem}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h3',[_vm._v("Media Library")])])])}]

export { render, staticRenderFns }