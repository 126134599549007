<template>
  <div>
    <landing-page @changeView="changeView($event)" v-if="view == 'landing'"></landing-page>
    <portal-main @changeView="changeView($event)" v-if="view == 'portal'"></portal-main>
    <password v-if="view == 'password'"></password>
  </div>
</template>

<script>
import { GlobalStore } from './globalStore'
//import { GlobalMethods } from './globalMethods'
import LandingPage from './masterPages/LandingPage'
import PortalMain from './masterPages/PortalMain'
import Password from './masterPages/Password'

export default {
  name: 'app',
  data: function ()  {
    return {
      localStore: GlobalStore.isAuthenticated,
      
      username: "",
      password: "",
    }
  },
  computed: {
    view: function() { 
      return GlobalStore.view
    }
  },
  methods:  {  
      changeView: function (view) {
        this.view = view;
        GlobalStore.view = view;
      }
  },
  components: {
    LandingPage,
    PortalMain,
    Password
  },
  async mounted () {
    //if (GlobalStore.isAuthenticated) {
      //GlobalMethods.getReferenceData();
    //}
  }
}
</script>

<style>

.ql-editor p {
    margin-bottom: 0.5rem !Important;
}

#fileInput {
  display: none;
}

#confirmation-modal .v--modal-overlay .v--modal-box {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    top: 195px;
    left: 170px;
    width: 600px;
    height: 200px !Important;
}

#image-detail-modal .v--modal-overlay .v--modal-box {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    top: 100px !important;
    left: 170px !important;
    width: 80% !important;
    height: 600px !important;
}

#product-search-modal .v--modal-overlay .v--modal-box {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    top: 100px !important;
    left: 170px;
    width: 600px;
    height: 620px !Important;
}

#user-detail-modal .v--modal-overlay .v--modal-box {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    top: 20px !important;
    /* left: 40% !Important; */
    width:90% !important;
    left: calc(50% - 45%) !important;
    height: fit-content !important;
}

#media-library-modal .v--modal-overlay .v--modal-box {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    top: 20px !important;
    /* left: 40% !Important; */
    width:90% !important;

    left: calc(50% - 45%) !important;
    height: 90vh !important;
}

.vue-tabs .nav > li span.title {
    font-weight: bold;
    font-size:10pt;
}

#media-library-modal .v--modal-box {
  overflow-y: scroll !important;
}

</style>

<style lang="scss">
  @import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
</style>
