<template>
   <div>
    <div class="actionRequestDashboard card mb-3" v-if='dataLoaded && selectedRequestItem'>

        <div class="row no-gutters ml-0">
          <h1 class="col-10">{{actionRequestLabel}} dashboard</h1>
          <button class="back-button col-2 btn btn-primary float-right" type="button" @click="retreatPage()">
            <i class="fas fa-chevron-left"></i>&nbsp;Back
          </button>
        </div>

        <h3>{{actionRequestTitle}}</h3>
        <!-- {{selectedRequestItem.action_request_guid}} -->
      <div class="mb-12 pl-md-0 pr-md-2" style="margin-bottom:20px;">
        <div class="card h-md-100">
          <div class="card-header d-flex flex-between-center pb-0">
            <h6 class="col-xs-4 mb-0">{{selectedRequestItem.title}}
              <span
                class="ml-1 text-400"
                data-toggle="tooltip"
                data-placement="top"
                :title="'This shows a summary of responses to the ' + this.actionRequestLabel">
                <span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
              </span>
            </h6>
          </div>
          <div class="card-body p-1">
            <div class="container mb-3 fs--1">
              <div class="row">
                <div class="page-thumbnail">
                  <tcx-image
                    className="roudned border border-200"
                    :imageSrc="selectedRequestItem.images.thumbnail_image_url"
                    :aspectBlur="true"
                  />
                </div>
                <div class="col-xl-4 col-lg-4 col-md-3 col-sm-12">
                  <div class="media align-items-top position-relative">
                    <div class="media-body ml-sm-0 ml-xl-3">
                      <h6 class="mb-1 font-weight-semi-bold">
                        Due by:
                        <!-- <a class="text-dark stretched-link" href="#!">Raven Pro</a> -->
                      </h6>
                      <p class="mb-0 text-500">{{fuzzyTime(selectedRequestItem.due_by)}}</p>
                      <h6 class="mb-1 font-weight-semi-bold">
                        Type:
                        <!-- <a class="text-dark stretched-link" href="#!">Raven Pro</a> -->
                      </h6>
                      <p class="mb-0 text-500">{{actionRequestMode}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div class="media align-items-top position-relative">
                    <div class="media-body">
                      <h6 class="mb-1 font-weight-semi-bold">
                        Summary:
                        <!-- <a class="text-dark stretched-link" href="#!">Raven Pro</a> -->
                      </h6>
                      <p class="mb-0 text-500">{{selectedRequestItem.summary}}</p>
                      <h6 class="mb-1 font-weight-semi-bold">
                        {{actionRequestLabel}} text:
                        <!-- <a class="text-dark stretched-link" href="#!">Raven Pro</a> -->
                      </h6>
                      <p class="font-weight-normal mb-0 text-500"><span v-html="selectedRequestItem.body_html"></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="additionalImages.length > 0" class="mb-3 pl-md-0 pr-md-2">
        <div class="card h-md-100">
          <div class="card-header pb-0">
            <h6>Additional Image{{(additionalImages.length == 1) ? "" : "s"}}</h6>
          </div>
          <div class="card-body row pt-0">
            <div class="pl-1" @click="openModal(image)" v-for="image in additionalImages" :key="image">
              <div class="img-tiles">
                <tcx-image
                    className="rounded border border-200"
                    :imageSrc="image"
                    :aspectBlur="true"
                />
              </div> 
            </div>
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <!-- Top Summary Counts -->
        <div class="col-md-2 col-xxl-2 mb-3 pr-md-2">
          <div class="card h-md-100">
            <div class="card-header pb-0">
              <h6 class="mb-0 mt-2 d-flex align-items-center">
                Audience
                <span
                  class="ml-1 text-400"
                  data-toggle="tooltip"
                  data-placement="top"
                  :title="'The people and stores who can see this ' + this.actionRequestLabel"
                >
                  <span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
                </span>
              </h6>
            </div>
            <div class="card-body d-flex align-items-end">
              <div class="row flex-grow-1 ml-0">
                <div v-if="actionRequestMode=='user'" class="col">
                  <div
                    class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1"
                  >{{audienceUsers.length}}</div>
                  <div
                    class="fs1 font-weight-normal text-sans-serif text-500 line-height-1 mb-1"
                  >people</div>
                </div>
                <div  v-if="actionRequestMode=='store'" class="col">
                  <div
                    class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1"
                  >{{this.audienceStores.audience ? this.audienceStores.audience.stores.length : 0}}</div>
                  <div
                    class="fs1 font-weight-normal text-sans-serif text-500 line-height-1 mb-1"
                  >stores</div>
                </div>
                <!-- <div class="col-auto pl-0">
                  <div ref="chart3" class="echart-bar-weekly-sales h-100"></div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-xxl-2 mb-3 pr-md-2">
          <div class="card h-md-100">
            <div class="card-header pb-0">
              <h6 class="mb-0 mt-2 d-flex align-items-center">
                Responses
                <span
                  class="ml-1 text-400"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="The number of responses so far."
                >
                  <span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
                </span>
              </h6>
            </div>
            <div class="card-body d-flex align-items-end">
              <div class="row flex-grow-1 ml-0">
                <div v-if="actionRequestMode=='user'" class="col">
                  <div
                    class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1"
                  >{{responsesUsers.length}}
                  </div>
                  <div
                    class="fs1 font-weight-normal text-sans-serif text-500 line-height-1 mb-1"
                  >people</div>
                </div>
                <div v-if="actionRequestMode=='store' && unrespondedStores.length>0" class="col">
                  <div
                    class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1">{{unrespondedStores[0].responded_stores ? unrespondedStores[0].responded_stores.filter(String).length : 0}}
                    <!-- <span
                    class="ml-1 text-400"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    v-bind:title="responsesStores">
                    <span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
                    </span> -->
                  </div>
                  <div
                    class="fs1 font-weight-normal text-sans-serif text-500 line-height-1 mb-1"
                  >stores</div>
                </div>
                <!-- <div class="col-auto pl-0">
                  <div ref="chart4" class="echart-bar-weekly-sales h-100"></div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2 col-xxl-2 mb-3 pr-md-2">
          <div class="card h-md-100">
            <div class="card-header pb-0">
              <h6 class="mb-0 mt-2 d-flex align-items-center">
                Yet to respond
                <span
                  class="ml-1 text-400"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="The people or stores who have not responded yet."
                >
                  <span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
                </span>
              </h6>
            </div>
            <div class="card-body d-flex align-items-end">
              <div class="row flex-grow-1 ml-0">
                <div v-if="actionRequestMode=='user' && unrespondedUsers.length>0" class="col">
                  <div
                    class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1"
                  >{{unrespondedUsers[0].remaining_users.length}}
                    <!-- <span
                    class="ml-1 text-400"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    v-bind:title="audienceUsers.length">
                    <span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
                    </span> -->
                  </div>
                  <div
                    class="fs1 font-weight-normal text-sans-serif text-500 line-height-1 mb-1"
                  >people
                  </div>
                </div>
                <div v-if="actionRequestMode=='store' && unrespondedStores.length>0" class="col">
                  <div
                    class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1"
                  >{{this.remainingStores.length}}</div>
                  <div
                    class="fs1 font-weight-normal text-sans-serif text-500 line-height-1 mb-1"
                  >stores</div>
                </div>
                <!-- <div class="col-auto pl-0">
                  <div ref="chart5" class="echart-bar-weekly-sales h-100"></div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-xxl-6 mb-3 pl-md-0 pr-md-2" style="margin-bottom:20px;">
          <div class="card h-md-100">
            <div class="card-body">
              <div class="row h-100 justify-content-between no-gutters">
                <div class="col-4 col-sm-4 col-xxl-4 pr-2">
                  <h6 class="mt-1">Progress<span
                    class="ml-1 text-400"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="This the percentage complete.">
                    <span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
                    </span>
                  </h6>
                  <div class="fs--2 mt-3">
                    <div class="d-flex flex-between-center mb-1">
                      <div class="d-flex align-items-center">
                        <span class="font-weight-semi-bold">Expires: {{formatToLocalTime(selectedRequestItem.display.expires)}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-4 pr-2">
                   <div ref="donut" class="echart-doughnut absolute-centered"></div>
                  <!-- <div class="absolute-centered font-weight-light text-dark fs-2">{{progressPercent}}%</div> -->
                  <div class="absolute-centered fs-2 font-weight-light text-sans-serif line-height-1 mb-1">{{(progressPotential) ? progressPercent + "%" : "N/A"}}</div>
                  <!-- <div
                    class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1">{{progressPercent}}%</div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Content Counts -->
      </div>
      <!-- Start of Audience Detail section -->
      <h3>Audience Information</h3>

      <div class="card mb-3 mr-2">
        <div class="card-header" id="audience">
          <h5 class="mb-0">
            <button class="btn btn-outline-secondary mb-0 mt-2 d-flex collapsed" data-toggle="collapse" data-target="#collapseAudience" aria-expanded="false" aria-controls="collapseAudience">
              Who can see this item
            </button>
          </h5>
        </div>

        <div id="accordion">
          <div id="collapseAudience" class="collapse" aria-labelledby="audience" data-parent="#accordion">
            <div class="card-body">
              <div class="container">
                <div class="row" v-if="actionRequestMode == 'user'">
                  <div class="col-md">
                    <div v-for="(item) in arrayToColumns(this.audienceUsers, 1,3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item.audience_users.display_name}} - <b>{{item.audience_users.store_summary}}</b>
                    </div>
                  </div>
                  <div class="col-md">
                    <div v-for="(item) in arrayToColumns(this.audienceUsers, 2, 3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item.audience_users.display_name}} - <b>{{item.audience_users.store_summary}}</b>
                    </div>
                  </div>
                  <div class="col-md">
                    <div v-for="(item) in arrayToColumns(this.audienceUsers, 3,3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item.audience_users.display_name}} - <b>{{item.audience_users.store_summary}}</b>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="actionRequestMode == 'store'">
                  <div class="col-md" v-if="this.audienceStores.audience">
                    <div v-for="(item) in arrayToColumns(this.audienceStores.audience.stores, 1, 3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item}}
                    </div>
                  </div>
                  <div class="col-md" v-if="this.audienceStores.audience">
                    <div v-for="(item) in arrayToColumns(this.audienceStores.audience.stores, 2, 3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item}}
                    </div>
                  </div>
                  <div class="col-md" v-if="this.audienceStores.audience">
                    <div v-for="(item) in arrayToColumns(this.audienceStores.audience.stores, 3, 3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingTwo">
            <h5 class="mb-0">
              <button class="btn btn-outline-secondary mb-0 mt-2 d-flex collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Who has responded so far
              </button>
            </h5>
          </div>
          <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
            <div class="card-body">
              <div class="container">
                <div class="row" v-if="actionRequestMode == 'user' && this.unrespondedUsers.length>0">
                  <div class="col-md">
                    <div v-for="(item) in arrayToColumns(this.unrespondedUsers[0].responded_users, 1,3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item.display_name}} - <b>{{item.store_summary}}</b>
                    </div>
                  </div>
                  <div class="col-md">
                    <div v-for="(item) in arrayToColumns(this.unrespondedUsers[0].responded_users, 2, 3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item.display_name}} - <b>{{item.store_summary}}</b>
                    </div>
                  </div>
                  <div class="col-md">
                    <div v-for="(item) in arrayToColumns(this.unrespondedUsers[0].responded_users, 3,3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item.display_name}} - <b>{{item.store_summary}}</b>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="actionRequestMode == 'store' && this.unrespondedStores.length>0">
                  <div class="col-md" >
                    <div v-for="(item) in arrayToColumns(this.unrespondedStores[0].responded_stores.filter(String).sort(), 1, 3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item}}
                    </div>
                  </div>
                  <div class="col-md" >
                    <div v-for="(item) in arrayToColumns(this.unrespondedStores[0].responded_stores.filter(String).sort(), 2, 3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item}}
                    </div>
                  </div>
                  <div class="col-md" >
                    <div v-for="(item) in arrayToColumns(this.unrespondedStores[0].responded_stores.filter(String).sort(), 3, 3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingThree">
            <h5 class="mb-0">
              <button class="btn btn-outline-secondary mb-0 mt-2 d-flex collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Who is yet to respond
              </button>
            </h5>
          </div>
          <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
            <div class="card-body">
              <div class="container">
                <div class="row" v-if="actionRequestMode == 'user' && unrespondedUsers.length>0">
                  <div class="col-sm">
                    <div v-for="(item) in arrayToColumns(this.unrespondedUsers[0].remaining_users, 1,3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item.display_name}} - <b>{{item.store_summary}}</b>
                    </div>
                  </div>
                  <div class="col-md">
                    <div v-for="(item) in arrayToColumns(this.unrespondedUsers[0].remaining_users, 2, 3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item.display_name}} - <b>{{item.store_summary}}</b>
                    </div>
                  </div>
                  <div class="col-md">
                    <div v-for="(item) in arrayToColumns(this.unrespondedUsers[0].remaining_users, 3,3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item.display_name}} - <b>{{item.store_summary}}</b>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="actionRequestMode == 'store' && unrespondedStores.length>0">
                  <div class="col-md" v-if="this.unrespondedStores">
                    <div v-for="(item) in arrayToColumns(this.remainingStores.filter(String).sort(), 1, 3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item}}
                    </div>
                  </div>
                  <div class="col-md" v-if="this.unrespondedStores">
                    <div v-for="(item) in arrayToColumns(this.remainingStores.filter(String).sort(), 2, 3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item}}
                    </div>
                  </div>
                  <div class="col-md" v-if="this.unrespondedStores">
                    <div v-for="(item) in arrayToColumns(this.remainingStores.filter(String).sort(), 3, 3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Start of Response summary section -->
        <h3 v-if="responseDetail.mode">Response Summary</h3>
        <div v-if="responseDetail.mode" class="row no-gutters">
          <div v-for="(response, index) in responseDetail.response_summary" :key=response.id class="col-12 col-lg-4 px-0">
            <div class="card mr-2 mb-2">
              <div class="card-header">
                <h6 class="mb-1">Q{{index}}.&nbsp;{{response.text}}</h6>
              </div>      
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col" v-if="response.hasOwnProperty('yes')">
                    <div class="font-weight-normal text-700 fs-2">{{response.yes}}</div>
                    <div class="font-weight-semi-bold text-500 fs--1">Yes</div>
                  </div>
                  <div class="col" v-if="response.hasOwnProperty('no')">
                    <div class="font-weight-normal text-700 fs-2">{{response.no}}</div>
                    <div class="font-weight-semi-bold text-500 fs--1">No</div>
                  </div>
                  <div class="col" v-if="response.hasOwnProperty('maybe')">
                    <div class="font-weight-normal text-700 fs-2">{{response.maybe}}</div>
                    <div class="font-weight-semi-bold text-500 fs--1">Maybe</div>
                  </div>
                  <div class="col" v-if="response.hasOwnProperty('no_answer')">
                    <div class="font-weight-normal text-700 fs-2">{{response.no_answer}}</div>
                    <div class="font-weight-semi-bold text-500 fs--1">Unanswered</div>
                  </div>
                  <div class="col" v-if="response.hasOwnProperty('checked')">
                    <div class="font-weight-normal text-700 fs-2">{{response.checked}}</div>
                    <div class="font-weight-semi-bold text-500 fs--1">Checked</div>
                  </div>
                  <div class="col" v-if="response.hasOwnProperty('unchecked')">
                    <div class="font-weight-normal text-700 fs-2">{{response.unchecked}}</div>
                    <div class="font-weight-semi-bold text-500 fs--1">Unchecked</div>
                  </div>
                  <div class="col" v-if="response.hasOwnProperty('imageupload')">
                    <div class="font-weight-normal text-700 fs-2">{{response.imageupload}}</div>
                    <div class="font-weight-semi-bold text-500 fs--1">Images</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- </div> -->
        <div>
          <div class="col-md-12 col-xxl-12 mb-3 pl-md-0 pr-md-2" style="margin-bottom:20px;">
          <!-- <div class="col-lg-7 col-xl-8 pr-lg-2 mb-3"> -->
            <div class="card h-lg-100 overflow-hidden">

            </div>
          </div>
        </div>
      <!-- Start of ImageUpload responses -->
      <div v-if="responseImageUploads.length != 0">
        <h3 >Uploaded Images</h3>

        <div class="mb-4">
          <label for="storeSelect">Display uploads from region:</label>
          <select ref="regionSelector" @input="populateUploads" class="form-control select-input" id="storeSelect">
            <option value="none">None</option>
            <option value="all">All</option>
            <option v-for="region in custObj.config.regions" :key=region.name :value="region.name">{{region.name}}</option>
          </select>
        </div>
        <h6 v-if="selectedRegion != 'none' && sortUploads.length == 0">Sorry... no uploads!</h6>
        <div v-for="store_group in sortUploads" :key=store_group.key>
          <h5 class="mb-3">{{(store_group.key != '') ? store_group.key : "Unknown store"}}</h5>

          <div class="pl-3">
            <div v-if="responseDetail.mode == 'user'" class="row no-gutters">
              <div class="px-0 col-lg-auto col-sm-6" v-for="(response, index) in store_group.values" :key=index>
                <div v-if="response.image.length > 0" class="card mb-3 mr-2">
                  <div v-if="response.user_name" class="card-header">
                    <h6 class="upload-header mb-1">Q{{response.response_id}}. {{requestItemText(response.response_id)}}</h6>
                  </div>
                  <div class="card-body col pt-1">
                    <div class="row">
                      <div class="mr-1 text-center img-tiles col" @click="openModal(image)" v-for="image in response.image" :key=image.id>
                          <!-- <img
                            class="rounded border border-200"
                            :src="image.thumbnail_url"
                            width="180"
                            height="220"
                            :alt="response.name + response.store"
                            :title="response.name + response.store"
                            @click="openModal(image)"
                          /> -->

                          <tcx-image
                            className="roudned border border-200"
                            :imageSrc="image.thumbnail_url"
                            :aspectBlur="true"
                          />

                      </div>
                    </div>
                    <div class="row">
                      <div class="img-text pl-3 pt-1">
                        <div class="font-weight-semi-bold mb-0 text-800 fs--1">{{(response.user_name) ? response.user_name : "Unknown"}}</div>
                        <div class="mb-0 text-500 fs--2">{{formatToLocalTime(response.timestamp)}}</div>
                      </div>
                    </div>
                  </div> 
                </div>
              </div>
            </div>

            <div v-if="responseDetail.mode == 'store'" class="row no-gutters">
              <div class="px-0 col-lg-auto col-sm-6" v-for="(response, index) in store_group.values" :key=index>
                <div v-if="response.image.length > 0" class="card mb-3 mr-2">
                  <div class="card-header">
                    <h6 class="upload-header mb-1">Q{{response.response_id}}. {{requestItemText(response.response_id)}}</h6>
                  </div>     
                  <div class="card-body col pt-1">
                    <div class="row">
                      <div class="mr-1 text-center img-tiles col" @click="openModal(image)" v-for="image in response.image" :key=image.id>
                          <!-- <img
                            class="rounded border border-200"
                            :src="image.thumbnail_url"
                            width="180"
                            height="220"
                            :alt="image.thumbnail_url"
                            :title="response.store"  
                            @click="openModal(image)"
                          /> -->

                          <tcx-image
                            className="roudned border border-200"
                            :imageSrc="image.thumbnail_url"
                            :aspectBlur="true"
                          />

                      </div>
                    </div>
                    <div class="row">
                      <div class="img-text pl-3 pt-1">
                        <div class="font-weight-semi-bold mb-0 text-800 fs--1">{{(response.user_name) ? response.user_name : "Unknown"}}</div>
                        <div class="mb-0 text-500 fs--2">{{formatToLocalTime(response.timestamp)}}</div>
                      </div>
                    </div>
                  </div> 
                </div>
              </div>
            </div>  
          </div>
        
        </div>      
      </div>
        <!-- <div class="row no-gutters">
        <div class="col-md-12 col-xxl-12 mb-3 pr-md-2">
          <div class="card h-lg-100 overflow-hidden">
            <div class="card-body p-0">
              <table class="table table-dashboard mb-0 table-borderless fs--1">
                <thead class="bg-light">
                  <tr class="text-900">
                    <th>Uploaded images</th>
                  </tr>
                </thead>
                <tbody>
                    <tr v-if="responseImageUploads.length == 0">
                      <td>
                          <div class="media-body ml-3">
                            <p class="font-weight-semi-bold mb-0 text-500">There are no upload requests for this item.</p>
                          </div>
                      </td>
                      <td></td>
                    </tr>
                    <tr v-for="imageupload in responseImageUploads" :key=imageupload.request_items_guid class="border-bottom border-200">
                      <td>
                        <div class="media-body align-items-center ml-3">
                          <h6 class="mb-1 font-weight-semi-bold">
                            {{imageupload.store}}
                          </h6>
                          <p class="font-weight-semi-bold mb-0 text-500">{{imageupload.user_name}}</p>
                        </div>
                        <div class="container">
                          <div class="row">
                            <div v-for="single_image in imageupload.image" :key=single_image.image_guid class="col">
                              <div class="media-body align-items-center ml-3">
                                <img
                                  class="rounded border border-200"
                                  :src="single_image.url"
                                  width="180"
                                  object-fit="contain"
                                  :alt="imageupload.store"
                                  :title="imageupload.store"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer bg-light py-2">
              <div class="row flex-between-center">
              </div>
            </div>
          </div>
        </div>
      </div> -->

    </div>
    <div v-if="!dataLoaded">
      <page-loader></page-loader>
    </div>
    <image-modal :item="selectedItem"/>
   </div>
</template>

<script>
import { aggregateMethods } from "../helpers/aggregate";
import pageLoader from "../components/pageLoader";
import { actionMethods } from "../helpers/actions";
import { graphMethods } from "../helpers/graphs"
import { GlobalStore } from '../globalStore';
import ImageModal from "../components/modals/ImageModal.vue"
import { GlobalMethods } from '../globalMethods';
import { dashboardMethods } from "../helpers/dashboard";
import tcxImage from "../components/tcxImage.vue";

export default {
  data() {
    return {
      actionRequestTitle: '',
      actionRequestGuid: '',
      actionRequestMode: 'Unknown',
      selectedRequestItem: null,
      audienceUsers: [],
      audienceStores: [],
      responsesUsers: [],
      responsesStores: [],
      unrespondedUsers: [],
      additionalImages: [],
      unrespondedStores: { unrespondedStores : []},
      remainingStores: [],
      progressPercent: 0.0,
      progressPotential: 0.0,
      progressCurrent: 0.0,
      responseSummaries: [],
      responseImageUploads: [],
      responseDetail: [],
      /** The region selected in the dropdown */
      selectedRegion: 'none' ,
      /** List of responses from the region specified in 'selectedRegion' */
      selectedRegionUploads: [],
      selectedItem: '',
      dataLoaded: false,
    };
  },
   watch: {
    '$route.params.guid'(guid) {
        //this.checkView(currentview);
        this.actionRequestGuid = guid;
      // this.actionRequestGuid = "2455a3d0-86e0-11ea-b0b1-09bf639f3d15";
      // this.actionRequestGuid = "4c91c5c1-f51c-4260-b1b4-4beb2e4f18c2";
      // this.actionRequestGuid = "079453e0-77c7-11ea-a322-09b258df5e8e";
      // this.actionRequestGuid = "58b9d3ed-d5a6-4f24-8a92-1788be544dd6";
    }
    // '$route.params.id'(id) {
    //     //this.checkView(currentview);
    //     this.actionRequestItemId = id;
    // },
  },
  computed: {
    custObj: function() {
      return GlobalStore.custObj;
    },
    actionRequestLabel: function() {
      var endpoint = "toDo";
      // console.log(this.custObj.config.modules);
      var confElement = this.custObj.config.modules.find(
        (obj) => obj.admin_url == endpoint
      );
      return confElement.menuText;
    },

    sortUploads: function() {

      var tmp = this.groupBy(this.selectedRegionUploads, "store")
      console.log(tmp);
      // * May need this for question grouping.
      // tmp.forEach(store => {
      //   console.log('store', store);
      //   store.values = this.groupBy(store.values, "response_id")
      // })
      
      return tmp;
    },
  },

  methods: {
    /**
     * Sorts (in ascending order) a list of objects by items in their
     * '.images' field.
     * @param group the list of objets to be sorted
     * @returns A list of objects
     */
    sortByImageCount(group) {
      return Object.keys(group)
      .sort(function(a, b) { return group[b].image.length - group[a].image.length; })
      .map(function(category) { return group[category]; }).reverse()
    },

    arrayToColumns(array, column, columns) {
        const total = array.length; // How many items
        const gap = Math.ceil(total / columns); // How many per col
        let top = (gap * column) - 1; // Top of the col
        const bottom = (gap * (column - 1)); // Bottom of the col
        return array.filter(item =>
            array.indexOf(item) >= bottom
            && array.indexOf(item) <= top,
        );
    },
    /**
     * Populated the image upload field.
     */
    populateUploads() {
      // Removing uploads that do not have images
      this.responseImageUploads = this.responseImageUploads.filter(upload => {
        return upload.image.length > 0;
      })
      
      this.selectedRegion = this.$refs.regionSelector.value

      // Base cases
      if (this.selectedRegion == 'none') {
        this.selectedRegionUploads = []
        return
      }
      if (this.selectedRegion == 'all') {
        this.selectedRegionUploads = this.responseImageUploads
        return
      }

      this.selectedRegionUploads = this.responseImageUploads
        .filter(upload => { return upload.region_name == this.selectedRegion })      
    },
    async getPageData() {
      // Defining variables
      const pipelineAudienceUsers = [
        { $match: { action_request_guid : this.actionRequestGuid }},
        { $unwind: "$audience_users" },
      ];
      // const pipelineAudienceStores = [
      //   { $match: { action_request_guid : this.actionRequestGuid }},
      // ];
      const pipelineUnrespondedUsers = [
        { $match: { action_request_guid : this.actionRequestGuid }},
        // { $unwind: "$remaining_users" },
        // { $unwind: "$responded_users" },
      ];
      const pipelineUnrespondedStores = [
        { $match: { action_request_guid : this.actionRequestGuid }},
        // { $unwind: "$remaining_stores" },
      ];
      const pipelineResponseSummaries = [
        { $match: { action_request_guid : this.actionRequestGuid}},
        { $match: { "request_items.request_type" : { "$ne" : "imageupload"}  }},
      ];
      const pipelineResponseImageUploads = [
        { $match: { action_request_guid : this.actionRequestGuid }},
        { $lookup: { 'from' : 'image',
                    'localField' : "image_guid" ,
                    'foreignField' : "image_guid",
                    'as' : "image"} },
      ];

      if (this.actionRequestMode == "user"){
        // Making requests
        await aggregateMethods.getAggregateResult("v_audience_action_request_users", pipelineAudienceUsers).then(res => {
          // this.audienceUsers = res.data
          // update store info and sort
          var i, objNew;

          for (i=0; i<res.data.length; i++){
              objNew = res.data[i];
              objNew.audience_users['store_summary'] = this.storeSummary(objNew.audience_users)
              objNew.audience_users['display_name'] = this.displayName(objNew.audience_users)
          //     objNew.audience_users['store_summary'] = this.storeSummary(objNew.audience_users);
              this.audienceUsers.push(objNew);
          }
          // Sort by given name
          this.audienceUsers = this.audienceUsers.sort(function(a, b){
            if (a.audience_users.store_summary === b.audience_users.store_summary){
              return a.audience_users.display_name > b.audience_users.display_name ? 1 : -1;
            }
            return a.audience_users.store_summary > b.audience_users.store_summary ? 1 : -1;
          })
        });
        await aggregateMethods.getAggregateResult("v_unresponded_action_request_users", pipelineUnrespondedUsers).then(res => {
          // this.unrespondedUsers = res.data
          var i, j, objNew;
          for (i=0; i<res.data.length; i++){
              objNew = res.data[i];
              // Update remaining users info
              for (j=0; j<objNew.remaining_users.length; j++){
                objNew.remaining_users[j]['store_summary'] = this.storeSummary(objNew.remaining_users[j])
                objNew.remaining_users[j]['display_name'] = this.displayName(objNew.remaining_users[j])
              }
              // Update responded users info
              for (j=0; j<objNew.responded_users.length; j++){
                objNew.responded_users[j]['store_summary'] = this.storeSummary(objNew.responded_users[j])
                objNew.responded_users[j]['display_name'] = this.displayName(objNew.responded_users[j])
              }
              // objNew.remaining_users['store_summary'] = this.storeSummary(objNew.remaining_users)
              // objNew.remaining_users['display_name'] = this.displayName(objNew.remaining_users)
          //     objNew.audience_users['store_summary'] = this.storeSummary(objNew.audience_users);
              this.unrespondedUsers.push(objNew);
          }
          // sort the remaining_users and responded_users arrays
          // console.log(this.unrespondedUsers.length);
          if (this.unrespondedUsers.length > 0){
            this.unrespondedUsers[0].remaining_users = this.unrespondedUsers[0].remaining_users.sort(function(a,b){
              if (a.store_summary === b.store_summary){
                return a.display_name > b.display_name ? 1 : -1;
              }
              return a.store_summary > b.store_summary ? 1 : -1;
            });
            this.unrespondedUsers[0].responded_users = this.unrespondedUsers[0].responded_users.sort(function(a,b){
              if (a.store_summary === b.store_summary){
                return a.display_name > b.display_name ? 1 : -1;
              }
              return a.store_summary > b.store_summary ? 1 : -1;
            });
          }

          // console.log("unrespondedUsers");
          // console.log(this.responsesUsers);
          // console.log(this.unrespondedUsers[0]);
          if (this.unrespondedUsers.length>0){
            this.responsesUsers = this.unrespondedUsers[0].responded_users;
          }
          // console.log(this.responsesUsers);
        });
      }
      if (this.actionRequestMode == "store"){
        await aggregateMethods.getAggregateResult("v_unresponded_action_request_stores", pipelineUnrespondedStores).then(res => {
          this.unrespondedStores = res.data
          console.log("unrespondedStores", this.unrespondedStores);
        });
      }
      await dashboardMethods.getDashboardData("audience_action_request", 5000, [{ $match: { action_request_guid : this.actionRequestGuid }}]).then(res => {
        if(res.hasOwnProperty('data')){
          this.audienceStores = res.data[0];
        console.log('audienceStores', this.audienceStores);
        }
      })
      await actionMethods.getActionRequestResponseDetail(this.actionRequestGuid).then(res => {
        this.responseDetail = res.data
        console.log(this.responseDetail);
      })
      await aggregateMethods.getAggregateResult("v_action_response_summary", pipelineResponseSummaries).then(res => {
        this.responseSummaries = res.data
        console.log("responseSummaries");
        console.log(this.responseSummaries);
      })
      await dashboardMethods.getDashboardData("action_request_image_uploads", 5000, pipelineResponseImageUploads).then(res => {
        if(res.hasOwnProperty('data')){
          this.responseImageUploads = res.data;
          console.log('getDashboardData: returned action_request_image_uploads=', this.responseImageUploads.length)
        }
      })
    }, 

    groupBy(xs, key)  {
      return xs.reduce(function (rv, x) { 
        let v = key instanceof Function ? key(x) : x[key]; 
        let el = rv.find((r) => r && r.key === v); 
        if (el) { 
          el.values.push(x); 
        } else { 
          rv.push({ key: v, values: [x] }); 
        }
        return rv; 
      }, []); 
    },

    requestItemText(ix) {
      return this.responseDetail.response_summary[Number(ix)]['text']
    },

    formatToLocalTime(ts) {
      return(GlobalMethods.formatToLocalTime(ts));
    },

    fuzzyTime(datestamp){
      // Make a fuzzy time
      return(GlobalMethods.fuzzyTime(datestamp));
    },

    uniqueUsersFromUserResponses(responses){
      var users = [], parts, i, elements;
      for (i=0, elements=responses.length ; i<elements; i++){
        parts = responses[i].split(":");
        users.push(parts[0]);
      }
      return(users.sort());
    },

    uniqueStoresFromUserResponses(responses){
      var stores = [], parts, i, elements;
      for (i=0, elements=responses.length ; i<elements; i++){
        parts = responses[i].split(":");
        if(stores.indexOf(parts[1]) == -1){
          // console.log("new store: " + parts[1])
          stores.push(parts[1]);
        }
      }
      return(stores.sort());
    },

    parseISOString(s) {
      var b = s.split(/\D+/);
      return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
    },

    longDateTimeFormat(datetime){
      var d = this.parseISOString(datetime);
      // var out = d.getDate()  + "-" + (d.getMonth()+1) + "-" + d.getFullYear() + " " + d.getHours() + ":" + d.getMinutes();
      // out = out.getDate();
      return(d);
    },

    removeImages(html){
      return(html.replace(/<img .*?>/g, "[IMAGE REMOVED]<br>"));
    },

    async loadActionRequest(){
      try{
        var resp = await actionMethods.getActionRequestItem(this.actionRequestGuid);
        this.selectedRequestItem = resp.data;
        this.actionRequestTitle = resp.data.title;
        this.actionRequestMode = resp.data.mode;
        console.log(this);
        // console.log(this.selectedRequestItem); 

        if(typeof resp.data.images.thumbnail_image_url != 'undefined'){
          this.selectedRequestItem.images.thumbnail_image_url = resp.data.images.thumbnail_image_url;
        }else{
          this.selectedRequestItem.images.thumbnail_image_url = resp.data.images.hero_image_url;
        }

        this.additionalImages = [...resp.data.images.additional_images_url]

        this.selectedRequestItem.due_by_string = this.longDateTimeFormat(this.selectedRequestItem.due_by);
        // Ensure no images in the text
        this.selectedRequestItem.body_html = this.removeImages(this.selectedRequestItem.body_html);
        // console.log(this.selectedRequestItem);
        if(this.actionRequestMode == "user"){
          this.responsesUsers = this.uniqueUsersFromUserResponses(this.selectedRequestItem.responses);
          console.log(this.responsesUsers);
          // console.log(this.responsesUsers);
          this.responsesStores = this.uniqueStoresFromUserResponses(this.selectedRequestItem.responses);
          // console.log(this.responsesUsers);
        }else{ // store mode
          this.responsesUsers = this.selectedRequestItem.responses.sort(); // Make it the same as stores, since one per store has responded
          this.responsesStores = this.selectedRequestItem.responses.sort();
        }
      }
      catch(err){
        console.log("getActionrequestItem failed: " + err.message)
      }
    },

    openModal(item) {
      if (!item.hasOwnProperty('url')) {
        console.log(GlobalStore.imageEndpoint);
        this.selectedItem = {url: GlobalStore.imageEndpoint + item}
      } else {
        this.selectedItem = item
      }
      
      this.$modal.show('image-modal')
    },

    retreatPage() {
      this.$router.go(-1);
    },
    storeSummary(objUser) {
      if (objUser.stores.hasOwnProperty('length')){
        if (objUser.stores.length == 1){
          return(objUser.stores[0] == "" ? "0 stores" : objUser.stores[0]);
        }
        return(objUser.stores.length + " stores");
      }
      return "0 stores"
    },
    displayName(objUser) {
      return(objUser.given_name + " " + objUser.last_name);
    }
  },

  async mounted() {
    this.actionRequestGuid = this.$route.params.guid;
    // console.log(this.actionRequestGuid);

    // Load data from the database
    try{
      await this.loadActionRequest();
    }
    catch(ex){
      console.log(ex)
    }
    // console.log(this.selectedRequestItem); 

    // Getting audience responses.
    try {
      this.getPageData().then(() => {
        // Update the remaining stores var
        console.log('this.audienceStores.audience.stores', this.audienceStores.audience.stores);
        if (this.actionRequestMode == 'store'){
          this.remainingStores = this.unrespondedStores[0].remaining_stores.filter(value => this.audienceStores.audience.stores.includes(value));
          // console.log('this.remainingStores', this.remainingStores);
        }
        
        // Reformatting image urls
        this.responseImageUploads.forEach(response => {
          response.image.forEach(img => {
            // img.thumbnail_url = GlobalStore.imageEndpoint + img.thumbnail_url 
            img.url = GlobalStore.imageEndpoint + img.url 
          })
        })

        if (this.actionRequestMode == "user") {

          if (this.audienceUsers.length == 0) {
            this.progressPercent = 0.0;  
          } else {
            this.progressPercent = (Math.min(100.0, this.responsesUsers.length / this.audienceUsers.length * 100)).toFixed(0);
            this.progressPotential = this.audienceUsers.length;
            this.progressCurrent = this.responsesUsers.length;
          }
          // console.log(this.audienceUsers);

        } else { // store mode
          // align the number of unresponded users to the number of unresponded stores
          this.unrespondedUsers = this.unrespondedStores;

          this.progressPotential = this.audienceStores.audience.stores.length;

          if (this.audienceStores.audience.stores.length == 0 || !this.unrespondedStores[0].responded_stores) {
            this.progressPercent = 0.0;  
            this.progressCurrent = 0.0;
          } else {
            this.progressPercent =  (Math.min(100.0, this.unrespondedStores[0].responded_stores.filter(String).length / this.audienceStores.audience.stores.length * 100)).toFixed(0);
            this.progressCurrent = this.unrespondedStores[0].responded_stores.filter(String).length;
          }
        }

        graphMethods.renderPieGraph(this.$refs.donut, (this.progressPotential) ?
        [{
          value: this.progressCurrent,
          name: 'Progress'
        }, {
          value: (Math.max(0, this.progressPotential - this.progressCurrent)),
          name: 'Yet to respond'
        }]
        :
        [{}]
        );
      })
    } catch(err) {
      console.error("An issue occured waiting for promise: " + err.message);
      this.responseSummaries = [];
    }
    this.dataLoaded = true;
  },
  components: {
    ImageModal,
    pageLoader,
    tcxImage,
  }
}
</script>

<style>

.actionRequestDashboard.card, .card-container {
  padding: 20px;
}

.actionRequestDashboard.card, .card-header {
  min-height: 67px !important;
}

.upload-header {
  width: 180px;
  word-wrap: break-word;
}

.img-tiles {
  padding: 0 !important;
  
  /* Need the 'important' here to overwrite the bootstrap 'col' styling. */
  min-width: 180px !important;
  width: 10vw !important;
}

.page-thumbnail {
  height: 120px;
  width: 120px;
}

.img-tiles img {
    cursor: pointer;
}
.img-text {
  max-width: 238px !important;
}

.back-button {
  max-height: 36px;
}

#storeSelect {
  width: 30%;
}

/* MODAL */
.v--modal-overlay {
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2000;
  opacity: 1;
}
</style>