<template>
  <div v-if="currentItem">
    <h3>{{ title }}</h3>
    <div class="container">
      <div class="row">
        <div class="col-9">
          <form>
            <div class="form-group">
              <label for="name">Title</label>
              <label style="color: red;">*</label>
              <input
                required
                @input="validationHelper($event)"
                @blur="validationHelper($event)"
                v-model="currentItem.title"
                class="form-control"
                id="title"
                type="text"
              />
              <span class="invalid-label" hidden
                >Please enter a valid title</span
              >
            </div>
            <div class="form-group">
              <label for="name">Strap Line</label>
              <label style="color: red;">*</label>
              <input
                required
                @input="validationHelper($event)"
                @blur="validationHelper($event)"
                v-model="currentItem.strapline"
                class="form-control"
                id="strapline"
                type="text"
              />
              <span class="invalid-label" hidden
                >Please enter a valid strap line</span
              >
            </div>
            <div class="row contact-row">
              <div class="column form-group">
                <label for="item_type">Type</label>
                <label style="color: red;">*</label>
                <select
                  required
                  @input="validationHelper($event)"
                  @blur="validationHelper($event)"
                  v-model="currentItem.item_type"
                  class="form-control select-input"
                  id="item_type"
                >
                  <option
                    class="item_type_option"
                    v-for="itemType in this.itemTypes"
                    :key="itemType"
                    :value="itemType"
                    >{{ itemType }}</option
                  >
                </select>
                <span class="invalid-label" hidden
                  >Please enter a valid addon prompt</span
                >
              </div>
              <div class="column form-group">
                <label for="exampleFormControlSelect1">Publish Status</label>
                <label style="color: red;">*</label>
                <select
                  required
                  @input="validationHelper($event)"
                  @blur="validationHelper($event)"
                  v-model="currentItem.display.publish_status"
                  class="form-control select-input"
                  id="publish_status"
                >
                  <option value="draft">Draft</option>
                  <option value="published">Published</option>
                </select>
                <span class="invalid-label" hidden
                  >Please enter a valid status</span
                >
              </div>
              <div class="column form-group text-left">
                <label for="name">Archived</label>
                <input
                      v-model="currentItem.is_archived"
                      class="form-control"
                      id="is_archived"
                      type="checkbox"
                    />
              </div>
              <div class="column form-group">
                <label for="name">Style Code</label>
                <input
                  v-model="currentItem.sku.code"
                  class="form-control"
                  id="sku_code"
                  type="text"
                  placeholder="Style Code"
                />
              </div>
              <div class="column form-group">
                <label for="name">Style Variation</label>
                <input
                  v-model="currentItem.sku.variation"
                  class="form-control"
                  id="sku_variation"
                  type="text"
                  placeholder="Style Variation"
                />
              </div>
            </div>
            <div class="form-group">
              <label for="name">Addon Prompt</label>
              <label style="color: red;">*</label>
              <input
                required
                @input="validationHelper($event)"
                @blur="validationHelper($event)"
                v-model="currentItem.addon_prompt"
                class="form-control"
                id="addon_prompt"
                type="text"
              />
              <span class="invalid-label" hidden
                >Please enter a valid addon prompt</span
              >
            </div>
            <div class="row contact-row">
              <div class="column form-group">
                <label for="name">Display Order (0 = unordered)</label>
                <label style="color: red;">*</label>
                <input
                  required
                  v-model="currentItem.display.order_by"
                  @input="validationHelper($event)"
                  @blur="validationHelper($event)"
                  class="form-control"
                  id="order_by"
                  type="text"
                  placeholder="Display Order - 1 will show at top"
                />
                <span class="invalid-label" hidden
                  >Please enter a valid display order</span
                >
              </div>
              <div id="display-after-group" class="column form-group">
                <label for="name">Display after</label>
                <label style="color: red;">*</label>
                <!-- <input v-model="currentItem.display.expires"  class="form-control" id="expires" type="text" placeholder="Expires"> -->
                <flat-pickr
                  @input="checkFlatPickr('display-after-group')"
                  @blur="checkFlatPickr('display-after-group')"
                  id="display-after-picker"
                  v-model="currentItem.display.display_after"
                  :config="config"
                  class="form-control no-disable"
                ></flat-pickr>
                <span class="invalid-label" hidden
                  >Please enter a valid date</span
                >
              </div>
              <div id="expires-group" class="column form-group">
                <label for="name">Expires</label>
                <label style="color: red;">*</label>
                <flat-pickr
                  @input="checkFlatPickr('expires-group')"
                  @blur="checkFlatPickr('expires-group')"
                  id="expires-picker"
                  v-model="currentItem.display.expires"
                  :config="config"
                  class="form-control no-disable"
                ></flat-pickr>
                <span class="invalid-label" hidden
                  >Please enter a valid date</span
                >
              </div>
            </div>
            <div
              class="form-group"
              v-if="currentItem.hyperlink && currentItem.hyperlink.url"
            >
              <label for="name">Hyperlink</label>
              <input
                v-model="currentItem.hyperlink.url"
                class="form-control"
                id="hyperlink-url"
                type="text"
                placeholder="example: https://www.google.com"
              />
            </div>

            <target-audience
              ref="targetAudience"
              :sourceArray="sourceArray"
              :destinationArray="destinationArray"
            />

            <div
              class="form-group"
              v-if="currentItem.content_item_type == 'memo'"
            >
              <label for="name">Html</label>
              <vue-editor v-model="currentItem.body_html"></vue-editor>
            </div>

            <div style="display: block; width:100%">
              <div class="add-product-left"><h5>Linked Product</h5></div>
              <div class="add-product-button">
                <button
                  class="btn btn-primary mb-3"
                  type="button"
                  @click="productSearch()"
                >
                  Add Product
                </button>
              </div>
            </div>

            <div class="product-list">
              <div v-if="currentItem.products.length > 0">
                <div v-for="(ri, idx) in currentItem.products" :key="idx">
                  <div class="product-image-div">
                    <div
                      class="product-image"
                      :style="
                        'background: url(' +
                          getProductImage(ri.product_guid) +
                          ');'
                      "
                    ></div>
                  </div>
                  <div class="product-line">
                    {{ getProductDetails(ri.product_guid) }}
                  </div>
                  <div class="delete-product" @click="removeProduct(idx)">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
              <div v-if="currentItem.products.length == 0">
                <div>
                  There is no linked product. Click
                  <a @click.prevent="productSearch()" href="#">here</a> to link
                  this item to a product.
                </div>
              </div>
            </div>
          </form>
          <div class="modal-footer">
            <button
              v-if="this.view == 'Edit'"
              :disabled="isSaving"
              class="btn btn-primary mb-3"
              type="button"
              @click="saveFYIItem(false)"
            >
              Save
            </button>
            <button
              :disabled="isSaving"
              class="btn btn-primary mb-3"
              type="button"
              @click="saveFYIItem(true)"
            >
              Save & Close
            </button>
            <button class="btn mb-3" type="button" @click="cancelAndReturn()">
              Cancel
            </button>
          </div>
        </div>
        <div class="col-3">
          <image-upload-sidebar :item="currentItem"></image-upload-sidebar>
          <div style="min-height: 40px;">&nbsp;</div>
          <content-tags-sidebar
            type="fyi"
            :item="currentItem"
            :selectedTags="this.currentItem.tags"
            @onSelectTag="onSelectTag"
            v-if="currentItem"
          />
        </div>
      </div>
    </div>
    <product-search-modal
      @onLink="linkProduct"
      :productList="productList"
      :item="currentItem"
    />
  </div>
</template>

<script>
import { GlobalStore } from "../globalStore";
import { GlobalMethods } from "../globalMethods";
import axios from "axios";
import { VueEditor } from "vue2-editor";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { imageMethods } from "../helpers/images";
import ImageUploadSidebar from "../components/ImageUploadSidebar";
import ContentTagsSidebar from "../components/ContentTagsSidebar.vue";
import ProductSearchModal from "../components/modals/ProductSearchModal";
import TargetAudience from "../components/TargetAudience";

export default {
  name: "fyi-item",
  data() {
    return {
      selectedTags: [],
      imageEndpoint: GlobalStore.imageEndpoint,
      isSaving: false,
      imageBlobEndpoint: GlobalStore.imageEndpoint,
      custConfig: GlobalStore.custObj.config,
      config: {
        enableTime: true,
        altFormat: "d-m-Y h:i K",
        altInput: true,
        dateFormat: "Z",
      },
      parseImageURL: imageMethods.parseImageURL,
    };
  },
  props: {
    title: String,
    return: String,
    view: String,
    productList: Array,
    currentItem: Object,
    sourceArray: Array,
    destinationArray: Array,
  },
  computed: {
    itemTypes: function() {
      return GlobalMethods.getDisplayableItemTypes("top_story");
    }
  },
  methods: {
    onSelectTag(tags) {
      this.selectedTags = tags;
    },
    // Validation methods
    validationHelper(event) {
      this.checkField(event.target);
    },

    checkFlatPickr(id) {
      const field = document.querySelector(`#${id}`);
      if (!Date.parse(field.querySelector("input").value)) {
        field.querySelectorAll("input")[1].classList.add("invalid-input");
        field.querySelector("span").hidden = false;
        return false;
      } else {
        field.querySelectorAll("input")[1].classList.remove("invalid-input");
        field.querySelector("span").hidden = true;
        return true;
      }
    },

    checkField(field) {
      let valid = true;
      if (field.value.trim() == "") {
        field.classList.add("invalid-input");
        field.nextElementSibling.hidden = false;
        valid = false;
      } else {
        field.classList.remove("invalid-input");
        field.nextElementSibling.hidden = true;
      }
      return valid;
    },

    validateForm: function() {
      const fields = document.querySelectorAll("[required]");
      const flat_pickrs = ["display-after-group", "expires-group"];
      let formValid = true;
      fields.forEach((field) => {
        if (this.checkField(field) == false) {
          formValid = false;
        }
      });
      flat_pickrs.forEach((pickr) => {
        if (!this.checkFlatPickr(pickr)) {
          formValid = false;
        }
      });
      if (formValid == false) {
        GlobalStore.toastObj = this.$toasted.show(
          "Please complete required fields",
          {
            position: "bottom-center",
            duration: 5000,
            type: "error",
            fitToScreen: true,
            fullWidth: false,
          }
        );
        return false;
      }
      return true;
    },

    // Other methods
    linkProduct: function(guid) {
      console.log(guid);
      this.currentItem.products.push({
        image_region_height: 0,
        image_region_width: 0,
        image_region_x: -1,
        image_region_y: -1,
        product_guid: guid,
      });
      //console.log(this.currentItem.products);
      //this.currentItem.products.push(guid);
      this.$modal.hide("product-search-modal");
    },
    removeProduct: function(idx) {
      this.currentItem.products.splice(idx, 1);
    },
    getProductDetails: function(product_guid) {
      var product = this.productList.find(
        (f) => f.product_guid == product_guid
      );
      if (product) {
        return product.name + " - $" + product.price;
      } else {
        return "";
      }
    },
    getProductImage: function(product_guid) {
      var product = this.productList.find(
        (f) => f.product_guid == product_guid
      );
      if (product) {
        return this.imageEndpoint + product.images.thumbnail_image_url;
      } else {
        return "";
      }
    },
    productSearch: function() {
      this.$modal.show("product-search-modal");
    },
    saveFYIItem: function(redirectAfterSave) {
      if (!this.validateForm()) {
        return;
      }

      var listData = this.$refs["targetAudience"].getAllListData();
      this.currentItem.display.regional_managers = listData.regional_managers;
      this.currentItem.display.regions = listData.regions;
      this.currentItem.display.roles = listData.roles;
      this.currentItem.display.store_types = listData.store_types;
      this.currentItem.display.stores = listData.stores;
      this.currentItem.tags = this.selectedTags;
      console.log(this.currentItem);

      if (this.currentItem.display.publish_status.length == 0) {
        this.currentItem.display.publish_status = "draft";
      }

      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      var urlencoded = new URLSearchParams();
      urlencoded.append("grant_type", "refresh_token");
      urlencoded.append("expires_in", "7200");

      this.isSaving = true;

      if (this.view == "Edit") {
        axios
          .put(
            GlobalStore.APIEndpoint +
              "/todaycx-admin/top_story/" +
              this.currentItem.top_story_guid,
            this.currentItem,
            {
              headers: headers,
            }
          )
          .then(() => {
            // this.$router.push(this.return);
            GlobalStore.toastObj = this.$toasted.show(
              "The item has been saved",
              {
                theme: "outline",
                position: "bottom-center",
                duration: 5000,
                type: "information",
                fitToScreen: true,
                fullWidth: false,
              }
            );
            if (redirectAfterSave == true) {
              this.cancelAndReturn();
            } else {
              this.isSaving = false;
            }
          })
          .catch((err) => {
            this.loggingIn = false;
            GlobalStore.toastObj = this.$toasted.show(
              JSON.stringify(err.response.data.message),
              {
                theme: "outline",
                position: "bottom-center",
                duration: 5000,
                type: "error",
                fitToScreen: true,
                fullWidth: false,
              }
            );
          });
      } else {
        delete this.currentItem.top_story_guid;

        axios
          .post(
            GlobalStore.APIEndpoint + "/todaycx-admin/top_story",
            this.currentItem,
            {
              headers: headers,
            }
          )
          .then((resp) => {
            console.log(resp);
            // this.$router.push(this.return);
            GlobalStore.toastObj = this.$toasted.show(
              "The item has been saved",
              {
                theme: "outline",
                position: "bottom-center",
                duration: 5000,
                type: "information",
                fitToScreen: true,
                fullWidth: false,
              }
            );
            if (redirectAfterSave == true) {
              this.cancelAndReturn();
            } else {
              this.isSaving = false;
            }
          })
          .catch((err) => {
            this.loggingIn = false;
            GlobalStore.toastObj = this.$toasted.show(
              JSON.stringify(err.response.data.message),
              {
                theme: "outline",
                position: "bottom-center",
                duration: 5000,
                type: "error",
                fitToScreen: true,
                fullWidth: false,
              }
            );
          });
      }
    },
    getCustGuid: function() {
      return GlobalStore.custGuid;
    },
    htmlDecode(input) {
      var e = document.createElement("div");
      e.innerHTML = input;
      return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    },
    cancelAndReturn() {
      this.$router.push(this.return);
    },
  },
  components: {
    VueEditor,
    flatPickr,
    ImageUploadSidebar,
    ProductSearchModal,
    TargetAudience,
    ContentTagsSidebar,
  },
  async mounted() {
    // Setting default date values when creating new item
    if (this.view == "Add") {
      let now = new Date();
      this.currentItem.display.display_after = now;
      this.currentItem.display.expires = new Date(now).setDate(now.getDate() + 14)
    }

    this.selectedTags = this.currentItem.tags;

    console.log(this.custConfig);
    document.querySelectorAll(".no-disable").forEach((item) => {
      item.readOnly = false;
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ql-editor p {
  margin-bottom: 0.5 !Important;
}

table {
  background-color: white;
  margin-top: 20px;
}
.contact-row {
  margin-left: 0px;
  width: 100%;
  
}

.contact-row .column {
  margin-right: 13px;
}

.contact-row input[type=checkbox], #active {
  transform: scale(0.5);
}

.contact-row select {
  display: block;
  font-size: 12px;
}

.form-control{
  font-size: 12px
}

.invalid-input {
  border: 2px solid red;
}
.invalid-label {
  color: red;
  font-size: 10px;
}

.card-img-top {
  max-height: 100px;
  overflow: hidden;
}

.card-title {
  min-height: 45px;
}

.card-text {
  min-height: 60px;
}
#publish_status {
  font-size: 12px;
  padding-left: 0;
}
#item_type {
  font-size: 12px;
  padding-left: 0;
}
#expires-group {
  font-size: 12px;
  padding-left: 0;  
}
#display-after-group {
  font-size: 12px;
  padding-left: 0;  
}

.additional-images {
  margin-top: 10px;
}

.additional-image {
  width: 50%;
}

.product-list {
  margin: 20px 0px 40px 0px;
  border: lightgray 1px solid;
  padding: 10px;
}

.product-line {
  display: inline-block;
  width: 69%;
  margin: 10px 0px 10px 0px;
  vertical-align: top;
}

.product-image-div {
  display: inline-block;
  width: 20%;
  margin: 10px 0px 10px 0px;
}
.product-image {
  width: 90px;
  height: 120px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center;
  border-radius: 3px;
}

.delete-product {
  display: inline-block;
  width: 10%;
  text-align: right;
  margin-right: 1px;
  cursor: pointer;
  vertical-align: top;
}

.add-product-left {
  width: 50%;
  display: inline-block;
}

.add-product-button {
  width: 50%;
  display: inline-block;
  text-align: right;
}

.select-input {
  font-size: 12px;
  padding-left: 0;
}
</style>
