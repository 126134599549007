<template>
  <div v-if="currentItem">
    <h3>{{ title }}</h3>
    <div class="container">
      <div class="row">
        <div class="col-9">
          <form>
            <div class="form-group">
              <label for="name">Title</label>
              <label style="color: red;">*</label>
              <input
                required
                @input="validationHelper($event)"
                @blur="validationHelper($event)"
                v-model="currentItem.title"
                class="form-control"
                id="title"
                type="text"
              />
              <span class="invalid-label" hidden
                >Please enter a valid title</span
              >
            </div>
            <div class="form-group">
              <label for="name">Summary</label>
              <!-- <label style="color: red;">*</label> -->
              <!-- <input
                required
                @input="validationHelper($event)"
                @blur="validationHelper($event)" -->
              <input
                v-model="currentItem.summary"
                class="form-control"
                id="summary"
                type="text"
              />
              <!-- <span class="invalid-label" hidden
                >Please enter a valid summary</span
              > -->
            </div>
            <div class="row contact-row">
              <div class="column form-group">
                <label for="item_type">Type</label>
                <label style="color: red;">*</label>
                <select
                  required
                  @input="validationHelper($event)"
                  @blur="validationHelper($event)"
                  v-model="currentItem.item_type"
                  class="form-control select-input"
                  id="item_type"
                >
                  <option
                    class="item_type_option"
                    v-for="itemType in this.itemTypes"
                    :key="itemType"
                    :value="itemType"
                    >{{ itemType }}</option
                  >
                </select>
                <span class="invalid-label" hidden
                  >Please enter a valid addon prompt</span
                >
              </div>
              <div class="column form-group">
                <label for="exampleFormControlSelect1">Publish Status</label>
                <label style="color: red;">*</label>
                <select
                  required
                  @input="validationHelper($event)"
                  @blur="validationHelper($event)"
                  v-model="currentItem.display.publish_status"
                  class="form-control select-input"
                  id="publish_status"
                >
                  <option value="draft">Draft</option>
                  <label style="color: red;">*</label>
                  <option value="published">Published</option>
                </select>
                <span class="invalid-label" hidden
                  >Please enter a valid status</span
                >
              </div>
              <div class="column form-group text-left">
                <label for="name">Archived</label>
                <input
                      v-model="currentItem.is_archived"
                      class="form-control"
                      id="is_archived"
                      type="checkbox"
                    />
              </div>
              <div
                v-if="['memo', 'best_seller'].includes(currentItem.content_item_type)"
                class="column form-group"
              >
                <label for="name">Category</label>
                <input
                  v-model="currentItem.category"
                  class="form-control"
                  id="category"
                  type="text"
                />
              </div>
              <div class="column form-group">
                <label for="name">Style Code</label>
                <input
                  v-model="currentItem.sku.code"
                  class="form-control"
                  id="sku_code"
                  type="text"
                  placeholder="Style Code"
                />
              </div>
              <div class="column form-group">
                <label for="name">Style Variation</label>
                <input
                  v-model="currentItem.sku.variation"
                  class="form-control"
                  id="sku_variation"
                  type="text"
                  placeholder="Style Variation"
                />
              </div>
            </div>
            <!-- REGULAR HEADLINES -->
            <div
              v-if="currentItem.content_item_type == 'best_store' && currentItem.item_type != '@headline_ranked'"
              id="headlines_container"
              class="form-group"
            >
              <label for="headlines_container"
                >Headlines
                <span style="color: grey"
                  >{{ numHeadlines }}/{{ maxHeadlines }}</span
                ></label
              >
              <div
                v-for="(headline, index) in currentItem.headlines"
                v-bind:key="index"
                class="headline"
              >
                <input
                  class="form-control mb-2 mr-2"
                  v-model="currentItem.headlines[index]"
                  type="text"
                  maxlength="40"
                />
                <div @click="deleteHeadline(index)">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </div>
              </div>
              <button
                :disabled="numHeadlines >= maxHeadlines"
                @click="addHeadline()"
                class="btn btn-secondary mb-3"
                type="button"
              >
                +
              </button>
            </div>
            <!-- RANKED HEADLINES -->
            <div
              v-if="currentItem.content_item_type == 'best_store' && currentItem.item_type == '@headline_ranked'"
              id="headlines_container"
              class="form-group"
            >
              <label for="headlines_container"
                >Headlines (ranked)
                <span style="color: grey"
                  >{{ numHeadlines }}/{{ maxHeadlines }}</span>
                </label
              >
              <div
                v-for="(headline, index) in currentItem.headlines_ranked"
                v-bind:key="index"
                class="headline"
              >
                <input
                  class="form-control mb-2 mr-2"
                  v-model="currentItem.headlines_ranked[index].headline"
                  type="text"
                  maxlength="40"
                />
                <input
                  class="form-control mb-2 mr-2"
                  v-model="currentItem.headlines_ranked[index].rank_value"
                  type="text"
                  maxlength="10"
                />
                <div @click="deleteRankedHeadline(index)">
                  <i class="fa fa-trash" aria-hidden="true"></i>
                </div>
              </div>
              <button
                :disabled="numHeadlines >= maxHeadlines"
                @click="addRankedHeadline()"
                class="btn btn-secondary mb-3"
                type="button"
              >
                +
              </button>
            </div>

            <div class="row contact-row">
              <div class="column form-group">
                <label for="name">Display Order (0 = unordered)</label>
                <label style="color: red;">*</label>
                <input
                  required
                  @input="validationHelper($event)"
                  @blur="validationHelper($event)"
                  v-model="currentItem.display.order_by"
                  class="form-control"
                  id="prder_by"
                  type="text"
                  placeholder="Display Order - 1 will show at top"
                />
                <span class="invalid-label" hidden
                  >Please enter a valid display order</span
                >
              </div>
              <div id="display-after-group" class="column form-group">
                <label for="name">Display after</label>
                <label style="color: red;">*</label>
                <!-- <input v-model="currentItem.display.expires"  class="form-control" id="expires" type="text" placeholder="Expires"> -->
                <flat-pickr
                  @input="checkFlatPickr('display-after-group')"
                  @blur="checkFlatPickr('display-after-group')"
                  v-model="currentItem.display.display_after"
                  :config="config"
                  class="form-control no-disable"
                ></flat-pickr>
                <span class="invalid-label" hidden
                  >Please enter a valid date</span
                >
              </div>
              <div id="expires-group" class="column form-group">
                <label for="name">Expires</label>
                <label style="color: red;">*</label>
                <flat-pickr
                  @input="checkFlatPickr('expires-group')"
                  @blur="checkFlatPickr('expires-group')"
                  v-model="currentItem.display.expires"
                  :config="config"
                  class="form-control no-disable"
                ></flat-pickr>
                <span class="invalid-label" hidden
                  >Please enter a valid date</span
                >
              </div>
            </div>
            <div
              class="form-group"
              v-if="
                currentItem.hyperlink && currentItem.content_item_type == 'memo'
              "
            >
              <label for="name">Hyperlink</label>
              <input
                v-model="currentItem.hyperlink.url"
                class="form-control"
                id="hyperlink-url"
                type="text"
                placeholder="example: https://www.google.com"
              />
            </div>

            <div
              class="form-group"
              v-if="currentItem.content_item_type == 'memo'"
            >
              <label for="name">Html</label>
              <vue-editor v-model="currentItem.body_html" :editorToolbar="customToolbar" ></vue-editor>
            </div>
            <target-audience
              ref="targetAudience"
              :sourceArray="sourceArray"
              :destinationArray="destinationArray"
            />
          </form>
          <div class="modal-footer">
            <button
              v-if="this.view == 'Edit' && this.currentItem.content_guid"
              :disabled="isSaving"
              class="btn btn-primary mb-3"
              type="button"
              @click="saveContentItem(false)"
            >
              Save
            </button>
            <button
              :disabled="isSaving"
              class="btn btn-primary mb-3"
              type="button"
              @click="saveContentItem(true)"
            >
              Save & Close
            </button>
            <button class="btn mb-3" type="button" @click="cancelAndReturn()">
              Cancel
            </button>
          </div>
        </div>
        <div class="col-3">
          <image-upload-sidebar
            @updateHeroImage="updateHeroImage"
            :item="currentItem"
          ></image-upload-sidebar>
          <div style="min-height: 40px;">&nbsp;</div>
          <content-tags-sidebar
            :type="this.currentItem.content_item_type"
            :item="currentItem"
            :selectedTags="this.currentItem.tags"
            @onSelectTag="onSelectTag"
            v-if="currentItem"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import axios from "axios";
import { GlobalStore } from "../globalStore";
import { GlobalMethods } from "../globalMethods";
import axios from "axios";
import { VueEditor } from "vue2-editor";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ImageUploadSidebar from "../components/ImageUploadSidebar";
import TargetAudience from "../components/TargetAudience";
import ContentTagsSidebar from "../components/ContentTagsSidebar.vue";

export default {
  name: "content-item",
  data() {
    return {
      selectedTags: [],
      maxHeadlines: GlobalStore.custObj.licence.max_headlines_best_stores,
      imageEndpoint: GlobalStore.imageEndpoint,
      hasImage: false,
      selectedImageItem: null,
      isSaving: false,
      config: {
        enableTime: true,
        altFormat: "d-m-Y h:i K",
        altInput: true,
        dateFormat: "Z",
      },
      customToolbar: GlobalStore.htmlEditorToolbar
    };
  },
  props: {
    currentItem: Object,
    title: String,
    return: String,
    view: String,
    sourceArray: Array,
    destinationArray: Array,
  },
  computed: {
    filteredItemList: function() {
      return this.itemList;
    },
    numHeadlines: {
      get() {
        if (this.currentItem.item_type == '@headline_ranked'){
          return this.currentItem.headlines_ranked.length;
        }
        return this.currentItem.headlines.length;

      },
      set(num) {
        return num;
      },
    },
    itemTypes: function() {
      console.log(this.currentItem);
      return GlobalMethods.getDisplayableItemTypes(this.currentItem.content_item_type);
    },

    // currentItem: function() {
    //   console.log("get current item")
    //   var tItem = JSON.parse(JSON.stringify(this.item));
    //   //if (tItem) {
    //   //tItem.body_html = this.htmlDecode(tItem.body_html);
    //   //}
    //   return tItem;
    // }
  },
  methods: {
    onSelectTag(tags) {
      this.selectedTags = tags;
    },
    // Validation methods
    validationHelper(event) {
      this.checkField(event.target);
    },

    checkFlatPickr(id) {
      const field = document.querySelector(`#${id}`);
      if (!Date.parse(field.querySelector("input").value)) {
        field.querySelectorAll("input")[1].classList.add("invalid-input");
        field.querySelector("span").hidden = false;
        return false;
      } else {
        field.querySelectorAll("input")[1].classList.remove("invalid-input");
        field.querySelector("span").hidden = true;
        return true;
      }
    },

    checkField(field) {
      let valid = true;
      if (field.value.trim() == "") {
        field.classList.add("invalid-input");
        field.nextElementSibling.hidden = false;
        valid = false;
      } else {
        field.classList.remove("invalid-input");
        field.nextElementSibling.hidden = true;
      }
      return valid;
    },

    validateForm: function() {
      const fields = document.querySelectorAll("[required]");
      const flat_pickrs = ["display-after-group", "expires-group"];
      let formValid = true;
      fields.forEach((field) => {
        if (this.checkField(field) == false) {
          formValid = false;
        }
      });
      flat_pickrs.forEach((pickr) => {
        if (!this.checkFlatPickr(pickr)) {
          formValid = false;
        }
      });
      if (formValid == false) {
        GlobalStore.toastObj = this.$toasted.show(
          "Please complete required fields",
          {
            position: "bottom-center",
            duration: 5000,
            type: "error",
            fitToScreen: true,
            fullWidth: false,
          }
        );
        return false;
      }
      return true;
    },

    // Other methods
    updateHeroImage: function(evt) {
      //console.log('uhi:' + evt);
      console.log(this.currentItem);
      this.currentItem.images.hero_image = evt;
    },
    saveContentItem: function(redirectAfterSave) {
      if (!this.validateForm()) {
        return;
      }
      var listData = this.$refs["targetAudience"].getAllListData();
      this.currentItem.display.regional_managers = listData.regional_managers;
      this.currentItem.display.regions = listData.regions;
      this.currentItem.display.roles = listData.roles;
      this.currentItem.display.store_types = listData.store_types;
      this.currentItem.display.stores = listData.stores;
      this.currentItem.tags = this.selectedTags;

      if (this.currentItem.display.publish_status.length == 0) {
        this.currentItem.display.publish_status = "draft";
      }

      console.log(this.return);

      console.log(this.currentItem);

      //this.currentItem.hyperlink.url = "http://www.google.com";

      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      var urlencoded = new URLSearchParams();
      urlencoded.append("grant_type", "refresh_token");
      urlencoded.append("expires_in", "7200");
      // WORKAROUND for issue when creating new items (HTML Editor trigger change of view mode)
      if (this.view == "Edit") {
        if (this.currentItem.content_guid) {
          if (this.currentItem.content_guid.length == 0) {
            // Can't have a zero-length content_guid - flip mode to 'Add'
            console.log("Zero-length guid found: Switched view mode to 'Add'");
            this.view = "Add";
          }
        } else {
          // Can't have a zero-length content_guid - flip mode to 'Add'
          console.log("Zero-length guid found: Switched view mode to 'Add'");
          this.view = "Add";
        }
      }
      // WORKAROUND for issue when creating new items (HTML Editor trigger change of view mode)

      this.isSaving = true;

      if (this.view == "Edit") {
        axios
          .put(
            GlobalStore.APIEndpoint +
              "/todaycx-admin/content/" +
              this.currentItem.content_guid,
            this.currentItem,
            {
              headers: headers,
            }
          )
          .then(() => {
            // this.$router.push(this.return);
            GlobalStore.toastObj = this.$toasted.show(
              "The item has been saved",
              {
                theme: "outline",
                position: "bottom-center",
                duration: 5000,
                type: "information",
                fitToScreen: true,
                fullWidth: false,
              }
            );
            if (redirectAfterSave == true) {
              this.cancelAndReturn();
            } else {
              this.isSaving = false;
            }
          })
          .catch((err) => {
            this.loggingIn = false;
            this.isSaving = false;
            GlobalStore.toastObj = this.$toasted.show(
              JSON.stringify(err.response.data.message),
              {
                theme: "outline",
                position: "bottom-center",
                duration: 5000,
                type: "error",
                fitToScreen: true,
                fullWidth: false,
              }
            );
          });
      } else {
        delete this.currentItem.content_guid;

        axios
          .post(
            GlobalStore.APIEndpoint + "/todaycx-admin/content",
            this.currentItem,
            {
              headers: headers,
            }
          )
          .then((resp) => {
            console.log(resp);
            // this.$router.push(this.return);
            GlobalStore.toastObj = this.$toasted.show(
              "The item has been saved",
              {
                theme: "outline",
                position: "bottom-center",
                duration: 5000,
                type: "information",
                fitToScreen: true,
                fullWidth: false,
              }
            );
            if (redirectAfterSave == true) {
              this.cancelAndReturn();
            } else {
              this.isSaving = false;
            }
          })
          .catch((err) => {
            this.loggingIn = false;
            this.isSaving = false;
            GlobalStore.toastObj = this.$toasted.show(
              JSON.stringify(err.response.data.message),
              {
                theme: "outline",
                position: "bottom-center",
                duration: 5000,
                type: "error",
                fitToScreen: true,
                fullWidth: false,
              }
            );
          });
      }
    },
    getCustGuid: function() {
      return GlobalStore.custGuid;
    },
    htmlDecode(input) {
      var e = document.createElement("div");
      e.innerHTML = input;
      return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    },
    cancelAndReturn() {
      this.$router.push(this.return);
    },

    deleteHeadline(index) {
      this.currentItem.headlines.splice(index, 1);
      this.numHeadlines--;
    },
    deleteRankedHeadline(index) {
      this.currentItem.headlines_ranked.splice(index, 1);
      this.numHeadlines--;
    },

    addHeadline() {
      console.log("addHeadline");
      this.currentItem.headlines.push("");
      this.numHeadlines++;
    },
    addRankedHeadline() {
      console.log("addRankedHeadline");
      this.currentItem.headlines_ranked.push({"headline" : "", "rank_value" : 0.0});
      this.numHeadlines++;
    },
  },
  components: {
    VueEditor,
    flatPickr,
    //tcxImage,
    ImageUploadSidebar,
    TargetAudience,
    ContentTagsSidebar,
  },
  mounted() {
    // Setting default date values when creating new item
    if (this.view == "Add") {
      let now = new Date();
      this.currentItem.display.display_after = now;
      this.currentItem.display.expires = new Date(now).setDate(now.getDate() + 14)
    }  

    // this.populateHeadlines();
    this.selectedTags = this.currentItem.tags;

    //console.log(this.selectedTags);

    console.log(this.currentItem);
    document.querySelectorAll(".no-disable").forEach((item) => {
      item.readOnly = false;
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ql-editor p {
  margin-bottom: 0.5 !Important;
}

table {
  background-color: white;
  margin-top: 20px;
}
.contact-row {
  margin-left: 0px;
  width: 100%;
  
}

.contact-row .column {
  margin-right: 13px;
}

.contact-row input[type=checkbox], #active {
  transform: scale(0.5);
}

.contact-row select {
  display: block;
  font-size: 13px;
}

.form-control input[type=select]{
  font-size: 12px
}
.invalid-input {
  border: 2px solid red;
}

.invalid-label {
  color: red;
  font-size: 10px;
}

.card-img-top {
  max-height: 100px;
  overflow: hidden;
}

.card-title {
  min-height: 45px;
}

.card-text {
  min-height: 60px;
}

#publish_status {
  font-size: 12px;
  padding-left: 0;
}
#item_type {
  font-size: 12px;
  padding-left: 0;
}
#expires-group {
  font-size: 12px;
  padding-left: 0;  
}
.headline {
  width: 50%;
  display: flex;
  flex-direction: row;
}

.headline div {
  cursor: pointer;
}

.headline svg {
  margin-top: 5px;
}

.select-input {
  font-size: 12px;
  padding-left: 0;
}

#headlines_container {
  display: flex;
  flex-direction: column;
}

#headlines_container button {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 10%;
  height: 27px;
}
</style>
