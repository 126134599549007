<template>
  <div id="product-search-modal" v-if="item">
    <modal name="product-search-modal">
      <div class="modal-header">Product Search</div>
      <div class="modal-body">
        <div class="row">
          <div class="col is-8">
            <form >
              <div class="form-group">
                <label for="name">Product Search</label>
                <input
                  v-model="searchText"
                  class="form-control"
                  id="searchText"
                  type="text"
                  placeholder="Enter product search keywords.."
                  @keyup="doSearch()"
                />
              </div>
            </form>
              <div class="results-table">
                <div class="row" v-for="(item,index) in filteredList" :key="index">
                  <div class="col-7">
                    <span>{{item.name}}</span>
                  </div>
                  <div class="col-3">
                    <div class="results-image" :style="'background: url(' + imageBlobEndpoint + item.images.thumbnail_image_url + ');'"></div>
                  </div>
                  <div class="col-2">
                    <a class="btn btn-primary btn-sm float-right link-button" @click="linkProduct(item.product_guid)">Link Product</a>
                  </div>
                </div>
              </div>
          </div>
          </div>
        </div>
      <div class="modal-footer">
        <button v-on:click="ok()" class="btn btn-primary">Save</button>
        <button v-on:click="$modal.hide('product-search-modal')" class="btn btn-secondary">Cancel</button>
      </div>
    </modal>
  </div>
</template>

<script>
//import axios from "axios";
import { GlobalStore } from "../../globalStore";
//import { imageMethods } from '../../helpers/images'

export default {
  name: "product-search-modal",
  data() {
    return {
      searchText: '',
      filteredList: this.productList,
      imageBlobEndpoint: GlobalStore.imageEndpoint
    };
  },
  props: {
    title: String,
    item: null,
    productList: Array
  },
  methods: {
    //ok: function() {
      //this.$emit("saveDetails", this.item);      
    //}
    linkProduct: function(guid) {
      this.$emit('onLink', guid);
    },
    doSearch: function() {
      
      this.filteredList = this.productList.filter(f=> { return f.name.toLowerCase().trim().startsWith(this.searchText.toLowerCase().trim()) } );

      console.log(this.searchText);
      console.log(this.productList);
      //return this.productList;
    }
  },
  computed: {
    
  },
  components: {},
  async mounted() {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Modal */

.v--modal-overlay {
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2000;
  opacity: 1;
}

.modal-header {
  font-size: 16pt;
  color: var(--primary);
}

.modal-body {
  margin-bottom: 70px;
  height: 100px;
}

.modal-footer {
  position: absolute;
  bottom: 0px !important;
  width: 100%;
  display: inline-block;
  background-color: white;
}

.medialibrary-image-details {
  width: 100%;
  height: 240px;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center !important;
}

.results-table {
  width: 100%;
  height: 370px;
  overflow-y: scroll;
}

.results-table .row:nth-child(odd) {background-color: #f9fafd}

.results-table .row {
  margin: 0 0 10px 0;
}

.results-image {
  width:60px; 
  height:90px; 
  background-size: contain !important; 
  background-position: top; 
  background-repeat: no-repeat !important;
  border-radius: 3px;
}

.link-button {
  color: white !important;
}

</style>
