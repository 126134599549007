<template>
	<div id="user-detail-modal">
		<modal name="user-detail-modal">
			<div class="modal-header">User Details</div>
			<div class="modal-body">
				<div class="row" v-if="item">
					<div class="col-6">
						<form>
							<div class="row contact-row">
								<div class="column">
									<div class="form-group">
										<label for="name">First name</label>
										<label style="color: red;">*</label>
										<input
											required
											@input="validationHelper($event)"
											@blur="validationHelper($event)"
											v-model="item.given_name"
											class="form-control"
											id="given_name"
											type="text"
											placeholder="First name"
										/>
										<span class="invalid-label" hidden>
											Please enter a valid first name
										</span>
									</div>
								</div>
								<div class="column">
									<div class="form-group">
										<label for="name">Last name</label>
										<label style="color: red;">*</label>
										<input
											required
											@input="validationHelper($event)"
											@blur="validationHelper($event)"
											v-model="item.last_name"
											class="form-control"
											id="last_name"
											type="text"
											placeholder="Last name"
										/>
										<span class="invalid-label" hidden>
											Please enter a valid last name
										</span>
									</div>
								</div>
								<div class="column">
									<div class="form-group">
										<label for="name">Staff ID</label>
										<input
											v-model="item.staff_id"
											class="form-control"
											id="staff_id"
											type="text"
											placeholder="Staff or POS ID"
										/>
									</div>
								</div>
							</div>
							<div class="form-group text-left">
								<label for="name">Active</label>
								<input
									v-model="item.active"
									class="form-control"
									id="active"
									type="checkbox"
								/>
							</div>
							<div class="row contact-row">
								<div class="column">
									<div class="form-group">
										<label for="name">Login</label>
										<label style="color: red;">*</label>
										<input
											required
											@input="validationHelper($event)"
											@blur="validationHelper($event)"
											v-model="item.login"
											class="form-control"
											size="50"
											id="login"
											type="text"
											placeholder="Login Email Address"
											:disabled="mode != 'add'"
										/>
										<span class="invalid-label" hidden>
											Please enter a valid email
										</span>
									</div>
								</div>
							</div>
							<div class="row contact-row">
								<div class="column">
									<div class="form-group">
										<label for="name">Email</label>
										<input
											v-model="item.alt_email"
											class="form-control actual-email-address"
											id="alt_email"
											type="text"
											placeholder="Actual Email Address"
											@keyup="validateAltEmail"
											v-on:blur="altEmailToLowerCase()"
										/>
										<span class="invalid-label" hidden>
											This email is not valid.
										</span>
									</div>
								</div>
								<div class="column">
									<div class="form-group">
										<label for="name">Verified</label>
										<input
											v-model="item.email_verified"
											class="form-control"
											id="email_verified"
											type="checkbox"
											placeholder="0"
										/>
									</div>
								</div>
								<div class="column">
									<div class="form-group">
										<label for="name">Mobile</label>
										<input
											v-model="item.mobile"
											class="form-control"
											id="mobile"
											type="text"
											placeholder="E.g. +61412345678."
											@keyup="validateMobile"
										/>
										<span class="invalid-label" hidden>
											This phone number is not valid.
										</span>
									</div>
								</div>
								<div class="column">
									<div class="form-group">
										<label for="name">Verified</label>
										<input
											v-model="item.mobile_verified"
											class="form-control"
											id="mobile_verified"
											type="checkbox"
										/>
									</div>
								</div>
								<div class="column">
									<div class="form-group">
										<label for="name">Preferred contact:</label>
										<select v-model="item.preferred_contact_method">
											class="form-control" id="mobile_verified">
											<option>email</option>
											<option>sms</option>
										</select>
									</div>
								</div>

								<div class="form-group" v-if="mode == 'add'">
									<label for="name">Send notification?</label>
									<input
										v-model="sendNotification"
										class="form-control"
										id="send_notification"
										type="checkbox"
									/>
								</div>

								<!-- <div class="error-text">{{errorText}}</div> -->
							</div>
							<div class="row contact-row" v-if="mode != 'add'">
								<div class="column">
									<label>Created: {{ localDate(item.date_created) }}</label>
								</div>
								<div class="column">
									<label
										>Last login:
										{{
											item.date_last_login.match('19..-')
												? 'Never'
												: localDate(item.date_last_login)
										}}</label
									>
								</div>
							</div>
						</form>
					</div>
					<div class="col is-6">
						<div class="form-group">
							<vue-tabs>
								<v-tab title="Roles">
									<DualListBox
										:source="source"
										:destination="destination"
										label="name"
										@onChangeList="onChangeList"
									/>
								</v-tab>
								<v-tab title="Stores">
									<DualListBox
										:source="sourceStores"
										:destination="destinationStores"
										label="name"
										@onChangeList="onChangeListStores"
									/>
								</v-tab>
							</vue-tabs>
						</div>
					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button
					:disabled="isSaving"
					v-on:click="saveDetails()"
					class="btn btn-primary mb-3"
				>
					Save
				</button>
				<button
					:disabled="isSaving"
					v-on:click="saveDetails('close')"
					class="btn btn-primary mb-3"
				>
					Save & Close
				</button>
				<button v-on:click="resetPassword(item)" class="btn btn-secondary mb-3">
					Reset Password
				</button>
				<button v-on:click="$modal.hide('user-detail-modal')" class="btn mb-3">
					Cancel
				</button>
			</div>
		</modal>
	</div>
</template>

<script>
import axios from 'axios';
import { GlobalStore } from '../../globalStore';
//import { imageMethods } from '../../helpers/images'
import DualListBox from 'dual-listbox-vue';
import 'dual-listbox-vue/dist/dual-listbox.css';

export default {
	name: 'user-detail-modal',
	data() {
		return {
			imageBlobEndpoint: GlobalStore.imageEndpoint,
			source: [],
			destination: [],
			sourceStores: [],
			destinationStores: [],
			// adminRoles: ["admin_global","admin_customer_user","admin_customer_top_story","admin_customer_best_seller","admin_customer_best_store","admin_customer_action_request","admin_customer_image"],
			isAdminGlobal: false, // Does the current have admin_global? Used for filtering the listed admin roles
			isAdminCustomerUser: false, // Does the current have admin_customer_user? Used for filtering the listed admin roles
			errorText: '',
			sendNotification: false,
			isSaving: false
		};
	},
	props: {
		title: String,
		item: null,
		modalName: String,
		mode: {
			default: 'edit',
			type: String
		}
	},
	methods: {
		/**
		 * Checks given input and returns it's validation status.
		 * @param {object} field HTML object of given input.
		 * @returns {bool} Validation status of given input.
		 */
		checkField(field) {
			let validity = true;

			// console.log("null check: field", field.id);
			if (field.value.trim() == '') {
				field.classList.add('invalid-input');
				field.nextElementSibling.hidden = false;
				validity = false;
			} else if (field.id == 'login') {
				if (!this.validateEmail(field.value.trim())) {
					field.classList.add('invalid-input');
					field.nextElementSibling.hidden = false;
					validity = false;
				} else {
					field.classList.remove('invalid-input');
					field.nextElementSibling.hidden = true;
				}
			} else {
				field.classList.remove('invalid-input');
				field.nextElementSibling.hidden = true;
			}

			return validity;
		},
		localDate(datestamp) {
			var d = new Date(datestamp);
			var options = {
				weekday: 'short',
				year: 'numeric',
				month: '2-digit',
				day: 'numeric'
			};
			return d.toLocaleDateString('en-AU', options);
		},

		validationHelper(event) {
			this.checkField(event.target);
		},

		validateAltEmail() {
			const email = document.getElementById('alt_email');
			// console.log("null check validateAltEmail", this.item);
			if (this.item.alt_email) {
				if (this.item.alt_email.trim() == '') {
					// Clear any whitespace
					email.classList.remove('invalid-input');
					email.nextElementSibling.hidden = true;
					return true;
				} else {
					if (!this.validateEmail(this.item.alt_email)) {
						email.classList.add('invalid-input');
						email.nextElementSibling.hidden = false;
						return false;
					} else {
						email.classList.remove('invalid-input');
						email.nextElementSibling.hidden = true;
						return true;
					}
				}
			} else {
				return true; // return true if there is no email
			}
		},

		validateMobile() {
			const mobile = document.getElementById('mobile');
			// console.log("null check validateMobile", this.item);
			if (this.item.mobile.trim() == '') {
				// Clear any whitespace
				this.item.mobile = '';
				mobile.classList.remove('invalid-input');
				mobile.nextElementSibling.hidden = true;
				return true;
			} else {
				if (!this.validateMobileHelper(this.item.mobile)) {
					mobile.classList.add('invalid-input');
					mobile.nextElementSibling.hidden = false;
					return false;
				} else {
					mobile.classList.remove('invalid-input');
					mobile.nextElementSibling.hidden = true;
					return true;
				}
			}
		},

		validateEmail: function(email) {
			var re = /\S+@\S+\.\S+/;
			return re.test(String(email));
		},
		validateMobileHelper: function(mobile) {
			var re = /^\+\d{8,12}$/im;
			return re.test(String(mobile));
		},
		validateForm: function() {
			this.formValid = true;

			const fields = document.querySelectorAll('[required]');
			fields.forEach((field) => {
				if (this.checkField(field) == false) {
					this.formValid = false;
				}
			});

			if (!this.validateAltEmail() && !this.email_verified) {
				this.formValid = false;
			}

			if (!this.validateMobile() && !this.mobile_verified) {
				this.formValid = false;
			}
		},

		altEmailToLowerCase: function() {
			if (this.item.alt_email) {
				this.item.alt_email = this.item.alt_email.trim().toLowerCase();
			}
		},

		saveDetails: function(closeAction = 'none') {
			this.validateForm();

			if (this.formValid) {
				this.item.roles = [];
				this.item.stores = [];

				this.destination.forEach((f) => {
					this.item.roles.push(f.code);
				});

				this.destinationStores.forEach((f) => {
					this.item.stores.push(f.code);
				});
				// console.log("saveDetails: item.alt_email:", this.item.alt_email);
				this.$emit(
					'saveDetails',
					this.item,
					this.sendNotification,
					closeAction
				);
				// console.log("closeAction=" + closeAction);
				//if(closeAction == "close"){
				//this.$modal.hide('user-detail-modal');
				//}
			} else {
				GlobalStore.toastObj = this.$toasted.show(
					'Some information is not valid. User cannot be saved.',
					{
						theme: 'outline',
						position: 'bottom-center',
						duration: 5000,
						type: 'error',
						fitToScreen: true,
						fullWidth: false
					}
				);
			}
		},
		resetPassword(item) {
			// console.log(item);
			// console.log('reset password: ' + item.login);

			if (item.preferred_contact_method == 'sms' && !item.mobile_verified) {
				GlobalStore.toastObj = this.$toasted.show(
					'The mobile number has not been verified. The preferred contact method needs to be verified.',
					{
						theme: 'outline',
						position: 'bottom-center',
						duration: 5000,
						type: 'error',
						fitToScreen: true,
						fullWidth: false
					}
				);
				return;
			}
			if (item.preferred_contact_method == 'email' && !item.email_verified) {
				GlobalStore.toastObj = this.$toasted.show(
					'The email address has not been verified. The preferred contact method needs to be verified.',
					{
						theme: 'outline',
						position: 'bottom-center',
						duration: 5000,
						type: 'error',
						fitToScreen: true,
						fullWidth: false
					}
				);
				return;
			}
			const headers = {
				'Content-Type': 'application/x-www-form-urlencoded',
				Authorization: 'Bearer ' + GlobalStore.access_token
			};

			axios
				.get(
					GlobalStore.APIEndpoint +
						'/todaycx-admin/password_reset_user/?login=' +
						item.login,
					item,
					{
						headers: headers
					}
				)
				.then((resp) => {
					console.log(resp);
					GlobalStore.toastObj = this.$toasted.show(
						'This person has been sent information about changing their password',
						{
							theme: 'outline',
							position: 'bottom-center',
							duration: 5000,
							type: 'information',
							fitToScreen: true,
							fullWidth: false
						}
					);
				})
				.catch((err) => {
					GlobalStore.toastObj = this.$toasted.show(
						JSON.stringify(err.response.data.message),
						{
							theme: 'outline',
							position: 'bottom-center',
							duration: 5000,
							type: 'error',
							fitToScreen: true,
							fullWidth: false
						}
					);
				});
		},
		onChangeList: function({ source, destination }) {
			this.source = source;
			this.destination = destination;
		},
		onChangeListStores: function({ source, destination }) {
			this.sourceStores = source;
			this.destinationStores = destination;
		},
		initModal: function() {
			this.destination = [];
			this.destinationStores = [];

			console.log(JSON.stringify(this.item));

      this.isAdminGlobal = GlobalStore.userMeObject.roles.includes(
				'admin_global'
			);
			// console.log("this.isAdminGlobal=" + this.isAdminGlobal);
			this.isAdminCustomerUser = GlobalStore.userMeObject.roles.includes(
				'admin_customer_user'
			);
			// console.log("this.isAdminCustomerUser=" + this.isAdminCustomerUser);

			this.item.roles.forEach((f) => {
				// console.log(f);
				if (f != 'admin_global' || this.isAdminGlobal) {
					// only show the admin_global role to other admin_globals
					var newItem = { name: f, code: f };
					this.destination.push(newItem);
				}
			});

			this.item.stores.forEach((f) => {
				// console.log(f);
				//if(f != "admin_global" || this.isAdminGlobal){ // only show the admin_global role to other admin_globals
				var newItem = { name: f, code: f };
				this.destinationStores.push(newItem);
				//}
			});

			this.source = [];
			this.sourceStores = [];

			// Redundant code? Seemingly replaced by GlobalStore.custObj.config.roles below
			// this.adminRoles.forEach(f=>{
			//   console.log(f);
			//   console.log(this.destination);
			//   console.log(this.destination.find(i => i.code == f));
			//   // for each admin role that's not in destination list, push it to the source list
			//   if (this.destination.find(i => i.code == f) == null) {
			//     var newItem = { name: f, code: f };
			//     this.source.push(newItem);
			//   }
			// });

			// for role that's not in destination list, push it to the source list
			// It got to the source list from this.item
			GlobalStore.custObj.config.roles.forEach((f) => {
				var newItem = { name: f, code: f };
				if (this.destination.find((i) => i.code == f) == null) {
					if (newItem.code != 'admin_global' || this.isAdminGlobal) {
						this.source.push(newItem);
					}
				}
			});

			console.log(GlobalStore.custObj.config.stores);

			GlobalStore.custObj.config.stores.forEach((f) => {
				var newItem = { name: f, code: f };
				if (this.destinationStores.find((i) => i.code == f) == null) {
					//if(newItem.code != "admin_global" || this.isAdminGlobal){
					this.sourceStores.push(newItem);
					//}
				}
			});

		}
	},
	components: {
		DualListBox
	},
	async mounted() {
		console.log('Mounted.');
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Modal */

.v--modal-overlay {
	position: fixed;
	box-sizing: border-box;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	background: rgba(0, 0, 0, 0.7);
	z-index: 2000;
	opacity: 1;
}

.modal-header {
	font-size: 16pt;
	color: var(--primary);
}

.modal-body {
	margin-bottom: 70px;
	max-height: calc(100vh - 210px);
	overflow-y: auto;
}

.modal-footer {
	position: absolute;
	bottom: 0px !important;
	width: 100%;
	display: inline-block;
	background-color: white;
}

.invalid-input {
	border: 2px solid red;
}

.invalid-label {
	color: red;
	font-size: 10px;
}

.medialibrary-image-details {
	width: 100%;
	height: 240px;
	background-repeat: no-repeat !important;
	background-size: contain !important;
	background-position: center !important;
}

.list-box-wrapper {
	font-size: 9pt;
}

.list-box-wrapper .list-box-item .list-box .list-item {
	padding: 0.25rem 0.5rem;
	border-bottom: 1px solid #ccc;
	cursor: pointer;
}

.contact-row {
	margin-left: 0px;
	width: 100%;
}

.contact-row .column {
	margin-right: 13px;
}

.contact-row input[type='checkbox'],
#active {
	transform: scale(0.5);
}

.contact-row select {
	display: block;
	font-size: 13px;
}

.actual-email-address {
	width: 200px;
}

section {
	padding-top: 30px;
	padding-bottom: 30px;
}

#active {
	width: 40px;
}
</style>
