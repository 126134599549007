<template>
  <div>
    <div class="card mb-3">
      <div class="row">
        <div class="col-6">
          <h3>Media Library</h3>
        </div>
        <!-- <div class="col-6">
          <div class="img-container">
            <image-uploader
              id="image1"
              :preview="false"
              capture="environment"
              :debug="1"
              doNotResize="gif"
              :autoRotate="true"
              outputFormat="verbose"
              @input="setImage($event)"
            >
              <label for="image1" slot="upload-label" class="upload-label">
                <div title="Upload Image" class="upload-button">Upload image</div>
              </label>
            </image-uploader>
          </div>
        </div> -->
      </div>
      <!-- <div class="row">
        <div class="col-12">
          <paginator :pagingInfo="pagingInfo" @selectPage="selectPage" />
        </div>
      </div> -->
      <ImportImage
        v-if="custObj"
          :pagingInfo="pagingInfo"
          :useAsImageChooser="useAsImageChooser"
          @selectPage="selectPage"
          @useImage="useImage"
      />
      <searchFilter
        v-if="custObj && itemList"
        :pagingInfo="pagingInfo"
        @selectPage="selectPage"
        @setItemType="setItemType"
        @showArchived="showArchived"
        @setSearchString="setSearchString"
        listItemType="image"
        hideStatus
        hideExpired
        :custObj="custObj"
      />
      <div v-if="this.contentLoading">
        <page-loader></page-loader>
      </div>
      <div class="row no-gutters">
        <div
          v-bind:class="{
            'col-md-2 col-xxl-2 mb-2 pr-md-2': useAsImageChooser,
            'col-md-3 col-xxl-3 mb-3 pr-md-3': !useAsImageChooser,
          }"
          class=""
          v-for="(item, index) in itemList"
          :key="index"
        >
          <div
            :alt_text="item.alt_text"
            :title="
              item.description +
                '\nWidth: ' +
                (item.width ? item.width : '0') +
                'px\nHeight: ' +
                (item.height ? item.height : '0') +
                'px'
            "
            @click="useAsImageChooser ? useImage(item) : null"
            class="card medialibrary-image"
          >
            <tcx-image
              className="img-fluid"
              :imageSrc="item.thumbnail_url ? item.thumbnail_url : item.url"
              :aspectBlur="true"
            />

            <div>
              <div
                class="card-body"
                :alt="item.thumbnail_alt_text"
                :title="item.thumbnail_alt_text"
              >
                <span
                v-if="item.sku.code.trim().length > 0 || item.sku.variation.trim().length > 0"
                class="badge sku pull-right hidden-xs"
                >{{abbrSkuString(item.sku.code)}}/{{abbrSkuString(item.sku.variation)}}</span
                >
                <span
                  v-if="item.is_archived"
                  class="badge archived pull-right hidden-xs"
                  >archived</span
                >
                <span
                  v-if="item.item_type"
                  class="badge item_type pull-right hidden-xs"
                  >{{ item.item_type }}</span
                >
                <div @click="openDetails(item)" class="info-button">
                  <i class="fas fa-info-circle"></i>
                </div>
                <div @click="confirmDelete(item)" class="delete-button">
                  <i class="fa fa-trash"></i>
                </div>
                <!-- <div v-if="! useAsImageChooser" class="use-button">
                  <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    @click="useImage(item)"
                  >
                    Select
                  </button>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <confirmation-modal
      modalName="image-delete-modal"
      :itemId="selectedItem && selectedItem.image_guid"
      @onConfirm="deleteItem"
      title="Delete Image"
      bodyText="Are you sure you want to delete this image?"
      okText="Delete"
      cancelText="Cancel"
    ></confirmation-modal>
    <image-detail-modal :item="selectedItem" @saveDetails="saveItem" />
  </div>
</template>

<script>
import ConfirmationModal from "../components/modals/ConfirmationModal";
import ImageDetailModal from "../components/modals/ImageDetailModal";
import { GlobalStore } from "../globalStore";
import { imageMethods } from "../helpers/images";
import axios from "axios";
import ImportImage from "../components/ImportImage";
import SearchFilter from "../components/SearchFilter";
import pageLoader from "../components/pageLoader";
import tcxImage from "../components/tcxImage";

export default {
  name: "media-library-component",
  data() {
    return {
      itemList: [],
      view: "List",
      imageBlobEndpoint: GlobalStore.imageEndpoint,
      selectedItem: null,
      item: { images: {} },
      pagingInfo: {},
      image: null,
      searchString: "",
      willShowExpired: false,
      willShowArchived: false,
      item_type: "All",
      contentLoading: false,
      maxSkuCodeLength: 12,
    };
  },
  props: {
    useAsImageChooser: Boolean,
    //msg: String
  },
  methods: {
    abbrSkuString: function(stringSku) {
      if(stringSku && stringSku.length > this.maxSkuCodeLength){
        return stringSku.substring(0, this.maxSkuCodeLength) + '...';
      }
      return stringSku;
    },
    setSearchString: function(val) {
      console.log(val);
      this.searchString = val;
      this.getItems(1);
    },
    showArchived: function(val) {
      console.log(val);
      this.willShowArchived = val;
      this.getItems(this.current_page);
    },
    setItemType: function(type) {
      console.log(type);
      this.item_type = type;
      this.getItems(this.current_page);
    },
    useImage: function(imageObj) {
      event.stopPropagation();
      this.$emit("useImage", imageObj);
    },
    selectPage(pageNo) {
      console.log("selectPage", pageNo);
      this.pagingInfo.current_page = pageNo;
      this.getItems(this.pagingInfo.current_page);
    },
    imageToDataUri: function(img, width, height, type) {
      // create an off-screen canvas
      var canvas = document.createElement("canvas"),
        ctx = canvas.getContext("2d");

      // set its dimension to target size
      canvas.width = width;
      canvas.height = height;

      // draw source image into the off-screen canvas:
      ctx.drawImage(img, 0, 0, width, height);

      // encode image to data-uri with base64 version of compressed image
      return canvas.toDataURL(type);
    },
    setImage: function(output) {
      this.image = output;
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      console.log("MediaLibraryComponent:setImage");


      //Thumbnail
      var ratio = this.image.info.orgWidth / this.image.info.orgHeight;

      var img = new Image();

      img.onload = () => {
        var thumbnail = this.imageToDataUri(
          img,
          250,
          250 / ratio,
          this.image.info.type
        );
        console.log("MediaLibraryComponent: imageThumbnail", thumbnail);

        axios
          .post(
            GlobalStore.APIEndpoint + "/todaycx-admin/image",
            {
              base64: thumbnail.replace(
                "data:" + this.image.info.type + ";base64,",
                ""
              ),
              content_type: this.image.info.type,
              is_thumbnail: true,
            },
            {
              headers: headers,
            }
          )
          .then((thumbresp) => {
            console.log(thumbresp);
            this.item.images.thumbnail_image = thumbresp.data.image_guid;
            this.item.images.thumbnail_image_url = thumbresp.data.url;

            axios
              .post(
                GlobalStore.APIEndpoint + "/todaycx-admin/image",
                {
                  base64: this.image.dataUrl.replace(
                    "data:" + this.image.info.type + ";base64,",
                    ""
                  ),
                  content_type: this.image.info.type,
                  is_thumbnail: false,
                  thumbnail_image_guid: thumbresp.data.image_guid,
                  thumbnail_url: thumbresp.data.url,
                  width: this.image.info.orgWidth,
                  height: this.image.info.orgHeight,
                },
                {
                  headers: headers,
                }
              )
              .then((resp) => {
                console.log(resp);
                //if (type == 'hero') {
                GlobalStore.customerImages.push(resp.data);
                console.log(resp.data);

                this.item.images.hero_image = resp.data.image_guid;
                this.item.images.hero_image_url = resp.data.url;

                console.log(this.item);

                if (this.useAsImageChooser) {
                  //console.log(this.item);
                  this.$emit("addImage", this.item);
                } else {
                  this.getItems(this.pagingInfo.current_page);
                }
              });
          });
      };

      img.src = this.image.dataUrl;
    },
    saveItem: function(imageItem) {
      console.log(JSON.stringify(imageItem));
      imageMethods.putImage(imageItem.image_guid, imageItem).then((resp) => {
        console.log("Next line should be the resp object");
        console.log(resp);
        //this.getItems(this.pagingInfo.current_page);

        imageMethods.getImages(this.pagingInfo.current_page).then(() => {
          //this.itemList = respUpdate.data.data;
          //console.log(this.pagingInfo);

          this.getItems(this.current_page);

          this.$modal.hide("image-detail-modal");

          GlobalStore.toastObj = this.$toasted.show("Image Details Updated", {
            theme: "outline",
            position: "bottom-center",
            duration: 3000,
            type: "success",
            fitToScreen: true,
            fullWidth: false,
          });
        });
      });
    },
    deleteItem(image_guid) {
      console.log(image_guid);
      console.log(this.itemList);

      var thumbnail_image_guid = this.itemList.find(
        (f) => f.image_guid == image_guid
      ).thumbnail_image_guid;

      console.log(thumbnail_image_guid);
      //console.log('Delete' + idx);
      this.$modal.hide("image-delete-modal");
      imageMethods.deleteImage(image_guid).then(async () => {
        if (thumbnail_image_guid != null) {
          await imageMethods.deleteImage(thumbnail_image_guid);
        }

        GlobalStore.toastObj = this.$toasted.show("Image Deleted", {
          theme: "outline",
          position: "bottom-center",
          duration: 3000,
          type: "success",
          fitToScreen: true,
          fullWidth: false,
        });

        this.getItems(this.pagingInfo.current_page);
      });
    },
    getItems(pageNum) {
      // Get Pagination Info first
      this.contentLoading = true;

      imageMethods
        .getImages(
          pageNum,
          this.willShowArchived,
          this.searchString,
          this.item_type
        )
        .then((resp) => {
          this.pagingInfo = resp.data;

          this.pagingInfo["current_page"] = pageNum;

          this.itemList = resp.data.data;
          this.contentLoading = false;
          //console.log(this.pagingInfo);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    openDetails(item) {
      this.selectedItem = { ...item };

      console.log(item);
      console.log("*** Opening Modal! ***");
      this.$modal.show("image-detail-modal");
      window.event.stopPropagation();
    },
    editItem: function(guid) {
      console.log(guid);
      //
      this.$router.push("/toDo/" + guid);
    },
    confirmDelete(item) {
      event.stopPropagation();
      //console.log(item);
      this.selectedItem = item;
      this.$modal.show("image-delete-modal");
    },
    checkView() {
      //if (typeof _view === 'undefined') {
      this.view = "List";

      imageMethods.getImages(1).then((pagingResp) => {
        console.log(pagingResp);

        this.pagingInfo = { ...pagingResp.data };
        delete this.pagingInfo.data;
        this.pagingInfo.current_page = 1;
        console.log(this.pagingInfo);
        this.getItems(this.pagingInfo.current_page);
      });

      // } else if (_view == 'add') {
      //     this.view = 'Add';
      //   }
      // else {
      //   this.view = 'Edit';
      //   //console.log(this.itemList);
      //   imageMethods.getImage(_view).then(
      //     resp => {
      //       this.selectedItem = resp.data;
      //     }
      //   )
      // }
    },
  },
  computed: {
    custObj: function() {
      return GlobalStore.custObj;
    },
  },
  async mounted() {
    this.checkView();
    console.log("*** Media Library Component loaded ***");
  },
  components: {
    ConfirmationModal,
    ImageDetailModal,
    SearchFilter,
    pageLoader,
    tcxImage,
    ImportImage,
  },
};
</script>

<style scoped>
.btn-sm {
  font-size: 8pt;
  padding: 2px 4px 2px 4px;
}

.card {
  padding: 30px;
}

.card-body {
  padding: 0;
}

.medialibrary-image {
  background-size: cover !important;
  background-position: top !important;
  border-radius: 3px;
  padding: 0;
  cursor: pointer;
  height: 150px;
}

.medialibrary-image img {
  border-radius: 3px;
}

.delete-button {
  position: absolute;
  bottom: 2px;
  right: 30px;
}
.delete-button svg {
  background: white;
  border-radius: 50%;
  border: 1px;
  border-color: white;
  border-style: solid;
}

.info-button {
  position: absolute;
  bottom: 2px;
  right: 3px;
  /* background: white;
  border-radius: 50%;
  border
  border: 1px;
  border-color: white;
  border-style: solid; */
}

.info-button svg {
  background: white;
  border-radius: 50%;
  border: 1px;
  border-color: white;
  border-style: solid;
}

.use-button {
  position: absolute;
  bottom: 3px;
  right: 3px;
}

.upload-button {
  padding: 0px;
  border-radius: 5px;
  font-size: 100%;
  text-align: center;
  background-color: lightgrey;
  cursor: pointer;
}

.upload-label {
  width: 100%;
  cursor: pointer;
}

.img-container {
  min-width: 165px;
  max-width: 165px;
  margin-left: auto;
}

.badge {
  padding: 3px 10px 2px 10px;
  opacity: 0.6;
  font-size: 7pt;
  font-weight: normal;
  z-index: 2;
}

.badge.item_type {
  background-color: lightslategray;
  color: #ffffff;
  bottom: 8px;
  left: 8px;
}

.badge.archived {
  background-color: firebrick;
  color: #ffffff;
  position: absolute;
  bottom: 24px;
  left: 8px;
}

.badge.sku {
  background-color: purple;
  color: #ffffff;
  position: absolute;
  top: 8px;
  left: 8px;
}
.info-button,
.delete-button,

@media (max-width: 414px) {
  .upload-button {
    width: 100px;
  }
  .img-container[data-v-8dbbe0f4] {
    min-width: 100px;
    max-width: 100px;
    margin-left: auto;
  }
}
</style>
