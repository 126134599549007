import Vue from "vue";
//import axios from "axios";
//import { ImageMethods } from './helpers/images'
export const GlobalStore = new Vue({
  data: {
        version: "1.32.1",
    APIEndpoint: process.env.VUE_APP_APIENDPOINT,
    imageEndpoint: process.env.VUE_APP_IMAGEENDPOINT,
    isAuthenticated: false,
    userObject: null,
    userMeObject: null,
    view: "landing",
    custGuid: "",
    custObj: "",
    refresh_token: "",
    expires_in: 0,
    expires_at: 0,
    expireTimer: null,
    access_token: "",
    customerImages: [],
    apiSchemas: [],
    defaultPageSize: 100,
    htmlEditorToolbar: [
      [{ header: [false, 1, 2, 3, 4, 5, 6] }],
      ["bold", "italic", "underline", "strike"], // toggled buttons
      [
        { align: "" },
        { align: "center" },
        { align: "right" },
        { align: "justify" },
      ],
      ["blockquote"],
      [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
      [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      ["link", "video"],
      ["clean"], // remove formatting button
    ],
  },
});
