<template>
  <div v-if="currentItem">
    <h3>{{ title }}</h3>
    <div class="container">
      <div class="row">
        <div class="col-9">
          <form>
            <div class="form-group">
              <label for="mode">Response required from</label>
              <label style="color: red;">*</label>
              <select
                required
                @input="validationHelper($event)"
                :disabled="view == 'Edit'"
                @blur="validationHelper($event)"
                v-model="currentItem.mode"
                class="form-control select-input"
                id="mode"
              >
                <option value="user">User</option>
                <option value="store">Store</option>
              </select>
              <span class="invalid-label" hidden>Selection invalid</span>
            </div>
            <div class="form-group">
              <label for="name">Title</label>
              <label style="color: red;">*</label>
              <input
                required
                @input="validationHelper($event)"
                @blur="validationHelper($event)"
                v-model="currentItem.title"
                class="form-control"
                id="title"
                type="text"
              />
              <span class="invalid-label" hidden
                >Please enter a valid title</span
              >
            </div>
            <div class="form-group">
              <label for="name">Summary</label>
              <label style="color: red;">*</label>
              <input
                required
                @input="validationHelper($event)"
                @blur="validationHelper($event)"
                v-model="currentItem.summary"
                class="form-control"
                id="summary"
                type="text"
              />
              <span class="invalid-label" hidden
                >Please enter a valid summary</span
              >
            </div>
            <div class="row contact-row">
              <div class="column form-group">
                <label for="item_type">Type</label>
                <label style="color: red;">*</label>
                <select
                  required
                  @input="validationHelper($event)"
                  @blur="validationHelper($event)"
                  v-model="currentItem.item_type"
                  class="form-control select-input"
                  id="item_type"
                >
                  <option
                    class="item_type_option"
                    v-for="itemType in this.itemTypes"
                    :key="itemType"
                    :value="itemType"
                    >{{ itemType }}</option
                  >
                </select>
                <span class="invalid-label" hidden
                  >Please enter a valid addon prompt</span
                >
              </div>
              <div class="column form-group">
                <label for="exampleFormControlSelect1">Publish Status</label>
                <label style="color: red;">*</label>
                <select
                  required
                  @input="validationHelper($event)"
                  @blur="validationHelper($event)"
                  v-model="currentItem.display.publish_status"
                  class="form-control select-input"
                  id="publish_status"
                >
                  <option value="draft">Draft</option>
                  <option value="published">Published</option>
                </select>
                <span class="invalid-label" hidden
                  >Please select a valid publish status</span
                >
              </div>
              <div class="column form-group text-left">
                <label for="name">Archived</label>
                <input
                      v-model="currentItem.is_archived"
                      class="form-control"
                      id="is_archived"
                      type="checkbox"
                    />
              </div>
              <div class="column form-group">
                <label for="name">Category</label>
                <label style="color: red;">*</label>
                <input
                  required
                  @input="validationHelper($event)"
                  @blur="validationHelper($event)"
                  v-model="currentItem.category"
                  class="form-control"
                  id="category"
                  type="text"
                />
                <span class="invalid-label" hidden
                  >Please enter a valid category</span
                >
              </div>
            </div>
            <div class="row contact-row">
              <div class="column form-group">
                <label for="name">Display Order (0 = unordered)</label>
                <label style="color: red;">*</label>
                <input
                  required
                  @input="validationHelper($event)"
                  @blur="validationHelper($event)"
                  v-model="currentItem.display.order_by"
                  class="form-control"
                  id="prder_by"
                  type="text"
                  placeholder="Display Order - 1 will show at top"
                />
                <span class="invalid-label" hidden
                  >Please enter a valid display order</span
                >
              </div>

              <div id="display-after-group" class="column form-group">
                <label for="name">Display after</label>
                <label style="color: red;">*</label>
                <!-- <input v-model="currentItem.display.expires"  class="form-control" id="expires" type="text" placeholder="Expires"> -->
                <flat-pickr
                  @input="checkFlatPickr('display-after-group')"
                  @blur="checkFlatPickr('display-after-group')"
                  v-model="currentItem.display.display_after"
                  :config="config"
                  class="form-control no-disable"
                ></flat-pickr>
                <span class="invalid-label" hidden
                  >Please enter a valid date</span
                >
              </div>
              <div id="expires-group" class="column form-group">
                <label for="name">Expires</label>
                <label style="color: red;">*</label>
                <!-- <input v-model="currentItem.display.expires"  class="form-control" id="expires" type="text" placeholder="Expires"> -->
                <flat-pickr
                  @input="checkFlatPickr('expires-group')"
                  @blur="checkFlatPickr('expires-group')"
                  v-model="currentItem.display.expires"
                  :config="config"
                  class="form-control no-disable"
                ></flat-pickr>
                <span class="invalid-label" hidden
                  >Please enter a valid date</span
                >
              </div>
            </div>
            <div class="row contact-row">
              <div class="column form-group">
                <label for="name">Due By</label>
                <flat-pickr
                  v-model="currentItem.due_by"
                  :config="config"
                  class="form-control no-disable"
                ></flat-pickr>
              </div>
            </div>
            <div class="form-group" v-if="currentItem.hyperlink">
              <label for="name">Hyperlink</label>
              <input
                v-model="currentItem.hyperlink.url"
                class="form-control"
                id="hyperlink-url"
                type="text"
                placeholder="example: https://www.google.com"
              />
            </div>
            <div class="form-group">
              <label for="name">Html</label>
              <vue-editor v-model="currentItem.body_html" :editorToolbar="customToolbar"></vue-editor>
            </div>
            <target-audience
              ref="targetAudience"
              :sourceArray="sourceArray"
              :destinationArray="destinationArray"
            />
            <div>
              <div style="display: inline-block;width:50%">
                <h3>To-do tasks</h3>
              </div>
              <div style="display: inline-block;width:50%">
                <button
                  type="button"
                  class="btn btn-primary float-right"
                  v-on:click="addActionRequestItem()"
                >
                  Add task
                </button>
              </div>

              <Container @drop="onDrop" class="drag-container">
                <Draggable
                  v-for="(ri, idx) in get_request_items()"
                  :key="ri.id"
                >
                  <div class="card mb-3 mt-3 mt-lg-0">
                    <div class="card-body fs--1">
                      <div
                        class="media"
                        v-on:click="editActionRequestItem(ri, idx)"
                      >
                        <div v-html="getTypeIcon(ri.request_type)"></div>
                        <div class="media-body ml-2 ri-item-label">
                          {{
                            ri.mandatory == true
                              ? ri.title + " (req'd)"
                              : ri.title
                          }}
                        </div>
                        <div class="media-body ml-2 ri-item-text">
                          {{
                            ri.mandatory == true
                              ? ri.request_text + "*"
                              : ri.request_text
                          }}
                          <div
                            v-if="
                              ri.request_type == 'checkbox' &&
                                ri.mandatory == true
                            "
                            class="ml-0 text-400"
                          >
                            A 'required' checkbox must be checked (yes) to
                            complete the Todo
                          </div>
                        </div>

                        <div
                          v-on:click="deleteActionRequestItem($event, idx)"
                          class="media-body ml-2 ri-item-delete"
                        >
                          <i class="fas fa-times"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </Draggable>
              </Container>
            </div>
            <div class="modal-footer">
              <button
                v-if="this.view == 'Edit' && this.currentItem.content_guid"
                :disabled="isSaving"
                class="btn btn-primary mb-3"
                type="button"
                @click="saveActionRequest(false)"
              >
                Save
              </button>
              <button
                :disabled="isSaving"
                class="btn btn-primary mb-3"
                type="button"
                @click="saveActionRequest(true)"
              >
                Save & Close
              </button>
              <button class="btn mb-3" type="button" @click="cancelAndReturn()">
                Cancel
              </button>
            </div>
          </form>
        </div>
        <div class="col-3">
          <image-upload-sidebar :item="currentItem"></image-upload-sidebar>
          <div style="min-height: 40px;">&nbsp;</div>
          <content-tags-sidebar
            type="todo"
            :item="currentItem"
            :selectedTags="this.currentItem.tags"
            @onSelectTag="onSelectTag"
            v-if="currentItem"
          />
        </div>
      </div>
    </div>

    <modal height="480" name="edit-request-item">
      <div class="modal-header">{{ capitalize(mode) }} Task</div>
      <div class="modal-body" v-if="selectedActionRequestItem">
        <div class="form-group">
          <label for="exampleFormControlSelect1">Request Type</label>
          <select
            v-model="selectedActionRequestItem.request_type"
            class="form-control"
            id="publish_status"
          >
            <option value="checkbox">Checkbox</option>
            <option value="yesno">Yes / No</option>
            <option value="imageupload">Image Upload</option>
          </select>
        </div>
        <div class="form-group">
          <label for="name">Title (This does not display for users on the app)</label>
          <input
            v-model="selectedActionRequestItem.title"
            class="form-control"
            type="text"
            placeholder="Title"
          />
        </div>
        <div class="form-group">
          <label for="name">Request Text (This is what the user sees on the app)</label>
          <input
            v-model="selectedActionRequestItem.request_text"
            class="form-control"
            type="text"
            placeholder="Request Text"
          />
        </div>
        <div class="form-group">
          <label for="name">Required </label>
          <input
            v-model="selectedActionRequestItem.mandatory"
            class="form-control"
            type="checkbox"
            id="mandatory-check"
          />
        </div>
      </div>
      <div class="modal-footer">
        <button
          v-on:click="
            saveActionRequestItem();
            $modal.hide('edit-request-item');
          "
          class="btn btn-primary mb-3"
        >
          Save
        </button>
        <button v-on:click="$modal.hide('edit-request-item')" class="btn mb-3">
          Cancel
        </button>
      </div>
    </modal>
  </div>
</template>
<script>
//import axios from "axios";
import { GlobalStore } from "../globalStore";
import { GlobalMethods } from "../globalMethods";
import axios from "axios";
import { Container, Draggable } from "vue-smooth-dnd";
import { VueEditor } from "vue2-editor";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
//import tcxImage from '../components/tcxImage'
import ImageUploadSidebar from "../components/ImageUploadSidebar";
import TargetAudience from "../components/TargetAudience";
import ContentTagsSidebar from "../components/ContentTagsSidebar.vue";

export default {
  name: "todo-item",
  data() {
    return {
      selectedTags: [],
      imageEndpoint: GlobalStore.imageEndpoint,
      selectedActionRequestItem: null,
      selectedActionRequestItemIndex: null,
      mode: "edit",
      custConfig: GlobalStore.custObj.config,
      config: {
        enableTime: true,
        altFormat: "d-m-Y h:i K",
        altInput: true,
        dateFormat: "Z",
      },
      isSaving: false,
      customToolbar: GlobalStore.htmlEditorToolbar
    };
  },
  props: {
    currentItem: Object,
    title: String,
    return: String,
    view: String,
    sourceArray: Array,
    destinationArray: Array,
  },
  computed: {
    filteredItemList: function() {
      return this.itemList;
    },
    itemTypes: function() {
      return GlobalMethods.getDisplayableItemTypes("action_request");
    }
    // currentItem:  function () {
    //   var tItem = JSON.parse(JSON.stringify(this.item));
    //   //if (tItem) {
    //     //tItem.body_html = this.htmlDecode(tItem.body_html);
    //   //}
    //   return tItem;
    // }
  },
  methods: {
    onSelectTag(tags) {
      this.selectedTags = tags;
    },
    // Validation methods
    validationHelper(event) {
      this.checkField(event.target);
    },

    checkFlatPickr(id) {
      const field = document.querySelector(`#${id}`);
      if (!Date.parse(field.querySelector("input").value)) {
        field.querySelectorAll("input")[1].classList.add("invalid-input");
        field.querySelector("span").hidden = false;
        return false;
      } else {
        field.querySelectorAll("input")[1].classList.remove("invalid-input");
        field.querySelector("span").hidden = true;
        return true;
      }
    },

    checkField(field) {
      let valid = true;
      if (field.value.trim() == "") {
        field.classList.add("invalid-input");
        field.nextElementSibling.hidden = false;
        valid = false;
      } else {
        field.classList.remove("invalid-input");
        field.nextElementSibling.hidden = true;
      }
      return valid;
    },

    validateForm: function() {
      const fields = document.querySelectorAll("[required]");
      const flat_pickrs = ["display-after-group", "expires-group"];
      let formValid = true;
      fields.forEach((field) => {
        if (this.checkField(field) == false) {
          formValid = false;
        }
      });
      flat_pickrs.forEach((pickr) => {
        if (!this.checkFlatPickr(pickr)) {
          formValid = false;
        }
      });
      if (formValid == false) {
        GlobalStore.toastObj = this.$toasted.show(
          "Please complete required fields",
          {
            position: "bottom-center",
            duration: 5000,
            type: "error",
            fitToScreen: true,
            fullWidth: false,
          }
        );
        return false;
      }
      return true;
    },

    // Other methods

    getTypeIcon: function(itm) {
      if (itm == "checkbox")
        return '<span class="far fa-check-square fa-2x"></span>';
      if (itm == "yesno") return '<span class="fas fa-toggle-on fa-2x"></span>';
      if (itm == "imageupload")
        return '<span class="fas fa-images fa-2x"></span>';
    },
    get_request_items: function() {
      return this.currentItem.request_items;
    },
    saveActionRequest: function(returnToList) {
      if (!this.validateForm()) {
        return;
      }

      var listData = this.$refs["targetAudience"].getAllListData();
      this.currentItem.display.regional_managers = listData.regional_managers;
      this.currentItem.display.regions = listData.regions;
      this.currentItem.display.roles = listData.roles;
      this.currentItem.display.store_types = listData.store_types;
      this.currentItem.display.stores = listData.stores;
      this.currentItem.tags = this.selectedTags;

      // Set defaults for empty vars
      if (this.currentItem.content_item_type.length == 0) {
        this.currentItem.content_item_type = "ToDo";
      }
      if (this.currentItem.display.publish_status.length == 0) {
        this.currentItem.display.publish_status = "draft";
      }
      console.log("Due by: " + this.currentItem.due_by);
      console.log("Display after: " + this.currentItem.display.display_after);

      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      var urlencoded = new URLSearchParams();
      urlencoded.append("grant_type", "refresh_token");
      urlencoded.append("expires_in", "7200");
      // WORKAROUND for issue when creating new items (HTML Editor trigger change of view mode)
      if (this.view == "Edit") {
        if (this.currentItem.action_request_guid) {
          if (this.currentItem.action_request_guid.length == 0) {
            // Can't have a zero-length content_guid - flip mode to 'Add'
            console.log("Zero-length guid found: Switched view mode to 'Add'");
            this.view = "Add";
          }
        } else {
          // Can't have a zero-length content_guid - flip mode to 'Add'
          console.log("Zero-length guid found: Switched view mode to 'Add'");
          this.view = "Add";
        }
      }
      // WORKAROUND for issue when creating new items (HTML Editor trigger change of view mode)

      this.isSaving = true;

      if (this.view == "Edit") {
        axios
          .put(
            GlobalStore.APIEndpoint +
              "/todaycx-admin/action_request/" +
              this.currentItem.action_request_guid,
            this.currentItem,
            {
              headers: headers,
            }
          )
          .then(() => {
            // this.$router.push(this.return);
            GlobalStore.toastObj = this.$toasted.show(
              "The item has been saved",
              {
                theme: "outline",
                position: "bottom-center",
                duration: 5000,
                type: "information",
                fitToScreen: true,
                fullWidth: false,
              }
            );
            if (returnToList == true) {
              this.cancelAndReturn();
            } else {
              this.isSaving = false;
            }
          })
          .catch((err) => {
            this.loggingIn = false;
            GlobalStore.toastObj = this.$toasted.show(
              JSON.stringify(err.response.data.message),
              {
                theme: "outline",
                position: "bottom-center",
                duration: 5000,
                type: "error",
                fitToScreen: true,
                fullWidth: false,
              }
            );
            if (returnToList == true) {
              this.cancelAndReturn();
            }
            this.isSaving = false;
          });
      } else {
        if (this.currentItem.action_request_guid == "") {
          console.log("Deleting emply action_request_guid");
          delete this.currentItem.action_request_guid;
        }
        this.currentItem.created_by = GlobalStore.userObject.user_id;
        //this.currentItem.action_request_type = "todo";

        this.currentItem.action_request_guid = this.$uuid.v1();

        axios
          .post(
            GlobalStore.APIEndpoint + "/todaycx-admin/action_request",
            this.currentItem,
            {
              headers: headers,
            }
          )
          .then((resp) => {
            console.log(resp);
            // this.$router.push(this.return);
            GlobalStore.toastObj = this.$toasted.show(
              "The item has been saved",
              {
                theme: "outline",
                position: "bottom-center",
                duration: 5000,
                type: "information",
                fitToScreen: true,
                fullWidth: false,
              }
            );
            if (returnToList == true) {
              this.cancelAndReturn();
            } else {
              this.isSaving = false;
            }
          })
          .catch((err) => {
            console.log(this.currentItem);
            this.loggingIn = false;
            GlobalStore.toastObj = this.$toasted.show(
              JSON.stringify(err.response.data.message),
              {
                theme: "outline",
                position: "bottom-center",
                duration: 5000,
                type: "error",
                fitToScreen: true,
                fullWidth: false,
              }
            );
            this.isSaving = false;
          });
      }
    },
    getCustGuid: function() {
      return GlobalStore.custGuid;
    },
    htmlDecode(input) {
      var e = document.createElement("div");
      e.innerHTML = input;
      return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    },
    cancelAndReturn() {
      this.$router.push(this.return);
    },
    onDrop: function(dropResult) {
      console.log(dropResult);
      var movedItem = JSON.parse(
        JSON.stringify(this.currentItem.request_items[dropResult.removedIndex])
      );
      this.currentItem.request_items.splice(dropResult.removedIndex, 1);
      this.currentItem.request_items.splice(
        dropResult.addedIndex,
        0,
        movedItem
      );
    },
    editActionRequestItem: function(selItem, selIndex) {
      this.mode = "edit";
      this.$modal.show("edit-request-item");
      this.selectedActionRequestItemIndex = selIndex;
      this.selectedActionRequestItem = JSON.parse(JSON.stringify(selItem));
      console.log(this.selectedActionRequestItem);
    },
    deleteActionRequestItem: function(evt, idx) {
      evt.stopPropagation();
      this.currentItem.request_items.splice(idx, 1);
    },
    addActionRequestItem: function() {
      this.mode = "add";
      this.$modal.show("edit-request-item");

      this.selectedActionRequestItem = {
        id: "0",
        mandatory: true,
        order_by: 0,
        request_text: "",
        request_type: "",
        title: "",
      };
    },
    getNextActionRequestId: function() {
      let tArray = [];
      this.currentItem.request_items.forEach((f) => {
        tArray.push(parseInt(f.id));
      });

      console.log(tArray);
      console.log(Math.max(...tArray));

      if (tArray.length > 0) {
        return Math.max(...tArray) + 1;
      }

      return 1;
    },
    saveActionRequestItem: function() {
      if (this.mode == "edit") {
        this.currentItem.request_items.splice(
          this.selectedActionRequestItemIndex,
          1,
          this.selectedActionRequestItem
        );
      } else {
        this.selectedActionRequestItem.id = this.getNextActionRequestId().toString();
        this.currentItem.request_items.push(this.selectedActionRequestItem);
      }
      // this.$modal.hide('edit-request-item');
    },
    capitalize(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
  },
  components: {
    VueEditor,
    Container,
    Draggable,
    flatPickr,
    ImageUploadSidebar,
    TargetAudience,
    ContentTagsSidebar,
    //tcxImage
  },
  async mounted() {
    // Setting default date values when creating new item
    if (this.view == "Add") {
      let now = new Date();
      this.currentItem.display.display_after = now;
      this.currentItem.display.expires = new Date(now).setDate(now.getDate() + 14)
      this.currentItem.due_by = new Date(now).setDate(now.getDate() + 7)
    }

    // Readyonly seems to be being added by default to 'flatpikr' components
    // this will overwrite it.
    document.querySelectorAll(".no-disable").forEach((item) => {
      item.readOnly = false;
    });

    this.selectedTags = this.currentItem.tags;



  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.modal-footer {
  text-align: right;
}

.ql-editor p {
  margin-bottom: 0.5 !Important;
}

table {
  background-color: white;
  margin-top: 20px;
}
#publish_status {
  font-size: 12px;
  padding-left: 0;
}
#item_type {
  font-size: 12px;
  padding-left: 0;
}
.contact-row {
  margin-left: 0px;
  width: 100%;
  
}

.contact-row .column {
  margin-right: 13px;
}

.contact-row input[type=checkbox], #active {
  transform: scale(0.5);
}

.contact-row select {
  display: block;
  font-size: 13px;
}

.form-control input[type=select]{
  font-size: 12px
}

.invalid-input {
  border: 2px solid red;
}

.invalid-label {
  color: red;
  font-size: 10px;
}

.card-img-top {
  max-height: 100px;
  overflow: hidden;
}

.card-title {
  min-height: 45px;
}

.card-text {
  min-height: 60px;
}

.additional-images {
  margin-top: 10px;
}

.additional-image {
  width: 50%;
}

.drag-container {
  background-color: lightgray;
  padding: 20px;
  border-radius: 20px;
  margin: 5px 0px 120px 0px;
}

.ri-item-label {
  line-height: 18pt;
  padding-left: 5px;
  font-size: 11pt;
  font-weight: bold;
}

.ri-item-delete {
  flex: none;
  margin-right: 10px;
}

.v--modal-overlay {
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2000;
  opacity: 1;
}

.modal-header {
  font-size: 16pt;
  color: var(--primary);
}

.modal-body {
  margin-bottom: 70px;
}

.modal-footer {
  position: absolute;
  bottom: 0px !important;
  width: 100%;
  display: inline-block;
  background-color: white;
}

.v--modal-overlay .v--modal-box {
  height: unset !important;
}

section {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.list-box-wrapper {
  font-size: 9pt;
}

.list-box-wrapper .list-box-item .list-box .list-item {
  padding: 0.25rem 0.5rem;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

#mandatory-check {
  width: 20px;
  height: 30px;
}

.select-input {
  font-size: 12px;
  padding-left: 0;
}
</style>
