<template>
   <div>
    <div class="contentDashboard card mb-3" v-if='dataLoaded && selectedContentItem'>

        <div class="row no-gutters ml-0">
          <h1 class="col-10">{{contentModuleTitle}} dashboard</h1>
          <button class="back-button col-2 btn btn-primary float-right" type="button" @click="retreatPage()">
            <i class="fas fa-chevron-left"></i>&nbsp;Back
          </button>
        </div>

        <h3>{{contentTitle}}</h3>
        <!-- {{selectedContentItem.action_request_guid}} -->
      <div class="mb-12 pl-md-0 pr-md-2" style="margin-bottom:20px;">
        <div class="card h-md-100">
          <div class="card-header d-flex flex-between-center pb-0">
            <h6 class="col-xs-4 mb-0">{{selectedContentItem.title}}
              <span
                class="ml-1 text-400"
                data-toggle="tooltip"
                data-placement="top"
                :title="'This shows a summary of responses to the ' + this.contentModuleTitle">
                <span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
              </span>
            </h6>
          </div>
          <div class="card-body p-1">
            <div class="container mb-3 fs--1">
              <div class="row">
                  <div class="page-thumbnail">
                    <tcx-image
                      className="roudned border border-200"
                      :imageSrc="selectedContentItem.images.thumbnail_image_url"
                      :aspectBlur="true"
                    />
                  </div>
                <div class="col-xl-4 col-lg-4 col-md-3 col-sm-12">
                  <div class="media align-items-top position-relative">
                    <div class="media-body ml-sm-0 ml-xl-3">
                      <h6 class="mb-1 font-weight-semi-bold">
                        Expires:
                        <!-- <a class="text-dark stretched-link" href="#!">Raven Pro</a> -->
                      </h6>
                      <p class="mb-0 text-500">{{selectedContentItem.expires_string}}</p>
                      <h6 class="mb-1 font-weight-semi-bold">
                        Summary:
                        <!-- <a class="text-dark stretched-link" href="#!">Raven Pro</a> -->
                      </h6>
                      <p class="mb-0 text-500">{{selectedContentItem.summary}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                  <div class="media align-items-top position-relative">
                    <div v-if="selectedContentItem.body_html.length>0" class="media-body">
                      <h6 class="mb-1 font-weight-semi-bold">
                        {{contentModuleTitle}} text:
                        <!-- <a class="text-dark stretched-link" href="#!">Raven Pro</a> -->
                      </h6>
                      <p class="font-weight-normal mb-0 text-500"><span v-html="selectedContentItem.body_html"></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="additionalImages.length > 0" class="mb-3 pl-md-0 pr-md-2">
        <div class="card h-md-100">
          <div class="card-header pb-0">
            <h6>Additional Image{{(additionalImages.length == 1) ? "" : "s"}}</h6>
          </div>
          <div class="card-body row pt-0">
            <div class="pl-1" @click="openModal(image)" v-for="image in additionalImages" :key="image">
              <div class="img-tiles">
                <tcx-image
                    className="rounded border border-200"
                    :imageSrc="image"
                    :aspectBlur="true"
                />
              </div> 
            </div>
          </div>
        </div>
      </div>

      <div class="row no-gutters">
        <!-- Top Summary Counts -->
        <div class="col-md-2 col-xxl-2 mb-3 pr-md-2">
          <div v-if="audienceUsers.hasOwnProperty('length')" class="card h-md-100">
            <div class="card-header pb-0">
              <h6 class="mb-0 mt-2 d-flex align-items-center">
                Audience
                <span
                  class="ml-1 text-400"
                  data-toggle="tooltip"
                  data-placement="top"
                  :title="'The people and stores who can see this ' + this.contentModuleTitle"
                >
                  <span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
                </span>
              </h6>
            </div>
            <div class="card-body d-flex align-items-end">
              <div class="row flex-grow-1 ml-0">
                <div class="col">
                  <div
                    class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1"
                  >{{audienceUsers.length}}</div>
                  <div
                    class="fs1 font-weight-normal text-sans-serif text-500 line-height-1 mb-1"
                  >people</div>
                </div>
                <!-- <div class="col-auto pl-0">
                  <div ref="chart3" class="echart-bar-weekly-sales h-100"></div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div v-if="['best_store'].includes(selectedContentItem.content_item_type)" class="col-md-10 col-xxl-10 mb-3 pr-md-2">
          <div class="card h-md-100">
            <div class="card-header pb-0">
              <h6 class="mb-0 mt-2 d-flex align-items-end">
                About engagement information
              </h6>
              <div class="card-body d-flex align-items-end">
                <div class="row flex-grow-1 ml-0">
                  <div class="col-1 fs-2 font-weight-normal mb-0 text-500">
                    <i class="fas fa-info-circle" aria-hidden="true"></i>
                  </div>
                  <div class="col font-weight-normal mb-0 text-500">
                    Enagement information cannot be collected for this content type because of the way it is displayed on the everyCX app.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>        
        <div v-if="audienceObject.hasOwnProperty('views') && ['best_seller','memo'].includes(selectedContentItem.content_item_type)" class="col-md-2 col-xxl-2 mb-3 pr-md-2">
          <div class="card h-md-100">
            <div class="card-header pb-0">
              <h6 class="mb-0 mt-2 d-flex align-items-center">
                Views
                <span
                  class="ml-1 text-400"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="The number of first-time views so far."
                >
                  <span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
                </span>
              </h6>
            </div>
            <div class="card-body d-flex align-items-end">
              <div class="row flex-grow-1 ml-0">
                <div class="col">
                  <div
                    class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1"
                  >{{audienceObject.views.length}}
                  </div>
                  <div
                    class="fs1 font-weight-normal text-sans-serif text-500 line-height-1 mb-1"
                  >people</div>
                </div>
                <!-- <div class="col-auto pl-0">
                  <div ref="chart4" class="echart-bar-weekly-sales h-100"></div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div v-if="audienceObject.hasOwnProperty('rating') && ['best_seller','memo'].includes(selectedContentItem.content_item_type)" class="col-md-2 col-xxl-2 mb-3 pr-md-2">
          <div class="card h-md-100">
            <div class="card-header pb-0">
              <h6 class="mb-0 mt-2 d-flex align-items-center">
                Ratings
                <span
                  class="ml-1 text-400"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="The number of people who have liked this item."
                >
                  <span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
                </span>
              </h6>
            </div>
            <div class="card-body d-flex align-items-end">
              <div class="row flex-grow-1 ml-0">
                <div class="col">
                  <div
                    class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1"
                  >{{audienceObject.rating.likes}}
                    <!-- <span
                    class="ml-1 text-400"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    v-bind:title="audienceUsers.length">
                    <span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
                    </span> -->
                  </div>
                  <div
                    class="fs1 font-weight-normal text-sans-serif text-500 line-height-1 mb-1"
                  >likes
                  </div>
                </div>
                <!-- <div class="col-auto pl-0">
                  <div ref="chart5" class="echart-bar-weekly-sales h-100"></div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div v-if="audienceObject.hasOwnProperty('yet_to_view') && ['best_seller','memo'].includes(selectedContentItem.content_item_type)" class="col-md-2 col-xxl-2 mb-3 pr-md-2">
          <div class="card h-md-100">
            <div class="card-header pb-0">
              <h6 class="mb-0 mt-2 d-flex align-items-center">
                Yet to view
                <span
                  class="ml-1 text-400"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="The number of people who have not viewed this item yet."
                >
                  <span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
                </span>
              </h6>
            </div>
            <div class="card-body d-flex align-items-end">
              <div class="row flex-grow-1 ml-0">
                <div class="col">
                  <div
                    class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1"
                  >{{audienceObject.yet_to_view.length}}
                    <!-- <span
                    class="ml-1 text-400"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    v-bind:title="audienceUsers.length">
                    <span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
                    </span> -->
                  </div>
                  <div
                    class="fs1 font-weight-normal text-sans-serif text-500 line-height-1 mb-1"
                  >people
                  </div>
                </div>
                <!-- <div class="col-auto pl-0">
                  <div ref="chart5" class="echart-bar-weekly-sales h-100"></div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div v-if="audienceObject.hasOwnProperty('percent_reach') && ['best_seller','memo'].includes(selectedContentItem.content_item_type)" class="col-md-4 col-xxl-4 mb-3 pl-md-0 pr-md-2" style="margin-bottom:20px;">
          <div class="card h-md-100">
            <div class="card-body">
              <div class="row h-100 justify-content-between no-gutters">
                <div class="col-4 col-sm-4 col-xxl-4 pr-2">
                  <h6 class="mt-1">Engagement<span
                    class="ml-1 text-400"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="These haven't viewed the item at all.">
                    <span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
                    </span>
                  </h6>
                </div>
                <div class="col-4 pr-2">
                   <div ref="donut" class="echart-doughnut absolute-centered"></div>
                  <!-- <div class="absolute-centered font-weight-light text-dark fs-2">{{progressPercent}}%</div> -->
                  <div class="absolute-centered fs-2 font-weight-light text-sans-serif line-height-1 mb-1">{{(audienceObject.percent_reach) ? audienceObject.percent_reach.toFixed(0) + "%" : "N/A"}}</div>
                  <!-- <div
                    class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1">{{progressPercent}}%</div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Content Counts -->
      </div>
      <!-- Start of Audience Detail section -->
      <h3>Audience Information</h3>

      <div class="card mb-3 mr-2">
        <div class="card-header" id="audience">
          <h5 class="mb-0">
            <button class="btn btn-outline-secondary mb-0 mt-2 d-flex collapsed" data-toggle="collapse" data-target="#collapseAudience" aria-expanded="false" aria-controls="collapseAudience">
              Who can see this item
            </button>
          </h5>
        </div>

        <div id="accordion">
          <div id="collapseAudience" class="collapse" aria-labelledby="audience" data-parent="#accordion">
            <div class="card-body">
              <div class="container">
                <div class="row">
                  <div class="col-md">
                    <div v-for="(item) in arrayToColumns(this.audienceUsers, 1,3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item.display_name}} - <b>{{item.store_summary}}</b>
                    </div>
                  </div>
                  <div class="col-md">
                    <div v-for="(item) in arrayToColumns(this.audienceUsers, 2, 3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item.display_name}} - <b>{{item.store_summary}}</b>
                    </div>
                  </div>
                  <div class="col-md">
                    <div v-for="(item) in arrayToColumns(this.audienceUsers, 3,3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item.display_name}} - <b>{{item.store_summary}}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="this.audienceViews.hasOwnProperty('length') && ['best_seller','memo'].includes(selectedContentItem.content_item_type)" class="card">
          <div class="card-header" id="headingTwo">
            <h5 class="mb-0">
              <button class="btn btn-outline-secondary mb-0 mt-2 d-flex collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                Who has viewed the item so far
              </button>
            </h5>
          </div>
          <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
            <div class="card-body">
              <div class="container">
                <div class="row" v-if="this.audienceViews.length>0">
                  <div class="col-md">
                    <div v-for="(item) in arrayToColumns(this.audienceViews, 1,3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item.display_name}} - <b>{{item.store_summary}}</b>
                    </div>
                  </div>
                  <div class="col-md">
                    <div v-for="(item) in arrayToColumns(this.audienceViews, 2, 3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item.display_name}} - <b>{{item.store_summary}}</b>
                    </div>
                  </div>
                  <div class="col-md">
                    <div v-for="(item) in arrayToColumns(this.audienceViews, 3,3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item.display_name}} - <b>{{item.store_summary}}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="this.audienceYetToView.hasOwnProperty('length') && ['best_seller','memo'].includes(selectedContentItem.content_item_type)" class="card">
          <div class="card-header" id="headingThree">
            <h5 class="mb-0">
              <button class="btn btn-outline-secondary mb-0 mt-2 d-flex collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                Who is yet to view
              </button>
            </h5>
          </div>
          <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
            <div class="card-body">
              <div class="container">
                <div class="row" v-if="this.audienceYetToView.length>0">
                  <div class="col-md">
                    <div v-for="(item) in arrayToColumns(this.audienceYetToView, 1,3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item.display_name}} - <b>{{item.store_summary}}</b>
                    </div>
                  </div>
                  <div class="col-md">
                    <div v-for="(item) in arrayToColumns(this.audienceYetToView, 2, 3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item.display_name}} - <b>{{item.store_summary}}</b>
                    </div>
                  </div>
                  <div class="col-md">
                    <div v-for="(item) in arrayToColumns(this.audienceYetToView, 3,3)" :key=item.id class="font-weight-normal mb-0 text-500">
                      {{item.display_name}} - <b>{{item.store_summary}}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- </div> -->
        <div>
          <div class="col-md-12 col-xxl-12 mb-3 pl-md-0 pr-md-2" style="margin-bottom:20px;">
          <!-- <div class="col-lg-7 col-xl-8 pr-lg-2 mb-3"> -->
            <div class="card h-lg-100 overflow-hidden">

            </div>
          </div>
        </div>


    </div>
    <div v-if="!dataLoaded">
      <page-loader></page-loader>
    </div>
   </div>
</template>

<script>
// import { aggregateMethods } from "../helpers/aggregate";
import pageLoader from "../components/pageLoader";
import { contentMethods } from "../helpers/content";
import { graphMethods } from "../helpers/graphs"
import { GlobalStore } from '../globalStore';
// import ImageModal from "../components/modals/ImageModal.vue"
import { GlobalMethods } from '../globalMethods';
import { dashboardMethods } from "../helpers/dashboard";
import tcxImage from "../components/tcxImage.vue"

export default {
  data() {
    return {
      contentTitle: '',
      contentGuid: '',
      selectedContentItem: null,
      audienceUsers: [],
      audienceViews: [],
      audienceYetToView: [],
      audienceObject: {},
      unrespondedUsers: [],
      additionalImages: [],
      dataLoaded: false,
    };
  },
   watch: {
    '$route.params.guid'(guid) {
        //this.checkView(currentview);
        this.contentGuid = guid;
      // this.contentGuid = "2455a3d0-86e0-11ea-b0b1-09bf639f3d15";
      // this.contentGuid = "4c91c5c1-f51c-4260-b1b4-4beb2e4f18c2";
      // this.contentGuid = "079453e0-77c7-11ea-a322-09b258df5e8e";
      // this.contentGuid = "58b9d3ed-d5a6-4f24-8a92-1788be544dd6";
    }
    // '$route.params.id'(id) {
    //     //this.checkView(currentview);
    //     this.actionRequestItemId = id;
    // },
  },
  computed: {
    custObj: function() {
      return GlobalStore.custObj;
    },
    contentModuleTitle: function() {
      var endpoint = '';
      if(this.selectedContentItem.hasOwnProperty('content_item_type')){
        switch(this.selectedContentItem.content_item_type){
          case 'best_seller' : {
            endpoint = 'bestSellers';
            break;
          }
          case 'best_store' : {
            endpoint = 'bestStores';
            break;
          }
          case 'memo' : {
            endpoint = 'memos';
            break;
          }
          default: {
            endpoint = '';
          }
        }
      }
      if(endpoint == ''){
        return "UNKNOWN TYPE"
      }
      // console.log(this.custObj.config.modules);
      var confElement = this.custObj.config.modules.find(
        (obj) => obj.admin_url == endpoint
      );
      return confElement.menuText;
    },

  },

  methods: {
    /**
     * Sorts (in ascending order) a list of objects by items in their
     * '.images' field.
     * @param group the list of objets to be sorted
     * @returns A list of objects
     */

    arrayToColumns(array, column, columns) {
        const total = array.length; // How many items
        const gap = Math.ceil(total / columns); // How many per col
        let top = (gap * column) - 1; // Top of the col
        const bottom = (gap * (column - 1)); // Bottom of the col
        return array.filter(item =>
            array.indexOf(item) >= bottom
            && array.indexOf(item) <= top,
        );
    },
    async getPageData() {
      // Defining variables
      // console.log(this.contentGuid);
      const pipelineAudienceContent = [
        { $match: { content_guid : this.contentGuid }}
      ];

      // Making requests
      await dashboardMethods.getDashboardData("audience_content", 1,  pipelineAudienceContent).then(res => {
        // this.audienceUsers = res.data
        // update store info and sort
        var i, objNew;
        this.audienceObject =  res.data[0];
        // console.log(this.audienceObject);
        for (i=0; i<this.audienceObject.audience_users.length; i++){
            objNew = this.audienceObject.audience_users[i];
            objNew['store_summary'] = this.storeSummary(objNew)
            objNew['display_name'] = this.displayName(objNew)
        //     objNew.audience_users['store_summary'] = this.storeSummary(objNew.audience_users);
            this.audienceUsers.push(objNew);
        }
        // Sort by given name
        this.audienceUsers = this.audienceUsers.sort(function(a, b){
          if (a.store_summary === b.store_summary){
            return a.display_name > b.display_name ? 1 : -1;
          }
          return a.store_summary > b.store_summary ? 1 : -1;
        })
        // Process the views
        // console.log(this.audienceObject)
        for (i=0; i<this.audienceObject.views.length; i++){
            objNew = this.audienceObject.views[i].user;
            objNew['store_summary'] = this.storeSummary(objNew)
            objNew['display_name'] = this.displayName(objNew)
        //     objNew.audience_users['store_summary'] = this.storeSummary(objNew.audience_users);
            this.audienceViews.push(objNew);
        }
        // console.log(this.audienceViews);
        // Sort by given name
        this.audienceViews = this.audienceViews.sort(function(a, b){
          if (a.store_summary === b.store_summary){
            return a.display_name > b.display_name ? 1 : -1;
          }
          return a.store_summary > b.store_summary ? 1 : -1;
        })
        // console.log(this.audienceViews);
        // Process the yet_to_views
        // console.log(this.audienceObject)
        for (i=0; i<this.audienceObject.yet_to_view.length; i++){
            objNew = this.audienceObject.yet_to_view[i];
            objNew['store_summary'] = this.storeSummary(objNew)
            objNew['display_name'] = this.displayName(objNew)
        //     objNew.audience_users['store_summary'] = this.storeSummary(objNew.audience_users);
            this.audienceYetToView.push(objNew);
        }
        // console.log(this.audienceYetToView);
        // Sort by given name
        this.audienceYetToView = this.audienceYetToView.sort(function(a, b){
          if (a.store_summary === b.store_summary){
            return a.display_name > b.display_name ? 1 : -1;
          }
          return a.store_summary > b.store_summary ? 1 : -1;
        })
        // console.log(this.audienceYetToView);
      })
    },


    groupBy(xs, key)  {
      return xs.reduce(function (rv, x) { 
        let v = key instanceof Function ? key(x) : x[key]; 
        let el = rv.find((r) => r && r.key === v); 
        if (el) { 
          el.values.push(x); 
        } else { 
          rv.push({ key: v, values: [x] }); 
        }
        return rv; 
      }, []); 
    },


    formatToLocalTime(ts) {
      return(GlobalMethods.formatToLocalTime(ts));
    },

    parseISOString(s) {
      var b = s.split(/\D+/);
      return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
    },

    longDateTimeFormat(datetime){
      var d = this.parseISOString(datetime);
      // console.log(datetime + " -> " + d);

      // var out = d.getDate()  + "-" + (d.getMonth()+1) + "-" + d.getFullYear() + " " + d.getHours() + ":" + d.getMinutes();
      // out = out.getDate();
      return(d);
    },

    fuzzyTime(datestamp){
      // Make a fuzzy time
      return(GlobalMethods.fuzzyTime(datestamp));
    },


    removeImages(html){
      return(html.replace(/<img .*?>/g, "[IMAGE REMOVED]<br>"));
    },

    async loadContent(){
      try{
        var resp = await contentMethods.getContentItem(this.contentGuid);
        this.selectedContentItem = resp.data;
        this.contentTitle = resp.data.title;
        // console.log(this);
        console.log(this.selectedContentItem); 

        if(typeof resp.data.images.thumbnail_image_url != 'undefined'){
          this.selectedContentItem.images.thumbnail_image_url = resp.data.images.thumbnail_image_url;
        }else{
          this.selectedContentItem.images.thumbnail_image_url = resp.data.images.hero_image_url;
        }

        this.additionalImages = [...resp.data.images.additional_images_url]

        this.selectedContentItem.expires_string = this.fuzzyTime(this.selectedContentItem.display.expires);
        // Ensure no images in the text
        this.selectedContentItem.body_html = this.removeImages(this.selectedContentItem.body_html);
        // console.log(this.selectedContentItem);
      }
      catch(err){
        console.log("getContentItem failed: " + err.message)
      }
    },

    retreatPage() {
      this.$router.go(-1);
    },
    storeSummary(objUser) {
      // console.log(objUser);
      if (objUser.stores.hasOwnProperty('length')){
        if (objUser.stores.length == 1){
          return(objUser.stores[0] == "" ? "0 stores" : objUser.stores[0]);
        }
        return(objUser.stores.length + " stores");
      }
      return "0 stores"
    },
    displayName(objUser) {
      return(objUser.given_name + " " + objUser.last_name);
    }
  },

  async mounted() {
    this.contentGuid = this.$route.params.guid;
    // console.log(this.contentGuid);

    // Load data from the database
    try{
      await this.loadContent();
    }
    catch(ex){
      console.log(ex)
    }
    // console.log(this.selectedContentItem); 

    // Getting audience responses.
    try {
      this.getPageData().then(() => {

        graphMethods.renderPieGraph(this.$refs.donut, (this.audienceObject.percent_reach) ?
        [{
          value: this.audienceObject.views.length,
          name: 'Progress'
        }, {
          value: (Math.max(0, this.audienceObject.yet_to_view.length)),
          name: 'Yet to respond'
        }]
        :
        [{}]
        );
      })
    } catch(err) {
      console.error("An issue occured waiting for promise: " + err.message);
    }
    this.dataLoaded = true;
  },
  components: {
    // ImageModal,
    pageLoader,
    tcxImage,
  }
}
</script>

<style>

.contentDashboard.card, .card-container {
    padding: 20px;
}

.contentDashboard.card, .card-header {
    min-height: 67px !important;
}

.row {
  margin-left: 0px !important;

}
.upload-header {
  width: 180px;
  word-wrap: break-word;
}

.img-tiles {
  padding: 0 !important;

  /* Need the 'important' here to overwrite the bootstrap 'col' styling. */
  min-width: 180px !important;
  width: 10vw !important;
}

.img-tiles img {
    cursor: pointer;
    object-fit: cover;
}
.img-text {
  max-width: 238px !important;
}

.back-button {
  max-height: 36px;
}

#storeSelect {
  width: 30%;
}

.page-thumbnail {
  height: 120px;
  width: 120px;
}

/* MODAL */
.v--modal-overlay {
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2000;
  opacity: 1;
}
</style>