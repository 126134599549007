
import Vue from 'vue'
import axios from "axios";
import { GlobalStore } from '../globalStore'

  export const dashboardMethods = new Vue({
    methods: {

    getDashboardData: async function(data_request, limit = 100, pipeline = []) {
       
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + GlobalStore.access_token
      };

      var requestBody = {};
      if (pipeline) {
        requestBody = {
            pipelines : pipeline
        };
      }
      var query_string = "";

      if (limit) {
        query_string = "?limit=" + limit
      }
      try{
        return axios.post(GlobalStore.APIEndpoint + '/todaycx-admin/dashboard/' + data_request + query_string,
            requestBody,
            {
            headers: headers
            });
        }catch{
            return []
        }
    },
    fuzzyTime(datestamp){
      // Make a fuzzy time
      var datestamp_ = Date.parse(datestamp)
      var current_time = new Date()
      var tzOffset = current_time.getTimezoneOffset()
      var adjusted_time = current_time.getTime() + (tzOffset * 60 * 1000)
      // console.log(datestamp_ + ": " + adjusted_time + ": " + current_time.getTime())
      var delta = Math.round((adjusted_time - datestamp_) / 1000);

      var minute = 60,
          hour = minute * 60,
          day = hour * 24,
          week = day * 7;

      var fuzzy;
      if(delta < 0){
        delta = -1 * delta;
        if (delta < 30) {
            fuzzy = 'In a few seconds';
        } else if (delta < minute) {
            fuzzy = 'In ' + delta + ' seconds.';
        } else if (delta < 2 * minute) {
            fuzzy = 'In a minute'
        } else if (delta < hour) {
            fuzzy = 'In ' + Math.floor(delta / minute) + ' minutes';
        } else if (Math.floor(delta / hour) == 1) {
            fuzzy = 'In an hour'
        } else if (delta < day) {
            fuzzy = 'In ' + Math.floor(delta / hour) + ' hours.';
        } else if (delta < day * 2) {
            fuzzy = 'Tomorrow';
        } else if (delta < 7 * day) {
            fuzzy = 'In ' + Math.floor(delta / day) + ' days';
        } else if (delta < week * 2){
            fuzzy = 'Next week';
        } else {
            fuzzy = 'In ' + Math.floor(delta / week) + ' weeks';
        }     
      }else{
        if (delta < 30) {
            fuzzy = 'Just then';
        } else if (delta < minute) {
            fuzzy = delta + ' seconds ago.';
        } else if (delta < 2 * minute) {
            fuzzy = 'A minute ago'
        } else if (delta < hour) {
            fuzzy = Math.floor(delta / minute) + ' minutes ago';
        } else if (Math.floor(delta / hour) == 1) {
            fuzzy = '1 hour ago'
        } else if (delta < day) {
            fuzzy = Math.floor(delta / hour) + ' hours ago';
        } else if (delta < day * 2) {
            fuzzy = 'Yesterday';
        } else if (delta < 7 * day) {
            fuzzy = Math.floor(delta / day) + ' days ago';
        } else if (delta < week * 2){
            fuzzy = 'Last week';
        } else {
            fuzzy = Math.floor(delta / week) + ' weeks ago';
        }     
      }
      return(fuzzy)
    }
  }
})