<template>
<div>
  <media-library-component />
</div>
</template>

<script>
import MediaLibraryComponent from '../components/MediaLibraryComponent';
import { GlobalStore } from '../globalStore';
export default {
  name: 'Second',
  data() {
    return {
      itemList: [],
      view: 'List',
      imageBlobEndpoint: GlobalStore.imageEndpoint,
      selectedItem: null,
      pagingInfo: {}
    }
  },
  props: {
    msg: String
  },
  methods:  {
     
    
  },
  components: {
    MediaLibraryComponent
    //DataTable,
    //ToDoItem
  },
   async mounted () {
  },
  watch: {
    '$route.params.view'(currentview) {
        this.checkView(currentview);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
