<template>

  <div class="main-container">
    <img
      v-if="!this.aspectBlur"
      @click="onClick" 
      :class="this.className"
      :src="this.imagePath + this.imageSrc" 
      :alt="altText" 
      :title="imageTitle">

    <div 
      v-if="this.aspectBlur"
      class="blur-image-container"
    >
      <div class="blur-background">
        <img 
          :src="imagePath + imageSrc"
          :alt="altText"
          :title="imageTitle"
        >
      </div>
      <img
        class="main-image"
        :src="imagePath + imageSrc"
        :alt="altText"
        :title="imageTitle"
      />
    </div>
  </div>

</template>

<script>
//import axios from "axios";
import { GlobalStore } from '../globalStore'


export default {
  name: 'tcx-image',
  data() {
    return {
      imagePath: GlobalStore.imageEndpoint,
      custGuid: GlobalStore.custGuid,
      imageObj: { url: '', description: '', alt_text: ''}
    }
  },
  props: {
    imageSrc: String,
    className: String,
    aspectBlur: Boolean,
  },
  computed: {
    altText: function() {
      var cimage = this.getCurrentImage();
      return cimage ? cimage.alt_text : '';
    },
    imageTitle: function() {
      var cimage = this.getCurrentImage();
      return cimage ? cimage.description : '';
    }
  },
  methods:  {
    getCurrentImage: function() {
      return GlobalStore.customerImages.find(f=>f.url === this.imageSrc);
    },
    onClick: function() {
      this.$emit("onclick");
    },
  },
  components: {

  },
  async mounted () {
    // var curImage = GlobalStore.customerImages.find(f=>f.url === this.imageSrc);
    // console.log(curImage);
    // if (curImage != null) {
    //   this.imageObj = { description: curImage.description, alt_text: curImage.alt_text};
    // }
  }
}
</script>

<style scoped>
.main-container {
  overflow: hidden;
  height: 100%;
  width: 100%;
  aspect-ratio: 1;
}

.main-container img {
  max-width: inherit;
  max-height: inherit;
}

.blur-image-container {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.blur-background {
  border-radius: 3px;
  display: flex;
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.blur-background img {
  filter: blur(15px);
  position: absolute;
  width: 100%;
  height: 100%;
}

.main-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  overflow: hidden;
  z-index: 1;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.35))
}
</style>
