<template>
  <div id="image-modal">
    <modal name="image-modal" :width="700" :height="700">
      <div class="d-flex justify-content-center align-items-center" style="height:100%; width:100%">
        <button @click="$modal.hide('image-modal')" class="cancel-button"><i class="fas fa-times"></i></button>
        <img class="modal-img rounded" :src="item.url">
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: "image-modal",
  data() {
    return {};
  },
  props: {
    item: null,
    modalName: String,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Modal */
.v--modal-overlay {
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2000;
  opacity: 1;
}

.modal-img {
  max-height: 90%;
  max-width: 90%;
  width: auto;
}

.modal-header {
  font-size: 16pt;
  color: var(--primary);
}

.modal-body {
  margin-bottom: 70px;
  font-size: 10pt;
}

.modal-footer {
  position: absolute;
  bottom: 0px !important;
  width: 100%;
  display: inline-block;
  background-color: white;
}

.image-dimensions {
  text-align: center;
  font-size: 9pt;
  margin-top: 5px;
}

.cancel-button {
  position: absolute;
  right: 0;
  top: 0;
  background-color: lightgray;
  border: none;
  border-bottom-left-radius: 5px;
  padding: 6px 12.5px;
}

</style>
