<template>
  <div>
    User Profile
  </div>
</template>

<script>
export default {
  name: 'UserProfile',
  data() {
    return {
      appName: 'Brieee2f'
    }
  },
  props: {
    msg: String
  },
  methods:  {
    handleClick: function() {
      alert(this.appName);
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
