<template>
  <div>
    <div class="row" style="margin-bottom: 10px;">
      <div class="col-3">
        <h3>{{ title }}</h3>
      </div>

      <div class="col-7 text-left">
        <!-- <ul class="navbar-nav align-items-center d-none d-lg-block">
                  <li class="nav-item">
                    <form class="form-inline search-box">
                      <input @keyup="doSearch" v-model="searchString" class="form-control rounded-pill search-input" type="search" placeholder="Search..." aria-label="Search" /><span class="position-absolute fas fa-search text-400 search-box-icon"></span>
                    </form>
                  </li>
                </ul> -->
      </div>

      <div class="col-2">
        <a class="btn btn-primary btn-sm float-right" @click="addItem()">Upload Item</a>
      </div>

    </div>
    <div class="row no-gutters">
      <div class="col-12 import-shopify-div">
        <ImportShopify
          v-if="shopifyConfig != null && (idField == 'top_story_guid' || (idField == 'content_guid' && contentItemType == 'best_seller'))"
            :idField="idField"
            :contentItemType="contentItemType"
            @selectPage="selectPage"
        />
      </div>
    </div>
    <div class="row no-gutters">
      <div
        class="col-md-3 col-xxl-3 mb-3 pr-md-2"
        v-for="(item, index) in filteredItemList"
        :key="index"
      >
        <div class="card">
          <div class="card-img-top">
            <div class="order-by-pill" title="Display Order">
              {{ item.display.order_by }}
            </div>
            <tcx-image
              v-if="
                item.images.hero_image_url != null &&
                  item.images.hero_image_url != ''
              "
              className="img-fluid"
              :imageSrc="item.images.thumbnail_image_url"
              :aspectBlur="true"
            />
            <img
              v-if="
                item.images.hero_image_url == null ||
                  item.images.hero_image_url == ''
              "
              class="img-fluid"
              src="/img/no-image-available-icon.jpg"
            />
            <span
              v-if="item.is_archived"
              class="badge archived pull-right hidden-xs"
              >archived</span
            >
            <span
              v-if="item.mode"
              class="badge action_request_mode pull-right hidden-xs"
              >{{ item.mode }}</span
            >
            <span
              v-if="item.sku && (item.sku.code.trim().length > 0 || item.sku.variation.trim().length > 0)"
              class="badge sku pull-right hidden-xs"
              >{{abbrSkuString(item.sku.code)}}/{{abbrSkuString(item.sku.variation)}}</span
            >
            <span
              v-if="item.item_type"
              class="badge item_type pull-right hidden-xs"
              >{{ item.item_type }}</span
            >
            <span
              v-if="item.due_by <= currentTimeISO"
              class="badge pastdue pull-right hidden-xs"
              >past due</span
            >
            <span
              v-if="item.display.expires <= currentTimeISO"
              class="badge expired pull-right hidden-xs"
              >expired</span
            >
            <!-- <span class="badge expired pull-right hidden-xs">Expired</span> -->
            <span
              v-if="item.display.publish_status == 'published'"
              class="badge published pull-right hidden-xs"
              >published</span
            >
            <span
              v-if="item.display.publish_status != 'published'"
              class="badge draft pull-right hidden-xs"
              >draft</span
            >
          </div>
          <div class="card-body">
            <h5 class="card-title">{{ item.title }}</h5>
            <div class="card-text">{{ item.summary }}</div>
          </div>

          <div
            v-if="
              item.hasOwnProperty('rating') &&
                item.content_item_type != 'best_store'
            "
            class="p-1 engagement-bar"
          >
            <div
              v-if="item.rating.hasOwnProperty('stars')"
              class="float-start engagement-item"
            >
              <i class="fas fa-star"></i>&nbsp;{{
                item.rating.stars.toFixed(1)
              }}&nbsp;-&nbsp;{{ item.rating.count }}&nbsp;{{
                item.rating.count == 1 ? "rating" : "ratings"
              }}
            </div>
            <div
              v-if="item.rating.hasOwnProperty('likes')"
              class="float-start engagement-item"
            >
              <i class="far fa-thumbs-up"></i>&nbsp;{{ item.rating.likes }}
            </div>
          </div>
          <div
            v-if="item.hasOwnProperty('responses')"
            class="p-1 engagement-bar"
          >
            <div class="float-start engagement-item">
              <i class="fas fa-check-circle"></i>&nbsp;{{
                item.responses.length
              }}
              {{ item.responses.length == 1 ? "response" : "responses" }}&nbsp;
              <i
                v-if="
                  item.request_items.some(
                    (el) => el.request_type === 'imageupload'
                  )
                "
                class="fas fa-images"
              ></i>
            </div>
          </div>
          <div class="card-buttons">
            <a class="btn btn-primary btn-sm" @click="editItem(item[idField])"
              >Edit</a
            >
            <div title="Copy" @click="copyItem(item)" class="copy_item">
              <i class="far fa-copy" aria-hidden="true"></i>
            </div>
            <div
              v-if="item.hasOwnProperty('action_request_guid')"
              title="View Responses"
              @click="viewResponses(item[idField])"
              class="view-responses"
            >
              <i class="far fa-chart-bar" aria-hidden="true"></i>
            </div>
            <div  
              v-if="item.hasOwnProperty('content_guid')"
              title="View Engagement" 
              @click="viewContentEngagement(item[idField])"
              class="view-responses">
              <i class="far fa-chart-bar" aria-hidden="true"></i>

            </div>
            <div  
              v-if="item.hasOwnProperty('top_story_guid')"
              title="View Engagement" 
              @click="viewTopStoryEngagement(item[idField])"
              class="view-responses">
              <i class="far fa-chart-bar" aria-hidden="true"></i>

            </div>
            <div @click="deleteItem(item[idField])" class="delete-item">
              <i class="fa fa-trash" aria-hidden="true"></i>
            </div>
          </div>
          <div class="p-1 tag-bar">
            <div
              class="float-start tag-item"
              v-for="tag in sortedTags(item.tags ? item.tags : [])"
              v-bind:key="tag"
            >
              <span class="badge badge-pill badge-secondary">{{ tag }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import axios from "axios";
import { GlobalStore } from "../globalStore";
import { imageMethods } from "../helpers/images";
import { fyiMethods } from "../helpers/fyi";
import { contentMethods } from "../helpers/content";
import { actionMethods } from "../helpers/actions";
import tcxImage from "../components/tcxImage";
import ImportShopify from "../components/ImportShopify";

export default {
  name: "data-table",
  data() {
    return {
      imageEndpoint: GlobalStore.imageEndpoint,
      renderImage: imageMethods.renderImage,
      shopifyConfig: GlobalStore.custObj.config.shopify,
      statusFilter: "Published",
      searchString: "",
      maxSkuCodeLength: 12,
    };
  },
  props: {
    itemList: Array,
    title: String,
    idField: String,
    contentItemType: String,
    custObj: Object,
  },
  computed: {
    currentTimeISO: function() {
      var currentTime = new Date();
      return currentTime.toISOString();
    },
    filteredItemList: function() {
      var statusFiltered = [];
      var textFiltered = [];

      var filteredItems = this.itemList.filter((f) => f.display.order_by != 0);

      var itemListZeros = this.itemList.filter((f) => f.display.order_by == 0);
      filteredItems.sort((a, b) =>
        parseInt(a.display.order_by) > parseInt(b.display.order_by) ? 1 : -1
      );

      itemListZeros.forEach((i) => {
        filteredItems.push(i);
      });

      statusFiltered = filteredItems;

      // if (this.statusFilter=='All') {
      //   statusFiltered = filteredItems;
      // } else {
      //   statusFiltered = filteredItems.filter(f => f.display.publish_status === this.statusFilter.toLowerCase());
      // }

      if (this.searchString != "") {
        textFiltered = statusFiltered.filter((f) =>
          f.title.toLowerCase().includes(this.searchString.toLowerCase())
        );
      } else {
        textFiltered = statusFiltered;
      }

      //textFiltered = statusFiltered;
      // console.log(textFiltered);
      console.log("filteredItemList() this", this);
      //       textFiltered.sort(function(a, b) {
      //   var keyA = a.display.order_by,
      //     keyB = b.display.order_by;
      //   // Compare the 2 dates
      //   if (keyA == 0 || keyB == 0) return -1;
      //   if (keyA < keyB) return -1;
      //   if (keyA > keyB) return 1;
      //   return 0;
      // });

      return textFiltered;
    },
  },
  methods: {
    abbrSkuString: function(stringSku) {
      if(stringSku && stringSku.length > this.maxSkuCodeLength){
        return stringSku.substring(0, this.maxSkuCodeLength) + '...';
      }
      return stringSku;
    },
    sortedTags: function(tags) {
      return tags.slice().sort();
    },
    copyItem: function(item) {
      // Work out which copy endpoint
      // console.log(item);
      if (item.hasOwnProperty("top_story_guid")) {
        // console.log(item.top_story_guid);
        fyiMethods.copyFYIItem(item.top_story_guid).then((resp) => {
          // console.log(resp.data);
          this.$emit("editItem", resp.data.top_story_guid);
        });
      }
      if (item.hasOwnProperty("content_guid")) {
        contentMethods.copyContentItem(item.content_guid).then((resp) => {
          // console.log(resp.data);
          this.$emit("editItem", resp.data.content_guid);
        });
      }
      if (item.hasOwnProperty("action_request_guid")) {
        try {
          actionMethods
            .copyActionRequestItem(item.action_request_guid)
            .then((resp) => {
              // console.log(resp.data);
              this.$emit("editItem", resp.data.action_request_guid);
            });
        } catch {
          alert;
        }
      }
    },
    viewResponses: function(id) {
      this.$router.push("/actionRequestDashboard/" + id);
    },
    viewContentEngagement: function(id) {
      this.$router.push("/contentDashboard/" + id);
    },
    viewTopStoryEngagement: function(id) {
      this.$router.push("/topstoryDashboard/" + id);
    },
    doSearch: function() {
      // console.log(this.searchString);
    },
    // setStatusFilter: function(status) {
    //   this.statusFilter = status;
    //   console.log(status);
    // },
    getCustGuid: function() {
      return GlobalStore.custGuid;
    },
    editItem: function(guid) {
      this.$emit("editItem", guid);
    },
    addItem: function() {
      this.$emit("addItem");
    },
    deleteItem: function(guid) {
      this.$emit("deleteItem", guid);
    },
    selectPage: function(pageNo) {
      this.$emit("selectPage", pageNo);
    }
  },
  components: {
    tcxImage,
    ImportShopify
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.import-shopify-div {
    padding: 0px;
  }
/* .btn-primary {
    width: 100%;
    padding: 0px;
    border-radius: 5px;
    font-size: 80%;
    color: white;
    text-align: center;
    cursor: pointer;
  } */

.order-by-pill {
  background-color: #24661e;
  font-size: 11px;
  border-radius: 20px;
  color: white;
  width: 35px;
  height: 24px;
  text-align: center;
  font-weight: bold;
  padding: 4px 4px 4px 4px;
  position: absolute;
  right: 10px;
  top: 10px;
}

table {
  background-color: white;
  margin-top: 20px;
}

.card {
  height: 100%;
}

.card-img-top {
  aspect-ratio: 1; /* Keeping the 1/1 aspect ratio. Not supported on < v83 IE*/
  overflow: hidden;
  position: relative;
}

/* IE Specific media query for the card headers to maintain a ~1:1 aspect ratio */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
   .card-img-top {
        height: calc(15vw + 15px);
   }
}

.card-title {
  min-height: 50px;
}

.card-text {
  min-height: 70px;
  position: relative;
  font-size: 10pt;
  margin-bottom: 10px;
}

a:not([href]),
a:not([href]):hover {
  color: white;
  text-decoration: none;
}

.card-body .delete-item {
  float: right;
  cursor: pointer;
}

.card-body .view-responses {
  display: inline-block;
  margin-left: 30px;
}
.card-body .copy_item {
  display: inline-block;
  margin-left: 30px;
}

.engagement-bar {
  margin-left: 4px;
  margin-bottom: 4px;
  color: #24661e;
  min-height: 24px;
  display: flex;
  flex-flow: wrap;
  row-gap: 5px;
  column-gap: 3px;
}

.tag-bar {
  margin: 0 4px;
  min-height: 24px;
  display: flex;
  flex-flow: wrap;
  row-gap: 5px;
  column-gap: 3px;
}

.card-buttons {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 10px 20px;
}

.card-buttons div {
  cursor: pointer;
}

.badge {
  font-size: 8pt;
  opacity: 0.6;
  display: flex;
  padding: 3px 5px 2px 5px;
  z-index: 2;
}

.badge.published {
  background-color: #24661e;
  color: #ffffff;
  padding: 3px 10px 2px 10px;
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.badge.draft {
  background-color: #fd7e14;
  color: #ffffff;
  padding: 3px 10px 2px 10px;
  position: absolute;
  bottom: 8px;
  right: 8px;
}

.badge.expired {
  background-color: firebrick;
  color: #ffffff;
  padding: 3px 10px 2px 10px;
  position: absolute;
  bottom: 24px;
  right: 8px;
}

.badge.pastdue {
  background-color: goldenrod;
  color: #ffffff;
  padding: 3px 10px 2px 10px;
  position: absolute;
  bottom: 40px;
  right: 8px;
}

.badge.action_request_mode {
  background-color: darkcyan;
  color: #ffffff;
  padding: 3px 10px 2px 10px;
  position: absolute;
  bottom: 40px;
  left: 8px;
}

.badge.item_type {
  background-color: lightslategray;
  color: #ffffff;
  padding: 3px 10px 2px 10px;
  position: absolute;
  bottom: 8px;
  left: 8px;
}
.badge.sku {
  background-color: purple;
  color: #ffffff;
  padding: 3px 10px 2px 10px;
  font-weight: normal;
  position: absolute;
  top: 8px;
  left: 8px;
}

.badge.archived {
  background-color: firebrick;
  color: #ffffff;
  padding: 3px 10px 2px 10px;
  position: absolute;
  bottom: 24px;
  left: 8px;
}

.status-filter {
  font-size: 0.875rem;
  padding: 0.1875rem 0.75rem;
}

.engagement-item {
  display: inline-block;
  font-size: 10pt;
}

.engagement-bar {
  margin-left: 16px;
}

/* tag display classes */
.tag-item {
  display: inline-block;
}


.order-by-pill {
  z-index: 2;
}
</style>
