<template>
  <div class="card mb-3">
         <!--Start search filters and pagination -->
    <div class="row" v-if="view == 'List'">
      <div class="col-6">
        <!-- <div class="btn-group">
          <button class="btn dropdown-toggle mb-2 btn-light status-filter" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Status - {{statusFilter}}
          </button>
          <div class="dropdown-menu">
            <a class="dropdown-item" href="#" @click.prevent="setStatusFilter('All')">All</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#" @click.prevent="setStatusFilter('Draft')">Draft</a>
            <a class="dropdown-item" href="#" @click.prevent="setStatusFilter('Published')">Published</a>
          </div>
        </div> -->
      </div>
      <div class="col-6">
        <paginator :pagingInfo="pagingInfo" @selectPage="selectPage" />
      </div>
    </div>
    <!--End search filters and pagination -->
    <product-data-table title="Products" v-if="view == 'List'" :itemList="itemList" @addItem="addItem()" @deleteItem="confirmDelete($event)" @editItem="editItem($event)" idField="product_guid"></product-data-table>
    <div v-if="view == 'List'" class="paging-stats" v-html="`Displaying ${((current_page * defaultPageSize)-defaultPageSize)+1}-${(current_page * defaultPageSize) > pagingInfo.total_count ? pagingInfo.total_count : current_page * defaultPageSize  } out of ${pagingInfo.total_count} items.`"></div>
    <product-item return='/product' :view="view" :title="`${view} Product`" v-if="selectedItem != null && (view == 'Add' || view == 'Edit')" :currentItem="selectedItem"></product-item>
  <confirmation-modal modalName="item-delete-modal" :itemId="selectedItem" @onConfirm="deleteItem" title="Delete Item" bodyText="Are you sure you want to delete this item?" okText="Delete" cancelText="Cancel"></confirmation-modal>

  </div>
</template>

<script>
//import axios from 'axios'
import { GlobalMethods } from '../globalMethods'
import { GlobalStore } from '../globalStore'
import ConfirmationModal from '../components/modals/ConfirmationModal'
import ProductDataTable from '../components/ProductDataTable'
import ProductItem from '../components/ProductItem'
import { productMethods } from '../helpers/product'
//import { imageMethods } from '../helpers/images'
import Paginator from '../components/Paginator'

export default {
  name: 'Products',
  data() {
    return {
      itemList: [],
      view: 'List',
      selectedItem: null,
      pagingInfo: { current_page: 1 },
      current_page: 1,
      statusFilter: 'Published'
    }
  },
  props: {
    msg: String
  },
  methods:  {
    selectPage(pageNo) {
      console.log(pageNo);
      this.pagingInfo.current_page = pageNo;
      this.current_page = pageNo;
      this.getItems(this.pagingInfo.current_page, status); 
    },
    setStatusFilter: function(status) {
      this.statusFilter = status;
      this.getItems(this.current_page, status);
      console.log(status);
    },
    addItem: function() {
      this.$router.push("/product/add");
    },
    editItem: function(guid) {
      //console.log(guid);
      //
      this.$router.push("/product/" + guid);
    },
    confirmDelete: function(guid) {
      event.stopPropagation();
      console.log(guid);
      this.selectedItem = guid;
      this.$modal.show('item-delete-modal');
    },
    deleteItem: function(guid) {
      //console.log(guid);
      productMethods.deleteItem(guid).then(
            resp => {
              console.log(resp);
              //this.itemList = resp.data.data;
              this.$modal.hide('item-delete-modal');
              this.getItems(this.current_page);
              
            }
           )
           .catch((err) => {
             console.log(err);
           });
    },
    getItems(pageNo, status) {
      var image_guid_array = [];
      productMethods.getProductList(pageNo, status).then(
            resp => {
              resp.data.data.forEach(element => {
                this.pagingInfo = resp.data;
                image_guid_array.push(element.images.hero_image);
                element.images.additional_images.forEach(ai => {
                    image_guid_array.push(ai);
                  });
                });
                // imageMethods.getImagesByGUID(image_guid_array.join(',')).then(guidResp=> {
                //     console.log(guidResp.data.data);
                //     GlobalStore.customerImages=guidResp.data.data;
                //     this.itemList = resp.data.data;
                // });
                this.itemList = resp.data.data;
            }
           )
           .catch((err) => {
             console.log(err);
           });
    },
    checkView(_view) {
      GlobalMethods.getCollectionSchema("product", () => {
      
      if (typeof _view === 'undefined') {
          this.view = 'List';
          this.getItems(this.current_page, this.statusFilter);    
        } else if (_view == 'add') {
            this.view = 'Add'; 
            this.selectedItem = GlobalStore.apiSchemas["product"].emptyObject;
            console.log(this.selectedItem);
            if (this.selectedItem.display) {
              this.selectedItem.display.expires = '2023-12-31T00:00:00+00:00';
            }
            this.selectedItem.content_item_type = "product";
          }
        else {
          this.view = 'Edit';
          var image_guid_array = [];
          productMethods.getProductItem(_view).then(
            resp => {
              image_guid_array.push(resp.data.images.hero_image);
              resp.data.images.additional_images.forEach(ai => {
                  image_guid_array.push(ai);
              });

              // resp.data.flexify = {
              //   last_updated:'2021-01-01T00:00:00+00:00'
              // };
              //resp.data.last_updated = '2021-01-01';

              //delete resp.data.flexify;

              //resp.data.flexify = null;

              //console.log(resp.data);
              
              // imageMethods.getImagesByGUID(image_guid_array.join(',')).then(guidResp=> {
              //   GlobalStore.customerImages=guidResp.data.data;
                
              // });

              this.selectedItem = resp.data;
              
            }
          )
        }
    });
    }
  },
  computed: {
    defaultPageSize () {
      return GlobalStore.defaultPageSize;
    }
  },
  async mounted () {
    this.checkView(this.$route.params.view);
  },
  watch: {
    '$route.params.view'(currentview) {
        this.checkView(currentview);
    }
    },
  components: {
    ProductDataTable,
    ProductItem,
    ConfirmationModal,
    Paginator
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
  padding: 30px;
}
.paging-stats {
  font-size: 10pt;
  width: 100%;
  text-align: right;
  font-weight: bold;
}
</style>
