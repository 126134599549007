<template>
<!-- <table class="table">
  <thead class="thead-dark">
    <tr>
      <th scope="col">#</th>
      <th scope="col">First</th>
      <th scope="col">Last</th>
      <th class="white-space-nowrap" scope="col">Handle</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Devid</td>
      <td>Fincher</td>
      <td class="white-space-nowrap">@mdo</td>
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>Alfred</td>
      <td>Hitchcock</td>
      <td class="white-space-nowrap">@fat</td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td>Woodley</td>
      <td>Allen</td>
      <td class="white-space-nowrap">@twitter</td>
    </tr>
  </tbody>
</table> -->

<div>
  <div class="row pb-3">
    <div class="col-6">
      <h1>{{title}}</h1>
    </div>
    <div class="col-4">
    </div>
    <div class="col-2">
        <a class="btn upload-button btn-sm float-right" @click="addItem()">Upload Item</a>
    </div>
  </div>
  <div class="row no-gutters">
      <div class="col-12 import-shopify-div">
        <ImportShopify
          v-if="shopifyConfig != null"
            idField="product_guid"
            contentItemType=""
        />
      </div>
    </div>

  <div class="row no-gutters">
    <div class="col-md-3 col-xxl-3 mb-3 pr-md-2"  v-for="(item,index) in filteredItemList" :key="index">
      <div class="card">
        <div class="card-img-top">
          <!-- <tcx-image className="img-fluid" :imageSrc="item.images.thumbnail_image_url" /> -->
          <tcx-image 
            v-if="item.images.hero_image_url!=null && item.images.hero_image_url != ''" 
            className="img-fluid" 
            :imageSrc="item.images.thumbnail_image_url" 
            :aspectBlur="true"
          /> 
          <img v-if="item.images.hero_image_url==null || item.images.hero_image_url == ''" 
            class="img-fluid"
            src="/img/no-image-available-icon.jpg"
          >
          <span
                v-if="item.sku && (item.sku.code.trim().length > 0 || item.sku.variation.trim().length > 0)"
                class="badge sku pull-right hidden-xs"
                >{{abbrSkuString(item.sku.code)}}/{{abbrSkuString(item.sku.variation)}}</span
              >
          <span 
            v-if="item.is_archived" 
            class="badge archived pull-right hidden-xs">
            archived
          </span>
          <span 
            v-if="item.item_type" 
            class="badge item_type pull-right hidden-xs">
            {{item.item_type}}
          </span>
        </div>
        <div class="card-body">
          <h5 class="card-title">{{item.name}}</h5>
          <p class="card-text">{{item.description}}<br><b>${{item.price.toFixed(2)}}</b></p>
        </div>
        <div v-if="item.hasOwnProperty('rating') && item.content_item_type != 'best_store'" class="p-1 engagement-bar">
          <div v-if="item.rating.hasOwnProperty('stars')" class="float-start engagement-item">
            <i class="fas fa-star"></i>&nbsp;{{item.rating.stars.toFixed(1)}}&nbsp;-&nbsp;{{item.rating.count}}&nbsp;{{(item.rating.count == 1) ? 'rating' : 'ratings'}} 
          </div>
          <div v-if="item.rating.hasOwnProperty('likes')" class="float-start engagement-item">
            <i class="far fa-thumbs-up"></i>&nbsp;{{item.rating.likes}} 
          </div>
        </div>
        <div class="card-buttons">
          <a class="btn btn-primary btn-sm" @click="editItem(item[idField])">Edit</a>
          <div @click="deleteItem(item[idField])" class="delete-item"><i class="fa fa-trash" aria-hidden="true"></i></div>
        </div>
      </div>
    </div>
  </div>
</div>

</template>

<script>
//import axios from "axios";
import { GlobalStore } from '../globalStore'
import { imageMethods } from '../helpers/images'
import tcxImage from '../components/tcxImage'
import ImportShopify from "../components/ImportShopify";

export default {
  name: 'product-data-table',
  data() {
    return {
    imageEndpoint: GlobalStore.imageEndpoint,
    renderImage: imageMethods.renderImage,
    shopifyConfig: GlobalStore.custObj.config.shopify,
    maxSkuCodeLength: 12,
    }
  },
  props: {
    itemList:  Array,
    title: String,
    idField: String
  },
  computed: {
    filteredItemList: function () {
      return this.itemList;
    } 
  },
  methods:  {
    abbrSkuString: function(stringSku) {
      if(stringSku && stringSku.length > this.maxSkuCodeLength){
        return stringSku.substring(0, this.maxSkuCodeLength) + '...';
      }
      return stringSku;
    },
    getCustGuid: function() {
      return GlobalStore.custGuid;
    },
    editItem: function(guid) {
      this.$emit('editItem', guid);
    },
    addItem: function() {
      this.$emit('addItem');
    },
    deleteItem: function(guid) {
      this.$emit('deleteItem', guid);
    }
  },
  components: {
    tcxImage,
    ImportShopify
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.import-shopify-div {
    padding: 0px;
  }

.upload-button {
    width: 100%;
    padding: 0px;
    border-radius: 5px;
    font-size: 80%;
    color:#333 !important;
    text-align: center;
    background-color: lightgrey;
    cursor: pointer;
  }

table {
  background-color: white;
  margin-top: 20px;
}

.card {
  height: 100%;
}
 
.card-img-top {
  aspect-ratio: 1; /* Keeping the 1/1 aspect ratio. Not supported on < v83 IE*/
  overflow: hidden;
  position: relative;
}

/* IE Specific media query for the card headers to maintain a ~1:1 aspect ratio */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
   .card-img-top {
        height: calc(15vw + 15px);
   }
}

.card-title {
  min-height: 45px
}
.card-text {
  min-height: 70px;
  position: relative;
  font-size: 10pt;
  margin-bottom: 10px;
}


.card-buttons {
  display: flex;
  justify-content: space-between;
  padding: 0 20px 10px 20px;
}

.card-buttons div {
  cursor: pointer;
}

a:not([href]), a:not([href]):hover {
    color: white;
    text-decoration: none;
}

.card-body .delete-item {
  float: right;
  cursor: pointer;
}

.engagement-bar {
  margin-left: 12px;
  margin-bottom: 4px;
  color: #24661e;
  min-height: 24px;
  display: flex;
  flex-flow: wrap;
  row-gap: 5px;
  column-gap: 3px;
}

.engagement-item {
  display: inline-block;
  font-size: 10pt;
}

.badge {
  font-size: 8pt;
  opacity: 0.6;
  display: flex;
  padding: 3px 5px 2px 5px;
  z-index: 2;
}
.badge.item_type {
    background-color: lightslategray;
    color: #ffffff;
    padding: 3px 10px 2px 10px;
    position: absolute;
    bottom: 8px;
    left: 8px;
}


.badge.archived {
    background-color: firebrick;
    color: #ffffff;
    padding: 3px 10px 2px 10px;
    position: absolute;
    bottom: 24px;
    left: 8px;
}
.badge.sku {
  background-color: purple;
  color: #ffffff;
  padding: 3px 10px 2px 10px;
  font-weight: normal;
  position: absolute;
  top: 8px;
  left: 8px;
}
</style>