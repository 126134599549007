<template>
    <div>
    <h2 class="x-boxed noBorder">Reset my Password</h2>

    <div class="text-content">Please enter a new password here.
        <br><br>
        <p>Your password needs to include:</p>
        <ul>
            <li>At least 10 characters</li>
            <li>A capital letter</li>
            <li>A lower case letter</li>
            <li>A number</li>
        </ul>
        HINT: <i>Sometimes, the first couple of words of a song can make the basis of a good password!.</i>
    </div>



              <div id="browserErrorPanel"></div>

              <div  id="submitForm" class="x-boxed">
                <form action="/" id="LoginForm" method="POST">
                  <input id="fragment" name="fragment" style="display:none" type="hidden" value />

                  <fieldset>
                    <div class="field">
                      <label for="password">New Password</label>
                      <input
                        v-bind:style="(errorText) ? 'border: 2px solid red' : ''"
                        autocomplete="off"
                        id="password"
                        name="password"
                        v-model="password1"
                        placeholder="New Password"
                        title="New Password"
                        type="password"
                        @keyup="validatePassword()"
                      />
                    </div>

                    <div class="field">
                      <label for="password1">One more time please</label>
                      <input
                        v-bind:style="(errorText) ? 'border: 2px solid red' : ''"
                        autocomplete="off"
                        id="password1"
                        name="password1"
                        v-model="password2"
                        placeholder="One more time please"
                        title="One more time please"
                        type="password"
                        @keyup="validatePassword()"
                      />
                    </div>


                  </fieldset>


                    <div v-bind:class="(errorText) ? 'invalid-text' : ''">
                        {{ errorText }}
                    </div>
                  
                    <div v-bind:class="(resetText) ? 'invalid-text' : ''">
                        {{ resetText }}
                    </div>

                  <div class="actions">
                    <button :disabled="isRequesting" @click="resetPassword()" type="button" id="submitButton" class="x-btn blue main" :class="formValid == false ? 'button-disabled' : '' ">
                        <span v-if="!isRequesting">Reset my password please.</span>
                        <div v-if="isRequesting" class="spinner-border text-light" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </button>
                    
                  </div>
                </form> 
              </div>
              </div>
</template>

<script>
//import { GlobalStore } from '../../globalStore'
import axios from "axios";
import { GlobalStore } from "../../globalStore";

export default {
    data () {
        return {
            isRequesting: false,
            password1: '',
            password2: '',
            errorText: '',
            formValid: false,
            token: String,
            login: String,
            resetText: ''
        }
    },
    methods: {
        resetPassword() {
            if (this.formValid)
            {
                console.log('call reset');
                this.isRequesting = true;
                
                const headers = {
                    "Content-Type": "application/x-www-form-urlencoded",
                };

                this.loading = true;

                axios
                    .post(
                    GlobalStore.APIEndpoint +
                        "/password_reset_anon",
                    {
                        password: this.password1,
                        token: this.token
                    },
                    {
                        headers: headers,
                    }
                    )
                    .then((resp) => {
                        console.log(resp);
                        GlobalStore.view = 'landing';
                        this.$router.push("/login/?login=" + encodeURIComponent(this.login));
                    }).catch(err => {
                        //console.log(err.response)
                        if (err.response.status == 422) {
                            this.resetText = 'This token has expired, please request a new password reset.'
                        }
                    }).finally(() => {
                        this.isRequesting = false
                    })

            }
        },
        
        validatePassword: function() {

            this.errorText = '';
            this.formValid = false;

            if (this.password1.length > 0 && this.password2.length > 0 && this.password1 != this.password2) {
                this.errorText = "Passwords do not match, please re-enter."
            }

            if (this.password1.length > 0 && this.password2.length > 0 && this.password1.length < 10 && this.errorText == '') {
                this.errorText = "Password needs to be at least 10 characters."
            }

            if(this.password1.length > 0 && this.password2.length > 0 && !(/[a-z]+/.test(this.password1) && /[A-Z]+/.test(this.password1) && /\d+/.test(this.password1)) && this.errorText == '') {
                this.errorText = "Your password does not have a capital letter, lower case letter and number. Please re-enter."

            }

            if (this.errorText == '' && this.password1 != '' && this.password2 != '') {
                this.formValid = true;
            }

            //return errorText;
        }
    },
    async mounted() {
    //console.log(GlobalStore);
    
        this.login = this.$route.query.login;
        this.token = this.$route.query.token;

        console.log(this.login);
        console.log(this.token);

    }

}
</script>

<style lang="scss">

.button-disabled {
    color: darkgray !important;
    cursor: not-allowed !important;
}

.error-text {
    color: red;
    font-weight: bold;
    margin-top: 15px;
}

.invalid-text {
  margin-top: 10px;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 30px;
  font-weight: bold;
  background-color: white;
  color: red;
  border-radius: 10px;
  text-align: center;
}

li {
    text-align: left;
}

.field {
    margin: 10px 0px 10px 0px;
    input { 
        border-radius: 10px !important; 
    }
}

.text-content {
    text-align: center;
    color: white;
    font-size: 14px;
    margin-bottom: 5px;
    margin: 0px auto 0px auto !important; 
    max-width: 300px;
}

.x-header h1 {
    background-repeat: no-repeat
}

.x-msg {
    font: normal 12px/1.2 Helvetica, Arial, sans-serif;
    display: block;
    max-width: 938px;
    padding: 15px 15px 15px 50px;
    margin: 0 auto 20px;
    border-width: 2px;
    border-style: solid;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-position: 10px 7px;
    position: relative;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #444
}

.x-msg p {
    margin: 0 0 5px 0;
    color: #444;
    padding-left: 0;
    padding-right: 0;
    line-height: 1.2
}

.x-msg p:last-of-type {
    margin-bottom: 0
}

.x-msg.orange {
    background-color: #fffbef;
    border-color: #fa8100;
    background-position: 10px 6px
}

.x-msg.blue {
    background-color: #fbfbfb;
    border-color: #1c8be0;
    background-position: 10px 6px
}

@media (max-width:960px) {
    .x-msg {
        margin-left: 20px;
        margin-right: 20px
    }
}

:focus {
    outline: 0
}

::-ms-clear {
    display: none
}

body {
    margin: 0;
    padding: 0;
    background-color: #fbfbfb;
    font-size: 62.5%;
    font-family: Helvetica, sans-serif;
    line-height: 1.2;
    color: #333
}

body h1,
body h2,
body h3 {
    margin: 0;
    padding: 0
}

body h2 {
    font-size: 22px !important;
    color: #444;
    clear: both;
    margin-bottom: 15px
}

body a {
    color: #048fc2;
    text-decoration: none
}

body a:hover {
    text-decoration: underline
}

body fieldset {
    border-width: 0;
    padding: 0
}

body label {
    padding-top: 1px
}

header {
    display: block
}

input[type=password],
input[type=text] {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: #b5b8bb #e2e4e7 #e8eaec #bdbfc3;
    height: 27px;
    padding: 0;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 12px;
    padding-left: 5px
}

:-moz-placeholder,
::-webkit-input-placeholder {
    font-style: italic
}

.x-header input.placeholder {
    background-repeat: no-repeat;
    background-position: 3px 6px
}

.x-hidden,
div.hidden,
div.x-hidden {
    display: none
}

.x-actions {
    float: right;
    overflow: hidden
}

.x-actions a:last-of-type {
    margin-right: 0
}

.x-page {
    margin: 0 auto;
    position: relative;
    z-index: 1;
    overflow: hidden;
    overflow: visible
}

.x-content {
    width: 938px;
    clear: left;
    padding: 20px;
    margin: 0 auto;
    position: relative;
    overflow: hidden
}

.form {
    opacity: 0;
    -moz-transition: opacity .3s ease-out;
    -o-transition: opacity .3s ease-out;
    -webkit-transition: opacity .3s ease-out;
    transition: opacity .3s ease-out
}

.x-form {
    background-color: #fff;
    border: 1px solid #dfe1e2;
    padding: 0;
    color: #444;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-box-shadow: 0 1px 3px rgba(221, 221, 221, .5);
    -webkit-box-shadow: 0 1px 3px rgba(221, 221, 221, .5);
    box-shadow: 0 1px 3px rgba(221, 221, 221, .5);
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px
}

.x-header {
    color: #fff;
    display: block;
    background-color: #0590c3;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzE2OTZjNSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzA0OGJiZSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==);
    background: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0, #1696c5), color-stop(100%, #048bbe));
    background: -moz-linear-gradient(#1696c5, #048bbe);
    background: -webkit-linear-gradient(#1696c5, #048bbe);
    background: linear-gradient(#1696c5, #048bbe)
}

.x-header h1 {
    margin: 0;
    position: absolute;
    top: 14px;
    right: 0;
    visibility: visible;
    text-indent: -1000em;
    background-position: 0 -115px;
    width: 44px;
    height: 45px
}

.x-header h2 {
    margin: 0
}

.x-header h2 a {
    font-size: 15px
}

.x-header div {
    width: 920px;
    height: 62px;
    margin: 0 auto;
    padding-top: 10px;
    position: relative;
    z-index: 3
}

.x-header a {
    color: #fff
}

.x-main {
    border: 1px solid #0e83ae;
    border-bottom-width: 0;
    -moz-border-radius: 3px 3px 0 0;
    -webkit-border-radius: 3px;
    border-radius: 3px 3px 0 0;
    background-color: #0990c3;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzBlOWRkMiIvPjxzdG9wIG9mZnNldD0iNTAlIiBzdG9wLWNvbG9yPSIjMDk5MGMzIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjMGU4M2FlIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g);
    background: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0, #0e9dd2), color-stop(50%, #0990c3), color-stop(100%, #0e83ae));
    background: -moz-linear-gradient(#0e9dd2, #0990c3, #0e83ae);
    background: -webkit-linear-gradient(#0e9dd2, #0990c3, #0e83ae);
    background: linear-gradient(#0e9dd2, #0990c3, #0e83ae)
}

.x-title {
    height: 64px;
    background-color: #fff;
    border-bottom: 1px solid #cfd2d4;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iI2Y2ZjdmOCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==);
    background: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0, #fff), color-stop(100%, #f6f7f8));
    background: -moz-linear-gradient(#fff, #f6f7f8);
    background: -webkit-linear-gradient(#fff, #f6f7f8);
    background: linear-gradient(#fff, #f6f7f8)
}

.x-title>div {
    width: 920px;
    margin: 0 auto;
    position: relative;
    height: 64px;
    z-index: 2
}

.x-title h1 {
    font-size: 24px;
    position: absolute;
    bottom: 10px;
    left: 0
}

.your-logo {
    border: 1px solid #ccc;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px
}

.x-fieldset {
    border: 0
}

.x-btn {
    float: left;
    margin: 0 10px 15px 0;
    padding: 6px 12px;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
    font-weight: 700;
    border-width: 1px;
    border-style: solid;
    border-color: #cfd2d4 #cfd2d4 #cfd2d4 #cfd2d4;
    background-color: #fafafa;
    white-space: nowrap;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIvPjxzdG9wIG9mZnNldD0iOTglIiBzdG9wLWNvbG9yPSIjZWZlZmVmIi8+PHN0b3Agb2Zmc2V0PSI5OSUiIHN0b3AtY29sb3I9IiNmZmZmZmYiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=);
    background: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0, #fff), color-stop(98%, #efefef), color-stop(99%, #fff));
    background: -moz-linear-gradient(#fff 0, #efefef 98%, #fff 99%);
    background: -webkit-linear-gradient(#fff 0, #efefef 98%, #fff 99%);
    background: linear-gradient(#fff 0, #efefef 98%, #fff 99%);
    -moz-box-shadow: 0 0 1px #eee, rgba(255, 255, 255, .4) 1px 1px 1px inset, rgba(255, 255, 255, .4) -1px -1px 1px inset;
    -webkit-box-shadow: 0 0 1px #eee, rgba(255, 255, 255, .4) 1px 1px 1px inset, rgba(255, 255, 255, .4) -1px -1px 1px inset;
    box-shadow: 0 0 1px #eee, rgba(255, 255, 255, .4) 1px 1px 1px inset, rgba(255, 255, 255, .4) -1px -1px 1px inset;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    -moz-transition: opacity .2s ease-out;
    -o-transition: opacity .2s ease-out;
    -webkit-transition: opacity .2s ease-out;
    transition: opacity .2s ease-out
}

.x-btn[type=submit] {
    font-family: Helvetica, Arial, sans-serif
}

.x-btn:hover {
    text-decoration: none;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIvPjxzdG9wIG9mZnNldD0iOTglIiBzdG9wLWNvbG9yPSIjZTJlNWU4Ii8+PHN0b3Agb2Zmc2V0PSI5OSUiIHN0b3AtY29sb3I9IiNmZmZmZmYiLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIiBmaWxsPSJ1cmwoI2dyYWQpIiAvPjwvc3ZnPiA=);
    background: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0, #fff), color-stop(98%, #e2e5e8), color-stop(99%, #fff));
    background: -moz-linear-gradient(#fff 0, #e2e5e8 98%, #fff 99%);
    background: -webkit-linear-gradient(#fff 0, #e2e5e8 98%, #fff 99%);
    background: linear-gradient(#fff 0, #e2e5e8 98%, #fff 99%)
}

.x-btn:active {
    background: #f1f3f5
}

.x-btn.x-text {
    border-width: 0;
    background-image: none;
    background-color: transparent;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    font-weight: 400
}

.x-btn.x-text {
    position: static;
    display: inline-block;
    float: none;
    margin: 0;
    padding: 0
}

.x-btn.x-text button,
.x-btn.x-text em {
    position: static;
    float: none;
    display: inline-block;
    font-weight: 400;
    margin: 0;
    padding: 0
}

.x-btn.main {
    padding: 6px 20px;
    min-width: 60px
}

.x-btn.blue {
    color: #fff;
    border-color: #027ca7;
    background-color: #18a4d4;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzMyYWRkOSIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzA0OWNkMSIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==);
    background: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0, #32add9), color-stop(100%, #049cd1));
    background: -moz-linear-gradient(#32add9, #049cd1);
    background: -webkit-linear-gradient(#32add9, #049cd1);
    background: linear-gradient(#32add9, #049cd1)
}

.x-btn.blue:hover {
    border-color: #026d93;
    background-color: #1696c2;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzI3YTNkMCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzA0OGRiZCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==);
    background: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0, #27a3d0), color-stop(100%, #048dbd));
    background: -moz-linear-gradient(#27a3d0, #048dbd);
    background: -webkit-linear-gradient(#27a3d0, #048dbd);
    background: linear-gradient(#27a3d0, #048dbd)
}

.x-btn.blue button {
    color: #fff
}

div.x-btn {
    position: relative
}

div.x-btn button {
    margin: 0;
    padding: 0;
    border-width: 0;
    background-color: transparent;
    font: bold 12px Helvetica, sans-serif;
    cursor: pointer;
    color: #048fc2
}

.x-msg {
    font: normal 12px/1.2 Helvetica, Arial, sans-serif;
    display: block;
    max-width: 938px;
    padding: 15px 15px 15px 50px;
    margin: 0 auto 20px;
    border-width: 2px;
    border-style: solid;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-position: 10px 7px;
    position: relative;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    color: #444
}

.x-msg p {
    margin: 0 0 5px 0;
    color: #444;
    padding-left: 0;
    padding-right: 0;
    line-height: 1.2
}

.x-msg p:last-of-type {
    margin-bottom: 0
}

.x-msg.orange {
    background-color: #fffbef;
    border-color: #fa8100;
    background-position: 10px 6px
}

.x-msg.blue {
    background-color: #fbfbfb;
    border-color: #1c8be0;
    background-position: 10px 6px
}

@media (max-width:960px) {
    .x-msg {
        margin-left: 20px;
        margin-right: 20px
    }
}

.x-notify {
    padding: 20px 20px 0;
    background-color: #fff;
    position: relative;
    z-index: 1;
    border-bottom: 1px solid #dfe1e2;
    overflow: hidden
}

.x-text-line {
    padding: 10px 0;
    margin-right: 0;
    position: relative;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #e9e9e9;
    width: 100%
}

.x-text-line:first-of-type {
    border-top-width: 0;
    padding-top: 0
}

.x-text-line:last-of-type {
    border-bottom-width: 0;
    padding-bottom: 0
}

.x-text-line:only-of-type {
    padding-top: 0;
    padding-bottom: 0
}

.privacy {
    -webkit-font-smoothing: antialiased
}

.privacy .top {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 700
}

@font-face {
    font-family: Chevin-Demibold
}

@font-face {
    font-family: Chevin-Medium
}

@font-face {
    font-family: Chevin-Light
}

@font-face {
    font-family: Chevin-Thin
}

@font-face {
    font-family: National
}

:focus {
    outline: 0
}

body {
    background-color: #f9fbfb;
    font: normal 62.5%/1.2 helvetica, arial, sans-serif
}

body h1 {
    font-size: 22px;
    margin-bottom: 20px
}

body h2 {
    color: #444;
    overflow: hidden;
    font-family: National;
    font-size: 22px;
    -webkit-font-smoothing: antialiased;
    margin-bottom: 20px
}

body h3 {
    overflow: hidden;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 10px
}

body a {
    color: #048abb;
    text-decoration: none
}

body a:hover {
    text-decoration: underline
}

body p {
    line-height: 18px
}

.field {
    margin-bottom: 20px
}

.center {
    text-align: center
}

.clear {
    clear: both
}

.hidden {
    position: absolute;
    margin: -9999px
}

.x-page {
    font-size: 12px
}

.x-page .inner {
    overflow: hidden;
    margin: 0 auto;
    width: 960px
}

@media (max-width:960px) {
    .x-page .inner {
        width: 100%
    }
}

.x-page .content {
    background-color: #fff;
    padding-bottom: 80px
}

.x-boxed {
    border: 1px solid #e8ebee;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-box-shadow: #e8ebee 0 1px 2px;
    -webkit-box-shadow: #e8ebee 0 1px 2px;
    box-shadow: #e8ebee 0 1px 2px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    margin: 10px auto 30px;
    padding: 20px;
    width: 280px
}

.x-boxed.warning {
    background-image: none;
    border-color: #c00;
    margin-top: 30px;
    text-align: center;
    width: 392px
}

.x-boxed.warning p {
    font-size: 16px;
    font-weight: 700;
    list-style: none;
    margin: 0 0 10px 0
}

.x-boxed.warning p:last-child {
    margin: 0
}

.x-boxed.warning a {
    display: block;
    font-size: 12px;
    font-weight: 400;
    padding-top: 10px
}

.x-boxed.warning.x-detect {
    width: 490px
}

.x-boxed.warning.x-detect a {
    display: inline
}

@media (max-width:392px) {
    .x-boxed.warning {
        margin: 0 20px;
        width: auto
    }
    .x-boxed.warning.x-detect {
        width: auto
    }
}

.x-boxed.noBorder {
    background-image: none;
    border: 0;
    -moz-box-shadow: transparent 0 0 0;
    -webkit-box-shadow: transparent 0 0 0;
    box-shadow: transparent 0 0 0;
    margin: 30px auto 0;
    padding-bottom: 0;
    text-align: center;
    width: 370px
}

.x-boxed.noBorder p {
    margin-bottom: 15px
}

.x-boxed.noBorder+.noBorder {
    margin-top: 0
}

@media (max-width:370px) {
    .x-boxed.noBorder {
        width: auto
    }
}

h2.x-boxed.noBorder {
    margin-bottom: 25px;
    font-weight: 400
}

#activate {
    padding: 50px 5px;
    width: 100%;
    margin: 0 auto;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

.x-header .inner {
    height: 68px;
    padding-top: 0
}

.x-header h1 {
    left: 50%;
    margin-left: -22px
}

.x-header .logo {
    background: var(--savepage-url-4) no-repeat;
    display: block;
    height: 45px;
    width: 45px
}

.x-header .logo:hover {
    background-position: -46px 0;
    border-bottom: 0;
    text-decoration: none
}

.x-footer {
    background-color: #f9fbfb;
    border-top: 1px solid #e8ebee;
    color: #7b8188;
    padding: 20px 0 40px;
    width: 100%
}

@media (max-width:960px) {
    .x-footer .inner {
        text-align: center
    }
}

.x-btn {
    margin: 0 10px 0 0
}

.x-detect.noBorder p,
.x-detect.warning p {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 10px
}

input[type=email],
input[type=password],
input[type=text] {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-box-shadow: #e8eaec 0 1px 3px inset;
    -webkit-box-shadow: #e8eaec 0 1px 3px inset;
    box-shadow: #e8eaec 0 1px 3px inset;
    font-family: Helvetica, Arial, sans-serif;
    font-size: 12px;
    border: 1px solid #b5b8bb;
    border-color: #bdbfc3 #e8eaec #e2e4e7;
    padding: 6px 5px;
    width: 100%
}

label {
    color: #333;
    display: block;
    font-weight: 700;
    margin-bottom: 5px
}

.x-page .forgot-password-advert {
    display: none
}

.x-page input::-webkit-input-placeholder {
    color: transparent
}

.x-page input::-moz-placeholder {
    color: transparent
}

.x-page input:-moz-placeholder {
    color: transparent
}

.x-page input:-ms-input-placeholder {
    color: transparent
}

.x-page.marketing-advert .x-msg {
    width: 690px
}

.x-page.marketing-advert .x-msg p {
    line-height: 1.4em
}

@media (max-width:690px) {
    .x-page.marketing-advert .x-msg {
        margin: 0 20px;
        width: auto
    }
}

.x-page.marketing-advert .x-header {
    background-image: none
}

.x-page.marketing-advert .x-header h1 {
    background: 0 0
}

.x-page.marketing-advert .x-header .logo {
    height: 45px;
    width: 45px
}

@media (-webkit-min-device-pixel-ratio:2),
(-o-min-device-pixel-ratio:3 / 2),
(min--moz-device-pixel-ratio:2),
(min-device-pixel-ratio:2),
(min-resolution:144dpi) {
    .x-page.marketing-advert .x-header .logo {
        background-size: 90px
    }
    .x-page.marketing-advert .x-header .logo:hover {
        background-position-x: -45px
    }
}

.x-page.marketing-advert .x-boxed.message,
.x-page.marketing-advert .x-boxed.warning {
    box-sizing: border-box;
    color: #fff;
    margin: 0;
    text-align: center;
    padding: 0 10px
}

.x-page.marketing-advert .x-boxed.message h3,
.x-page.marketing-advert .x-boxed.message p,
.x-page.marketing-advert .x-boxed.warning h3,
.x-page.marketing-advert .x-boxed.warning p {
    color: #fff;
    font-size: 12px;
    font-weight: 400
}

.x-page.marketing-advert .x-boxed.message a,
.x-page.marketing-advert .x-boxed.warning a {
    display: none
}

.x-page.marketing-advert .x-boxed.warning+#submitForm input {
    border: 2px solid #e0476f
}

.x-page.marketing-advert .x-boxed.warning+#submitForm input[type=password] {
    border-top: none
}

.x-page.marketing-advert a:hover {
    text-decoration: none
}

.x-page.marketing-advert .content {
    background-color: #fff;
    padding-bottom: 50px;
    padding-top: 31px
}

.x-page.marketing-advert .content h2 {
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    padding-bottom: 14px;
    text-align: center
}

.x-page.marketing-advert .inner {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-border-radius: 5px 5px 5px 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px 5px 5px 5px;
    position: relative;
    width: 770px
}

@media (max-width:690px) {
    .x-page.marketing-advert .content {
        background-color: #00a2d5;
        padding-bottom: 0;
        padding-top: 0
    }
    .x-page.marketing-advert .inner {
        width: 100%
    }
}

.x-page.marketing-advert .form-container {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #00a2d5;
    -moz-border-radius: 5px 0 0 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px 0 0 5px;
    padding-top: 50px;
    position: relative
}

.x-page.marketing-advert .form-container h2 {
    color: #fff
}

@media (max-width:690px) {
    .x-page.marketing-advert .form-container {
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        border-radius: 5px
    }
}

.x-page.marketing-advert fieldset {
    margin: 0
}

.x-page.marketing-advert input {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #fff;
    border: 0;
    color: #000;
    font-size: 15px;
    line-height: 20px;
    margin: 0;
    padding: 0 12px 0 35px;
    height: 48px
}

.x-page.marketing-advert input::-webkit-input-placeholder {
    opacity: 1;
    color: #aeaba9
}

.x-page.marketing-advert input::-moz-placeholder {
    opacity: 1;
    color: #aeaba9
}

.x-page.marketing-advert input:-moz-placeholder {
    opacity: 1;
    color: #aeaba9
}

.x-page.marketing-advert input:-ms-input-placeholder {
    opacity: 1;
    color: #aeaba9
}

.x-page.marketing-advert input#email,
.x-page.marketing-advert input[type=email] {
    background: #fff var(--savepage-url-5) 10px 50% no-repeat;
    -moz-border-radius: 5px 5px 0 0;
    -webkit-border-radius: 5px;
    border-radius: 5px 5px 0 0
}

@media (-webkit-min-device-pixel-ratio:2),
(-o-min-device-pixel-ratio:3 / 2),
(min--moz-device-pixel-ratio:2),
(min-device-pixel-ratio:2),
(min-resolution:144dpi) {
    .x-page.marketing-advert input#email,
    .x-page.marketing-advert input[type=email] {
        background-size: 14px 14px
    }
}

.x-page.marketing-advert input#password,
.x-page.marketing-advert input[type=password] {
    background: #fff var(--savepage-url-6) 10px 50% no-repeat;
    -moz-border-radius: 0 0 5px 5px;
    -webkit-border-radius: 0;
    border-radius: 0 0 5px 5px;
    border-top: 1px solid #e8ebee
}

.x-page.marketing-advert input#password[data-placeholder-active=true],
.x-page.marketing-advert input[type=password][data-placeholder-active=true] {
    font-size: 15px
}

.x-page.marketing-advert input#password::-webkit-input-placeholder,
.x-page.marketing-advert input[type=password]::-webkit-input-placeholder {
    font-size: 15px;
    padding-top: 1px
}

.x-page.marketing-advert input#password::-moz-placeholder,
.x-page.marketing-advert input[type=password]::-moz-placeholder {
    font-size: 15px;
    padding-top: 1px
}

.x-page.marketing-advert input#password:-moz-placeholder,
.x-page.marketing-advert input[type=password]:-moz-placeholder {
    font-size: 15px;
    padding-top: 1px
}

.x-page.marketing-advert input#password:-ms-input-placeholder,
.x-page.marketing-advert input[type=password]:-ms-input-placeholder {
    font-size: 15px;
    padding-top: 1px
}

@media (-webkit-min-device-pixel-ratio:2),
(-o-min-device-pixel-ratio:3 / 2),
(min--moz-device-pixel-ratio:2),
(min-device-pixel-ratio:2),
(min-resolution:144dpi) {
    .x-page.marketing-advert input#password,
    .x-page.marketing-advert input[type=password] {
        background-size: 14px 14px
    }
}

.x-page.marketing-advert .actions {
    padding: 10px 0
}

.x-page.marketing-advert .actions .x-btn {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-box-shadow: #006d9b 0 1px 1px;
    -webkit-box-shadow: #006d9b 0 1px 1px;
    box-shadow: #006d9b 0 1px 1px;
    background-color: #0488b8;
    background-image: none;
    border: 1px solid #088aba;
    display: block;
    float: none;
    font-size: 17px;
    font-weight: 400;
    margin: 0;
    padding: 15px 0;
    width: 100%
}

.x-page.marketing-advert .actions .x-btn:hover {
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzA3OTVjNCIvPjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzA2ODdiNiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==);
    background: -webkit-gradient(linear, 50% 0, 50% 100%, color-stop(0, #0795c4), color-stop(100%, #0687b6));
    background: -moz-linear-gradient(#0795c4, #0687b6);
    background: -webkit-linear-gradient(#0795c4, #0687b6);
    background: linear-gradient(#0795c4, #0687b6)
}

.x-page.marketing-advert .forgot-password-advert {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: none;
    color: #afddf0;
    display: block;
    letter-spacing: .04em;
    position: absolute;
    text-align: center;
    bottom: 45px;
    width: 100%
}

.x-page.marketing-advert .forgot-password-advert:hover {
    color: #fff
}

.x-page.marketing-advert .cant-log-in {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: none;
    color: #afddf0;
    display: block;
    letter-spacing: .04em;
    position: absolute;
    text-align: center;
    bottom: 14px;
    width: 100%
}

.x-page.marketing-advert .cant-log-in:hover {
    color: #fff
}

.x-page.marketing-advert .x-footer .forgot-password {
    display: none
}

.x-page.marketing-advert .login-container {
    margin: 0 auto;
    position: relative;
    width: 690px;
    height: 600px !important;
}

@media (max-width:690px) {
    .x-page.marketing-advert .login-container {
        width: 100%
    }
}

.x-page.marketing-advert #submitForm {
    margin-top: 23px
}

.x-page.marketing-advert .form-container {
    width: 100%;
    height: 800px !important;
}

.x-page.marketing-advert .form-container h2 {
    padding-bottom: 23px;
    width: 100%
}

@media (max-width:690px) {
    .x-page.marketing-advert .form-container {
        width: 100%
    }
}

.x-page.marketing-advert .iframe-container {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: #f5f6f7;
    background-position: center center;
    border: 1px solid #e8ecef;
    -moz-border-radius: 0 5px 5px 0;
    -webkit-border-radius: 0;
    border-radius: 0 5px 5px 0;
    border-left: 0;
    position: absolute;
    top: 0;
    left: 275px;
    width: 415px;
    height: 800px
}

.x-page.marketing-advert .iframe-container iframe {
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    -moz-transition: opacity .5s cubic-bezier(.39, .575, .565, 1);
    -o-transition: opacity .5s cubic-bezier(.39, .575, .565, 1);
    -webkit-transition: opacity .5s cubic-bezier(.39, .575, .565, 1);
    transition: opacity .5s cubic-bezier(.39, .575, .565, 1)
}

@media (max-width:690px) {
    .x-page.marketing-advert .iframe-container {
        display: none
    }
}

.x-page.marketing-advert .field {
    margin-bottom: 0
}

.x-page.marketing-advert .x-boxed {
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: 0 0;
    border: 0;
    padding: 0 20px;
    margin: 0;
    width: 100%
}

.x-page.marketing-advert .x-footer {
    background-color: #f9fbfb;
    border-top: 1px solid #e8ebee;
    color: #7b8188;
    padding: 10px 0 40px;
    width: 100%
}

.x-page.marketing-advert .x-footer p {
    font-size: .9em;
    line-height: 1em;
    margin: 14px 0
}

.x-page.marketing-advert .x-footer a {
    line-height: .9em;
    color: #a9abb0
}

.x-page.marketing-advert .x-footer .center a+a {
    margin-left: 10px
}

.x-page.marketing-advert label {
    display: none
}



.x-page.marketing-advert input[type='email'], .x-page.marketing-advert input#email {
    background: #fff url(/img/envelope-51933199.png) 10px 50% no-repeat;
    -moz-border-radius: 5px 5px 0 0;
    -webkit-border-radius: 5px;
    border-radius: 5px 5px 0 0;
}

.x-page.marketing-advert input[type='password'], .x-page.marketing-advert input#password {
    background: #fff url(/img/padlock-ccc3dff1.png) 10px 50% no-repeat;
    -moz-border-radius: 0 0 5px 5px;
    -webkit-border-radius: 0;
    border-radius: 0 0 5px 5px;
    border-top: 1px solid #e8ebee;
}

.tag-line {
	text-align: center;
	margin-top: 180px;
	font-size: 15pt;
}

.x-header .inner {
	text-align: center;
}

.x-header {
	background-color: white !important;
	margin-top:0px;
  padding-top: 20px;
}

.x-page.marketing-advert .form-container {
    background-color: #3fa535;
}

.x-page.marketing-advert .actions .x-btn {
background-color: #24661e;
    border: 1px solid #24661e;
    box-shadow: #24661e 0 1px 1px !important;
}

.x-page.marketing-advert .content {
    background-color: #fff;
    padding-bottom: 50px;
    padding-top: 10px;
    
}

.x-page.marketing-advert .actions .x-btn:hover {
	background: linear-gradient(#24661e,#2d8824);
    border: 1px solid #24661e !important;
    box-shadow: #24661e 0 1px 1px !important;
}

.x-page.marketing-advert .forgot-password-advert, .x-page.marketing-advert .cant-log-in {
color: white;
}

</style>