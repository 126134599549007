var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row",staticStyle:{"margin-bottom":"10px"}},[_c('div',{staticClass:"col-3"},[_c('h3',[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"col-7 text-left"}),_c('div',{staticClass:"col-2"},[_c('a',{staticClass:"btn btn-primary btn-sm float-right",on:{"click":function($event){return _vm.addItem()}}},[_vm._v("Upload Item")])])]),_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-12 import-shopify-div"},[(_vm.shopifyConfig != null && (_vm.idField == 'top_story_guid' || (_vm.idField == 'content_guid' && _vm.contentItemType == 'best_seller')))?_c('ImportShopify',{attrs:{"idField":_vm.idField,"contentItemType":_vm.contentItemType},on:{"selectPage":_vm.selectPage}}):_vm._e()],1)]),_c('div',{staticClass:"row no-gutters"},_vm._l((_vm.filteredItemList),function(item,index){return _c('div',{key:index,staticClass:"col-md-3 col-xxl-3 mb-3 pr-md-2"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-img-top"},[_c('div',{staticClass:"order-by-pill",attrs:{"title":"Display Order"}},[_vm._v(" "+_vm._s(item.display.order_by)+" ")]),(
              item.images.hero_image_url != null &&
                item.images.hero_image_url != ''
            )?_c('tcx-image',{attrs:{"className":"img-fluid","imageSrc":item.images.thumbnail_image_url,"aspectBlur":true}}):_vm._e(),(
              item.images.hero_image_url == null ||
                item.images.hero_image_url == ''
            )?_c('img',{staticClass:"img-fluid",attrs:{"src":"/img/no-image-available-icon.jpg"}}):_vm._e(),(item.is_archived)?_c('span',{staticClass:"badge archived pull-right hidden-xs"},[_vm._v("archived")]):_vm._e(),(item.mode)?_c('span',{staticClass:"badge action_request_mode pull-right hidden-xs"},[_vm._v(_vm._s(item.mode))]):_vm._e(),(item.sku && (item.sku.code.trim().length > 0 || item.sku.variation.trim().length > 0))?_c('span',{staticClass:"badge sku pull-right hidden-xs"},[_vm._v(_vm._s(_vm.abbrSkuString(item.sku.code))+"/"+_vm._s(_vm.abbrSkuString(item.sku.variation)))]):_vm._e(),(item.item_type)?_c('span',{staticClass:"badge item_type pull-right hidden-xs"},[_vm._v(_vm._s(item.item_type))]):_vm._e(),(item.due_by <= _vm.currentTimeISO)?_c('span',{staticClass:"badge pastdue pull-right hidden-xs"},[_vm._v("past due")]):_vm._e(),(item.display.expires <= _vm.currentTimeISO)?_c('span',{staticClass:"badge expired pull-right hidden-xs"},[_vm._v("expired")]):_vm._e(),(item.display.publish_status == 'published')?_c('span',{staticClass:"badge published pull-right hidden-xs"},[_vm._v("published")]):_vm._e(),(item.display.publish_status != 'published')?_c('span',{staticClass:"badge draft pull-right hidden-xs"},[_vm._v("draft")]):_vm._e()],1),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"card-text"},[_vm._v(_vm._s(item.summary))])]),(
            item.hasOwnProperty('rating') &&
              item.content_item_type != 'best_store'
          )?_c('div',{staticClass:"p-1 engagement-bar"},[(item.rating.hasOwnProperty('stars'))?_c('div',{staticClass:"float-start engagement-item"},[_c('i',{staticClass:"fas fa-star"}),_vm._v(" "+_vm._s(item.rating.stars.toFixed(1))+" - "+_vm._s(item.rating.count)+" "+_vm._s(item.rating.count == 1 ? "rating" : "ratings")+" ")]):_vm._e(),(item.rating.hasOwnProperty('likes'))?_c('div',{staticClass:"float-start engagement-item"},[_c('i',{staticClass:"far fa-thumbs-up"}),_vm._v(" "+_vm._s(item.rating.likes)+" ")]):_vm._e()]):_vm._e(),(item.hasOwnProperty('responses'))?_c('div',{staticClass:"p-1 engagement-bar"},[_c('div',{staticClass:"float-start engagement-item"},[_c('i',{staticClass:"fas fa-check-circle"}),_vm._v(" "+_vm._s(item.responses.length)+" "+_vm._s(item.responses.length == 1 ? "response" : "responses")+"  "),(
                item.request_items.some(
                  function (el) { return el.request_type === 'imageupload'; }
                )
              )?_c('i',{staticClass:"fas fa-images"}):_vm._e()])]):_vm._e(),_c('div',{staticClass:"card-buttons"},[_c('a',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){return _vm.editItem(item[_vm.idField])}}},[_vm._v("Edit")]),_c('div',{staticClass:"copy_item",attrs:{"title":"Copy"},on:{"click":function($event){return _vm.copyItem(item)}}},[_c('i',{staticClass:"far fa-copy",attrs:{"aria-hidden":"true"}})]),(item.hasOwnProperty('action_request_guid'))?_c('div',{staticClass:"view-responses",attrs:{"title":"View Responses"},on:{"click":function($event){return _vm.viewResponses(item[_vm.idField])}}},[_c('i',{staticClass:"far fa-chart-bar",attrs:{"aria-hidden":"true"}})]):_vm._e(),(item.hasOwnProperty('content_guid'))?_c('div',{staticClass:"view-responses",attrs:{"title":"View Engagement"},on:{"click":function($event){return _vm.viewContentEngagement(item[_vm.idField])}}},[_c('i',{staticClass:"far fa-chart-bar",attrs:{"aria-hidden":"true"}})]):_vm._e(),(item.hasOwnProperty('top_story_guid'))?_c('div',{staticClass:"view-responses",attrs:{"title":"View Engagement"},on:{"click":function($event){return _vm.viewTopStoryEngagement(item[_vm.idField])}}},[_c('i',{staticClass:"far fa-chart-bar",attrs:{"aria-hidden":"true"}})]):_vm._e(),_c('div',{staticClass:"delete-item",on:{"click":function($event){return _vm.deleteItem(item[_vm.idField])}}},[_c('i',{staticClass:"fa fa-trash",attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"p-1 tag-bar"},_vm._l((_vm.sortedTags(item.tags ? item.tags : [])),function(tag){return _c('div',{key:tag,staticClass:"float-start tag-item"},[_c('span',{staticClass:"badge badge-pill badge-secondary"},[_vm._v(_vm._s(tag))])])}),0)])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }