<template>
  <nav aria-label="Page navigation example" class="float-right">
    <ul class="pagination">
      <li class="page-item" @click="prevPage()">
        <a
          class="page-link"
          v-bind:class="getCurrentPage() == 1 ? 'inactive' : ''"
          aria-label="Previous"
          ><span aria-hidden="true">&laquo;</span
          ><span class="sr-only">Previous</span></a
        >
      </li>
      <li
        v-if="current_page > 1 && pagingInfo.total_pages > displayPageLimit"
        class="page-item page-min"
        @click="selectPage(1)"
      >
        <a class="page-link">1</a>
      </li>
      <li
        v-if="current_page > 1 && pagingInfo.total_pages > displayPageLimit"
        class="page-item"
        @click="prevPage()"
      >
        <a class="page-link page-link-ellipsis">&#8230;</a>
      </li>

      <li
        class="page-item"
        @click="selectPage(index)"
        v-bind:class="getCurrentPage() == index ? 'active' : ''"
        v-for="index in this.pagingInfo.total_pages"
        :key="index"
      >
        <a v-if="showPageButton(index)" class="page-link">{{ index }}</a>
      </li>
      <li
        v-if="current_page < pagingInfo.total_pages - displayPageLimit"
        class="page-item"
        @click="nextPage()"
      >
        <a class="page-link page-link-ellipsis">&#8230;</a>
      </li>

      <li
        v-if="current_page < pagingInfo.total_pages - (displayPageLimit - 1)"
        class="page-item page-max"
        @click="selectPage(pagingInfo.total_pages - 1)"
      >
        <a class="page-link">{{ pagingInfo.total_pages }}</a>
      </li>
      <li class="page-item" @click="nextPage()">
        <a
          class="page-link"
          v-bind:class="
            getCurrentPage() == pagingInfo.total_pages ? 'inactive' : ''
          "
          aria-label="Next"
          ><span aria-hidden="true">&raquo;</span
          ><span class="sr-only">Next</span></a
        >
      </li>
    </ul>
  </nav>
</template>
<script>
//import axios from "axios";
//import { GlobalStore } from '../globalStore'

export default {
  name: "paginator",
  data() {
    return {
      //imagePath: '',
      //custGuid: GlobalStore.custGuid,
      //imageObj: { url: '', description: '', alt_text: ''}
      current_page: null,
      displayPageLimit: 5,
    };
  },
  props: {
    pagingInfo: Object,
    //imageSrc: String,
    //className: String
  },
  methods: {
    showPageButton: function(idx) {
      if (
        (idx >= this.current_page ||
          idx > this.pagingInfo.total_pages - this.displayPageLimit) &&
        idx < this.current_page + this.displayPageLimit
      ) {
        return true;
      }
    },
    selectPage: function(pageNo) {
      this.$emit("selectPage", pageNo);
      this.current_page = pageNo;
    },
    getCurrentPage() {
      this.current_page = this.pagingInfo.current_page || this.current_page;
      return this.current_page;
    },
    prevPage() {
      if (this.current_page > 1) {
        this.selectPage(this.current_page - 1);
      }
    },
    nextPage() {
      if (this.current_page < this.pagingInfo.total_pages) {
        this.selectPage(this.current_page + 1);
      }
    },
    screenSizeChangeEventHandler() {
      if (screen.width >= 380 && screen.width < 1000) {
        this.displayPageLimit = 3;
      } else if (screen.width < 380) {
        this.displayPageLimit = 4;
      } else {
        this.displayPageLimit = 5;
      }
    },
  },
  created() {
    window.addEventListener("resize", this.screenSizeChangeEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.screenSizeChangeEventHandler);
  },
  components: {},
  async mounted() {
    console.log(this.pagingInfo);
    this.current_page = 1;
    this.screenSizeChangeEventHandler();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.inactive {
  color: lightgray !important;
}

.inactive:hover {
  background-color: white !important;
  border-color: rgb(216, 226, 239);
}

.page-item.active .page-link {
  background-color: var(--green);
  border-color: var(--green);
}

.page-item .page-link:hover {
  background-color: var(--white);
  border-color: rgb(216, 226, 239);
}

@media (hover: hover) {
  .page-item .page-link:hover {
    background-color: var(--green);
    border-color: var(--green);
  }
}

.page-item.active .page-link {
  background-color: var(--green);
  border-color: var(--green);
}

.page-item .page-link {
  cursor: pointer;
}
.page-link-ellipsis {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

@media (max-width: 767px) {
  .page-link-ellipsis {
    display: none;
  }
}

@media (max-width: 380px) {
  .page-link-ellipsis,
  .page-max,
  .page-min {
    display: none;
  }
}
</style>
