<template>
  <div v-if="item">
    <h5>Tags</h5>
    <hr />
    <div v-for="tag in tagList" v-bind:key="tag">
      <input
        :id="tag"
        :value="tag"
        name="tag"
        type="checkbox"
        v-model="checkedTags"
        @change="onTagSelect"
      />
      <label style="margin-left: 20px;" :for="tag"
        ><span>{{ tag }}</span></label
      >
    </div>
  </div>
</template>

<script>
import { GlobalStore } from "../globalStore";
//import tcxImage from "../components/tcxImage";
//import { imageMethods } from "../helpers/images";
//import ImageDetailModal from "../components/modals/ImageDetailModal";
//import ConfirmationModal from "../components/modals/ConfirmationModal";
//import MediaLibraryModal from "../components/modals/MediaLibraryModal";

export default {
  name: "content-tags-sidebar",
  data() {
    return {
      imageEndpoint: GlobalStore.imageEndpoint,
      selectedImageItemId: null,
      selectedImageItem: null,
      selectedImageType: null,
      custConfig: GlobalStore.custObj.config,
      tagList: [],
      checkedTagList: [],
      checkedTags: Array,
    };
  },
  props: {
    itemList: Array,
    title: String,
    idField: String,
    item: null,
    type: String,
    selectedTags: Array,
  },
  computed: {
    filteredItemList: function() {
      return this.itemList;
    },
  },
  methods: {
    onTagSelect: function() {
      this.$emit("onSelectTag", this.checkedTags);
    },
  },
  async mounted() {
    //this.currentItem = this.item;
    //console.log(this.custConfig.tags);

    console.log(this.selectedTags);

    this.checkedTags = this.selectedTags;

    if (this.type == "fyi") {
      if (this.custConfig.tags.top_story) {
        this.tagList = this.custConfig.tags.top_story.sort();
      } else {
        this.tagList = [];
      }
    }
    if (this.type == "best_seller") {
      if (this.custConfig.tags.best_seller) {
        this.tagList = this.custConfig.tags.best_seller.sort();
      } else {
        this.tagList = [];
      }
    }
    if (this.type == "best_store") {
      if (this.custConfig.tags.best_store) {
        this.tagList = this.custConfig.tags.best_store.sort();
      } else {
        this.tagList = [];
      }
    }
    if (this.type == "memo") {
      if (this.custConfig.tags.memo) {
        this.tagList = this.custConfig.tags.memo.sort();
      } else {
        this.tagList = [];
      }
    }
    if (this.type == "todo") {
      if (this.custConfig.tags.action_request) {
        this.tagList = this.custConfig.tags.action_request.sort();
      } else {
        this.tagList = [];
      }
    }
  },
  components: {
    //tcxImage,
    //ImageDetailModal,
    //ConfirmationModal,
    //MediaLibraryModal,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.additional-images {
  margin-top: 10px;
}

.additional-image {
  width: 100%;
}

.additional-image-container {
  width: 50%;
  display: inline-block;
}

.hero-image,
.additional-image-container {
  position: relative;
}

.delete-button {
  position: absolute;
  right: 7px;
  top: 2px;
  cursor: pointer;
}

.delete-button svg {
  background: white;
  border-radius: 50%;
  border: 1px;
  border-color: white;
  border-style: solid;
}

.upload-button {
  width: 100%;
  padding: 0px;
  font-size: 20pt;
  text-align: center;
  background-color: lightgrey;
  cursor: pointer;
}

.upload-label {
  width: 100%;
  cursor: pointer;
}
</style>
<style>
#image1,
#image2 {
  display: none !important;
}
</style>
