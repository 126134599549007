import Vue from "vue";
import axios from "axios";
import { GlobalStore } from "../globalStore";

export const contentMethods = new Vue({
  methods: {
    getContentList: async function(
      contentItemType,
      pageNo,
      archived,
      query,
      item_type,
      status
    ) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      // var status_filter = "";

      // if (status == "Published") {
      //   status_filter = "&publish_status=published";
      // }

      // if (status == "Draft") {
      //   status_filter = "&publish_status=draft";
      // }

      // this.loading = true;

      // return axios.get(
      //   GlobalStore.APIEndpoint +
      //     "/todaycx-admin/content?content_item_type=" +
      //     contentItemType +
      //     "&per_page=" +
      //     GlobalStore.defaultPageSize +
      //     "&page=" +
      //     pageNo +
      //     status_filter,
      //   {
      //     headers: headers,
      //   }
      // );

      if (query != "") {
        query = (query ? query : "") + "*";

        let optional_filter = "";

        if (item_type != "All") {
          optional_filter = "&item_type=" + item_type;
        }

        return axios.get(
          GlobalStore.APIEndpoint +
            `/todaycx-admin/search/${contentItemType}?per_page=${
              GlobalStore.defaultPageSize
            }&page=${pageNo}&is_archived=${
              archived == true ? "true" : "false"
            }&query=${query}&publish_status=${status.toLowerCase()}${optional_filter}`,
          {
            headers: headers,
          }
        );
      } else {
        var status_filter = "";
        var archived_filter = "";
        var query_filter = "";

        if (status == "Published") {
          status_filter = "&publish_status=published";
        }

        if (status == "Draft") {
          status_filter = "&publish_status=draft";
        }

        archived_filter = "&is_archived=" + (archived ? "true" : "false");

        if (query != "") {
          query_filter = "&query=" + query;
        }

        if (item_type != "All") {
          query_filter = "&item_type=" + item_type;
        }

        return axios.get(
          GlobalStore.APIEndpoint +
            "/todaycx-admin/content?content_item_type=" +
            contentItemType +
            "&per_page=" +
            GlobalStore.defaultPageSize +
            "&page=" +
            pageNo +
            status_filter +
            archived_filter +
            query_filter,
          {
            headers: headers,
          }
        );
      }
    },
    getContentItem: async function(content_guid) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      this.loading = true;

      return axios.get(
        GlobalStore.APIEndpoint + "/todaycx-admin/content/" + content_guid,
        {
          headers: headers,
        }
      );
    },
    copyContentItem: async function(content_guid, new_item_type = "content") {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      this.loading = true;

      return axios.post(
        GlobalStore.APIEndpoint +
          "/todaycx-admin/content/" +
          content_guid +
          "/?new_item_type=" +
          new_item_type,
        "{}",
        {
          headers: headers,
        }
      );
    },
    deleteItem: async function(content_guid) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      this.loading = true;

      return axios.delete(
        GlobalStore.APIEndpoint + "/todaycx-admin/content/" + content_guid,
        {
          headers: headers,
        }
      );
    },
  },
});
