<template>
	<div class="form-group">
		<label for="name"
			>Target Audience
			<span
				class="ml-1 text-400"
				data-toggle="tooltip"
				data-placement="top"
				title="People with ANY of the selected roles AND ANY of the selected stores will see this. NOTE: No selected roles or 'everyone' = all roles. No selected stores = all stores."
			>
				<span
					class="far fa-question-circle"
					data-fa-transform="shrink-1"
				></span>
			</span>
		</label>
		<vue-tabs>
			<!-- <v-tab title="Managers" style="d:none;">
                <DualListBox
                    :source="source0" 
                    :destination="destination0"
                    label="name"
                    @onChangeList="onChangeList0"
                />
              </v-tab> -->

			<!-- <v-tab title="Regions">
                <DualListBox
                    :source="source1" 
                    :destination="destination1"
                    label="name"
                    @onChangeList="onChangeList1"
                />
              </v-tab> -->

			<v-tab title="Roles">
				<div class="label-container">
					<div>
						<label for="name">Roles Available</label>
					</div>
					<div>
						<label for="name">Roles Allocated</label>
					</div>
				</div>
				<DualListBox
					:source="source2"
					:destination="destination2"
					label="name"
					@onChangeList="onChangeList2"
				/>
			</v-tab>

			<!-- <v-tab title="Store Types">
                <DualListBox
                    :source="source3" 
                    :destination="destination3"
                    label="name"
                    @onChangeList="onChangeList3"
                />
              </v-tab> -->

			<v-tab title="Stores">
				<div class="label-container">
					<div>
						<label for="name">Stores Available</label>
					</div>
					<div>
						<label for="name">Stores Allocated</label>
					</div>
				</div>
				<DualListBox
					:source="source4"
					:destination="destination4"
					label="name"
					@onChangeList="onChangeList4"
				/>
			</v-tab>
		</vue-tabs>
	</div>
</template>

<script>
import DualListBox from 'dual-listbox-vue';
//import { GlobalStore } from '../globalStore'
export default {
	data() {
		return {
			source0: this.sourceArray[0],
			source1: this.sourceArray[1],
			source2: this.sourceArray[2],
			source3: this.sourceArray[3],
			source4: this.sourceArray[4],

			destination0: this.destinationArray[0],
			destination1: this.destinationArray[1],
			destination2: this.destinationArray[2],
			destination3: this.destinationArray[3],
			destination4: this.destinationArray[4]
		};
	},
	props: {
		sourceArray: Array,
		destinationArray: Array
	},
	methods: {
		onChangeList0: function({ source, destination }) {
			this.source0 = source;
			this.destination0 = destination;
		},
		onChangeList1: function({ source, destination }) {
			console.log(source, destination);
			this.source1 = source;
			this.destination1 = destination;
		},
		onChangeList2: function({ source, destination }) {
			this.source2 = source;
			this.destination2 = destination;
		},
		onChangeList3: function({ source, destination }) {
			this.source3 = source;
			this.destination3 = destination;
		},
		onChangeList4: function({ source, destination }) {
			this.source4 = source;
			this.destination4 = destination;
		},
		getAllListData: function() {
			var listData = {};

			listData.regional_managers = [];
			this.destination0.forEach((f) => {
				listData.regional_managers.push(f.code);
			});

			listData.regions = [];
			this.destination1.forEach((f) => {
				listData.regions.push(f.code);
			});

			listData.roles = [];
			this.destination2.forEach((f) => {
				listData.roles.push(f.code);
			});

			listData.store_types = [];
			this.destination3.forEach((f) => {
				listData.store_types.push(f.code);
			});

			listData.stores = [];
			this.destination4.forEach((f) => {
				listData.stores.push(f.code);
			});

			return listData;
		}
	},
	components: {
		DualListBox
	},
	async mounted() {
		//console.log(GlobalStore.userMeObject);
		//this.source4 = this.getUserStores();
	}
};
</script>

<style scoped>
section {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.label-container {
	display: flex;
	justify-content: space-between;
}

.label-container div {
	width: 22.5vw;
	text-align: center;
}
</style>
