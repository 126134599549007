<template>
<div>
    <ul class="navbar-nav flex-column">
            <li class="nav-item"><a class="nav-link dropdown-indicator" href="#home" data-toggle="collapse" role="button" aria-expanded="true" aria-controls="home">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><span class="fas fa-chart-pie"></span></span><span class="nav-link-text">Home</span>
                </div>
              </a>
              <ul class="nav collapse show" id="home" data-parent="#navbarVerticalCollapse">
                <li class="nav-item">
                  <router-link class="nav-link" to="/dashboard">Dashboard</router-link>
                </li>
              </ul>
            </li>
            <li class="nav-item"><a class="nav-link dropdown-indicator" href="#engage" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="engage">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><span class="fas fa-toolbox"></span></span><span class="nav-link-text">Engage</span>
                </div>
              </a>
              <ul vif="custObj" class="nav collapse" id="engage" data-parent="#navbarVerticalCollapse" v-if="custObj.config">
                <li v-if="this.userHasRole('admin_customer_product')" class="nav-item">
                  <router-link class="nav-link" to="/product">Products</router-link>
                </li>
                <li v-if="this.userHasRole('admin_customer_image')" class="nav-item">
                  <router-link class="nav-link" to="/mediaLibrary">Media Library</router-link>
                </li>
                <li class="nav-item">
                  <div class="nav-divider"></div>
                </li>
                <li class="nav-item" v-for="menuItem in displayableModules" :key=menuItem.menuText>
                  <router-link class="nav-link" :to="assertRootURL(menuItem.admin_url)">{{menuItem.menuText}}</router-link>
                </li>
                <!-- <li class="nav-item">
                  <router-link class="nav-link" to="/bestSellers">Best Sellers</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="/bestStores">Best Stores</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="/memos">Memos</router-link>
                </li>
                <li class="nav-item">
                  <router-link class="nav-link" to="/toDo">To Do</router-link>
                </li> -->
              </ul>
            </li>
            
            <li class="nav-item"><a class="nav-link dropdown-indicator" href="#people" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="people">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><span class="fas fa-user"></span></span><span class="nav-link-text">People</span><span class="badge badge-pill ml-2 badge-soft-success"></span>
                </div>
              </a>
              <ul class="nav collapse" id="people" data-parent="#navbarVerticalCollapse">
                <li v-if="this.userHasRole('admin_customer_user')" class="nav-item">
                  <router-link class="nav-link" to="/users">Users</router-link>
                </li>
              </ul>
            </li>
            
          </ul>
          <!--
          <ul class="navbar-nav flex-column">

            <li class="nav-item"><a class="nav-link dropdown-indicator" href="#settings" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="settings">
                <div class="d-flex align-items-center"><span class="nav-link-icon"><span class="fas fa-book"></span></span><span class="nav-link-text">Settings</span>
                </div>
              </a>
              <ul class="nav collapse" id="settings" data-parent="#navbarVerticalCollapse">
                 <li class="nav-item"><a class="nav-link" href="../documentation/getting-started.html">Getting started</a>
                </li>
                <li class="nav-item"><a class="nav-link" href="../documentation/file-structure.html">File structure</a>
                </li>
                <li class="nav-item"><a class="nav-link" href="../documentation/customization.html">Customization</a>
                </li>
                <li class="nav-item"><a class="nav-link" href="../documentation/dark-mode.html">Dark mode</a>
                </li>
                <li class="nav-item"><a class="nav-link" href="../documentation/fluid-layout.html">Fluid layout</a>
                </li>
                <li class="nav-item"><a class="nav-link" href="../documentation/gulp.html">Gulp</a>
                </li>
                <li class="nav-item"><a class="nav-link" href="../documentation/RTL.html">RTL</a>
                </li>
                <li class="nav-item"><a class="nav-link" href="../documentation/plugins.html">Plugins</a>
                </li>
              </ul>
            </li>
          </ul> -->
</div>
</template>

<script>
//import axios from "axios";
import { GlobalStore } from "../globalStore";
import { GlobalMethods } from "../globalMethods";

// import { customerMethods } from "../helpers/customer";

export default {
  name: 'admin-menu',
  data() {
    return {
      customerModules: []
    }
  },
  props: {
    
  },
  computed: {
    custObj:  function() {
      return GlobalStore.custObj;
    },
    userObj:  function() {
      return GlobalStore.userMeObject;
    },
    displayableModules(){
      var displayable = [];
      var titlesFYI = {};
      var titlesBestSeller = {};
      var titlesBestStore = {};
      var titlesMemo = {};
      var titlesActionRequest = {};
      
      GlobalStore.custObj.config.modules.forEach(obj => {
        if (this.userObj){
          if (obj.admin_url.startsWith('fyi') && this.userHasRole('admin_customer_top_story')){
            titlesFYI[obj.title] = true;
            obj['context'] = 'fyi'
            if (obj.optional !== true) {
                  displayable.push(obj);               
            }
          }
          if (obj.admin_url.startsWith('bestSellers') && this.userHasRole('admin_customer_best_seller')){
            titlesBestSeller[obj.title] = true;
            obj['context'] = 'best_seller'
            if (obj.optional !== true) {
              displayable.push(obj);
            }
          }
          if (obj.admin_url.startsWith('bestStores') && this.userHasRole('admin_customer_best_store')){
            titlesBestStore[obj.title] = true;
            obj['context'] = 'best_store'
            if (obj.optional !== true) {
              displayable.push(obj);
            }
          }
          if (obj.admin_url.startsWith('memos') && this.userHasRole('admin_customer_memo')){
            titlesMemo[obj.title] = true;
            obj['context'] = 'memo'
            if (obj.optional !== true) {
              displayable.push(obj);
            }
          }
          if (obj.admin_url.startsWith('toDo') && this.userHasRole('admin_customer_action_request')){
            titlesActionRequest[obj.title] = true;
            obj['context'] = 'action_request'
            if (obj.optional !== true) {
              displayable.push(obj);
            }
          }
        }
      });
      // Update the items with grouped titles
      console.log('displayable', displayable);
      console.log('titlesMemo', titlesMemo);
      console.log('titlesFYI keys', Object.keys(titlesFYI));
      
      if(Object.keys(titlesFYI).length > 0){
        displayable[displayable.findIndex((obj => obj.context == 'fyi'))].menuText = Object.keys(titlesFYI).join(', ');
      }
      if(Object.keys(titlesBestSeller).length > 0){
        displayable[displayable.findIndex((obj => obj.context == 'best_seller'))].menuText = Object.keys(titlesBestSeller).join(', ');
      }
      if(Object.keys(titlesBestStore).length > 0){
        displayable[displayable.findIndex((obj => obj.context == 'best_store'))].menuText = Object.keys(titlesBestStore).join(', ');
      }
      if(Object.keys(titlesMemo).length > 0){
        displayable[displayable.findIndex((obj => obj.context == 'memo'))].menuText = Object.keys(titlesMemo).join(', ');
      }
      if(Object.keys(titlesActionRequest).length > 0){
        displayable[displayable.findIndex((obj => obj.context == 'action_request'))].menuText = Object.keys(titlesActionRequest).join(', ');
      }
      return displayable;
      // return  GlobalStore.custObj.config.modules.filter(obj => {
      //   return obj.optional !== true
      // });
    }  
  },
  methods: {
    // populateMenu: function() {
    //     if(GlobalStore.custObj){
    //     var index;
    //     for (index = 0; index < GlobalStore.custObj.config.modules.length; ++index) {
    //       this.customerModules.push(GlobalStore.custObj.config.modules[index]);
    //       console.log("doing something " + index);
    //     }
    //   }else{
    //     console.log("Customer object is not available");
    //   }
    // }
    // assertRootURL: function(url) {
    userHasRole(role){
      return this.userObj.roles.includes(role);
    },
    assertRootURL(url) {
      // asserts a leading slash on the url
      console.log(GlobalMethods.assertRootURL(url));
      return "/" + url;
      // return "/" + url.replace("^/", "")
    }
  },
async mounted() {
    //console.log(GlobalStore);
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.nav-divider {
  width: 50%;
  height: 10px;
  margin: 0 0 10px 24px;
  border-bottom: 1px solid #5e6e82;
}

</style>
