import Vue from "vue";
import axios from "axios";
import { GlobalStore } from "../globalStore";

export const fyiMethods = new Vue({
  methods: {
    getFYIList: async function(pageNo, archived, query, item_type, status) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      console.log(archived);

      this.loading = true;

      if (query != "") {
        query = (query ? query : "") + "*";

        let optional_filter = "";

        if (item_type != "All") {
          optional_filter = "&item_type="  + item_type;
        }


        return axios.get(
          GlobalStore.APIEndpoint +
            `/todaycx-admin/search/top_stories?per_page=${
              GlobalStore.defaultPageSize
            }&page=${pageNo}&is_archived=${
              archived == true ? "true" : "false"
            }&query=${query}&publish_status=${status.toLowerCase()}${optional_filter}`,
          {
            headers: headers,
          }
        );
      } else {
        var status_filter = "";
        var archived_filter = "";
        var query_filter = "";

        if (status == "Published") {
          status_filter = "&publish_status=published";
        }

        if (status == "Draft") {
          status_filter = "&publish_status=draft";
        }

        archived_filter = "&is_archived=" + (archived ? "true" : "false");

        if (query != "") {
          query_filter = "&query=" + query;
        }

        if (item_type != "All") {
          query_filter = "&item_type="  + item_type;
        }

        return axios.get(
          GlobalStore.APIEndpoint +
            "/todaycx-admin/top_story?per_page=" +
            GlobalStore.defaultPageSize +
            "&page=" +
            pageNo +
            status_filter +
            archived_filter +
            query_filter,
          {
            headers: headers,
          }
        );
      }
    },
    getFYIItem: async function(top_story_guid) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      this.loading = true;

      return axios.get(
        GlobalStore.APIEndpoint + "/todaycx-admin/top_story/" + top_story_guid,
        {
          headers: headers,
        }
      );
    },
    copyFYIItem: async function(top_story_guid, new_item_type = "content") {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      this.loading = true;

      return axios.post(
        GlobalStore.APIEndpoint +
          "/todaycx-admin/top_story/" +
          top_story_guid +
          "/?new_item_type=" +
          new_item_type,
        "{}",
        {
          headers: headers,
        }
      );
    },
    deleteFYIItem: async function(top_story_guid) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      this.loading = true;

      return axios.delete(
        GlobalStore.APIEndpoint + "/todaycx-admin/top_story/" + top_story_guid,
        {
          headers: headers,
        }
      );
    },
  },
});
