import { render, staticRenderFns } from "./ImageDetailModal.vue?vue&type=template&id=12e6f2da&scoped=true&"
import script from "./ImageDetailModal.vue?vue&type=script&lang=js&"
export * from "./ImageDetailModal.vue?vue&type=script&lang=js&"
import style0 from "./ImageDetailModal.vue?vue&type=style&index=0&id=12e6f2da&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12e6f2da",
  null
  
)

export default component.exports