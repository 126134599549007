<template v-if="loaded">
    <div class="import-image-box">
      <div class="row">
      <div class="col-12">
        <h5 class="mb-0 ml-2 mb-3 d-flex align-items-center">
          Import Images
        </h5>
      </div>
    </div>
      <div class="row">
        <div class="input-container">
          <div class="col-1">
            <label for="imageSrc" class="import-label">Image:</label>
          </div>
          <div class="col-10">
            <input type="text" placeholder="Paste a URL to the image or select a file to upload" v-model="imageSrc" id="imageSrc" maxlength="512">
          </div>          
          <div class="col-1">
            <div class="import-button-container">
              <label for="imageImport1" slot="upload-label" class="upload-label">
                <div title="Upload Image" class="btn-primary"><span class="fas fa-file"></span></div>
              </label>
            <image-uploader
              id="imageImport1"
              :preview="false"
              capture="environment"
              :debug="1"
              doNotResize="gif"
              :autoRotate="true"
              outputFormat="verbose"
              @input="setLocalImage($event)"
            >
            </image-uploader>
          </div>
            <!-- bla
            <div class="img-container">
              <image-uploader
              id="fileInput"
              :preview="false"
              capture="environment"
              :debug="1"
              doNotResize="gif"
              :autoRotate="true"
              outputFormat="verbose"
              @input="handleFileSelection"
              >
                <label for="fileInput" class="upload-button"><span class="fas fa-file">ddas</span></label>
              </image-uploader>
              <input title="Select file" type="file" id="fileInput" accept=".jpg, .jpeg, .png, .gif" name="fileInput" @change="handleFileSelection"> -->
            <!-- </div> --> 
          </div>
        </div>
      </div>
      <div class="row">
        <div class="input-container">
          <div class="col-1">
            <label for="imageDescription">Description:</label>
          </div>
          <div class="col-11">
            <input type="text" placeholder="Describe what the image is..." v-model="imageDescription" id="imageDescription" maxlength="100"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="input-container">
          <div class="col-1">
            <label for="imageAltText">Alt-text:</label>
          </div>
          <div class="col-11">
            <input type="text" placeholder="This text is shown when the mouse hovers over the image." v-model="imageAltText" id="imageAltText" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="input-container">
          <div class="col-1">
            <label for="imageSkuCodes">Sku&nbsp;code:</label>
          </div>
          <div class="col-5">
            <input type="text" placeholder="Separate codes with commas. e.g. sku123A,sku123b" v-model="imageSkuCodes" id="imageSkuCodes" maxlength="100" />
          </div>
          <div class="col-2">
            <label for="imageItemType">Item type:</label>
          </div>
          <div class="col-2">
            <select v-model="imageItemType" id="dropdown" name="dropdown" class="import-dropdown">
              <option v-for="item in getItemTypes" :key="item" :value="item">
                {{ capitalizeFirstLetter(item) }}
              </option>
            </select>
          </div>
          <div class="col-2">
            <div class="import-button-container">
              <label for="imageImport" slot="import-button-label" class="import-button-label">
              <div title="Import Image" class="btn-primary" @click="importImage" id="imageImport" :disabled="importingImage">
                <!-- Display spinner if importingImage is true -->
                <div v-if="importingImage" class="spinner-border text-light" role="status"></div>
                <!-- Otherwise, display the import button text -->
                <span v-else>Import image</span>  
              </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <!-- <button class="btn-primary" >Import Image</button> -->
    </div>
  </template>
  
  <script>

  import { GlobalMethods } from "../globalMethods";
  import { GlobalStore } from "../globalStore";
  import axios from "axios";

  export default {
    name: "importImage",
    data() {
      return {
        imageUrl: "",
        imageItemType: "content",
        selectedFileName: null,
        imageSrc: null,
        imageDescription: null,
        imageAltText: null,
        imageSkuCodes: null,
        pagingInfo : {},
        importingImage: false, // A variable to track import status
      };
    },
    props: {
      useAsImageChooser: Boolean,
    },
    methods: {
      handleFileSelection(event) {
        const fileInput = event.target;
        const file = fileInput.files[0];
        console.log("fileselection", file);
        if (file) {
          this.selectedFileName = file.name;
          this.imageSrc = file.name;
          this.hasImage = true;
          this.image = file;
          console.log("handleFileSelection file length = ", file.length);
        } else {
          this.selectedFileName = null;
          this.imageSrc = null;
          this.hasImage = false;
          this.image = null;
        }
      },
      setItemType: function(type) {
      this.itemType = type;
      this.$emit("setItemType", type);
      },
      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      },
      hasImageExtension(imageName) {
        return imageName && (imageName.endsWith(".jpg") || imageName.endsWith(".jpeg") || imageName.endsWith(".png") || imageName.endsWith(".gif") || imageName.endsWith(".webp"))
      },
      isUrl(imageName) {
        return imageName.startsWith("http")
      },
      getApiHeaders: function() {
      return {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };      
      },
      getApiBody: function() {
        let apiSkuList = [];
        let apiSkuObject = ""
        if(this.imageSkuCodes && this.imageSkuCodes.length > 0){
          apiSkuList = this.imageSkuCodes.split(",|;| ");
          apiSkuObject = {code: this.imageSkuCodes, variation: ""};
        }else{
          apiSkuList = [];
          apiSkuObject = {code: "", variation: ""};
        }
        return {
          url: this.imageSrc,
          description: this.imageDescription ?? "",
          alt_text: this.imageAltText ?? "",
          item_type: this.imageItemType ?? "",
          sku_list: apiSkuList,
          sku : apiSkuObject,
          }
      },
      importImagefromUrl: async function(callback) {
        console.log("importImagefromUrl");
        var apiHeaders = this.getApiHeaders();
        var apiUrl = GlobalStore.APIEndpoint + "/todaycx-admin/image_import/";
        var apiBody = [this.getApiBody()];
        console.log("importImagefromUrl:apiHeaders", apiHeaders);
        console.log("importImagefromUrl:apiUrl", apiUrl);
        console.log("importImagefromUrl:apiBody", apiBody);

        axios.post(apiUrl, apiBody, { headers: apiHeaders })
            .then(resp => {
                console.log("image_import response:", resp);
                // check that it was actually successful
                if(resp.data[0].url.length > 0){
                  callback(null, resp.data[0]);
                }else{
                  console.error("import was unsuccessful", resp.data[0].description);
                  throw resp.data[0].description; // the error info is in the description
                }               
            })
            .catch(error => {
                console.error("importImageFromUrl", error);
                this.handleRequestError(error, callback);
            });
      },
      importImagefromLocal: function (callback) {
          if (this.image == null) {
              GlobalStore.toastObj = this.$toasted.show("Please select an image to upload.", {
                theme: "outline",
                position: "bottom-center",
                duration: 3000,
                type: "error",
                fitToScreen: true,
                fullWidth: false,
              });
              return;
          }

          var apiHeaders = this.getApiHeaders();
          var apiUrl = GlobalStore.APIEndpoint + "/todaycx-admin/image/";
          var apiBody = this.getApiBody();

          console.log("importImagefromLocal:apiHeaders", apiHeaders);
          console.log("importImagefromLocal:apiUrl", apiUrl);
          console.log("importImagefromLocal:apiBody", apiBody);
          console.log("importImagefromLocal:apiBody - loaded image", this);

          // Thumbnail
          var ratio = this.image.info.orgWidth / this.image.info.orgHeight;
          console.log("new Image() ratio:", ratio);
          var img = new Image();
          img.src = this.image.dataUrl;

          // Image onload event
          img.onload = () => {
              try {
                  var thumbnail = this.imageToDataUri(
                      img,
                      250,
                      250 / ratio,
                      this.image.info.type
                  );

                  // Update body fields for thumbnail
                  apiBody['base64'] = thumbnail.replace("data:" + this.image.info.type + ";base64,", "");
                  apiBody['content_type'] = this.image.info.type;
                  apiBody['is_thumbnail'] = true;

                  // First axios.post call (thumbnail)
                  axios.post(apiUrl, apiBody, { headers: apiHeaders })
                      .then(thumbresp => {
                          console.log("respThumbnail:", thumbresp);

                          if (thumbresp.status === 201) {
                              // Update body fields for full-size image
                              apiBody['base64'] = this.image.dataUrl.replace("data:" + this.image.info.type + ";base64,", "");
                              apiBody['content_type'] = this.image.info.type;
                              apiBody['is_thumbnail'] = false;
                              apiBody['thumbnail_image_guid'] = thumbresp.data.image_guid;
                              apiBody['thumbnail_url'] = thumbresp.data.url;
                              apiBody['width'] = this.image.info.orgWidth;
                              apiBody['height'] = this.image.info.orgHeight;
                              console.log("importImagefromLocal:apiBody (enhanced):", apiBody);

                              // Second axios.post call (full-size image)
                              axios.post(apiUrl, apiBody, { headers: apiHeaders })
                                  .then(resp => {
                                      console.log("respImage", resp);
                                      GlobalStore.customerImages.push(resp.data);
                                      console.log("importImagefromLocal: respData:", resp.data);
                                      callback(null, resp.data);
                                  })
                                  .catch(error => {
                                      this.handleRequestError(error, callback);
                                      console.error("importImageFromLocal", error);
                                  });
                          } else {
                              callback(null, thumbresp.data);
                          }
                      })
                      .catch(error => {
                          this.handleRequestError(error, callback);
                          console.error("importImageFromLocal", error);
                      });

              } catch (error) {
                  console.error('Error during image import:', error.message);
                  this.handleRequestError(error, callback);
              }
          };

          // Image onerror event
          img.onerror = (error) => {
              console.log("Image() load failed. Error: ", error.message);
              this.handleRequestError(error, callback);
          };
      },
      resetMetaDataFields: function() {
        this.imageSrc = "";
        this.imageDescription = "";
        this.imageAltText = "";
        this.imageSkuCodes = "";
        // this.imageItemType = "content"; // Leave this as selected
        
      },
      setLocalImage: function(file) {
        this.hasImage = true;
        this.image = file;
        this.imageSrc = file.info.name;

        console.log("setLocalImage file: ", file);
      },
      imageToDataUri: function(img, width, height, type) {
        // create an off-screen canvas
        var canvas = document.createElement("canvas"),
          ctx = canvas.getContext("2d");

        // set its dimension to target size
        canvas.width = width;
        canvas.height = height;

        // draw source image into the off-screen canvas:
        ctx.drawImage(img, 0, 0, width, height);

        // encode image to data-uri with base64 version of compressed image
        return canvas.toDataURL(type);
      },
      handleRequestError: function (error, callback) {
          if (error.response && error.response.status === 401) {
              // Handle 401 Unauthorized error here (e.g., redirect to login page)
              console.error('Unauthorized - Redirect to login or take appropriate action');
              callback(error);
          } else {
              // Handle other errors
              console.error('handleRequestError: Error during request:', error.message);
              callback(error);
          }
      },
      importImage() {
        this.importingImage = true;
        if(this.imageSrc !== null){
          this.imageSrc = this.imageSrc.trim()
        }
        if (this.hasImageExtension(this.imageSrc)) {
          if(this.isUrl(this.imageSrc)) {
            console.log("IS a Url - importing from Url");
            this.importImagefromUrl((error, result) => {
              if(error){
                console.log("importImagefromUrl error", error)
                GlobalStore.toastObj = this.$toasted.show("The web image could not be imported: " + error, {
                  theme: "outline",
                  position: "bottom-center",
                  duration: 3000,
                  type: "error",
                  fitToScreen: true,
                  fullWidth: false,
                });
                this.importingImage = false;
              }else{
                console.log("importImagefromUrl result", result)
                this.resetMetaDataFields();
                if(this.useAsImageChooser){
                  console.log("emitting useImage", result);
                  this.$emit("useImage", result);
                }else{
                  console.log("emitting selectPage", result);
                  this.$emit("selectPage", 0);
                }
                GlobalStore.toastObj = this.$toasted.show("The web image was imported with Item type: " + this.imageItemType, {
                  theme: "outline",
                  position: "bottom-center",
                  duration: 3000,
                  type: "success",
                  fitToScreen: true,
                  fullWidth: false,
                });
                this.importingImage = false;
              }

            });
          }else{
            // upload it
            console.log("Not a Url - importing from local");
            this.importImagefromLocal((error, result) => {
              if(error){
                GlobalStore.toastObj = this.$toasted.show("The local image could not be uploaded: " + error , {
                  theme: "outline",
                  position: "bottom-center",
                  duration: 3000,
                  type: "error",
                  fitToScreen: true,
                  fullWidth: false,
                });
                this.importingImage = false;
              }else{
                console.log("importImagefromLocal result", result)
                this.resetMetaDataFields();
                if(this.useAsImageChooser){
                  console.log("emitting useImage", result);
                  this.$emit("useImage", result);
                }else{
                  console.log("emitting selectPage", result);
                  this.$emit("selectPage", 0);
                }
                GlobalStore.toastObj = this.$toasted.show("The local image was uploaded with Item type: " + this.imageItemType, {
                  theme: "outline",
                  position: "bottom-center",
                  duration: 3000,
                  type: "success",
                  fitToScreen: true,
                  fullWidth: false,
                });
              }
              this.importingImage = false;
            });
          }
        } else {
          // Notify the user if the URL is empty
          // alert("Please enter a valid image path or URL.");
          GlobalStore.toastObj = this.$toasted.show("Please select an image to upload or enter a web link to an image.", {
                theme: "outline",
                position: "bottom-center",
                duration: 3000,
                type: "error",
                fitToScreen: true,
                fullWidth: false,
              });
        }
        this.importingImage = false;
      },
    },
    computed: {
      getItemTypes() {
        console.log("ImportImage getItemTypes: (image) - before")
        const itemTypes = GlobalMethods.getDisplayableItemTypes(
          "image"
        );
        console.log("ImportImage getItemTypes: (image)", itemTypes)
         
        return itemTypes;
      },
    },
    async mounted() {
//      this.getItemTypes()
      console.log("*** ImportImage Component loaded ***");
  },
  };
  </script>
  
  <style scoped>
   
  .hidden-element {
    display: hidden !important;
  }
  .import-image-box {
    border-style: solid;
    border-color: lightgrey;
    border-width: 1px;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 0px;
    background-color: #f7f7f7;
  }
  .import-label {
  margin-right:10px;
  font-size: 80%;
  cursor: pointer;
  }
  .upload-label {
    width: 100%;
    cursor: pointer;
  }
  .import-button-label {
    width: 100%;
    font-size: 100%;
    cursor: pointer;
  }
  .import-dropdown {
    font-size: 80%;
    border-style: solid;
    border-color: lightgrey;
  }
  .file-selector {
    font-size: 80%;
    border-style: solid;
    border-color: lightgrey;
    background-color: blue;
  }
  .btn-primary {
      padding: 0px;
      border-radius: 5px;
      font-size: 80%;
      width: 100%;
      height: 100%;
      text-align: center;
      cursor: pointer;
    }

  .upload-button {
    padding: 0px;
    width: 100%;
    border-radius: 5px;
    font-size: 100%;
    text-align: center;
    background-color: primary;
    cursor: pointer;
  }

  .img-container {
    min-width: 165px;
    max-width: 165px;
    margin-left: auto;
  }
  .input-container {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .import-button-container {
    width: 100%;
    padding-top: 6px;
    display: flex;
    align-items: center;
  }
  @media (max-width: 1000px) {
    .search-box .search-input {
      width: 75%;
      padding-left: 1.95rem;
    }
  }

  @media (max-width: 767px) {
    .btn-primary {
      padding: 0px;
      border-radius: 5px;
      font-size: 100%;
      text-align: center;
      background-color: indigo;
      cursor: pointer;
    }
  .search-div {
      max-width: 100%;
      flex: 0 0 100%;
    }

    .search-box .search-input {
      width: 100%;
      padding-left: 1.95rem;
    }

    .paginator-div {
      width: 100%;
      margin-top: 20px;
      margin-left: 0px;
    }

    .paginator-div nav {
      float: left !important;
    }

    .archived-div {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }

  @media (max-width: 320px) {
    .search-filter-box {
      padding: 20px 0px 0px 0px;
    }
    .search-box .search-input {
      width: 90%;
      padding-left: 1.95rem;
    }
  }
  </style>
  <style>
  #imageImport1 {
    display: none !important;
  }
  </style>