<template>
  <div v-if="currentItem">
    <h3>{{title}}</h3>
    <div class="container">
      <div class="row">
        <div class="col-9">
          <div v-if="currentItem.flexify">
            <div v-if="currentItem.flexify.last_updated" style="font-size: 0.8em" class="alert alert-danger" role="alert">
            ⛔ This product information must be updated in Shopify. Any <strong>text changes</strong> made here <strong>will be overwritten</strong> by Shopify.
            <br>Last Shopify update: {{fuzzyTime(currentItem.flexify.last_updated)}}
            </div>
          </div>
          <form>
            <div class="form-group">
              <label for="name">Name</label>
              <label style="color: red;">*</label>
              <input
                required
                @input="validationHelper($event)"
                @blur="validationHelper($event)"
                v-model="currentItem.name"
                class="form-control"
                id="name"
                type="text"
              />
              <span class="invalid-label" hidden>Please enter a valid name</span>
            </div>
            <div class="row contact-row">
              <div class="column form-group">
                <label for="name">Brand</label>
                <label style="color: red;">*</label>
                <input
                  required
                  @input="validationHelper($event)"
                  @blur="validationHelper($event)"
                  v-model="currentItem.brand"
                  class="form-control"
                  id="title"
                  type="text"
                />
                <span class="invalid-label" hidden>Please enter a valid brand</span>
              </div>
              <div class="column form-group">
                <label for="item_type">Type</label>
                <label style="color: red;">*</label>
                <select
                  required
                  @input="validationHelper($event)"
                  @blur="validationHelper($event)"
                  v-model="currentItem.item_type"
                  class="form-control select-input"
                  id="item_type"
                >
                  <option
                    class="item_type_option"
                    v-for="itemType in this.itemTypes"
                    :key="itemType"
                    :value="itemType"
                    >{{ itemType }}</option
                  >
                </select>
                <span class="invalid-label" hidden
                  >Please enter a valid addon prompt</span
                >
              </div>
              <div class="column form-group text-left">
                <label for="name">Archived</label>
                <input
                      v-model="currentItem.is_archived"
                      class="form-control"
                      id="is_archived"
                      type="checkbox"
                    />
              </div>
              <div class="column form-group text-left">
                  <label for="name">Style Code</label>
                  <input
                    v-model="currentItem.sku.code"
                    class="form-control"
                    id="sku_code"
                    type="text"
                    placeholder="Style Code"
                  />
                </div>
                <div class="column form-group">
                  <label for="name">Style Variation</label>
                  <input
                    v-model="currentItem.sku.variation"
                    class="form-control"
                    id="sku_variation"
                    type="text"
                    placeholder="Style Variation"
                  />
                </div>
              <!-- <div class="row contact-row">
                  <label for="name">Sku list</label>
                  <input
                    v-model="localSkuList"
                    class="form-control"
                    id="sku_code"
                    type="text"
                    placeholder="Style Code"
                  />
              </div> -->
            </div>
            <div class="form-group">
              <label for="name">Description</label>
              <label style="color: red;">*</label>
              <textarea
                required
                @input="validationHelper($event)"
                @blur="validationHelper($event)"
                v-model="currentItem.description"
                class="form-control"
                id="description"
                type="text"
                rows="3"
                style="font-size: 0.8em"
              />
              <span class="invalid-label" hidden>Please enter a valid description</span>
            </div>
             <div class="form-group">
              <label for="name">Price</label>
              <label style="color: red;">*</label>
              <input
                required
                @input="validationHelper($event)"
                @blur="validationHelper($event)"
                @keypress="onlyForCurrency"
                v-model="currentItem.price"
                class="form-control number-format"
                id="price"
                placeholder="000.00"
              />
              <span class="invalid-label" hidden>Please enter a valid price</span>
            </div>
            <div class="form-group">
              
              <div style="display: inline-block;width:50%">
                <h5>Features</h5>
              </div>
              <div style="display: inline-block;width:50%">
                <button type="button" id="add-feature" class="btn btn-primary float-right" v-on:click="addFeature()">Add Feature</button>
              </div>
              <div v-for="(ri,idx) in currentItem.features" :key="idx">
              <input
                v-model="currentItem.features[idx]"
                class="form-control feature-line"
                :id="'feature_' + idx"
                type="text"
                placeholder="Feature"
              />
              <div class="delete-feature" @click="removeFeature(idx)"><i class="fa fa-trash" aria-hidden="true"></i></div>
              </div>
            </div>
          </form>
          <button :disabled="isSaving" false class="btn btn-primary mb-3" type="button" @click="validateForm()">Save</button>
          <button class="btn mb-3" type="button" @click="cancelAndReturn()">Cancel</button>
        </div>
        <div class="col-3">
          <image-upload-sidebar @updateHeroImage="updateHeroImage" :item="currentItem"></image-upload-sidebar>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
//import axios from "axios";
import { GlobalStore } from "../globalStore";
import axios from "axios";
//import { VueEditor } from "vue2-editor";
//import flatPickr from "vue-flatpickr-component";
//import "flatpickr/dist/flatpickr.css";
import ImageUploadSidebar from "../components/ImageUploadSidebar";
import { GlobalMethods } from '../globalMethods';

export default {
  name: "product-item",
  data() {
    return {
      imageEndpoint: GlobalStore.imageEndpoint,
      hasImage: false,
      selectedImageItem: null,
      config: {
        enableTime: true,
        altFormat: "d-m-Y h:i K",
        altInput: true,
        dateFormat: "Z"
      },
      isSaving: false,
      itemTypes: []
    };
  },
  props: {
    currentItem: Object,
    title: String,
    return: String,
    view: String
  },
  computed: {
    
    filteredItemList: function() {
      return this.itemList;
    },
    // localSkuList: function() {
    //   if(this.item && this.item.sku_list){
    //     return this.item.sku_list.join(', ');
    //   }
    //   return ""
    // },
    // currentItem: function() {
    //   console.log("get current item")
    //   var tItem = JSON.parse(JSON.stringify(this.item));
    //   //if (tItem) {
    //   //tItem.body_html = this.htmlDecode(tItem.body_html);
    //   //}
    //   return tItem;
    // }
  },
  methods: {
    // Validation methods
    validationHelper(event) {
      this.checkField(event.target)
    },
    onlyForCurrency ($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

      // only allow number and one dot
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.currentItem.price.indexOf('.') != -1)) { // 46 is dot
      $event.preventDefault();
      }

      // restrict to 2 decimal places
      if(this.currentItem.price!=null && this.currentItem.price.indexOf(".")>-1 && (this.currentItem.price.split('.')[1].length > 1)){
      $event.preventDefault();
      }
    },
    checkField(field) {
      let valid = true
      if (field.value.trim() == '') {
        field.classList.add('invalid-input')
        field.nextElementSibling.hidden = false
        valid = false
      } else {
        field.classList.remove('invalid-input')
        field.nextElementSibling.hidden = true
      }
      return valid
    },

    // Other methods
    removeFeature: function(idx) {
      this.currentItem.features.splice(idx,1);
    },
    addFeature: function() {
      this.currentItem.features.push('');
    },
    updateHeroImage: function(evt) {
      console.log(this.currentItem);
      this.currentItem.images.hero_image = evt;
    },
    validateForm: function() {
      const fields = document.querySelectorAll('[required]')
      let formValid = true
      fields.forEach((field) => {
        if (this.checkField(field) == false) {
          formValid = false
        }
      })
      if (formValid == false) {
        GlobalStore.toastObj = this.$toasted.show(
            "Please complete required fields",
            {
              position: "bottom-center",
              duration: 5000,
              type: "error",
              fitToScreen: true,
              fullWidth: false
            }
          ); 
      } else {
        this.saveContentItem()
      }
    },
      
    saveContentItem: function() {

      console.log(this.return);


      console.log(this.currentItem);

      this.isSaving = true;

      //this.currentItem.hyperlink.url = "http://www.google.com";

      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token
      };

      var urlencoded = new URLSearchParams();
      urlencoded.append("grant_type", "refresh_token");
      urlencoded.append("expires_in", "7200");

      if (this.view == "Edit") {
        axios
          .put(
            GlobalStore.APIEndpoint +
              "/todaycx-admin/product/" +
              this.currentItem.product_guid,
            this.currentItem,
            {
              headers: headers
            }
          )
          .then(() => {
            this.isSaving = false;
            this.$router.push(this.return);
            
          })
          .catch(err => {
            this.loggingIn = false;
            this.isSaving = false;
            GlobalStore.toastObj = this.$toasted.show(
              JSON.stringify(err.response.data.message),
              {
                theme: "outline",
                position: "bottom-center",
                duration: 5000,
                type: "error",
                fitToScreen: true,
                fullWidth: false
              }
            );
          });
      } else {
        delete this.currentItem.product_guid;

        axios
          .post(
            GlobalStore.APIEndpoint + "/todaycx-admin/product",
            this.currentItem,
            {
              headers: headers
            }
          )
          .then(resp => {
            console.log(resp);
            this.isSaving = false;
            this.$router.push(this.return);
          })
          .catch(err => {
            this.loggingIn = false;
            this.isSaving = false;
            GlobalStore.toastObj = this.$toasted.show(
              JSON.stringify(err.response.data.message),
              {
                theme: "outline",
                position: "bottom-center",
                duration: 5000,
                type: "error",
                fitToScreen: true,
                fullWidth: false
              }
            );
          });
      }
    },
    getCustGuid: function() {
      return GlobalStore.custGuid;
    },
    htmlDecode(input) {
      var e = document.createElement("div");
      e.innerHTML = input;
      return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    },
    cancelAndReturn() {
      this.$router.push(this.return);
    },
    fuzzyTime(datestamp){
        return GlobalMethods.fuzzyTime(datestamp);
      }
  },
  components: {
    //VueEditor,
    //flatPickr,
    //tcxImage,
    ImageUploadSidebar
  },
  async mounted() {
    console.log(this.view);
    this.itemTypes = GlobalMethods.getDisplayableItemTypes("image");
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ql-editor p {
  margin-bottom: 0.5 !Important;
}

table {
  background-color: white;
  margin-top: 20px;
}

#item_type {
  font-size: 12px;
  padding-left: 0;
}

.contact-row {
  margin-left: 0px;
  width: 100%;
  
}

.contact-row .column {
  margin-right: 13px;
}

.contact-row input[type=checkbox], #active {
  transform: scale(0.5);
}

.contact-row select {
  display: block;
  font-size: 13px;
}

.form-control input[type=select]{
  font-size: 12px
}

.invalid-input {
  border: 2px solid red;
}
.invalid-label {
  color: red;
  font-size: 10px;
}

.card-img-top {
  max-height: 100px;
  overflow: hidden;
}

.card-title {
  min-height: 45px;
}

.card-text {
  min-height: 60px;
}

.feature-line {
  display: inline-block;
  width: 89%;
  margin: 10px 0px 10px 0px;
}
.delete-feature {
  display: inline-block;
  width: 10%;
  text-align: right;
  margin-right: 1px;
  cursor: pointer;
}

.number-format {
  font-size: 0.8em;
}

</style>
