<template v-if="loaded">
    <div class="import-shopify-box">
      <div class="row">
        <div class="col-12">
          <h5 class="mb-0 ml-2 mb-3 d-flex align-items-center">
            Import from Shopify
          </h5>
        </div>
      </div>
      <div class="row">
        <div class="input-container">
          <div class="col-1">
            <label for="shopifySrc" class="import-label">Shopify URL:</label>
          </div>
          <div class="col-11">
            <input type="text" placeholder="Paste the URL/link to the Shopify product you wish to import" v-model="shopifySrc" id="shopifySrc" maxlength="512">
          </div>          
        </div>
      </div>
      <div class="row">
        <div class="input-container">
          <div class="col-10">
          </div>
          <div class="col-2">
            <div class="import-button-container">
              <label for="importShopify" slot="import-button-label" class="import-button-label">
              <div title="Import Shopify" class="btn-primary" @click="importShopify" id="importShopify" :disabled="importingItem">
                <!-- Display spinner if importingItem is true -->
                <div v-if="importingItem" class="spinner-border text-light" role="status"></div>
                <!-- Otherwise, display the import button text -->
                <span v-else>Import item</span>  
              </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <!-- <button class="import-button" >Import Image</button> -->
    </div>
  </template>
  
  <script>

  import { GlobalStore } from "../globalStore";
  import axios from "axios";

  export default {
    name: "importShopify",
    data() {
      return {
        shopifySrc: null,
        pagingInfo : {},
        importingItem: false, // variable for spinner
      };
    },
    props: {
      idField: String,
      contentItemType: String,
    },
    methods: {
      assertJsonExtension(url) {
        if(url && (url.endsWith(".json"))){
          return url;
        }else{
          return url + '.json';
        }
      },
      isUrl(url) {
        return url !== null && url.trim() !== "" && url.startsWith("http")
      },
      getApiUrl: function() {
        console.log("getApiUrl", this);
        if(this.idField == "product_guid"){
          return GlobalStore.APIEndpoint + "/todaycx-admin/product_import_shopify/";
        }else if(this.idField == "top_story_guid"){
          return GlobalStore.APIEndpoint + "/todaycx-admin/top_story_import_shopify/";
        }else if(this.idField == "content_guid"){
          return GlobalStore.APIEndpoint + "/todaycx-admin/content_import_shopify/";
        }else{
          alert("UNHANDLED idField", this.idField);
        }
      },
      getEditItemPath: function(resultData) {
        if(this.idField == "product_guid"){
          return '/product/' + resultData.product_guid;
        }else if(this.idField == "top_story_guid"){
          return '/fyi/' + resultData.top_story_guid;
        }else if(this.idField == "content_guid"){
          if(this.contentItemType == "best_seller"){
            return '/bestSellers/' + resultData.content_guid;
          }else{
            alert("Invalid contentItemType for idField content_guid", this.contentItemType);
          }
        }else{
          alert("UNHANDLED idField", this.idField);
        }
      },
      getApiHeaders: function() {
        return { headers : {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + GlobalStore.access_token,
        }};      
      },
      getApiBody: function() {
        return [this.shopifySrc]
      },
      importShopifyfromUrl: async function(callback) {
        console.log("importShopifyfromUrl");
        var apiHeaders = this.getApiHeaders();
        var apiUrl = this.getApiUrl();
        var apiBody = this.getApiBody();
        console.log("importShopifyfromUrl:apiHeaders", apiHeaders);
        console.log("importShopifyfromUrl:apiUrl", apiUrl);
        console.log("importShopifyfromUrl:apiBody", apiBody);

        axios.post(apiUrl, apiBody, apiHeaders)
            .then(resp => {
                console.log("shopify_import response:", resp);
                console.log("resp.data[0].shopify.source_url", resp.data[0].shopify.source_url);
                console.log("this.shopifySrc", this.shopifySrc);
                // check that it was actually successful
                if(resp.data[0].shopify.source_url == this.shopifySrc){
                  callback(null, resp.data[0]);
                }else{
                  console.error("Import was unsuccessful. Check that the link is valid.");
                  throw "Import was unsuccessful. Check that the link is valid."; // the error info is in the description
                }               
            })
            .catch(error => {
                console.error("importShopifyfromUrl", error);
                this.handleRequestError(error, callback);
            });
      },
      resetMetaDataFields: function() {
        this.shopifySrc = "";
        
      },
      handleRequestError: function (error, callback) {
          if (error.response && error.response.status === 401) {
              // Handle 401 Unauthorized error here (e.g., redirect to login page)
              console.error('Unauthorized - Redirect to login or take appropriate action');
              callback(error);
          } else {
              // Handle other errors
              console.error('handleRequestError: Error during request:', error.message);
              callback(error);
          }
      },
      importShopify() {
        this.importingItem = true;
        if(this.shopifySrc !== null){
          this.shopifySrc = this.shopifySrc.trim()
        }
        if (this.isUrl(this.shopifySrc)) {
          console.log("IS a Url - importing from Url", this.shopifySrc);
          this.shopifySrc = this.assertJsonExtension(this.shopifySrc);
          console.log("assertedJson", this.shopifySrc);
          this.importShopifyfromUrl((error, result) => {
            if(error){
              console.log("import error", error)
              GlobalStore.toastObj = this.$toasted.show("The Shopify item could not be imported: " + error, {
                theme: "outline",
                position: "bottom-center",
                duration: 3000,
                type: "error",
                fitToScreen: true,
                fullWidth: false,
              });
              this.importingItem = false;
            }else{
              console.log("importShopifyfromUrl result", result)
              this.resetMetaDataFields();
              // console.log("emitting selectPage", result);
              this.$emit("selectPage", 0);
              if(this.idField != "product_guid"){
                GlobalStore.toastObj = this.$toasted.show("The Shopify item was imported with DRAFT status.", {
                  theme: "outline",
                  position: "bottom-center",
                  duration: 3000,
                  type: "success",
                  fitToScreen: true,
                  fullWidth: false,
                });
              }
              var editPath = this.getEditItemPath(result);
              console.log('Redirect to edit item', editPath);
              this.$router.push(editPath);
              this.importingItem = false;
            }
          this.importingItem = false;
          });
        } else {
          // Notify the user if the URL is empty
          // alert("Please enter a valid image path or URL.");
          //this.importingItem = false;
          GlobalStore.toastObj = this.$toasted.show("Please enter a link to a Shopify product to import.", {
                theme: "outline",
                position: "bottom-center",
                duration: 3000,
                type: "error",
                fitToScreen: true,
                fullWidth: false,
              });
        }
      this.importingItem = false;
      },
    },
    computed: {
    },
    async mounted() {
//      this.getItemTypes()
      console.log("*** ImportShopify Component loaded ***");
  },
  };
  </script>
  
  <style scoped>
   
  .hidden-element {
    display: hidden !important;
  }
  .import-shopify-box {
    border-style: solid;
    border-color: lightgrey;
    border-width: 1px;
    padding: 10px;
    margin-bottom: 10px;
    margin-left: 0px;
    border-radius: 0px;
    background-color: #f7f7f7;
  }
  .import-label {
  margin-right:10px;
  font-size: 80%;
  cursor: pointer;
  }
  .import-button-label {
    width: 100%;
    font-size: 100%;
    cursor: pointer;
  }
  .btn-primary {
      padding: 0px;
      border-radius: 5px;
      font-size: 80%;
      width: 100%;
      text-align: center;
      cursor: pointer;
    }

  .input-container {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .import-button-container {
    width: 100%;
    padding-top: 6px;
    display: flex;
    align-items: center;
  }
  
  @media (max-width: 767px) {
    .btn-primary {
      padding: 0px;
      border-radius: 5px;
      font-size: 100%;
      text-align: center;
      cursor: pointer;
    }
  
  }

  
  </style>
  