<template>
  <div>
    <div class="row no-gutters">
      <!-- Top 6 Content Counts -->
      <div class="col-md-4 col-xxl-3 mb-3 pr-md-2">
        <div class="card h-md-100">
          <div class="card-header pb-0">
            <h6 class="mb-0 mt-2 d-flex align-items-center">
              Team members
              <span
                class="ml-1 text-400"
                data-toggle="tooltip"
                data-placement="top"
                title="The number of people who can log into everyCX. Activated people have logged in at least once."
              >
                <span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
              </span>
            </h6>
          </div>
          <div class="card-body d-flex align-items-end">
            <div class="row flex-grow-1">
              <div class="col">
                <div
                  class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1"
                >{{userCountActivated}}</div>
                <div
                  class="fs1 font-weight-normal text-sans-serif text-500 line-height-1 mb-1"
                >activated</div>
              </div>
              <div class="col">
                <div
                  class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1"
                >{{userCountEnabled}}</div>
                <div
                  class="fs1 font-weight-normal text-sans-serif text-500 line-height-1 mb-1"
                >enabled</div>
              </div>
              <!-- <div class="col-auto pl-0">
                <div ref="chart1" class="echart-bar-weekly-sales h-100"></div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xxl-3 mb-3 pr-md-2">
        <div class="card h-md-100">
          <div class="card-header pb-0">
            <h6 class="mb-0 mt-2 d-flex align-items-center">
              Products
              <span
                class="ml-1 text-400"
                data-toggle="tooltip"
                data-placement="top"
                title="The total number of products configured"
              >
                <span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
              </span>
            </h6>
          </div>
          <div class="card-body d-flex align-items-end">
            <div class="row flex-grow-1">
              <div class="col">
                <div
                  class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1"
                >{{productCount}}</div>
                <div
                  class="fs1 font-weight-normal text-sans-serif text-500 line-height-1 mb-1"
                >loaded</div>
              </div>
              <!-- <div class="col-auto pl-0">
                <div ref="chart2" class="echart-bar-weekly-sales h-100"></div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xxl-3 mb-3 pr-md-2">
        <div class="card h-md-100">
          <div class="card-header pb-0">
            <h6 class="mb-0 mt-2 d-flex align-items-center">
              {{this.custObj.config.modules[0].title}}
              <span
                class="ml-1 text-400"
                data-toggle="tooltip"
                data-placement="top"
                v-bind:title="'The number of published and draft non-expired ' + this.custObj.config.modules[0].title + ' items'"
              >
                <span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
              </span>
            </h6>
          </div>
          <div class="card-body d-flex align-items-end">
            <div class="row flex-grow-1">
              <div class="col">
                <div
                  class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1"
                >{{topstoryCountPublished}}</div>
                <div
                  class="fs1 font-weight-normal text-sans-serif text-500 line-height-1 mb-1"
                >published</div>
              </div>
              <div class="col">
                <div
                  class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1"
                >{{topstoryCountDraft}}</div>
                <div
                  class="fs1 font-weight-normal text-sans-serif text-500 line-height-1 mb-1"
                >draft</div>
              </div>
              <!-- <div class="col-auto pl-0">
                <div ref="chart3" class="echart-bar-weekly-sales h-100"></div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xxl-3 mb-3 pr-md-2">
        <div class="card h-md-100">
          <div class="card-header pb-0">
            <h6 class="mb-0 mt-2 d-flex align-items-center">
              {{this.custObj.config.modules[1].title}}
              <span
                class="ml-1 text-400"
                data-toggle="tooltip"
                data-placement="top"
                v-bind:title="'The number of published and draft non-expired ' + this.custObj.config.modules[1].title + ' items'"
              >
                <span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
              </span>
            </h6>
          </div>
          <div class="card-body d-flex align-items-end">
            <div class="row flex-grow-1">
              <div class="col">
                <div
                  class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1"
                >{{bestsellerCountPublished}}</div>
                <div
                  class="fs1 font-weight-normal text-sans-serif text-500 line-height-1 mb-1"
                >published</div>
              </div>
              <div class="col">
                <div
                  class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1"
                >{{bestsellerCountDraft}}</div>
                <div
                  class="fs1 font-weight-normal text-sans-serif text-500 line-height-1 mb-1"
                >draft</div>
              </div>
              <!-- <div class="col-auto pl-0">
                <div ref="chart4" class="echart-bar-weekly-sales h-100"></div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xxl-3 mb-3 pr-md-2">
        <div class="card h-md-100">
          <div class="card-header pb-0">
            <h6 class="mb-0 mt-2 d-flex align-items-center">
              {{this.custObj.config.modules[2].title}}
              <span
                class="ml-1 text-400"
                data-toggle="tooltip"
                data-placement="top"
                v-bind:title="'The number of published and draft non-expired ' + this.custObj.config.modules[2].title + ' items'"
              >
                <span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
              </span>
            </h6>
          </div>
          <div class="card-body d-flex align-items-end">
            <div class="row flex-grow-1">
              <div class="col">
                <div
                  class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1"
                >{{beststoreCountPublished}}</div>
                <div
                  class="fs1 font-weight-normal text-sans-serif text-500 line-height-1 mb-1"
                >published</div>
              </div>
              <div class="col">
                <div
                  class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1"
                >{{beststoreCountDraft}}</div>
                <div
                  class="fs1 font-weight-normal text-sans-serif text-500 line-height-1 mb-1"
                >draft</div>
              </div>
              <!-- <div class="col-auto pl-0">
                <div ref="chart5" class="echart-bar-weekly-sales h-100"></div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xxl-3 mb-3 pr-md-2">
        <div class="card h-md-100">
          <div class="card-header pb-0">
            <h6 class="mb-0 mt-2 d-flex align-items-center">
              {{this.custObj.config.modules[3].title}}
              <span
                class="ml-1 text-400"
                data-toggle="tooltip"
                data-placement="top"
                v-bind:title="'The number of published and draft non-expired ' + this.custObj.config.modules[3].title + ' items'"
              >
                <span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
              </span>
            </h6>
          </div>
          <div class="card-body d-flex align-items-end">
            <div class="row flex-grow-1">
              <div class="col">
                <div
                  class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1"
                >{{memoCountPublished}}</div>
                <div
                  class="fs1 font-weight-normal text-sans-serif text-500 line-height-1 mb-1"
                >published</div>
              </div>
              <div class="col">
                <div
                  class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1"
                >{{memoCountDraft}}</div>
                <div
                  class="fs1 font-weight-normal text-sans-serif text-500 line-height-1 mb-1"
                >draft</div>
              </div>
              <!-- <div class="col-auto pl-0">
                <div ref="chart6" class="echart-bar-weekly-sales h-100"></div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xxl-3 mb-3 pr-md-2">
        <div class="card h-md-100">
          <div class="card-body">
            <div class="row h-100 justify-content-between no-gutters">
              <h6 class="mb-0">
              {{this.custObj.config.modules[4].title}}
                <span
                    class="ml-1 text-400"
                    data-toggle="tooltip"
                    data-placement="top"
                    v-bind:title="'The number of published and draft non-expired ' + this.custObj.config.modules[4].title + ' items'">
                    <span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
                    </span></h6>
            </div>
          </div>
          <div class="card-body pt-2">
            <div class="row no-gutters h-100 align-items-center">
              <div class="col">
                <div class="media align-items-center">
                  <img class="mr-3" src="@/assets/img/icons/tick.jpg" alt height="60" />
                  <div class="media-body">
                    <h6 class="mb-2">Complete</h6>
                    <div class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1">
                      {{this.actionRequestsStoresComplete + this.actionRequestsUsersComplete}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="media align-items-center">
                  <img class="mr-3" src="@/assets/img/icons/clock2.jpg" alt height="60" />
                  <div class="media-body">
                    <h6 class="mb-2">Open</h6>
                    <div class="fs-4 font-weight-normal text-sans-serif text-700 line-height-1 mb-1">
                      {{(this.actionRequestStores.length - this.actionRequestsStoresComplete) + (this.actionRequestUsers.length - this.actionRequestsUsersComplete)}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Content Counts -->
      <div class="col-md-6 col-xxl-3 mb-3 pr-md-2">
        <div class="card h-md-100">
          <div class="card-body">
            <div class="row h-100 justify-content-between no-gutters">
              <div class="col-5 col-sm-6 col-xxl pr-2">
                <h6 class="mt-1">Engagement<span
                  class="ml-1 text-400"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="This is the proportion of the team that the currently published articles have reached">
                  <span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
                  </span>
                </h6>
                <div class="fs--2 mt-3">
                  <div class="d-flex flex-between-center mb-1">
                    <div class="d-flex align-items-center">
                      <span class="font-weight-semi-bold">{{this.custObj.config.modules[3].title}}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto doughnut-stat">
                <div ref="donut" class="echart-doughnut"></div>
                <div class="absolute-centered font-weight-light text-dark fs-2">{{reachPercent}}%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row no-gutters">
      <div class="col-md-12 col-xxl-12 mb-12 pl-md-2" style="margin-bottom:20px;">
        <div class="card h-md-100">
          <div class="card-header d-flex flex-between-center pb-0">
            <h6 class="mb-0">Sales by Store<span
                  class="ml-1 text-400"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="This shows Sales by Store with Total sales, plus Add-on Sales">
                  <span class="far fa-question-circle" data-fa-transform="shrink-1"></span>
                  </span></h6>
          </div>
          <div class="card-body pt-2">
            <div ref="bar1" class="echart-bar-top-products" style="height: 300px;" data-echart-responsive="true"></div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- Action Request lists -->
    <div class="row no-gutters">
      <div class="col-lg-6 col-xl-6 pr-lg-2 mb-3">
        <div class="card h-lg-100 overflow-hidden">
          <div class="card-body p-0">
            <div class="justify-content-between">
              <h6 class="ml-3 mt-3">Store-based {{this.custObj.config.modules[4].title}}s</h6>
            </div>
            <table class="table table-dashboard mb-0 table-borderless fs--1">
              <thead class="bg-light">
                <tr class="text-900">
                  <th>{{this.custObj.config.modules[4].title}} Title</th>
                  <th></th>
                  <th class="text-right">Done</th>
                  <th class="pr-card text-right" style="width: 8rem">%</th>
                </tr>
              </thead>
              <tbody>
                  <div v-if="actionRequestStores.length == 0" class="row no-gutters">
                    <tr class="border-bottom border-200">
                      <td><p class="font-weight-semi-bold mb-0 text-500">No To-dos</p>
                      </td>  
                    </tr>  
                  </div>
                  <tr v-for="openActionRequest in actionRequestStores" :key=openActionRequest.action_request_guid class="border-bottom border-200">
                    <td onMouseOver="this.style.cursor='pointer'" @click="editActionRequest(openActionRequest.action_request_guid)">
                          <h6 class="mb-1 text-700">
                            {{openActionRequest.title}}
                            <!-- <a class="text-dark stretched-link" href="#!">Raven Pro</a> -->
                          </h6>
                          <p class="font-weight-semi-bold mb-0 text-500">{{openActionRequest.summary}}</p>
                          <p class="mb-0 text-500"
                            :class="openActionRequest.past_due ? 'text-goldenrod' : 'text-green'"
                            >Due: {{openActionRequest.due_by_string}}</p>
                    </td>
                    <!-- <td class="align-top text-right font-weight-semi-bold text-700">
                      {{openActionRequest.due_by_string}}
                    </td> -->
                    <td onMouseOver="this.style.cursor='pointer'"  @click="viewActionRequestDashboard(openActionRequest.action_request_guid)"><i class="far fa-chart-bar" aria-hidden="true"></i></td>
                    <td class="align-top text-right font-weight-semi-bold text-700"  @click="viewActionRequestDashboard(openActionRequest.action_request_guid)">
                      {{openActionRequest.progress.completed_names.length}}/{{openActionRequest.progress.potential_names.length}}
                    </td>
                    <td class="align-top pr-card" @click="viewActionRequestDashboard(openActionRequest.action_request_guid)">
                      <div class="d-flex align-items-center col3">
                        <div class="progress w-100 mr-2 rounded-soft bg-200" style="height: 5px;">
                          <div
                            class="progress-bar rounded-capsule"
                            role="progressbar"
                            :style="openActionRequest.widthPercentComplete"
                            aria-valuenow=33
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <div class="font-weight-semi-bold ml-2">{{openActionRequest.progress.completed_percent.toFixed(2)}}%</div>
                      </div>
                      <div>&nbsp;</div>
                      <div v-if="openActionRequest.is_expired==true" class="mb-0 text-right font-weight-semi-bold text-500 text-firebrick">Expired</div>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer bg-light py-2">
            <div class="row flex-between-center">
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-xl-6 pr-lg-2 mb-3">
        <div class="card h-lg-100 overflow-hidden">
          <div class="card-body p-0">
            <div class="justify-content-between">
              <h6 class="ml-3 mt-3">Staff-based {{this.custObj.config.modules[4].title}}s</h6>
            </div>
            <table class="table table-dashboard mb-0 table-borderless fs--1">
              <thead class="bg-light">
                <tr class="text-900">
                  <th>{{this.custObj.config.modules[4].title}} Title</th>
                  <th></th>
                  <th class="text-right">Done</th>
                  <th class="pr-card text-right" style="width: 8rem">%</th>
                </tr>
              </thead>
              <tbody>
                  <div v-if="actionRequestUsers.length == 0" class="row no-gutters">
                    <tr class="border-bottom border-200">
                      <td><p class="font-weight-semi-bold mb-0 text-500">No To-dos</p>
                      </td>  
                    </tr>  
                  </div>
                  <tr v-for="openActionRequest in actionRequestUsers" :key=openActionRequest.action_request_guid class="border-bottom border-200">
                    <td onMouseOver="this.style.cursor='pointer'" @click="editActionRequest(openActionRequest.action_request_guid)">
                          <h6 class="mb-1 text-700">
                            {{openActionRequest.title}}
                            <!-- <a class="text-dark stretched-link" href="#!">Raven Pro</a> -->
                          </h6>
                          <p class="font-weight-semi-bold mb-0 text-500">{{openActionRequest.summary}}</p>
                          <p class="mb-0 text-500"
                            :class="openActionRequest.past_due ? 'text-goldenrod' : 'text-green'"
                          >Due: {{openActionRequest.due_by_string}}</p>
                    </td>
                    <td onMouseOver="this.style.cursor='pointer'" @click="viewActionRequestDashboard(openActionRequest.action_request_guid)"><i class="far fa-chart-bar" aria-hidden="true"></i></td>
                    <td class="align-top text-right font-weight-semi-bold text-700" @click="viewActionRequestDashboard(openActionRequest.action_request_guid)">
                      {{openActionRequest.progress.completed_names.length}}/{{openActionRequest.progress.potential_names.length}}
                    </td>
                    <td class="align-top pr-card" @click="viewActionRequestDashboard(openActionRequest.action_request_guid)">
                      <div class="d-flex align-items-center col3">
                        <div class="progress w-100 mr-2 rounded-soft bg-200" style="height: 5px;">
                          <div
                            class="progress-bar rounded-capsule"
                            role="progressbar"
                            :style="openActionRequest.widthPercentComplete"
                            aria-valuenow=33
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <div class="font-weight-semi-bold ml-2">{{openActionRequest.progress.completed_percent.toFixed(2)}}%</div>
                      </div>
                      <div>&nbsp;</div>
                      <div v-if="openActionRequest.is_expired==true" class="mb-0 text-right font-weight-semi-bold text-500 text-firebrick">Expired</div>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer bg-light py-2">
            <div class="row flex-between-center">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Content views and team feeds -->
    <div class="row no-gutters">
      <div class="col-lg-7 col-xl-8 pr-lg-2 mb-3">
        <div class="card h-lg-100 overflow-hidden">
          <div class="card-body p-0">
            <table class="table table-dashboard mb-0 table-borderless fs--1">
              <thead class="bg-light">
                <tr class="text-900">
                  <th>Most viewed</th>
                  <th class="text-right"></th>
                  <th class="text-right">🔍</th>
                  <th class="text-right">👍</th>
                  <th class="pr-card text-right" style="width: 8rem">Reach (%)</th>
                </tr>
              </thead>
              <tbody>
                  <tr v-for="(contentView, index) in contentViews" :key=index class="border-bottom border-200" onMouseOver="this.style.cursor='pointer'">
                    <td @click="editContent(contentView)">
                      <div class="media align-items-center position-relative">
                        <!-- <img
                          class="rounded border border-200"
                          :src="contentView.thumbnail"
                          width="60"
                          height="45"
                          object-fit="contain"
                          :alt="contentView.thumbnail_alt_text"
                          :title="contentView.thumbnail_alt_text"
                        /> -->

                        <div 
                          :alt="contentView.thumbnail_alt_text"
                          :title="contentView.thumbnail_alt_text"
                          :style="'background: url(' + contentView.thumbnail + ');width:60px; height:60px; background-size: cover; background-position: top; border-radius: 3px;'"
                        ></div>

                        <div class="media-body ml-3">
                          <h6 class="mb-1 font-weight-semi-bold">
                            {{contentView.item}}
                            <!-- <a class="text-dark stretched-link" href="#!">Raven Pro</a> -->
                          </h6>
                          <p class="font-weight-semi-bold mb-0 text-500">{{contentView.view_type_name}}</p>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle text-right fs-0 font-weight-normal text-sans-serif text-700 line-height-1 mb-1" onMouseOver="this.style.cursor='pointer'" @click="viewContentDashboard(contentView.content_guid)"><i class="far fa-chart-bar" aria-hidden="true"></i></td>
                    <td class="align-middle text-right fs-2 font-weight-normal text-sans-serif text-700 line-height-1 mb-1">{{contentView.count}}</td>
                    <td class="align-middle text-right fs-2 font-weight-normal text-sans-serif text-700 line-height-1 mb-1">{{contentView.rating.likes}}</td>
                    <!-- <td class="align-middle text-right font-weight-semi-bold">{{contentView.count}}</td> -->
                    <td class="align-middle pr-card">
                      <div class="d-flex align-items-center">
                        <div class="progress w-100 mr-2 rounded-soft bg-200" style="height: 5px;">
                          <div
                            class="progress-bar rounded-capsule"
                            role="progressbar"
                            :style="contentView.widthpercentReach"
                            aria-valuenow=33
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                        <div class="font-weight-semi-bold ml-2">{{contentView.percentReach.toFixed(2)}}%</div>
                      </div>
                      <div v-if="contentView.is_expired==true" class="mb-0 text-right font-weight-semi-bold text-500 text-firebrick">Expired</div>
                    </td>
                  </tr>
              </tbody>
            </table>
          </div>
          <div class="card-footer bg-light py-2">
            <div class="row flex-between-center">
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-xl-4 pl-lg-2 mb-3 pr-md-2">
        <div class="card h-lg-100">
          <div class="card-header d-flex flex-between-center bg-light py-2">
            <h6 class="mb-0">Team feed</h6>
            <!-- <a class="btn btn-sm btn-link pr-0 fs--1" href="#!">View All</a> -->
          </div>
          <div v-for="activity in activityFeed" :key=activity._id class="border-bottom-0 notification rounded-0 border-x-0 border border-300">
            <div class="notification-avatar">
              <div class="avatar avatar-xl mr-3">
                <div class="avatar-emoji rounded-circle" v-html="activity.emoji"></div>
                <!-- <div class="avatar-emoji rounded-circle"><span role="img" aria-label="Emoji">🔍</span></div> -->
              </div>
            </div>
            <div class="notification-body">
              <p class="mb-1"><strong>{{activity.display_name}}</strong> {{activity.activity}} <strong>{{activity.resource_name}}</strong></p>
              <span class="notification-time">{{activity.timetext}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Content views and team feeds -->
    <div class="row no-gutters">
      <div class="col-xl-6 col-lg-6 mb-3 pr-md-2">
        <div class="card h-lg-100">
          <div class="card-header d-flex flex-between-center bg-light py-2">
            <h6 class="mb-0">Login issues</h6>
            <!-- <a class="btn btn-sm btn-link pr-0 fs--1" href="#!">View All</a> -->
          </div>
          <div v-if="loginFailFeed.length == 0" class="border-bottom-0 notification rounded-0 border-x-0 border border-300">
            <div class="notification-avatar">
              <div class="avatar avatar-xl mr-3">
                <div class="avatar-emoji rounded-circle">😊</div>
                <!-- <img class="mr-3" src="@/assets/img/icons/tick.jpg" alt height="60" /> -->
              </div>
            </div>
            <div class="notification-body">
              <p class="mb-1">Everyone seems to be logging in OK.</p>
            </div>
          </div>
          <div v-for="activity in loginFailFeed" :key=activity._id class="border-bottom-0 notification rounded-0 border-x-0 border border-300">
            <div class="notification-avatar">
              <div class="avatar avatar-xl mr-3">
                <div class="avatar-emoji rounded-circle" v-html="activity.emoji"></div>
                <!-- <div class="avatar-emoji rounded-circle"><span role="img" aria-label="Emoji">🔍</span></div> -->
              </div>
            </div>
            <div class="notification-body">
              <p class="mb-1"><strong>{{activity.display_name}}</strong> ({{activity.resource_name}}) {{activity.activity}}</p>
              <span class="notification-time">{{activity.timetext}} ({{formatToLocalTime(activity.timestamp)}})</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 pl-lg-2 mb-3 pr-md-2">
        <div class="card h-lg-100">
          <div class="card-header d-flex flex-between-center bg-light py-2">
            <h6 class="mb-0">Recent logins</h6>
            <!-- <a class="btn btn-sm btn-link pr-0 fs--1" href="#!">View All</a> -->
          </div>
          <div v-for="activity in loginSuccessFeed" :key=activity._id class="border-bottom-0 notification rounded-0 border-x-0 border border-300">
            <div class="notification-avatar">
              <div class="avatar avatar-xl mr-3">
                <div class="avatar-emoji rounded-circle" v-html="activity.emoji"></div>
                <!-- <div class="avatar-emoji rounded-circle"><span role="img" aria-label="Emoji">🔍</span></div> -->
              </div>
            </div>
            <div class="notification-body">
              <p class="mb-1"><strong>{{activity.display_name}}</strong> ({{activity.resource_name}}) {{activity.activity}}</p>
              <span class="notification-time">{{activity.timetext}} ({{formatToLocalTime(activity.timestamp)}})</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import axios from "axios";
//import { GlobalStore } from "../global";
// import { aggregateMethods } from "../helpers/aggregate";
import { dashboardMethods } from "../helpers/dashboard";

// import { dbqueryMethods } from "../helpers/dbquery";

import { graphMethods } from "../helpers/graphs"
import { GlobalStore } from '../globalStore';
import { GlobalMethods } from '../globalMethods';

export default {
  name: "Dashboard",
  data() {
    return {
      currentTimeISO : 0,
      userCountEnabled: 0,
      userCountActivated: 0,
      memoCountPublished: 0,
      memoCountDraft: 0,
      topstoryCountPublished: 0,
      topstoryCountDraft: 0,
      bestsellerCountPublished: 0,
      bestsellerCountDraft: 0,
      beststoreCountPublished: 0,
      beststoreCountDraft: 0,
      actionRequestCountPublished: 0,
      productCount: 0,
      contentViews: [],
      activityFeed: [],
      loginSuccessFeed: [],
      loginFailFeed: [],
      reachPercent: 0.0,
      kpiData: [],
      kpiStoreNames: [],
      kpiTotalSales: [],
      kpiAddOnSales: [],
      kpiNewCollectionSales: [],
      customerGUID: "",
      actionRequestResponsesStore: [],
      actionRequestResponsesUser: [],
      actionRequestsStoresOpen: 0,
      actionRequestsStoresComplete: 0,
      actionRequestsUsersOpen: 0,
      actionRequestsUsersComplete: 0,

      actionRequestStores: [],
      actionRequestUsers: []
};
  },
  props: {
    msg: String
  },
  computed: {
    custObj:  function() {
      return GlobalStore.custObj;
    },
    userObj:  function() {
      return GlobalStore.userMeObject;
    },
  },
  methods: {
    userHasRole(role){
      return this.userObj.roles.includes(role);
    },
    displayName(given_name, last_name){
      return(given_name + ' ' + last_name)
    },
    viewActionRequestDashboard: function (id) {
      this.$router.push('/actionRequestDashboard/' + id);
    },
    viewContentDashboard: function (id) {
      this.$router.push('/contentDashboard/' + id);
    },
    editContent: function (item) {
      // console.log(item);
      switch (item.content_item_type){
        case 'memo':
          if(this.userHasRole('admin_customer_memo')){
            this.$router.push('/memos/' + item.content_guid);
          }
          break;
        case 'best_seller':
          if(this.userHasRole('admin_customer_best_seller')){
            this.$router.push('/bestSellers/' + item.content_guid);
          }
          break;
        case 'best_store':
          if(this.userHasRole('admin_customer_best_store')){
            this.$router.push('/bestSellers/' + item.content_guid);
          }
          break;
        default:
          break;
      }
    },
    editActionRequest: function (id) {
      if(this.userHasRole('admin_customer_action_request')){
        this.$router.push('/toDo/' + id);
      }
    },
    getTypeName(view_type){
        switch(view_type){
          case 'view_top_story':
              return(this.custObj.config.modules[0].title);
          case 'view_best_store':
              return(this.custObj.config.modules[2].title);
          case 'view_best_seller':
              return(this.custObj.config.modules[1].title);
          case 'view_memo':
              return(this.custObj.config.modules[3].title);
          case 'action_response':
              return(this.custObj.config.modules[4].title)
          case 'user_login_ok':
              return('Login');
          case 'user_login_bad_password':
              return('Bad password');
          case 'user_login_inactive_user':
              return('Inactive User');
        }
        return('Unknown view type:' + view_type);
    },
    getModuleTitle(base_type){
        switch(base_type){
          case 'top_story':
              return(this.custObj.config.modules[0].title);
          case 'best_seller':
              return(this.custObj.config.modules[1].title);
          case 'best_store':
              return(this.custObj.config.modules[2].title);
          case 'memo':
              return(this.custObj.config.modules[3].title);
          case 'action_response':
              return(this.custObj.config.modules[4].title)
        }
        return('Unknown base type:' + base_type);
    },
    parseISOString(s) {
      var b = s.split(/\D+/);
      // console.log(b)
      return new Date(Date.UTC(b[0], b[1], b[2], b[3], b[4], b[5], b[6]));
    },
    formatToLocalTime(ts) {
      return(GlobalMethods.formatToLocalTime(ts));
    },
    longDateTimeFormat(datetime){
      var d = this.parseISOString(datetime);
      // var out = d.getDate()  + "-" + (d.getMonth()+1) + "-" + d.getFullYear() + " " + d.getHours() + ":" + d.getMinutes();
      // out = out.getDate();
      return(d);
    },
    getTypeData(view_type){
      // returns description and emoji as tuple
      // emojis = ['⭐', '👍','📌','📸','🛍️','🎁','✍','🏷️','🔍','📝','🏬','🏆', '😊' ];
        var ret = {};
        switch(view_type){
          case 'view_top_story':
                ret.activity = 'viewed the ' + this.custObj.config.modules[0].title;
                ret.emoji='<span role="img" aria-label="Emoji">🔍</span>';
                break;
          case 'view_best_store':
                ret.activity = 'viewed the ' + this.custObj.config.modules[2].title;
                ret.emoji='<span role="img" aria-label="Emoji">🔍</span>';
                break;
          case 'view_best_seller':
                ret.activity = 'viewed the ' + this.custObj.config.modules[1].title;
                ret.emoji = '<span role="img" aria-label="Emoji">🔍</span>';
                break;
          case 'view_memo':
                ret.activity = 'viewed the ' + this.custObj.config.modules[3].title;
                ret.emoji = '<span role="img" aria-label="Emoji">🔍</span>';
                break;
          case 'star_rating_top_story':
                ret.activity = 'star-rated the ' + this.custObj.config.modules[0].title;
                ret.emoji = '<span role="img" aria-label="Emoji">⭐</span>';
                break;
          case 'star_rating_product':
                ret.activity = 'star-rated the product';
                ret.emoji = '<span role="img" aria-label="Emoji">⭐</span>';
                break;
          case 'like_memo':
                ret.activity = 'liked the ' + this.custObj.config.modules[3].title;
                ret.emoji = '<span role="img" aria-label="Emoji">👍</span>';
                break;
          case 'like_best_seller':
                ret.activity = 'liked the ' + this.custObj.config.modules[1].title;
                ret.emoji = '<span role="img" aria-label="Emoji">👍</span>';
                break;
          case 'like_best_store':
                ret.activity = 'liked the ' + this.custObj.config.modules[2].title;
                ret.emoji = '<span role="img" aria-label="Emoji">👍</span>';
                break;
          case 'action_response':
                ret.activity = 'responded to' + this.custObj.config.modules[4].title;
                ret.emoji = '<span role="img" aria-label="Emoji">✍</span>';
                break;
          case 'user_login_ok':
                ret.activity = 'logged in';
                ret.emoji = '<span role="img" aria-label="Emoji">😊</span>';
                break;
          case 'user_login_bad_password':
                ret.activity = 'tried the wrong password';
                ret.emoji = '<span role="img" aria-label="Emoji">✋</span>';
                break;
          case 'user_login_inactive_user':
                ret.activity = 'is not allowed to login: Inactive.';
                ret.emoji = '<span role="img" aria-label="Emoji">⛔</span>';
                break;
          default:
                ret.activity = 'Unknown view type: ' + view_type;
                ret.emoji = '<span role="img" aria-label="Emoji">😕</span>';
                break;
        }
        return(ret);
    }
  },
  async mounted() {
    var currentTime = new Date();
    this.currentTimeISO = currentTime.toISOString();
    // console.log("currentTimeISO=" + this.currentTimeISO);
    this.customerGUID = GlobalStore.custGuid;

    try{  
      let respContentCount = await dashboardMethods.getDashboardData("content_count");
      // console.log(respContentCount);
      this.memoCountPublished = respContentCount.data.memo.published
      this.memoCountDraft = respContentCount.data.memo.draft
      this.bestsellerCountPublished = respContentCount.data.best_seller.published
      this.bestsellerCountDraft = respContentCount.data.best_seller.draft
      this.beststoreCountPublished = respContentCount.data.best_store.published
      this.beststoreCountDraft = respContentCount.data.best_store.draft
    }catch (error){
      console.log("dashboard error: " + error)
    }
    try{  
      let respUserCount = await  dashboardMethods.getDashboardData("user_count");
       console.log(respUserCount);
      this.userCountEnabled = respUserCount.data.enabled;
      this.userCountActivated = respUserCount.data.activated_enabled;
    }catch (error){
      console.log("dashboard error: " + error)
    }
    try{
      let respProductCount = await dashboardMethods.getDashboardData("product_count");
      // console.log(respProductCount);
      this.productCount = respProductCount.data.active;
    }catch (error){
      console.log("dashboard error: " + error)
    }
    try{
      let respTopStoryCount = await dashboardMethods.getDashboardData("top_story_count");
      // console.log(respTopStoryCount);
      this.topstoryCountPublished = respTopStoryCount.data.published
      this.topstoryCountDraft = respTopStoryCount.data.draft
    }catch (error){
      console.log("dashboard error: " + error)
    }
    try{
      let respContentViews = await dashboardMethods.getDashboardData("content_views", 22, {"$match" : { "is_archived" : false}});
      var data_obj;
      // var sum_reach = 0;
      elements = 0;
      var sum_reach = 0, count_reach = 0;
      let nowTime = new Date().toISOString();
      console.log(respContentViews);
      for (i=0, elements = respContentViews.data.length; i<elements; i++){
        data_obj = respContentViews.data[i];
        // add fields
        data_obj.view_type_name = this.getTypeName(data_obj.view_type);
        data_obj.percentReach = data_obj.percent_reach;
        if (data_obj.content_item_type == 'memo'){
          sum_reach += data_obj.percent_reach;
          count_reach++;
        }
        data_obj.widthpercentReach = "width: " + data_obj.percent_reach.toFixed(1) + "%";
        data_obj.thumbnail = GlobalStore.imageEndpoint + '/' + data_obj.thumbnail_url;
        data_obj.is_expired = (data_obj.display.expires < nowTime);
        this.contentViews.push(data_obj);
      }      
      // console.log(this.contentViews);
      this.reachPercent = count_reach == 0 ? 0 : (sum_reach / count_reach).toFixed(0);
    }catch (error){
      console.log("dashboard error: " + error)
    }
    try{
      let resp6 = await dashboardMethods.getDashboardData("activity_feed", 20);
      var i, elements;
      var typeData;
      // console.log(resp6);
      for (i=0, elements = resp6.data.length; i<elements; i++){
        typeData = this.getTypeData(resp6.data[i].tracking_type);
        resp6.data[i].activity = typeData.activity;
        resp6.data[i].emoji = typeData.emoji;
        resp6.data[i].timetext = resp6.data[i].timestamp_humanize;
        this.activityFeed.push(resp6.data[i]);
      }
    }catch (error){
      console.log("dashboard error: " + error)
    }
    try{
      let respLoginSuccess = await dashboardMethods.getDashboardData("login_success_feed", 10);
      // console.log(respLoginSuccess);
      for (i=0, elements = respLoginSuccess.data.length; i<elements; i++){
        typeData = this.getTypeData(respLoginSuccess.data[i].tracking_type);
        respLoginSuccess.data[i].activity = typeData.activity;
        respLoginSuccess.data[i].emoji = typeData.emoji;
        respLoginSuccess.data[i].timetext = respLoginSuccess.data[i].timestamp_humanize;
        this.loginSuccessFeed.push(respLoginSuccess.data[i]);
      }
    }catch (error){
      console.log("dashboard error: " + error)
    }
    try{
      let respLoginFail = await dashboardMethods.getDashboardData("login_unresolved_fail_feed", 10);
      console.log(respLoginFail);
      for (i=0, elements = respLoginFail.data.length; i<elements; i++){
        typeData = this.getTypeData(respLoginFail.data[i].tracking_type);
        respLoginFail.data[i].activity = typeData.activity;
        respLoginFail.data[i].emoji = typeData.emoji;
        respLoginFail.data[i].timetext = respLoginFail.data[i].timestamp_humanize;
        this.loginFailFeed.push(respLoginFail.data[i]);
      }
    }catch (error){
      console.log("dashboard error: " + error)
    }

    try{
      const pipelineAR = {"$match" : { "is_archived" : false,
                                      "display.publish_status" : "published"
                                    }
                        };
      await  dashboardMethods.getDashboardData("audience_action_request", 100, pipelineAR)
        .then((responses) => {
          let nowTime = new Date().toISOString();
          console.log('DashboardData responses', responses);
          responses.data.filter(item => item.mode == 'store').forEach((actionRequest) => {
            actionRequest.widthPercentComplete = 'width: ' + actionRequest.progress.completed_percent.toFixed(1) + '%';
            actionRequest.due_by_string = GlobalMethods.fuzzyTime(actionRequest.due_by);
            actionRequest.past_due = actionRequest.due_by < nowTime;
            actionRequest.is_expired = actionRequest.display.expires < nowTime;
            console.log("STORE actionRequest", actionRequest);
            this.actionRequestStores.push(actionRequest);
          })
          responses.data.filter(item => item.mode == 'user').forEach((actionRequest) => {
            actionRequest.widthPercentComplete = 'width: ' + actionRequest.progress.completed_percent.toFixed(1) + '%';
            actionRequest.due_by_string = GlobalMethods.fuzzyTime(actionRequest.due_by);
            actionRequest.past_due = actionRequest.due_by < nowTime;
            actionRequest.is_expired = actionRequest.display.expires < nowTime;
            console.log("USER actionRequest", actionRequest);
            this.actionRequestUsers.push(actionRequest);
          })
        })
        .catch((err) => {
          console.error('Failed to get dashboard data (audience_action_request): ' + err);
        })
        .finally(() => {
          console.log('actionRequestItems', this.actionRequests);
          this.isLoading = false;
        });		
      console.log("this.actionRequestStores", this.actionRequestStores);
      console.log("this.actionRequestUsers", this.actionRequestUsers);
      // Summary items
      this.actionRequestsStoresComplete =  this.actionRequestStores.filter((ar) => ar.progress.completed_percent >= 100).length; 
      this.actionRequestsUsersComplete =  this.actionRequestUsers.filter((ar) => ar.progress.completed_percent >= 100).length; 
      
      
      console.log("stores complete:", this.actionRequestsStoresComplete);
      console.log("users complete:", this.actionRequestsUsersComplete);

    }catch (error){
      console.log("getDashboardData failed: " + error)
    }
    try{
      graphMethods.renderPieGraph(this.$refs.donut, [{
            value: this.reachPercent,
            name: 'Engagement'
          }, {
            value: (100.0 - this.reachPercent),
            name: 'Yet to view content'
          }]);
    }catch (error){
      console.log("renderPieChart failed: " + error)
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.text-green {
  color: #42b983 !important;
}
.text-goldenrod {
  color: goldenrod !important;
}
.text-firebrick {
  color: firebrick !important;
}

.progress-bar {
  background-color: #3FA535;
}
.doughnut-stat {
  max-height: 106px !important;
}
</style>
