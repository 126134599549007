
import Vue from 'vue'
import axios from "axios";
import { GlobalStore } from '../globalStore'

  export const aggregateMethods = new Vue({
    methods: {

    getAggregateResult: async function(collection, pipelines) {
       
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + GlobalStore.access_token
      };

      const requestBody = {
        "collection":collection,
        "pipelines":pipelines
      };

      return axios.post(GlobalStore.APIEndpoint + '/todaycx-admin/aggregate?collection=' + collection,
        requestBody,
        {
          headers: headers
        });

    }
  }
})