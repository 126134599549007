import Vue from "vue";
import axios from "axios";
import { GlobalStore } from "../globalStore";

export const userMethods = new Vue({
  methods: {
    doUserSearch: async function(per_page, page, statusFilter, query) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      this.loading = true;

      let filterString = "";

      if (statusFilter.toLowerCase() == "inactive") {
        filterString = "&is_archived=true";
      }

      if (statusFilter.toLowerCase() == "active") {
        filterString = "&is_archived=false";
      }

      return axios.get(
        GlobalStore.APIEndpoint +
          `/todaycx-admin/search/users?per_page=${per_page}&page=${page}&query=${query}` +
          filterString,
        {
          headers: headers,
        }
      );
    },

    getUserList: async function(per_page, page, statusFilter) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      this.loading = true;

      let activeFlag = true;

      if (statusFilter.toLowerCase() == "inactive") {
        activeFlag = false;
      }

      return axios.get(
        GlobalStore.APIEndpoint +
          `/todaycx-admin/user/?per_page=${per_page}&page=${page}&active=` +
          activeFlag,
        {
          headers: headers,
        }
      );
    },
    getUserMe: async function() {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      this.loading = true;

      return axios.get(GlobalStore.APIEndpoint + "/user/me", {
        headers: headers,
      });
    },
  },
});
