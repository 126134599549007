<template>
  <div id="image-detail-modal">
    <modal name="image-detail-modal">
      <div class="modal-header">Image Details</div>
      <div class="modal-body">
        <div class="row" v-if="item">
          <div class="col-8 is-8">
            <form>
              <div class="row contact-row">
                <div class="column form-group">
                  <label for="name">Description</label>
                  <input
                    v-model="item.description"
                    class="form-control"
                    id="description"
                    type="description"
                    placeholder="Description"
                  />
                </div>
                <div class="column form-group" style="width:40%">
                  <label for="name">Alt Text</label>
                  <input
                    v-model="item.alt_text"
                    class="form-control"
                    id="alt_text"
                    type="alt_text"
                    placeholder="Alt Text"
                    width="100%"
                  />
                </div>
              </div>
              <div class="row contact-row">
                <div class="column form-group">
                  <label for="name">Style Code</label>
                  <input
                    v-model="item.sku.code"
                    class="form-control"
                    id="style_code"
                    type="style_code"
                    placeholder="Style Variation"
                  />
                </div>
                <div class="column form-group">
                  <label for="name">Style Variation</label>
                  <input
                    v-model="item.sku.variation"
                    class="form-control"
                    id="style_variation"
                    type="style_variation"
                    placeholder="Style Variation"
                  />
                </div>
              </div>
              <div class="row contact-row">
                <div class="column form-group">
                  <label for="item_type">Image type</label>
                  <select
                    v-model="item.item_type"
                    class="form-control font-size-sm"
                    id="item_type"
                  >
                    <option
                      class="item_type_option"
                      v-for="itemType in itemTypeList"
                      :key="itemType"
                      :value="itemType"
                      >{{ itemType }}</option
                    >
                  </select>
                </div>
                <div class="column form-group text-left">
                  <label for="is_archived">Archived</label>
                <input
                      v-model="item.is_archived"
                      class="form-control"
                      id="is_archived"
                      type="checkbox"
                    />
                  <!-- <label for="item_type">Archive status</label>
                  <dd id="rr-element">
                    <input
                      v-model="item.is_archived"
                      class="checkbox"
                      value="item.is_archived"
                      id="is_archived"
                      type="checkbox"
                    />
                    <label for="is_archived">Is archived</label> -->
                  <!-- </dd> -->
                  <!-- <div class="font-weight-light"><strong>Archived</strong> {{this.localCreatedTimestamp}}</div> -->
                </div>
              </div>
              <div class="form-group">
                <div class="font-weight-light">
                  <strong>Created:</strong> {{ this.localCreatedTimestamp }}
                </div>
                <div class="font-weight-light">
                  <strong>Created by:</strong> {{ this.createdByName }}
                </div>
                <div class="font-weight-light">
                  <strong>Updated:</strong> {{ this.localUpdatedTimestamp }}
                </div>
                <div class="font-weight-light">
                  <strong>Updated by:</strong> {{ this.updatedByName }}
                </div>
              </div>
              <div class="form-group">
                <div class="font-weight-light">
                    <strong>Image source:</strong> {{ this.localImageSource }}
                  </div>
              </div>
              <div class="form-group">
                <i class="far fa-file-image"></i>&nbsp;<a
                  :href="imageBlobEndpoint + item.url"
                  class="card-link"
                  target="_blank"
                  title="View image in a browser"
                  >Zoom image</a
                >
                <!-- <p class="card-text">Url: {{imageBlobEndpoint + item.url}}</p> -->
              </div>
              <div class="form-group">
                <i class="far fa-file-image"></i>&nbsp;<a
                  :href="imageBlobEndpoint + item.thumbnail_url"
                  class="card-link"
                  target="_blank"
                  title="View thumbnail in a browser"
                  >Zoom thumbnail</a
                >
                <!-- <p class="card-text">Url: {{imageBlobEndpoint + item.url}}</p> -->
              </div>
            </form>
          </div>
          <div class="col-4 is-4">
            <div
              class="medialibrary-image-details"
              :style="'background: url(' + imageBlobEndpoint + item.url + ')'"
            ></div>
            <div class="image-dimensions">
              {{ item.width }}px x {{ item.height }}px
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button v-on:click="ok()" class="btn btn-primary">Save</button>
        <button
          v-on:click="$modal.hide('image-detail-modal')"
          class="btn btn-secondary"
        >
          Cancel
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
//import axios from "axios";
import { GlobalStore } from "../../globalStore";
import { GlobalMethods } from "../../globalMethods";
//import { imageMethods } from '../../helpers/images'

export default {
  name: "image-detail-modal",
  data() {
    return {
      imageBlobEndpoint: GlobalStore.imageEndpoint,
      itemTypeList: [],
      //imagePath: '',
      //custGuid: GlobalStore.custGuid,
      //imageObj: { url: '', description: '', alt_text: ''}
    };
  },
  props: {
    title: String,
    item: null,
    modalName: String,
  },
  computed: {
    localImageSource: function() {
      if (this.item && this.item.source) {
        return "Imported from " + this.item.source;
      }
      return "Uploaded imaged.";
    },
    localCreatedTimestamp: function() {
      if (this.item && this.item.date_created) {
        return GlobalMethods.formatToLocalTime(this.item.date_created);
      }
      return "Unknown";
    },
    localUpdatedTimestamp: function() {
      if (this.item.updated_timestamp) {
        return GlobalMethods.formatToLocalTime(this.item.updated_timestamp);
      }
      return "Unknown";
    },
    createdByName: function() {
      if (this.item.created_by_name) {
        return this.item.created_by_name;
      }
      return "Unknown";
    },
    updatedByName: function() {
      if (this.item.updated_by_name) {
        return this.item.updated_by_name;
      }
      return "Unknown";
    },
    // itemTypes: function() {
    //   console.log("hit");
    //   return []; //GlobalMethods.getDisplayableItemTypes("image");
    // },
  },
  methods: {
    ok: function() {
      console.log("image Object:", this.item);
      this.$emit("saveDetails", this.item);
    },
  },
  components: {},
  async mounted() {
    console.log("** Image Modal Mounted **");
    this.itemTypeList = GlobalMethods.getDisplayableItemTypes("image");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Modal */
.v--modal-overlay {
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2000;
  opacity: 1;
}

.modal-header {
  font-size: 16pt;
  color: var(--primary);
}

.modal-body {
  margin-bottom: 70px;
  font-size: 10pt;
}

.modal-footer {
  position: absolute;
  bottom: 0px !important;
  width: 100%;
  display: inline-block;
  background-color: white;
}

.medialibrary-image-details {
  width: 100%;
  height: 240px;
  background-repeat: no-repeat !important;
  background-size: contain !important;
  background-position: center !important;
}

.image-dimensions {
  text-align: center;
  font-size: 9pt;
  margin-top: 5px;
}

#item_type {
  font-size: 16px;
  padding-left: 0.5;
}

.item_type_option {
  font-size: 12px;
  padding-left: 0.5;
}
#rr-element {
  white-space: nowrap;
}

#rr-element label {
  padding-left: 0.4em;
}
.contact-row {
  margin-left: 0px;
  width: 100%;
  
}

.contact-row .column {
  margin-right: 13px;
}

.contact-row input[type=checkbox], #active {
  transform: scale(0.5);
}

.contact-row select {
  display: block;
  font-size: 12px;
}

/* .checkbox {
  margin: 10 !important;
  transform: scale(1);
}
.checkbox_label_r {
  font-size: 12px;
  margin-left: 50q !important;
} */
</style>
