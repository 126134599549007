
import Vue from 'vue'
import axios from "axios";
import { GlobalStore } from '../globalStore'

  export const productMethods = new Vue({
    methods: {

    getProductList: async function(pageNo,statusFilter) {
       
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + GlobalStore.access_token
          }

          var status_filter = "";

          if (statusFilter=='Published') {
            status_filter = '&publish_status=published';
          }

          if (statusFilter=='Draft') {
            status_filter = '&publish_status=draft';
          }
      
          this.loading = true;
      
          return axios.get(GlobalStore.APIEndpoint + '/todaycx-admin/product?per_page=' + GlobalStore.defaultPageSize + '&page=' + pageNo + status_filter,
          {
            headers: headers
          });
    },
    getProductListForSearch: async function() {
       
      const headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer ' + GlobalStore.access_token
        }

        this.loading = true;

        return axios.get(GlobalStore.APIEndpoint + '/todaycx-admin/product?per_page=200',
        {
          headers: headers
        });
  },
    getProductItem: async function(product_guid ) {
       
      const headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': 'Bearer ' + GlobalStore.access_token
        }
    
        this.loading = true;
    
        return axios.get(GlobalStore.APIEndpoint + '/todaycx-admin/product/' + product_guid,
          {
            headers: headers
          });

  },
  deleteItem: async function(product_guid ) {
       
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + GlobalStore.access_token
      }
  
      this.loading = true;
  
      return axios.delete(GlobalStore.APIEndpoint + '/todaycx-admin/product/' + product_guid,
        {
          headers: headers
        });

}
    

    }
})