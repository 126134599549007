import Vue from "vue";
import axios from "axios";
import { GlobalStore } from "../globalStore";

export const imageMethods = new Vue({
  data() {
    return {};
  },
  methods: {
    getImages: async function(pageNo, archived, query, item_type) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      this.loading = true;

      if (!pageNo) {
        pageNo = 1;
      }
      //

      // return axios.get(GlobalStore.APIEndpoint + "/todaycx-admin/image?per_page=" + GlobalStore.defaultPageSize + "&page=" + pageNo + '&is_thumbnail=false', {
      //   headers: headers
      // });

      if (query != "") {
        query = (query ? query : "") + "*";

        let optional_filter = "";

        if (item_type != "All") {
          optional_filter = "&item_type=" + item_type;
        }

        return axios.get(
          GlobalStore.APIEndpoint +
            `/todaycx-admin/search/image?per_page=${
              GlobalStore.defaultPageSize
            }&page=${pageNo}&is_archived=${
              archived == true ? "true" : "false"
            }&query=${query}${optional_filter}`,
          {
            headers: headers,
          }
        );
      } else {
        var archived_filter = "";
        var query_filter = "";

        archived_filter = "&is_archived=" + (archived ? "true" : "false");

        if (query != "") {
          query_filter = "&query=" + query;
        }

        if (item_type != "All") {
          query_filter = "&item_type=" + item_type;
        }

        // return axios.get(
        //   GlobalStore.APIEndpoint +
        //     "/todaycx-admin/content?content_item_type=" +
        //     contentItemType +
        //     "&per_page=" +
        //     GlobalStore.defaultPageSize +
        //     "&page=" +
        //     pageNo +
        //     archived_filter +
        //     query_filter,
        //   {
        //     headers: headers,
        //   }
        // );

        return axios.get(
          GlobalStore.APIEndpoint +
            "/todaycx-admin/image?per_page=" +
            GlobalStore.defaultPageSize +
            "&page=" +
            pageNo +
            "&is_thumbnail=false" +
            archived_filter +
            query_filter,
          {
            headers: headers,
          }
        );
      }
    },
    getImagesByGUID: async function(image_guids) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      this.loading = true;
      var imageList = await axios.get(
        GlobalStore.APIEndpoint +
          "/todaycx-admin/image/?per_page=" +
          GlobalStore.defaultPageSize +
          "&image_guid=" +
          image_guids,
        {
          headers: headers,
        }
      );

      return imageList;
    },
    getImageURL: async function(image_guid) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      this.loading = true;

      return axios.get(GlobalStore.APIEndpoint + "/image/" + image_guid, {
        headers: headers,
      });
    },
    putImage: async function(image_guid, imageObj) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      axios.put(
        GlobalStore.APIEndpoint + "/todaycx-admin/image/" + image_guid,
        imageObj,
        {
          headers: headers,
        }
      );
    },
    deleteImage: async function(image_guid) {
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + GlobalStore.access_token,
      };

      // const requestBody = {
      //   "collection":collection,
      //   "pipelines":pipelines
      // };

      return axios.delete(
        GlobalStore.APIEndpoint + "/todaycx-admin/image/" + image_guid,
        {
          headers: headers,
        }
      );
    },
  },
});
