<template>
  <div class="card mb-3">
    <!--Start search filters and pagination -->
    <!-- <div v-if="view == 'List'" class="row">
      <div class="col-6">
        <div class="btn-group">
          <button
            class="btn dropdown-toggle mb-2 btn-light status-filter"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Status - {{ statusFilter }}
          </button>
          <div class="dropdown-menu">
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="setStatusFilter('All')"
              >All</a
            >
            <div class="dropdown-divider"></div>
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="setStatusFilter('Draft')"
              >Draft</a
            >
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="setStatusFilter('Published')"
              >Published</a
            >
          </div>
        </div>
      </div>
      <div class="col-6">
        <paginator :pagingInfo="pagingInfo" @selectPage="selectPage" />
      </div>
    </div> -->

    <!--End search filters and pagination -->
    <searchFilter
      v-if="custObj != '' && view == 'List'"
      :pagingInfo="pagingInfo"
      @selectPage="selectPage"
      @setStatusFilter="setStatusFilter"
      @setItemType="setItemType"
      @showExpired="showExpired"
      @showArchived="showArchived"
      @setSearchString="setSearchString"
      :willShowExpired="willShowExpired"
      :willShowArchived="willShowArchived"
      :custObj="custObj"
      listItemType="action_request"
    />
    <div v-if="this.contentLoading">
      <page-loader></page-loader>
    </div>
    <data-table
      :title="pageTitle"
      v-if="this.custObj.config && view == 'List'"
      :itemList="itemList"
      @addItem="addItem()"
      @editItem="editItem($event)"
      @deleteItem="confirmDelete($event)"
      idField="action_request_guid"
    ></data-table>
    <div
      v-if="view == 'List'"
      class="paging-stats"
      v-html="
        `Displaying ${current_page * defaultPageSize - defaultPageSize + 1}-${
          current_page * defaultPageSize > pagingInfo.total_count
            ? pagingInfo.total_count
            : current_page * defaultPageSize
        } out of ${pagingInfo.total_count} items.`
      "
    ></div>
    <to-do-item
      :sourceArray="sourceArray"
      :destinationArray="destinationArray"
      return="/toDo"
      :view="view"
      :title="`${view} ${pageTitle} Item`"
      v-if="selectedItem != null && (view == 'Add' || view == 'Edit')"
      :currentItem="selectedItem"
    ></to-do-item>
    <confirmation-modal
      modalName="item-delete-modal"
      :itemId="selectedItem"
      @onConfirm="deleteItem"
      title="Delete Item"
      bodyText="Are you sure you want to delete this item?"
      okText="Delete"
      cancelText="Cancel"
    ></confirmation-modal>
  </div>
</template>

<script>
import DataTable from "../components/DataTable";
import ToDoItem from "../components/ToDoItem";
import { actionMethods } from "../helpers/actions";
import { GlobalMethods } from "../globalMethods";
import { GlobalStore } from "../globalStore";
import ConfirmationModal from "../components/modals/ConfirmationModal";
//import { imageMethods } from '../helpers/images'
import SearchFilter from "../components/SearchFilter";
import pageLoader from "../components/pageLoader";

export default {
  name: "ActionRequest",
  data() {
    return {
      itemList: [],
      view: "List",
      selectedItem: null,
      sourceArray: [[], [], [], [], []],
      destinationArray: [[], [], [], [], []],
      pagingInfo: { current_page: 1 },
      current_page: 1,
      statusFilter: "Published",
      searchString: "",
      willShowExpired: true,
      willShowArchived: false,
      item_type: "All",
      contentLoading: false,
    };
  },
  props: {
    msg: String,
  },
  computed: {
    custObj: function() {
      return GlobalStore.custObj;
    },
    pageTitle: function() {
      var endpoint = "toDo";
      console.log(this.custObj.config.modules);
      var confElement = this.custObj.config.modules.find(
        (obj) => obj.admin_url == endpoint
      );
      return confElement.menuText;
    },
    defaultPageSize() {
      return GlobalStore.defaultPageSize;
    },
  },
  methods: {
    setSearchString: function(val) {
      console.log(val);
      this.searchString = val;
      this.getItems(1);
    },
    showExpired: function(val) {
      console.log(val);
      this.willShowExpired = val;
      this.getItems(this.current_page);
    },
    showArchived: function(val) {
      console.log(val);
      this.willShowArchived = val;
      this.getItems(this.current_page);
    },
    setItemType: function(type) {
      console.log(type);
      this.item_type = type;
      this.getItems(this.current_page);
    },
    selectPage(pageNo) {
      console.log(pageNo);
      this.pagingInfo.current_page = pageNo;
      this.current_page = pageNo;
      this.getItems(this.pagingInfo.current_page, this.statusFilter);
    },
    setStatusFilter: function(status) {
      this.statusFilter = status;
      this.getItems(this.current_page, status);
      console.log(status);
    },
    addItem: function() {
      this.$router.push("/toDo/add");
    },
    editItem: function(guid) {
      console.log(guid);
      //
      this.$router.push("/toDo/" + guid);
    },
    confirmDelete: function(guid) {
      event.stopPropagation();
      console.log(guid);
      this.selectedItem = guid;
      this.$modal.show("item-delete-modal");
    },
    deleteItem: function(guid) {
      //console.log(guid);
      actionMethods
        .deleteItem(guid)
        .then((resp) => {
          console.log(resp);
          //this.itemList = resp.data.data;
          this.$modal.hide("item-delete-modal");
          this.getItems(this.current_page, this.statusFilter);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getItems(pageNo) {
      var image_guid_array = [];
      this.contentLoading = true;
      actionMethods
        .getActionRequestList(
          pageNo,
          this.willShowArchived,
          this.searchString,
          this.item_type,
          this.statusFilter
        )
        .then((resp) => {
          this.pagingInfo = resp.data;
          this.pagingInfo["current_page"] = pageNo;
          resp.data.data.forEach((element) => {
            image_guid_array.push(element.images.hero_image);
            element.images.additional_images.forEach((ai) => {
              image_guid_array.push(ai);
            });
          });
          // imageMethods.getImagesByGUID(image_guid_array.join(',')).then(guidResp=> {
          //     console.log(guidResp.data.data);
          //     GlobalStore.customerImages=guidResp.data.data;

          // });
          this.itemList = resp.data.data;

          if (this.willShowExpired == false) {
            this.itemList = this.itemList.filter(
              (f) => f.display.expires >= new Date().toISOString()
            );
          }

          this.contentLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkView(_view) {
      // if (typeof _view === 'undefined') {
      //     this.view = 'List';
      //     this.getItems();
      //   } else if (_view == 'add') {
      //       this.view = 'Add';
      //     }
      //   else {
      //     this.view = 'Edit';
      //     //console.log(this.itemList);
      //     actionMethods.getActionRequestItem(_view).then(
      //       resp => {
      //         this.selectedItem = resp.data;
      //       }
      //     )
      //   }

      GlobalMethods.getCollectionSchema("action_request", () => {
        if (typeof _view === "undefined") {
          this.view = "List";
          this.getItems(this.current_page, this.statusFilter);
        } else {
          this.sourceArray = [[], [], [], [], []];
          this.destinationArray = [[], [], [], [], []];

          if (_view == "add") {
            this.view = "Add";

            //console.log(GlobalStore.apiSchemas);
            this.selectedItem =
              GlobalStore.apiSchemas["action_request"].emptyObject;
            console.log(this.selectedItem);
            this.selectedItem.display.expires = "2023-12-31T00:00:00+00:00";
            this.selectedItem.mode = "user";

            // Target Audience **
            GlobalMethods.getAudienceListData()[2].forEach((m) => {
              this.sourceArray[2].push(m);
            });

            GlobalMethods.getAudienceListData()[4].forEach((m) => {
              this.sourceArray[4].push(m);
            });
            /**********/
          } else {
            this.view = "Edit";
            var image_guid_array = [];
            this.selectedItem = null;
            actionMethods.getActionRequestItem(_view).then((resp) => {
              // **** Target Audience ****
              resp.data.display.roles.forEach((f) => {
                this.destinationArray[2].push({
                  code: f,
                  name: f,
                  selected: false,
                });
              });

              resp.data.display.stores.forEach((f) => {
                this.destinationArray[4].push({
                  code: f,
                  name: f,
                  selected: false,
                });
              });

              GlobalMethods.getAudienceListData(
                this.destinationArray
              )[2].forEach((m) => {
                this.sourceArray[2].push(m);
              });

              GlobalMethods.getAudienceListData(
                this.destinationArray
              )[4].forEach((m) => {
                this.sourceArray[4].push(m);
              });
              /**********/

              image_guid_array.push(resp.data.images.hero_image);
              resp.data.images.additional_images.forEach((ai) => {
                image_guid_array.push(ai);
              });

              // imageMethods.getImagesByGUID(image_guid_array.join(',')).then(guidResp=> {
              //   GlobalStore.customerImages=guidResp.data.data;

              //   console.log(this.selectedItem);
              // });

              this.selectedItem = resp.data;
            });
          }
        }
      });
    },
  },
  components: {
    DataTable,
    ToDoItem,
    ConfirmationModal,
    SearchFilter,
    pageLoader,
  },
  async mounted() {
    this.checkView(this.$route.params.view);
  },
  watch: {
    "$route.params.view"(currentview) {
      this.checkView(currentview);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card {
  padding: 30px;
}
.paging-stats {
  font-size: 10pt;
  width: 100%;
  text-align: right;
  font-weight: bold;
}
</style>
