<template>
  <div v-if="item">
    <h5>Images</h5>
    <hr />
    <h6>Hero Image</h6>
    <div class="hero-image">
      <tcx-image
        v-if="item.images.hero_image != ''"
        class="img-fluid"
        :imageSrc="item.images.hero_image_url"
        @onclick="openDetails(item.images.hero_image)"
        :aspectBlur="false"
      />
      <div
        v-if="item.images.hero_image_url != '' && item.images.hero_image_url != null"
        @click="confirmDelete(item.images.hero_image, 'hero')"
        class="delete-button"
      >
        <i class="fas fa-times-circle"></i>
      </div>
    </div>

    <div class="image-uploader">
      <!-- <image-uploader
      id="image1"
        :preview="false"
        capture="environment"
        :debug="1"
        doNotResize="gif"
        :autoRotate="true"
        outputFormat="verbose"
        @input="setImage($event,'hero')"
      >
        <label for="image1" slot="upload-label" class="upload-label">
          <div title="Upload Image" class="upload-button">+</div>
        </label>
      </image-uploader> -->
      <label
        for="image1"
        slot="upload-label"
        class="upload-label"
        @click="openMediaLibrary('hero')"
      >
        <div title="Upload Image" class="upload-button">+</div>
      </label>
    </div>

    <div v-if="item.content_item_type != 'best_store'">
      <h6 class="additional-images">Additional Images</h6>
      <!-- <img v-bind:src="`${imageEndpoint}/${getCustGuid()}/${aitem}`" alt="Card image cap"> -->

      <div
        :key="aindex"
        class="additional-image-container"
        v-for="(aitem, aindex) in getAdditionalImagesURL()"
      >
        <tcx-image
          class="img-fluid additional-image"
          :imageSrc="aitem"
          @onclick="openDetails(item.images.additional_images[aindex])"
        />
        <div
          @click="
            confirmDelete(item.images.additional_images[aindex], 'additional')
          "
          class="delete-button"
        >
          <i class="fas fa-times-circle"></i>
        </div>
      </div>

      <div class="image-uploader">
        <!-- <image-uploader
          id="image2"
          :preview="false"
          capture="environment"
          :debug="1"
          doNotResize="gif"
          :autoRotate="true"
          outputFormat="verbose"
          @input="setImage($event,'additional')"
        >
          <label for="image2" slot="upload-label" class="upload-label">
            <div title="Upload Image" class="upload-button">+</div>
          </label>
        </image-uploader> -->
        <label
          for="image2"
          slot="upload-label"
          class="upload-label"
          @click="openMediaLibrary('additional')"
        >
          <div title="Upload Image" class="upload-button">+</div>
        </label>
      </div>
    </div>
    <image-detail-modal
      :item="selectedImageItem"
      @saveDetails="saveImageItem"
    ></image-detail-modal>
    <confirmation-modal
      modalName="image-delete-modal"
      :itemId="selectedImageItemId"
      @onConfirm="deleteImageItem"
      title="Remove Image"
      bodyText="Are you sure you want to remove this image from the item?"
      okText="Remove"
      cancelText="Cancel"
    ></confirmation-modal>
    <media-library-modal
      @useImage="useImage"
      @addImage="addImage"
    ></media-library-modal>
  </div>
</template>

<script>
import { GlobalStore } from "../globalStore";
import tcxImage from "../components/tcxImage";
import { imageMethods } from "../helpers/images";
import ImageDetailModal from "../components/modals/ImageDetailModal";
import ConfirmationModal from "../components/modals/ConfirmationModal";
import MediaLibraryModal from "../components/modals/MediaLibraryModal";

export default {
  name: "image-upload-sidebar",
  data() {
    return {
      imageEndpoint: GlobalStore.imageEndpoint,
      selectedImageItemId: null,
      selectedImageItem: null,
      selectedImageType: null,
    };
  },
  props: {
    itemList: Array,
    title: String,
    idField: String,
    item: null,
  },
  computed: {
    filteredItemList: function() {
      return this.itemList;
    },
  },
  methods: {
    addImage: function(imageObj) {
      console.log(imageObj);
      this.$modal.hide("media-library-modal");
      if (this.selectedImageType == "hero") {
        this.item.images.hero_image = imageObj.images.hero_image;
        this.item.images.hero_image_url = imageObj.images.hero_image_url;
        this.item.images.thumbnail_image = imageObj.images.thumbnail_image;
        this.item.images.thumbnail_image_url =
          imageObj.images.thumbnail_image_url;
      } else {
        this.item.images.additional_images.push(imageObj.images.hero_image);
        this.item.images.additional_images_url.push(
          imageObj.images.hero_image_url
        );
      }
    },
    useImage: function(imageObj) {
      if (this.selectedImageType == "hero") {
        this.item.images.hero_image = imageObj.image_guid;
        this.item.images.hero_image_url = imageObj.url;

        if (imageObj.thumbnail_image_guid != null) {
          this.item.images.thumbnail_image = imageObj.thumbnail_image_guid;
          this.item.images.thumbnail_image_url = imageObj.thumbnail_url;
        } else {
          this.item.images.thumbnail_image = imageObj.image_guid;
          this.item.images.thumbnail_image_url = imageObj.url;
        }
      } else if (this.selectedImageType == "additional") {
        if (!this.item.images.additional_images_url) {
          this.item.images["additional_images_url"] = [];
        }
        this.item.images.additional_images.push(imageObj.image_guid);
        this.item.images.additional_images_url.push(imageObj.url);

        this.$forceUpdate();
      }
    },
    getAdditionalImagesURL: function() {
      return this.item.images.additional_images_url;
    },
    openMediaLibrary: function(type) {
      console.log(type);
      this.selectedImageType = type;
      this.$modal.show("media-library-modal");
    },

    saveImageItem: function() {
      imageMethods
        .putImage(this.selectedImageItem.image_guid, this.selectedImageItem)
        .then((resp) => {
          console.log(resp);
          this.$modal.hide("image-detail-modal");
        });
    },
    openDetails: function(imageitem) {
      console.log(imageitem);
      // console.log(GlobalStore.customerImages.length());
      // this.selectedImageItem = GlobalStore.customerImages.find(f => f.image_guid.trim() == imageitem.trim());
      // this.selectedItem = this.selectedImageItem
      // console.log(this.selectedItem);
      // console.log(this.selectedImageItem);
      // this.$modal.show('image-detail-modal');
    },
    getCustGuid: function() {
      return GlobalStore.custGuid;
    },
    editItem: function(guid) {
      this.$emit("editItem", guid);
    },
    addItem: function() {
      this.$emit("addItem");
    },
    confirmDelete: function(_item, _type) {
      console.log(_item, _type);
      this.selectedImageItemId = _item;
      this.selectedImageType = _type;
      this.$modal.show("image-delete-modal");
    },
    deleteImageItem(image_guid) {
      console.log(image_guid);

      this.$modal.hide("image-delete-modal");

      if (this.selectedImageType == "additional") {
        var removeIndex = this.item.images.additional_images
          .map(function(item) {
            return item;
          })
          .indexOf(image_guid);
        this.item.images.additional_images.splice(removeIndex, 1);
        this.item.images.additional_images_url.splice(removeIndex, 1);
      } else {
        this.item.images.hero_image = "";
        this.item.images.hero_image_url = "";
        this.item.images.thumbnail_image = "";
        this.item.images.thumbnail_image_url = "";
      }

      // imageMethods.deleteImage(image_guid).then(resp => {

      //   console.log(resp);

      //   GlobalStore.toastObj = this.$toasted.show(
      //       "Image Deleted",
      //       {
      //         theme: "outline",
      //         position: "bottom-center",
      //         duration: 3000,
      //         type: "success",
      //         fitToScreen: true,
      //         fullWidth: false
      //       }
      //   );
      //   //this.getItems(this.pagingInfo.current_page);
      // });
    },
  },
  async mounted() {
    //this.currentItem = this.item;
    console.log(this.currentItem);
  },
  components: {
    tcxImage,
    ImageDetailModal,
    ConfirmationModal,
    MediaLibraryModal,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img {
  max-width: inherit;
}

.additional-images {
  margin-top: 10px;
}

.additional-image {
  width: 100%;
}

.additional-image-container {
  width: 50%;
  display: inline-block;
}

.hero-image,
.additional-image-container {
  position: relative;
}

.delete-button {
  position: absolute;
  right: 7px;
  top: 2px;
  cursor: pointer;
}

.delete-button svg {
  background: white;
  border-radius: 50%;
  border: 1px;
  border-color: white;
  border-style: solid;
}

.upload-button {
  width: 100%;
  padding: 0px;
  font-size: 20pt;
  text-align: center;
  background-color: lightgrey;
  cursor: pointer;
}

.upload-label {
  width: 100%;
  cursor: pointer;
}
</style>
<style>
#image1,
#image2 {
  display: none !important;
}
</style>
