<template>
  <main class="main" id="top">
    <div class="container" data-layout="container">
      <nav class="navbar navbar-vertical navbar-expand-xl navbar-light">
        <div class="d-flex align-items-center">
          <div class="toggle-icon-wrapper">
            <button
              class="btn btn-link navbar-vertical-toggle"
              data-toggle="tooltip"
              data-placement="left"
              title="Toggle Navigation"
            >
              <span class="navbar-toggle-icon"
                ><span class="toggle-line"></span
              ></span>
            </button>
          </div>
          <a class="navbar-brand text-left" href="/dashboard">
            <div class="d-flex align-items-center py-3">
              <img :src="headerLogo" width="150" />
            </div>
          </a>
        </div>
        <div
          class="collapse navbar-collapse navbar-glass perfect-scrollbar scrollbar"
          id="navbarVerticalCollapse"
        >
          <admin-menu></admin-menu>
          <div class="px-3 px-xl-0 navbar-vertical-divider">
            <div class="version-number">v {{ versionNumber }}</div>
            <hr class="border-300 my-2" />
          </div>
        </div>
      </nav>
      <div class="content">
        <nav
          class="navbar navbar-light navbar-glass fs--1 font-weight-semi-bold row navbar-top sticky-kit navbar-expand"
        >
          <button
            class="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarVerticalCollapse"
            aria-controls="navbarVerticalCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggle-icon"
              ><span class="toggle-line"></span
            ></span></button
          ><a class="navbar-brand text-left ml-3" href="/dashboard">
            <div class="d-flex align-items-center">
              <img :src="headerLogo" width="150" />
            </div>
          </a>
          <div class="collapse navbar-collapse" id="navbarNavDropdown1">
            <ul class="navbar-nav align-items-center ml-auto">
              <!-- <li class="nav-item dropdown"><a class="nav-link px-0 notification-indicator notification-indicator-warning notification-indicator-fill" href="../e-commerce/shopping-cart.html"><span class="fas fa-shopping-cart fs-4" data-fa-transform="shrink-7"></span><span class="notification-indicator-number">1</span></a></li> -->
              <!-- <li class="nav-item dropdown"><a class="nav-link notification-indicator notification-indicator-primary px-0" id="navbarDropdownNotification" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="fas fa-bell fs-4" data-fa-transform="shrink-6"></span></a>
                <div class="dropdown-menu dropdown-menu-right dropdown-menu-card" aria-labelledby="navbarDropdownNotification">
                  <div class="card card-notification shadow-none" style="max-width: 20rem">
                    <div class="card-header">
                      <div class="row justify-content-between align-items-center">
                        <div class="col-auto">
                          <h6 class="card-header-title mb-0">Notifications</h6>
                        </div>
                        <div class="col-auto"><a class="card-link font-weight-normal" href="#">Mark all as read</a></div>
                      </div>
                    </div>
                    <div class="list-group list-group-flush font-weight-normal fs--1">
                      <div class="list-group-title">NEW</div>
                      <div class="list-group-item">
                        <a class="notification notification-flush bg-200" href="#!">
                          <div class="notification-avatar">
                            <div class="avatar avatar-2xl mr-3">
                              <img class="rounded-circle" src="/img/team/1-thumb.png" alt="" />

                            </div>
                          </div>
                          <div class="notification-body">
                            <p class="mb-1"><strong>Emma Watson</strong> replied to your comment : "Hello world 😍"</p>
                            <span class="notification-time"><span class="mr-1" role="img" aria-label="Emoji">💬</span>Just now</span>

                          </div>
                        </a>

                      </div>
                      <div class="list-group-item">
                        <a class="notification notification-flush bg-200" href="#!">
                          <div class="notification-avatar">
                            <div class="avatar avatar-2xl mr-3">
                              <div class="avatar-name rounded-circle"><span>AB</span></div>
                            </div>
                          </div>
                          <div class="notification-body">
                            <p class="mb-1"><strong>Albert Brooks</strong> reacted to <strong>Mia Khalifa's</strong> status</p>
                            <span class="notification-time"><span class="mr-1 fab fa-gratipay text-danger"></span>9hr</span>

                          </div>
                        </a>

                      </div>
                      <div class="list-group-title">EARLIER</div>
                      <div class="list-group-item">
                        <a class="notification notification-flush" href="#!">
                          <div class="notification-avatar">
                            <div class="avatar avatar-2xl mr-3">
                              <img class="rounded-circle" src="/img/icons/weather-sm.jpg" alt="" />

                            </div>
                          </div>
                          <div class="notification-body">
                            <p class="mb-1">The forecast today shows a low of 20&#8451; in California. See today's weather.</p>
                            <span class="notification-time"><span class="mr-1" role="img" aria-label="Emoji">🌤️</span>1d</span>

                          </div>
                        </a>

                      </div>
                    </div>
                    <div class="card-footer text-center border-top-0"><a class="card-link d-block" href="../pages/notifications.html">View all</a></div>
                  </div>
                </div>
              </li> -->

              <li class="nav-item dropdown">
                <div class="avatar" :title="getUsername()">
                  <div class="avatar__letters">{{ getInitials() }}</div>
                </div>
                <a
                  style="display: inline-block"
                  class="nav-link pr-0"
                  id="navbarDropdownUser"
                  href="#"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div class="avatar avatar-xl">
                    <img
                      src="/img/logos/exclamation-mark-white-cutout-small.png"
                      alt="Profile & logout"
                    />
                  </div>
                </a>
                <div
                  class="dropdown-menu dropdown-menu-right py-0"
                  aria-labelledby="navbarDropdownUser"
                >
                  <div class="bg-white rounded-soft py-2">
                    <!-- <a class="dropdown-item font-weight-bold text-warning" href="#!"><span class="fas fa-crown mr-1"></span><span>Go Pro</span></a>

                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#!">Set status</a>
                    <a class="dropdown-item" href="../pages/profile.html">Profile &amp; account</a>
                    <a class="dropdown-item" href="#!">Feedback</a>

                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="../pages/settings.html">Settings</a> -->
                    <a
                      style="cursor: pointer;"
                      class="dropdown-item"
                      @click="logout()"
                      >Logout</a
                    >
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </nav>
        <router-view></router-view>
        <footer>
          <!-- <div class="row no-gutters justify-content-between fs--1 mt-4 mb-3">
            <div class="col-12 col-sm-auto text-center">
              <p class="mb-0 text-600">Thank you for creating with Falcon <span class="d-none d-sm-inline-block">| </span><br class="d-sm-none" /> 2019 &copy; <a href="https://themewagon.com">Themewagon</a></p>
            </div>
            <div class="col-12 col-sm-auto text-center">
              <p class="mb-0 text-600">v2.3.1</p>
            </div>
          </div> -->
        </footer>
      </div>
    </div>
  </main>
</template>

<script>
import { GlobalStore } from "../globalStore";
import { GlobalMethods } from "../globalMethods";
import AdminMenu from "../components/AdminMenu";
export default {
  name: "portal-main",
  data() {
    return {
      searchString: "",
      versionNumber: GlobalStore.version,

      // selectedRating: 0
    };
  },
  props: {
    // rating: Object,
    // mode: String,
    // title: String
  },
  computed: {
    expiresIn: function() {
      const now = new Date();
      const secondsSinceEpoch = Math.round(now.getTime() / 1000);
      var secondsBeforeExpiry = GlobalStore.expires_at - secondsSinceEpoch;
      return secondsBeforeExpiry;
    },
    headerLogo: function() {
      if (GlobalStore.isAuthenticated == false) {
        return GlobalStore.imageEndpoint + "/todaycx/logo.png";
      } else {
        if (typeof GlobalStore.custObj.config == "undefined") {
          return GlobalStore.imageEndpoint + "/todaycx/logo.png";
        } else {
          return (
            GlobalStore.imageEndpoint + GlobalStore.custObj.images.logo_admin
          );
        }
      }
    },
  },
  methods: {
    getUsername: function() {
      if (GlobalStore.userMeObject) {
        return (
          GlobalStore.userMeObject.given_name +
          " " +
          GlobalStore.userMeObject.last_name +
          " (" +
          GlobalStore.userMeObject.login +
          ")"
        );
      }
    },
    getInitials: function() {
      if (GlobalStore.userMeObject) {
        return (
          GlobalStore.userMeObject.given_name.toString().substr(0, 1) +
          GlobalStore.userMeObject.last_name.toString().substr(0, 1)
        );
      }
    },
    doSearch: function() {
      console.log(this.searchString);
    },
    logout: function() {
      GlobalMethods.clearSession();
      GlobalStore.isAuthenticated = false;
      this.$emit("changeView", "landing");
      this.$router.push("/login");
    },
    // selectStar: function(idx) {
    //   this.selectedRating = idx;
    // },
    // submitRating: function() {
    //   this.$emit('submitRating', this.selectedRating);
    // }
  },
  components: {
    AdminMenu,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.version-number {
  font-size: 9pt;
  float: left;
  margin-top: 20px;
}

.avatar {
  /* Center the content */
  display: inline-block;
  vertical-align: middle;

  /* Used to position the content */
  position: relative;

  /* Colors */
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;

  /* Rounded border */
  border-radius: 50%;
  height: 35px;
  width: 35px;
  margin-right: 5px;
}

.avatar__letters {
  /* Center the content */
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 15px;
}
</style>
